import React, { useEffect, useState } from 'react';
import AppBarComponent from '../../components/AppBar';
import FooterDashboard from '../../components/FooterDashboard';
import CreateEvent from '../../components/CreateEventComponent'
import SliderCarousel from '../../components/SliderCarousel';
import SearchEvent from '../../components/SearchEvent';
import UpcomingEvent from '../../components/UpcomingEvent';
import PopularEvent from '../../components/PopularEvent';
// import CategoryEvent from '../../components/CategoryEvent';
import CategoryEventMob from '../../components/CategoryEventMob'
import SearchEventMob from '../../components/searchEventMob'
import { makeStyles } from '@material-ui/core/styles';

import {
    getUpcomingEvent,
    getPopularEvent,
    getOnlineEvent,
    getStoreEvent,
    getPublicEvent
} from './../../redux'
import { useDispatch } from 'react-redux';
import SearchedEvent from '../../components/SearchedEvent';
// import ArrowUpwardOutlinedIcon from '@material-ui/icons/ArrowUpwardOutlined';
// import Meta from '../../components/Util/MetaDecorator'
import { FaArrowCircleUp } from 'react-icons/fa';
import { Box } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    rootcatevent: {
        [theme.breakpoints.up('xs')]: {
            display: 'none'
        },
        [theme.breakpoints.up('sm')]: {
            display: 'block'
        },
        [theme.breakpoints.up('md')]: {
            display: 'block'
        },
        [theme.breakpoints.up('lg')]: {
            display: 'block'
        },
        [theme.breakpoints.up('xl')]: {
            display: 'block'
        }
    },
    rootcateventmob: {
        [theme.breakpoints.up('xs')]: {
            display: 'block'
        },
        [theme.breakpoints.up('sm')]: {
            display: 'none'
        },
        [theme.breakpoints.up('md')]: {
            display: 'none'
        },
        [theme.breakpoints.up('lg')]: {
            display: 'none'
        },
        [theme.breakpoints.up('xl')]: {
            display: 'none'
        }
    },
    rootcategoryvent: {
        [theme.breakpoints.up('xs')]: {
            display: 'block'
        },
        [theme.breakpoints.up('sm')]: {
            display: 'block'
        },
        [theme.breakpoints.up('md')]: {
            display: 'block'
        },
        [theme.breakpoints.up('lg')]: {
            display: 'block'
        },
        [theme.breakpoints.up('xl')]: {
            display: 'block'
        }
    }
}));


const HomePage = () => {
    const classes = useStyles();
    const [showScroll, setShowScroll] = useState(false)

    // dispatch action
    const dispatch = useDispatch()

    useEffect(() => {

        dispatch(getUpcomingEvent())
        dispatch(getPopularEvent())
        dispatch(getOnlineEvent())
        dispatch(getStoreEvent())
        dispatch(getPublicEvent())

        window.addEventListener('scroll', checkScrollTop)
        return function cleanup() {
            window.removeEventListener('scroll', checkScrollTop)
        }

    }, [dispatch]);


    const checkScrollTop = () => {

        if (window.pageYOffset > 400) {

            setShowScroll(true)
        } else if (window.pageYOffset <= 400) {

            setShowScroll(false)
        }
    };

    const scrollTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };


    return (
        <React.Fragment>
            {/* <Meta title="Eventories | Eventories.com" description="Eventories.com" /> */}
            <AppBarComponent />
            <SliderCarousel />
            <div className={classes.rootcatevent}>
                <SearchEvent />
                <SearchedEvent />
            </div>
            <div className={classes.rootcateventmob}>
                <SearchEventMob />
                <SearchedEvent />
            </div>
            {/* <PopularEvent /> */}
            <div className={classes.rootcategoryvent}>
                <CategoryEventMob />
            </div>
            <PopularEvent />
            <UpcomingEvent />
            <div>
                <CreateEvent />
            </div>

            <Box textAlign="right" >
                <FaArrowCircleUp className="scrollTop" onClick={scrollTop} style={{ height: 40, display: showScroll ? 'flex' : 'none', color: "#b285dfec" }} />
            </Box>
            <FooterDashboard />
        </React.Fragment>
    )
}

export default HomePage;