import {
    // register user email
    REGISTER_USER_REQUEST,
    REGISTER_USER_FAILED,
    REGISTER_USER_SUCCESS,
    // register user socmed
    REGISTER_USER_SOCMED_REQUEST,
    REGISTER_USER_SOCMED_FAILED,
    REGISTER_USER_SOCMED_SUCCESS,
    // login user 
    SEND_LOGINUSER_REQUEST,
    SEND_LOGINUSER_SUCCESS,
    SEND_LOGINUSER_FAILED,

    GET_PROFILE_REQUEST,
    GET_PROFILE_SUCCESS,
    GET_PROFILE_FAILED,

    INPUT_CAPTCHA

} from './AuthUserTypes'

const initialState = {
    loading: false,
    message: '',
    response_register_email: '',
    response_register_socmed: '',
    response_login: '',
    get_profile: '',
    captcha: ''

}

const AuthUserReducers = (state = initialState, action) => {
    switch (action.type) {
        case REGISTER_USER_REQUEST:
            return {
                ...state,
                loading: true
            }
        case REGISTER_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                response_register_email: action.payload
            }
        case REGISTER_USER_FAILED:
            return {
                ...state,
                message: action.payload,
                loading: false
            }
        case REGISTER_USER_SOCMED_REQUEST:
            return {
                ...state,
                loading: true
            }
        case REGISTER_USER_SOCMED_SUCCESS:
            return {
                ...state,
                loading: false,
                response_register_socmed: action.payload
            }
        case REGISTER_USER_SOCMED_FAILED:
            return {
                ...state,
                message: action.payload,
                loading: false
            }
        case SEND_LOGINUSER_REQUEST:
            return {
                ...state,
                loading: true
            }
        case SEND_LOGINUSER_SUCCESS:
            return {
                ...state,
                response_login: action.payload,
                loading: false,
                message: ''
            }
        case SEND_LOGINUSER_FAILED:
            return {
                ...state,
                message: action.payload,
                loading: false
            }
        case GET_PROFILE_REQUEST:
            return {
                ...state,
                loading: true
            }
        case GET_PROFILE_SUCCESS:
            return {
                ...state,
                get_profile: action.payload,
                loading: false,
                message: ''
            }
        case GET_PROFILE_FAILED:
            return {
                ...state,
                message: action.payload,
                loading: false
            }

        case INPUT_CAPTCHA:
            return {
                ...state,
                captcha: action.payload,
                loading: false
            }

        default: return state
    }
}

export default AuthUserReducers