import React from 'react';
import AppBarComponent from '../../components/AppBar';
import FooterDashboard from '../../components/FooterDashboard';
import {
    makeStyles,
    Container,
    Grid,
    Button,
    Box,
    Typography
} from '@material-ui/core';
import errorimg from './../../assets/img/errorpage.png'
import Meta from '../../components/Util/MetaDecorator'

const useStyles = makeStyles((theme) => ({
    rootdiv: {
        paddingTop: '270px',
        paddingBottom: '100px',
        [theme.breakpoints.up('xs')]: {
            paddingTop: '170px',
            paddingBottom: '120px',
        },
        [theme.breakpoints.up('sm')]: {
            paddingTop: '100px',
            paddingBottom: '70px',
        },
        [theme.breakpoints.up('md')]: {
            paddingTop: '200px',
            paddingBottom: '70px',
        },
        [theme.breakpoints.up('lg')]: {
            paddingTop: '270px',
            paddingBottom: '100px',
        },
        [theme.breakpoints.up('xl')]: {
            paddingTop: '270px',
            paddingBottom: '100px',
        },
    },
}))

const ErrorPage = () => {
    const classes = useStyles()

    return (
        <React.Fragment>
            <Meta title="Not Found | Eventories.com" description="Halaman tidak ditemukan, kembali ke Home" />

            <AppBarComponent />
            <Container maxWidth='sm' className={classes.rootdiv}>
                <Grid xs={12}>
                    <Box>
                        <img src={errorimg} alt='error' width='100%' />
                    </Box>
                    <Box textAlign='center' pt={3} >
                        <Typography variant='h4' style={{ fontWeight: 'bold', color: '#9e69d2' }}>
                            Whoops, lost in space?
                        </Typography>
                    </Box>
                    <Box textAlign='center' pt={2}>
                        The page you're looking for isn't found. <br />
                        We suggest you back to home.
                    </Box>
                    <Box textAlign='center' pt={2}>
                        <a href='/'>
                            <Button variant='contained' size='medium' style={{ backgroundColor: '#9e69d2', color: '#fff' }} >
                                <Box style={{ textTransform: 'capitalize' }}>Back to Home</Box>
                            </Button>
                        </a>
                    </Box>
                </Grid>
            </Container>
            <FooterDashboard />
        </React.Fragment>
    )
}

export default ErrorPage;