import React from 'react';
import AppBarUser from '../../components/AppBarUser';
import ProfileDetailComponent from '../../components/DashboardUser/ProfileDetail/ProfileDetail'
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Container } from '@material-ui/core';
import MenuSideUser from '../../components/DashboardUser/MenuSideUser';
import Meta from '../../components/Util/MetaDecorator'

const useStyles = makeStyles((theme) => ({
    menuSide: {

    },

}));



const ProfileDetail = () => {
    // document.title = "Detail Profil | Eventories.com "
    const classes = useStyles();

    return (
        <React.Fragment>
            <Meta title="Detail Profil | Eventories.com" description="DetaiL Profil Saya" />

            <AppBarUser />
            <Container maxWidth='lg'>
                <Grid container spacing={2}>
                    <Grid item lg={2} md={2} className={classes.menuSide} >
                        <MenuSideUser />

                    </Grid>
                    <Grid item lg={10} md={10} sm={12} xs={12}>

                        <ProfileDetailComponent />

                    </Grid>

                </Grid>
            </Container>
        </React.Fragment>
    )
}

export default ProfileDetail;