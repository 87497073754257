import Axios from 'axios'
import { API } from '../../config'

import {
    POST_PURCHASE_TICKET_REQUEST,
    POST_PURCHASE_TICKET_FAILED,
    POST_PURCHASE_TICKET_SUCCESS,

    GET_PURCHASE_TICKET_REQUEST,
    GET_PURCHASE_TICKET_FAILED,
    GET_PURCHASE_TICKET_SUCCESS,

    GET_PENDING_TICKET_REQUEST,
    GET_PENDING_TICKET_FAILED,
    GET_PENDING_TICKET_SUCCESS

} from './PurchaseTicketTypes'
import jwt_decode from 'jwt-decode'

import * as SecureLs from 'secure-ls'

const ls = new SecureLs();

const CheckToken = () => {
    let rtoken = ls.get('@_rtknid').data
    let tokenn = ls.get('@_tknid').data
    if (tokenn) {
        var jwt = jwt_decode(tokenn);
        var today = new Date().getTime();
        var jwt_exp = jwt.exp * 1000 - (1000 * 20); // sebelum 20 detik

        if (jwt_exp < today) {
            // request
            let formdata = new FormData()
            let options = {
                headers: {
                    Authorization: `Bearer ${tokenn}`
                }
            }
            formdata.append("refresh_token", rtoken)
            const refreshToken = Axios.post(`${API}/refresh_token`, formdata, options, null)
            if (refreshToken) {
                ls.set('@_tknid', { data: refreshToken.data.data.token })
                ls.set('@_rtknid', { data: refreshToken.data.data.refreshToken })
            }
        } else {
            console.log("tes")

        }
    }
}

const purchaseTicketRequest = () => {
    return {
        type: POST_PURCHASE_TICKET_REQUEST
    }
}

const purchaseTicketSuccess = (data) => {
    return {
        type: POST_PURCHASE_TICKET_SUCCESS,
        payload: data
    }
}

const purchaseTicketFailed = (message) => {
    return {
        type: POST_PURCHASE_TICKET_FAILED,
        payload: message
    }
}

export const purchaseTicket = (input) => {


    // console.log(input, 'data input from ticket')

    let inputbody = {
        seat_detail_id: input.seat_detail_id,
        user_id: localStorage.getItem('id'),
        seat_number: input.seat_number,
        ms_payment_id: input.ms_payment_id,
    }

    // console.log(inputbody, 'data inputbody ')

    return (
        async (dispatch) => {
            try {
                const testing = await CheckToken()

                dispatch(purchaseTicketRequest())
                let token = ls.get('@_tknid').data

                let options = {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
                const res = await Axios.post(`${API}/transaction/ticket`, inputbody, options, null)
                if (res.data) {
                    dispatch(purchaseTicketSuccess(res.data.token))

                }
            } catch (error) {
                ls.removeAll()
                localStorage.clear()
                sessionStorage.clear()
                window.location.href = "/"
            }
            // dispatch(purchaseTicketRequest())
            // let options = {
            //     headers: {
            //         Authorization: `Bearer ${token}`
            //     }
            // }

            // await Axios.post(`${API}/transaction/ticket`, inputbody, options, null)
            //     .then((res) => {
            //         // console.log('purchase ticket', res)   
            //         // let token = res.data.token      

            //         // snap.pay(token, {
            //         //     onSuccess: function ()
            //         // })
            //         // console.log('loginheaders', res.response)                
            //         dispatch(purchaseTicketSuccess(res.data.token))
            //     }).catch((err) => {
            //         dispatch(purchaseTicketFailed(err))
            //         if (err.response.status === 401) {
            //             let tokenn = ls.get('@_tknid').data
            //             let rtokenn = ls.get('@_rtknid').data
            //             let formdata = new FormData()
            //             let options = {
            //                 headers: {
            //                     Authorization: `Bearer ${tokenn}`
            //                 }
            //             }
            //             formdata.append("refresh_token", rtokenn)
            //             Axios.post(`${API}/refresh_token`, formdata, options, null)
            //                 .then(res => {
            //                     ls.set('@_tknid', { data: res.data.data.token })
            //                     ls.set('@_rtknid', { data: res.data.data.refreshToken })
            //                 }).catch(err => {
            //                 })
            //         }
            //         // console.log(err)

            //     })
        }
    )
}


// ticket issued
const ticketIssuedRequest = () => {
    return {
        type: GET_PURCHASE_TICKET_REQUEST
    }
}

const ticketIssuedSuccess = (data) => {
    return {
        type: GET_PURCHASE_TICKET_SUCCESS,
        payload: data
    }
}

const ticketIssuedFailed = (message) => {
    return {
        type: GET_PURCHASE_TICKET_FAILED,
        payload: message
    }
}

export const getticketIssued = (page) => {
    // let token = ls.get('@_tknid').data

    let pages = page

    // console.log(pages, 'ini page dari dispatch')

    return (
        async (dispatch) => {
            try {
                const testing = await CheckToken()
                dispatch(ticketIssuedRequest())
                let token = ls.get('@_tknid').data
                let options = {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
                const res = await Axios.get(`${API}/ticket?ticket_status_name=issued`, options, null)
                if (res.data) {
                    dispatch(ticketIssuedSuccess(res.data))

                }
            } catch (error) {
                ls.removeAll()
                localStorage.clear()
                sessionStorage.clear()
                window.location.href = "/"
            }
            // dispatch(ticketIssuedRequest())
            // let options = {
            //     headers: {
            //         Authorization: `Bearer ${token}`
            //     }
        }
        // &page=${pages}&limit=8
        // Axios.get(`${API}/ticket?ticket_status_name=issued`, options, null)
        //     .then((res) => {
        //         dispatch(ticketIssuedSuccess(res.data))
        //         // console.log(res.data)
        //     }).catch((err) => {
        //         dispatch(ticketIssuedFailed(err))
        //         // console.log("ini", err)
        //         // console.log("ini response", err.response)
        //         let tokenn = ls.get('@_tknid').data
        //         let rtokenn = ls.get('@_rtknid').data
        //         let formdata = new FormData()
        //         let options = {
        //             headers: {
        //                 Authorization: `Bearer ${tokenn}`
        //             }
        //         }
        //         formdata.append("refresh_token", rtokenn)
        //         Axios.post(`${API}/refresh_token`, formdata, options, null)
        //             .then(res => {
        //                 ls.set('@_tknid', { data: res.data.data.token })
        //                 ls.set('@_rtknid', { data: res.data.data.refreshToken })
        //             }).catch(err => {
        //                 // console.log(err, 'di tok')
        //             })

        //     })
        // }
    )
}

// export const getLikedEvents = (page) => {
//     let token = ls.get('@_tknid').data
//     let pages = page
//     return(
//         (dispatch)=>{

//         }
//     )

// }

// ticket pending
const ticketPendingRequest = () => {
    return {
        type: GET_PENDING_TICKET_REQUEST
    }
}

const ticketPendingSuccess = (data) => {
    return {
        type: GET_PENDING_TICKET_SUCCESS,
        payload: data
    }
}

const ticketPendingFailed = (message) => {
    return {
        type: GET_PENDING_TICKET_FAILED,
        payload: message
    }
}

export const getTicketPending = (page) => {

    let pages = page

    // console.log(pages, 'ini page dari dispatch')

    return (
        async (dispatch) => {
            try {
                const testing = await CheckToken()
                let token = ls.get('@_tknid').data

                dispatch(ticketPendingRequest())
                let options = {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
                await Axios.get(`${API}/ticket?ticket_status_name=pending&page=${pages}&limit=8`, options, null)
                    .then((res) => {
                        // console.log('response ticket', res.data)
                        dispatch(ticketPendingSuccess(res.data))
                    }).catch((err) => {
                        dispatch(ticketPendingFailed(err))
                        // console.log(err)
                    })
            } catch (error) {
                // console.log('errornya disini', error)
            }

        }
    )
}

// let options = {
//     headers: {                    
//         Authorization: `Bearer ${token}`
//     }
// }
// Axios.get(`${API}/ticket?ticket_status_name=pending&page=${pages}&limit=8`, options, null )
// .then((res) => {
//     // console.log('ISSUED ticket', res)                
//     console.log('response', res.data)                
//     dispatch(ticketPendingSuccess(res.data))             
// }).catch((err) => {
//     dispatch(ticketPendingFailed(err))
//     // console.log(err)

// })