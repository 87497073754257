import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(theme => ({
    root: {
        marginBottom: '100px',
        marginTop: '80px'
    },
    rootimg: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    imgstyle: {
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        borderRadius: '10px',

    },
    gridList: {
        width: '100%',
        height: 'fit-content',
    },
    nameEvent: {
        color: '#707070',
        textTransform: 'capitalize',
        textAlign: 'center',
        paddingTop: '20px',
        [theme.breakpoints.up('xs')]: {
            fontSize: '15px',
            fontWeight: 600,
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '20px',
            fontWeight: 600,
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '20px',
            fontWeight: 600,
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '28px',
            fontWeight: 700,
        },
        [theme.breakpoints.up('xl')]: {
            fontSize: '28px',
            fontWeight: 700,
        }
    },
    borderBox: {
        [theme.breakpoints.up('md')]: {
            borderRight: '1px solid #eeee'
        },
        [theme.breakpoints.up('lg')]: {
            borderRight: '1px solid #eeee'
        },
        [theme.breakpoints.up('xl')]: {
            borderRight: '1px solid #eeee'

        }
    },

    titleBox: {
        color: "#39364F",
        [theme.breakpoints.up('xs')]: {
            fontSize: '10px',
            paddingTop: '10px',
            paddingBottom: '5px',

        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '10px',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '10px',
            textAlign: 'center',

        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '13px',
            textAlign: 'center',

        },
        [theme.breakpoints.up('xl')]: {
            fontSize: '13px',
            textAlign: 'center',


        }
    },
    captionBox: {
        color: "#39364F",

        [theme.breakpoints.up('xs')]: {
            fontSize: '10px',
            fontWeight: 700,
            color: '#707070',
            paddingRight: '8px',
            paddingLeft: '8px',
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '10px',
            fontWeight: 700,
            color: '#707070',
            paddingRight: '8px',
            paddingLeft: '8px',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '13px',
            fontWeight: 700,
            color: '#707070',
            textAlign: 'center'
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '13px',
            fontWeight: 700,
            color: '#707070',
            textAlign: 'center'
        },
        [theme.breakpoints.up('xl')]: {
            fontSize: '15px',
            fontWeight: 700,
            color: '#707070',
            textAlign: 'center'
        }
    },

    boxInside: {
        backgroundColor: 'blue',
        borderRadius: '10px',
        position: 'absolute',
        [theme.breakpoints.up('xs')]: {
            right: 0,
            top: 100
        },
        [theme.breakpoints.up('sm')]: {
            right: 0,
            top: 450
        },
        [theme.breakpoints.up('md')]: {
            right: 0,
            top: 550
        },
        [theme.breakpoints.up('lg')]: {
            right: 0,
            top: 600
        },

    },

    buttonticket: {
        borderRadius: '30px',
        paddingLeft: '40px',
        paddingRight: '40px',
        color: '#2dbded',
        backgroundColor: '#fff'
    },

    paperTiket: {
        height: 'fit-content',
        backgroundColor: '#9e69d2',
        // border: '1px solid #E6D7F1'
    }
}));
