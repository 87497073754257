export const POST_PURCHASE_TICKET_REQUEST = 'POST_PURCHASE_TICKET_REQUEST'
export const POST_PURCHASE_TICKET_FAILED = 'POST_PURCHASE_TICKET_FAILED'
export const POST_PURCHASE_TICKET_SUCCESS = 'POST_PURCHASE_TICKET_SUCCESS'

export const GET_PURCHASE_TICKET_REQUEST = 'GET_PURCHASE_TICKET_REQUEST'
export const GET_PURCHASE_TICKET_FAILED = 'GET_PURCHASE_TICKET_FAILED'
export const GET_PURCHASE_TICKET_SUCCESS = 'GET_PURCHASE_TICKET_SUCCESS'

export const GET_PENDING_TICKET_REQUEST = 'GET_PENDING_TICKET_REQUEST'
export const GET_PENDING_TICKET_FAILED = 'GET_PENDING_TICKET_FAILED'
export const GET_PENDING_TICKET_SUCCESS = 'GET_PENDING_TICKET_SUCCESS'
