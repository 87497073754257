import {
    // GET DATAO UPCOMING EVENT
    GET_MS_PAYMENT_REQUEST,
    GET_MS_PAYMENT_FAILED,
    GET_MS_PAYMENT_SUCCESS
        
} from './MsPaymentTypes'

const initialState = {
    loading: false,
    message: '',
    ms_payment: []

}
 
const msPaymentReducers = ( state = initialState, action ) => {
    switch(action.type) {
        case GET_MS_PAYMENT_REQUEST:
            return {
                ...state,
                loading: true
            }
        case GET_MS_PAYMENT_FAILED:
            return {
                ...state,
                loading: false,
                message: action.payload                
            }
        case GET_MS_PAYMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                ms_payment: action.payload
            }             
        
        default: return state
    }
}

export default msPaymentReducers