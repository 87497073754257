import {
    // GET DATA UPCOMING EVENT
    GET_SEARCHED_ALL_EVENT_REQUEST,
    GET_SEARCHED_ALL_EVENT_FAILED,
    GET_SEARCHED_ALL_EVENT_SUCCESS,
    GET_SEARCHED_ONLINE_EVENT_SUCCESS,
    GET_SEARCHED_STORE_EVENT_SUCCESS,
    GET_SEARCHED_PUBLIC_EVENT_SUCCESS,
    KLIK_SEARCH_TO_SHOW_CONTENT,
    GET_FILTER_SEARCHED,
    GET_SEARCHED_BY_KEYWORD,
    KLIK_TO_SHOW_SEARCHED_BY_KEYWORD,
    GET_EVENT_BY_TAG,
    KLIK_TO_SHOW_SEARCHED_BY_TAG
} from './SearchedTypes'

const initialState = {
    loading: false,
    message: '',
    searched_all_event: null,
    searched_online_event: null,
    searched_store_event: null,
    searched_public_event: null,
    searched_keyword_event: null,
    showbykey: '',
    event_by_tag: null,
    showbytag: '',
    show: false,
    last_page: 0,
    last_page_keyword: 0,
    last_page_tag: 0,
    category: '',
    kota: '',
    date: '',
    load: false


}

const SearchedEventReducers = (state = initialState, action) => {
    switch (action.type) {
        case GET_SEARCHED_ALL_EVENT_REQUEST:
            return {
                ...state,
                loading: true
            }
        case GET_SEARCHED_ALL_EVENT_FAILED:
            return {
                ...state,
                loading: false,
                message: action.payload
            }
        case GET_SEARCHED_ALL_EVENT_SUCCESS:
            return {
                ...state,
                loading: false,
                searched_all_event: action.payload.data,
                last_page: action.payload.last_page
            }
        case GET_FILTER_SEARCHED:
            return {
                ...state,
                loading: false,
                category: action.payload.category,
                kota: action.payload.kota,
                date: action.payload.date
            }
        case GET_SEARCHED_ONLINE_EVENT_SUCCESS:
            return {
                ...state,
                loading: false,
                searched_online_event: action.payload
            }
        case GET_SEARCHED_STORE_EVENT_SUCCESS:
            return {
                ...state,
                loading: false,
                searched_store_event: action.payload
            }
        case GET_SEARCHED_PUBLIC_EVENT_SUCCESS:
            return {
                ...state,
                loading: false,
                searched_public_event: action.payload
            }
        case KLIK_SEARCH_TO_SHOW_CONTENT:
            return {
                ...state,
                show: action.payload
            }
        case GET_SEARCHED_BY_KEYWORD:
            return {
                ...state,
                searched_keyword_event: action.payload.data,
                last_page_keyword: action.payload.last_page

            }
        case KLIK_TO_SHOW_SEARCHED_BY_KEYWORD:
            return {
                ...state,
                showbykey: action.payload
            }

        case GET_EVENT_BY_TAG:
            return {
                ...state,
                event_by_tag: action.payload.data,
                last_page_tag: action.payload.last_page

            }
        case KLIK_TO_SHOW_SEARCHED_BY_TAG:
            return {
                ...state,
                showbytag: action.payload
            }

        case "KLIK_UNSEARCH_TO_SHOW_CONTENT":
            return state

        default: return state
    }
}

export default SearchedEventReducers