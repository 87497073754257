import React, { useState } from 'react';
import AppBar from '../../components/AppBar'
import FooterBar from '../../components/FooterDashboard'
import Meta from '../../components/Util/MetaDecorator'
import BG from '../../assets/img/events.png'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import {
    Box, Grid, Paper, Link
} from '@material-ui/core';
import Grow from '@material-ui/core/Grow';
import Fade from '@material-ui/core/Fade';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';


const Syaratketentuan = () => {

    const [q1, setq1] = useState(false);
    const [q2, setq2] = useState(false);
    const [q3, setq3] = useState(false);
    const [q4, setq4] = useState(false);
    const [q5, setq5] = useState(false);


    return (
        <React.Fragment>

            <Meta title="Panduan dan Informasi Dasar mengenai Eventories | Eventories.com " description="Selamat datang di situs Eventories.com yang dimiliki oleh PT Exel Integrasi Solusindo, selaku pemilik brand EVENTORIES. PT Exel Integrasi Solusindo adalah perseroan terbatas yang bergerak di bidang teknologi" />

            <AppBar />
            <Box >
                <Box className="panduan">
                    <Box textAlign="center">
                        <img src={BG} alt="img" width="30%" />
                    </Box>
                    <Box pt={3} className="title-panduan">Panduan dan Informasi</Box>

                    <Grid container space={2} justify="space-around" alignContent="center" alignItems="center" >
                        {/* <Grid item xl={7} lg={7} md={7} sm={12} xs={12} style={{ paddingRight: '2%' }}> */}
                        <Grid item >

                            <Paper elevation={2} style={{ marginBottom: '20px' }} width="100%">
                                <Box p={2} style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                        setq1(!q1)
                                        setq2(false)
                                        setq3(false)
                                        setq4(false)
                                        setq5(false)
                                    }}>
                                    <Grid container alignItems="center">
                                        <Grid item xl={11} lg={11} md={11} sm={11} xs={11}>
                                            <Box pl={2} className="question">
                                                Event apa saja yang dapat menggunakan platform Eventories.com?
                                           </Box>
                                        </Grid>
                                        <Grid item xl={1} lg={1} md={1} sm={1} xs={1}>
                                            {
                                                q1 ?
                                                    <ExpandLessIcon style={{ color: '#9E69D2' }} /> :
                                                    <KeyboardArrowDownIcon className="icon" style={{ color: '#9E69D2' }} />
                                            }
                                        </Grid>
                                    </Grid>
                                    {
                                        q1 ?
                                            <Grow direction="down" in={q1}>
                                                <Box pl={2} pt={2} className="answer">
                                                    Eventories.com dapat melayani semua jenis acara mulai dari konser maupun event berskala kecil hingga besar (online, offline, webinar, etc). Tidak terbatas pada event, platform kami juga dapat membantu menyebarkan promo produk & servis Anda yang bias Anda bagikan melalui kupon, kode promo, dan lain sebagainya.
                                            </Box>
                                            </Grow>
                                            :
                                            null
                                    }
                                </Box>

                            </Paper>
                            <Paper elevation={2} style={{ marginBottom: '20px' }}>
                                <Box p={2} style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                        setq2(!q2)
                                        setq1(false)
                                        setq3(false)
                                        setq4(false)
                                        setq5(false)
                                    }}>
                                    <Grid container alignItems="center">
                                        <Grid item xl={11} lg={11} md={11} sm={11} xs={11}>
                                            <Box pl={2} className="question">
                                                Bagaimana cara mendaftarkan acara/promo saya di Eventories.com?
                                            </Box>
                                        </Grid>
                                        <Grid item xl={1} lg={1} md={1} sm={1} xs={1}>
                                            {
                                                q2 ?
                                                    <ExpandLessIcon style={{ color: '#9E69D2' }} /> :
                                                    <KeyboardArrowDownIcon className="icon" style={{ color: '#9E69D2' }} />
                                            }
                                        </Grid>
                                    </Grid>
                                    {
                                        q2 ?
                                            <Grow direction="down" in={q2}>
                                                <Box pl={2} pt={2} className="answer">
                                                    Jika Anda sudah siap untuk menggunakan servis kami, Anda perlu membuat akun di
                                                    <Link href="www.mediacartz.com" target="_black" > www.mediacartz.com</Link> terlebih dahulu. Tim kami siap memandu Anda <span style={{ fontWeight: "bold" }} >via email: cs@mediacartz.com</span> | <span style={{ fontWeight: "bold" }} >Call/Whatsapp: +62 813 8888 9228</span>
                                                </Box>
                                            </Grow>
                                            :
                                            null
                                    }
                                </Box>
                            </Paper>
                            <Paper elevation={2} style={{ marginBottom: '20px' }}>
                                <Box p={2} style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                        setq3(!q3)
                                        setq2(false)
                                        setq1(false)
                                        setq4(false)
                                        setq5(false)
                                    }}>
                                    <Grid container alignItems="center">
                                        <Grid item xl={11} lg={11} md={11} sm={11} xs={11}>
                                            <Box pl={2} className="question">
                                                Bisakah Eventories.com membagikan acara saya yang tidak berbayar?
                                            </Box>
                                        </Grid>
                                        <Grid item xl={1} lg={1} md={1} sm={1} xs={1}>
                                            <Grid item xl={1} lg={1} md={1} sm={1} xs={1}>
                                                {
                                                    q3 ?
                                                        <ExpandLessIcon style={{ color: '#9E69D2' }} /> :
                                                        <KeyboardArrowDownIcon className="icon" style={{ color: '#9E69D2' }} />
                                                }
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    {
                                        q3 ?
                                            <Grow direction="down" in={q3}>
                                                <Box pl={2} pt={2} className="answer">
                                                    Tentu bisa, akan ada formulir yang bisa Anda isi untuk memuat informasi tentang acara anda, mulai dari harga tiket (berbayar atau gratis), jumlah kuota tiket, dan detail informasi lainnya.
                                            </Box>
                                            </Grow>
                                            :
                                            null
                                    }
                                </Box>
                            </Paper>
                            <Paper elevation={2} style={{ marginBottom: '20px' }}>
                                <Box p={2} style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                        setq4(!q4)
                                        setq1(false)
                                        setq2(false)
                                        setq3(false)
                                        setq5(false)
                                    }
                                    }>
                                    <Grid container alignItems="center">
                                        <Grid item xl={11} lg={11} md={11} sm={11} xs={11}>
                                            <Box pl={2} className="question">
                                                Apakah Eventories.com menyediakan metode pembayaran yang mudah?</Box>
                                        </Grid>
                                        <Grid item xl={1} lg={1} md={1} sm={1} xs={1}>
                                            <Grid item xl={1} lg={1} md={1} sm={1} xs={1}>
                                                {
                                                    q4 ?
                                                        <ExpandLessIcon style={{ color: '#9E69D2' }} /> :
                                                        <KeyboardArrowDownIcon className="icon" style={{ color: '#9E69D2' }} />
                                                }
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    {
                                        q4 ?
                                            <Grow direction="down" in={q4}>
                                                <Box pl={2} pt={2} className="answer" >
                                                    Ada berbagai macam metode pembayaran untuk mendapatkan tiket melalui Eventories.com yaitu transfer bank via ATM/ebanking, kartu kredit dan dompet digital (Gopay, Ovo, Dana, LinkAja, dll).
                                            </Box>
                                            </Grow>
                                            :
                                            null
                                    }
                                </Box>
                            </Paper>
                            <Paper elevation={2} style={{ marginBottom: '20px' }}>
                                <Box p={2} style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                        setq5(!q5)
                                        setq2(false)
                                        setq3(false)
                                        setq4(false)
                                        setq1(false)
                                    }}>
                                    <Grid container alignItems="center">
                                        <Grid item xl={11} lg={11} md={11} sm={11} xs={11}>
                                            <Box pl={2} className="question">
                                                Apakah Eventories.com dapat membantu acara saya untuk meningkatkan penjualan tiketnya?
                                            </Box>
                                        </Grid>
                                        <Grid item xl={1} lg={1} md={1} sm={1} xs={1}>
                                            {
                                                q5 ?
                                                    <ExpandLessIcon style={{ color: '#9E69D2' }} /> :
                                                    <KeyboardArrowDownIcon className="icon" style={{ color: '#9E69D2' }} />
                                            }
                                        </Grid>
                                    </Grid>
                                    {
                                        q5 ?
                                            <Grow direction="down" in={q5}>
                                                <Box pl={2} pt={2} className="answer">
                                                    Dengan senang hati, ada beragam servis advertising yang kami sediakan untuk Anda di<Link href="www.mediacartz.com" target="_black" > www.mediacartz.com</Link>. Segera hubungi kami <span style={{ fontWeight: "bold" }} >via email: cs@mediacartz.com</span> | <span style={{ fontWeight: "bold" }} >Call/Whatsapp: +62 813 8888 9228</span>
                                                </Box>
                                            </Grow>
                                            :
                                            null
                                    }
                                </Box>
                            </Paper>

                        </Grid>
                        {/* <Grid item xl={5} lg={5} md={5} sm={12} xs={12}>
                            <img src={BG} alt="img" width="100%" />
                        </Grid> */}
                    </Grid>
                </Box>

                <Box>
                    <FooterBar />
                </Box>

            </Box>
        </React.Fragment>
    )

}
export default Syaratketentuan;












{/* <div>
                                <input type="checkbox" id="question1" name="q" className="questions" />
                                <div htmlFor="question1" className="plus">+</div>
                                <label htmlFor="question1" className="question">
                                    Event apa saja yang dapat menggunakan platform Eventories.com?
                                    </label>
                                <div className="answers">
                                    <p>Eventories.com dapat melayani semua jenis acara mulai dari konser maupun event berskala kecil hingga besar (online, offline, webinar, etc). Tidak terbatas pada event, platform kami juga dapat membantu menyebarkan promo produk & servis Anda yang bias Anda bagikan melalui kupon, kode promo, dan lain sebagainya. </p>
                                </div>
                            </div>

                            <div>
                                <input type="checkbox" id="question2" name="q" className="questions" />
                                <div className="plus">+</div>
                                <label htmlFor="question2" className="question">
                                    Bagaimana cara mendaftarkan acara/promo saya di Eventories.com?
                                    </label>
                                <div className="answers">
                                    <p>Jika Anda sudah siap untuk menggunakan servis kami, Anda perlu membuat akun di www.mediacartz.com terlebih dahulu. Tim kami siap memandu Anda via email: cs@mediacartz.com | Call/Whatsapp: +62 813 8888 9228 </p>
                                </div>
                            </div>

                            <div>
                                <input type="checkbox" id="question3" name="q" className="questions" />
                                <div className="plus">+</div>
                                <label htmlFor="question3" className="question">
                                    Bisakah Eventories.com membagikan acara saya yang tidak berbayar?
                                    </label>
                                <div className="answers">
                                    <p>Eventories.com dapat melayani semua jenis acara mulai dari konser maupun event berskala kecil hingga besar (online, offline, webinar, etc). Tidak terbatas pada event, platform kami juga dapat membantu menyebarkan promo produk & servis Anda yang bias Anda bagikan melalui kupon, kode promo, dan lain sebagainya. </p>
                                </div>
                            </div>

                            <div>
                                <input type="checkbox" id="question1" name="q" className="questions" />
                                <div className="plus">+</div>
                                <label htmlFor="question1" className="question">
                                    Event apa saja yang dapat menggunakan platform Eventories.com?
                                    </label>
                                <div className="answers">
                                    <p>Tentu bisa, akan ada formulir yang bisa Anda isi untuk memuat informasi tentang acara anda, mulai dari harga tiket (berbayar atau gratis), jumlah kuota tiket, dan detail informasi lainnya.</p>
                                </div>
                            </div>

                            <div>
                                <input type="checkbox" id="question1" name="q" className="questions" />
                                <div className="plus">+</div>
                                <label htmlFor="question1" className="question">
                                    Event apa saja yang dapat menggunakan platform Eventories.com?
                                    </label>
                                <div className="answers">
                                    <p>Eventories.com dapat melayani semua jenis acara mulai dari konser maupun event berskala kecil hingga besar (online, offline, webinar, etc). Tidak terbatas pada event, platform kami juga dapat membantu menyebarkan promo produk & servis Anda yang bias Anda bagikan melalui kupon, kode promo, dan lain sebagainya. </p>
                                </div>
                            </div>

                            <div>
                                <input type="checkbox" id="question1" name="q" className="questions" />
                                <div className="plus">+</div>
                                <label htmlFor="question1" className="question">
                                    Event apa saja yang dapat menggunakan platform Eventories.com?
                                    </label>
                                <div className="answers">
                                    <p>Eventories.com dapat melayani semua jenis acara mulai dari konser maupun event berskala kecil hingga besar (online, offline, webinar, etc). Tidak terbatas pada event, platform kami juga dapat membantu menyebarkan promo produk & servis Anda yang bias Anda bagikan melalui kupon, kode promo, dan lain sebagainya. </p>
                                </div>
                            </div> */}