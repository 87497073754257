// REGISTER USER BY EMAIL
export const REGISTER_USER_REQUEST = 'REGISTER_USER_REQUEST'
export const REGISTER_USER_FAILED = 'REGISTER_USER_FAILED'
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS'

// REGISTER BY SOCMED
export const REGISTER_USER_SOCMED_REQUEST = 'REGISTER_USER_SOCMED_REQUEST'
export const REGISTER_USER_SOCMED_FAILED = 'REGISTER_USER_SOCMED_FAILED'
export const REGISTER_USER_SOCMED_SUCCESS = 'REGISTER_USER_SOCMED_SUCCESS'

//LOGIN user
export const SEND_LOGINUSER_REQUEST = 'SEND_LOGINUSER_REQUEST'
export const SEND_LOGINUSER_SUCCESS = 'SEND_LOGINUSER_SUCCESS'
export const SEND_LOGINUSER_FAILED = 'SEND_LOGINUSER_FAILED'

export const GET_PROFILE_REQUEST = 'GET_PROFILE_REQUEST'
export const GET_PROFILE_FAILED = 'GET_PROFILE_FAILED'
export const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS'

export const INPUT_CAPTCHA = 'INPUT_CAPTCHA'



