import {
    // GET DATA UPCOMING EVENT
    GET_UPCOMING_EVENT_REQUEST,
    GET_UPCOMING_EVENT_FAILED,
    GET_UPCOMING_EVENT_SUCCESS,
    GET_ONLINE_EVENT_SUCCESS,
    GET_STORE_EVENT_SUCCESS,
    GET_PUBLIC_EVENT_SUCCESS

} from './UpcEventTypes.js'

const initialState = {
    loading: false,
    message: '',
    upcoming_event: [],
    online_event: [],
    store_event: [],
    public_event: []

}

const UpcomingEventReducers = (state = initialState, action) => {
    switch (action.type) {
        case GET_UPCOMING_EVENT_REQUEST:
            return {
                ...state,
                loading: true
            }
        case GET_UPCOMING_EVENT_FAILED:
            return {
                ...state,
                loading: false,
                message: action.payload
            }
        case GET_UPCOMING_EVENT_SUCCESS:
            return {
                ...state,
                loading: false,
                upcoming_event: action.payload
            }
        case GET_ONLINE_EVENT_SUCCESS:
            return {
                ...state,
                loading: false,
                online_event: action.payload
            }
        case GET_STORE_EVENT_SUCCESS:
            return {
                ...state,
                loading: false,
                store_event: action.payload
            }
        case GET_PUBLIC_EVENT_SUCCESS:
            return {
                ...state,
                loading: false,
                public_event: action.payload
            }

        default: return state
    }
}

export default UpcomingEventReducers