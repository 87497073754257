// import 'date-fns';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Grid, Box } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import './../assets/css/main.css'
// import SectionPillsSearched from './SectionPillsSearchedEvent';
import { useSelector } from 'react-redux';
import SearchedEventAll from "../components/SearchedEvent/CategoryAll";


const useStyles = makeStyles((theme) => ({
    rootdiv: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(5)
    },
    divmargin: {
        paddingLeft: '15px',
        paddingRight: '15px'
    },
    buttontab: {
        borderRadius: '30px'
    }
}));


const SearchedEvent = () => {

    const classes = useStyles();


    const show = useSelector(state => state.searched_event.show)


    return (
        <div className={classes.rootdiv} style={{ display: show ? 'block' : 'none' }} >
            <Container maxWidth="lg">
                <Grid container >
                    <Typography variant='h5'>
                        <Box pt={3} fontWeight={600} color='#7D4DB7'>Hasil Pencarian</Box>
                    </Typography>
                </Grid>
                <Box pt={3} style={{ height: 'fit-content' }}>
                    <SearchedEventAll />
                </Box>
            </Container>
        </div>

    )
}

export default SearchedEvent;