import React from 'react';
import AppBarUser from '../../components/AppBarUser';
import {
    makeStyles,
    Grid,
    Container,
    Paper,
    Box,
    Divider,
} from '@material-ui/core';
import { Redirect } from 'react-router-dom'
import Midtrans from '../../components/Midtrans';
import { Link } from 'react-router-dom';
import { CLIENT_KEY } from './../../config'

import Countdown from 'react-countdown';
import Meta from '../../components/Util/MetaDecorator'

const useStyles = makeStyles((theme) => ({
    root: {
        [theme.breakpoints.up('xs')]: {
            paddingTop: theme.spacing(10),
        },
        [theme.breakpoints.up('sm')]: {
            paddingTop: theme.spacing(10),
        },
        [theme.breakpoints.up('md')]: {
            paddingTop: theme.spacing(13),
        },
        [theme.breakpoints.up('lg')]: {
            paddingTop: theme.spacing(13),
        },
        [theme.breakpoints.up('xl')]: {
            paddingTop: theme.spacing(13),
        },
    },

    rootcatevent: {
        [theme.breakpoints.up('xs')]: {
            display: 'none'
        },
        [theme.breakpoints.up('sm')]: {
            display: 'block'
        },
        [theme.breakpoints.up('md')]: {
            display: 'block'
        },
        [theme.breakpoints.up('lg')]: {
            display: 'block'
        },
        [theme.breakpoints.up('xl')]: {
            display: 'block'
        }
    },
    rootcateventmob: {
        [theme.breakpoints.up('xs')]: {
            display: 'block'
        },
        [theme.breakpoints.up('sm')]: {
            display: 'none'
        },
        [theme.breakpoints.up('md')]: {
            display: 'none'
        },
        [theme.breakpoints.up('lg')]: {
            display: 'none'
        },
        [theme.breakpoints.up('xl')]: {
            display: 'none'
        }
    },
    paper: {
        height: "auto"
    },
    title: {
        color: '#707070',
        textTransform: 'capitalize',
        textAlign: 'center',
        paddingTop: '15px',
        [theme.breakpoints.up('xs')]: {
            fontSize: '10px',
            fontWeight: 600,
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '20px',
            fontWeight: 600,
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '20px',
            fontWeight: 600,
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '25px',
            fontWeight: 700,
        },
        [theme.breakpoints.up('xl')]: {
            fontSize: '25px',
            fontWeight: 700,
        }
    },
    titleBox: {
        [theme.breakpoints.up('xs')]: {
            fontSize: '12px',
            paddingTop: '10px',
            paddingBottom: '5px'
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '12px',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '12px',
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '13px',
        },
        [theme.breakpoints.up('xl')]: {
            fontSize: '13px',
        }
    },
    captionBox: {
        [theme.breakpoints.up('xs')]: {
            fontSize: '10px',
            fontWeight: 700,
            color: '#707070'
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '10px',
            fontWeight: 700,
            color: '#707070'
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '15px',
            fontWeight: 700,
            color: '#707070'
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '15px',
            fontWeight: 700,
            color: '#707070'
        },
        [theme.breakpoints.up('xl')]: {
            fontSize: '15px',
            fontWeight: 700,
            color: '#707070'
        }
    },
    timeOutExpire: {
        border: '1px solid #ed2d47',
        borderRadius: '5px',
        marginLeft: '15px',
        marginRight: '15px'
    },
    titleExpire: {
        textAlign: 'center',
        paddingTop: '10px',
        paddingBottom: '12px',
        color: '#ed2d47',
        [theme.breakpoints.up('xs')]: {
            fontSize: '14px',

        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '14px',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '14px',
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '17px',
        },
        [theme.breakpoints.up('xl')]: {
            fontSize: '17px',
        }
    },
    titleBoxBottom: {
        [theme.breakpoints.up('xs')]: {
            fontSize: '20px',

        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '20px',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '20px',
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '25px',
        },
        [theme.breakpoints.up('xl')]: {
            fontSize: '25px',
        }
    },

    seatPrice: {
        [theme.breakpoints.up('xs')]: {
            fontSize: '14px',

        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '14px',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '14px',
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '18px',
        },
        [theme.breakpoints.up('xl')]: {
            fontSize: '18px',
        }
    },

    seat: {
        [theme.breakpoints.up('xs')]: {
            fontSize: '14px',

        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '14px',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '14px',
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '18px',
        },
        [theme.breakpoints.up('xl')]: {
            fontSize: '18px',
        }
    },

    totalText: {
        [theme.breakpoints.up('xs')]: {
            fontWeight: 'bold',
            fontSize: '15px',

        },
        [theme.breakpoints.up('sm')]: {
            fontWeight: 'bold',
            fontSize: '15px',
        },
        [theme.breakpoints.up('md')]: {
            fontWeight: 'bold',
            fontSize: '15px',
        },
        [theme.breakpoints.up('lg')]: {
            fontWeight: 'bold',
            fontSize: '24px',
        },
        [theme.breakpoints.up('xl')]: {
            fontWeight: 'bold',
            fontSize: '24px',
        }
    },
    totalNumber: {
        [theme.breakpoints.up('xs')]: {
            fontWeight: 'bold',
            fontSize: '18px',

        },
        [theme.breakpoints.up('sm')]: {
            fontWeight: 'bold',
            fontSize: '18px',
        },
        [theme.breakpoints.up('md')]: {
            fontWeight: 'bold',
            fontSize: '18px',
        },
        [theme.breakpoints.up('lg')]: {
            fontWeight: 'bold',
            fontSize: '26px',
        },
        [theme.breakpoints.up('xl')]: {

            fontWeight: 'bold',
            fontSize: '26px',
        }
    },

    totalTextSmall: {
        [theme.breakpoints.up('xs')]: {
            fontSize: '14px',

        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '14px',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '14px',
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '20px',
        },
        [theme.breakpoints.up('xl')]: {
            fontSize: '20px',
        }
    },
    totalNumberSmall: {
        [theme.breakpoints.up('xs')]: {
            fontSize: '14px',

        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '14px',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '14px',
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '18px',
        },
        [theme.breakpoints.up('xl')]: {
            fontSize: '18px',
        }
    },
    btnCustom: {
        textAlign: 'center',
        cursor: 'pointer',
        borderRadius: '5px',
        border: 'solid 1px #fbfbfb',
        backgroundColor: '#9e69d2',
        width: '100%',
        color: '#fbfbfb',
        height: '40px',
        paddingTop: '7px',
        "&:hover": {
            backgroundColor: '#fbfbfb',
            color: '#9e69d2',
            border: 'solid 1px #9e69d2',
        }
    },
    kebijakan: {
        [theme.breakpoints.up('xs')]: {
            fontSize: '13px',

        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '13px',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '13px',
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '15px',
        },
        [theme.breakpoints.up('xl')]: {
            fontSize: '15px',
        }
    }
}))

const CheckoutPage = ({ history }) => {
    // document.title = "Checkout Page | Eventories.com "
    const classes = useStyles()

    // if(localStorage.getItem('data_seat')) {
    // }

    // if(localStorage.getItem('data_seat_unreserved')) {
    // }
    const dataSeat = JSON.parse(localStorage.getItem('data_seat'))
    const dataSeatUnreserved = JSON.parse(localStorage.getItem('data_seat_unreserved'))
    const dateNowNow = JSON.parse(localStorage.getItem('_datEtIcket'))

    // const CLIENT_KEY = "CLIENT_KEY=SB-Mid-client-yx4-WiXuFr8wDbix" //STG
    // const CLIENT_KEY = "CLIENT_KEY=Mid-client-J9DrJKmAqpmYAJ6o"  //PROD

    let seatPrice = parseInt(localStorage.getItem('seat_price'))

    // console.log("client", CLIENT_KEY)
    const renderData = () => {
        if (dataSeat.length !== 0) {
            // console.log('masuk gak')
            let total_price = dataSeat.length * parseInt(seatPrice)
            // setPrice_SubTotal(total_price)
            return (
                <>
                    <Box pb={2} className={classes.titleBoxBottom} id='fontStyle'>Ringkasan Pembelian</Box>
                    <Divider />
                    <Box pt={2} pb={2} fontWeight='bold' style={{ textTransform: "capitalize" }}>
                        {localStorage.getItem('category_ticket')}
                    </Box>
                    <Box pl={4} pb={2}>
                        {dataSeat.map((data, index) => (
                            <Grid key={index} container direction='row' justify='space-between'>
                                <Box className={classes.seat}>Seat {data} </Box>
                                <Box className={classes.seatprice}>IDR. {seatPrice.toLocaleString()}</Box>
                            </Grid>
                        ))}
                    </Box>
                    <Divider />
                    <Box pt={2} pb={2}>
                        <Grid container direction='row' justify='space-between'>
                            <Box className={classes.totalTextSmall} >Sub Total</Box>
                            <Box className={classes.totalNumberSmall}>
                                IDR. {total_price}
                            </Box>
                        </Grid>
                    </Box>
                    <Box>
                        <Grid container direction='row' justify='space-between'>
                            <Box className={classes.totalTextSmall} >Donasi</Box>
                            <Box className={classes.totalNumberSmall}>
                                IDR.{parseInt(localStorage.donation_amount).toLocaleString()}
                            </Box>
                        </Grid>
                    </Box>
                    <Box pt={2} pb={2}>
                        <Grid container direction='row' justify='space-between'>
                            <Box className={classes.totalText} >Total</Box>
                            <Box className={classes.totalNumber}>
                                IDR.{parseInt(localStorage.getItem('total_price')).toLocaleString()}
                            </Box>

                        </Grid>
                    </Box>
                </>
            )
        } else {
            return ""
        }
    }

    const renderDataUnreserved = () => {
        if (dataSeatUnreserved.length !== 0) {
            let total_price = dataSeatUnreserved.length * parseInt(seatPrice)
            // setPrice_SubTotal(total_price)
            return (
                <>
                    <Box pb={2} className={classes.titleBoxBottom} id='fontStyle'>Ringkasan Pembelian</Box>
                    <Divider />
                    <Box pt={2} pb={2} fontWeight='bold'>
                        {localStorage.getItem('category_ticket')}
                    </Box>
                    <Box pl={4} pb={2}>
                        {dataSeatUnreserved.map((data, index) => (
                            <Grid key={index} container direction='row' justify='space-between'>
                                <Box>{index + 1} </Box>
                                <Box>IDR. {seatPrice.toLocaleString()}</Box>
                            </Grid>
                        ))}
                    </Box>
                    <Divider />
                    <Box pt={2} pb={2}>
                        <Grid container direction='row' justify='space-between'>
                            <Box className={classes.totalTextSmall} >Sub Total</Box>
                            <Box className={classes.totalNumberSmall}>
                                IDR. {total_price}
                            </Box>
                        </Grid>
                    </Box>
                    <Box>
                        <Grid container direction='row' justify='space-between'>
                            <Box className={classes.totalTextSmall} >Donasi</Box>
                            <Box className={classes.totalNumberSmall}>
                                IDR.{parseInt(localStorage.donation_amount).toLocaleString()}
                            </Box>
                        </Grid>
                    </Box>
                    <Box pt={2} pb={2}>
                        <Grid container direction='row' justify='space-between'>
                            <Box className={classes.totalText} >Total</Box>
                            <Box className={classes.totalNumber}>
                                IDR.{parseInt(localStorage.getItem('total_price')).toLocaleString()}
                            </Box>
                        </Grid>
                    </Box>
                </>
            )
        } else {
            return ""
        }
    }

    // Random component
    const Completionist = () => <span>You are good to go!</span>;

    // Renderer callback with condition
    const renderer = ({ hours, minutes, seconds, completed }) => {
        if (completed) {
            // Render a complete state
            localStorage.clear()
            return <Completionist />;
        } else {
            // Render a countdown
            return (
                <span>
                    {hours}:{minutes}:{seconds}
                </span>
            );
        }
    };

    let token_pay = localStorage.getItem('_pay_tkn')

    if (token_pay !== 'undefined') {
        return (
            <React.Fragment>
                <Meta title="Checkout | Eventories.com" description="Silakan lakukan pembayaran pesanan Anda" />

                <AppBarUser />
                <Container maxWidth='lg' className={classes.root}>

                    <Grid container spacing={2} >
                        <Grid item lg={12} md={12} sm={12} xs={12} className={classes.rootcatevent}>
                            <Box p={2}>
                                <Paper elevation={3} className={classes.paper}>

                                    <Box className={classes.title} id='fontStyle'>
                                        {localStorage.getItem('event_name')}
                                    </Box>

                                    <Box pl={4} pr={4} pt={2} pb={2} textAlign='center' >
                                        <Grid container style={{ borderTop: 'solid 1px #eeeeee', paddingTop: '10px' }} >
                                            <Grid item lg={3} md={2} sm={2} xs={6}>
                                                <Box id='fontStyle' className={classes.titleBox} >TANGGAL</Box>
                                                <Box id='fontStyle' className={classes.captionBox}>
                                                    {localStorage.getItem('event_start_datetime')} - {localStorage.getItem('event_end_datetime')}
                                                </Box>


                                            </Grid>
                                            <Grid item lg={3} md={2} sm={2} xs={6} style={{ borderLeft: 'solid 1px #eeeeee', borderRight: 'solid 1px #eeeeee' }}>
                                                <Box id='fontStyle' className={classes.titleBox}>PUKUL</Box>
                                                {
                                                    localStorage.getItem('event_start_datetime_time') === localStorage.getItem('event_end_datetime_time') ?
                                                        <Box id='fontStyle' className={classes.captionBox}>{localStorage.getItem('event_end_datetime_time')} WIB </Box> :
                                                        <Box id='fontStyle' className={classes.captionBox}>{localStorage.getItem('event_start_datetime_time')} - {localStorage.getItem('event_end_datetime_time')} WIB </Box>
                                                }

                                            </Grid>

                                            <Grid item lg={4} md={5} sm={5} xs={6} style={{ borderRight: 'solid 1px #eeeeee' }}>
                                                <Box id='fontStyle' className={classes.titleBox}>TEMPAT</Box>
                                                <Box id='fontStyle' className={classes.captionBox}>
                                                    {/* {dataEvent.event_location_detail} */}
                                                    {localStorage.getItem('event_place')}
                                                </Box>
                                            </Grid>
                                            <Grid item lg={2} md={3} sm={3} xs={6}>
                                                <Box id='fontStyle' className={classes.titleBox}>PENYELENGGARA</Box>
                                                <Box id='fontStyle' className={classes.captionBox}>
                                                    {localStorage.getItem('organizer_name')}
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Paper>
                            </Box>
                        </Grid>


                        {/* <Grid container > */}
                        <Grid item xs={12} className={classes.rootcateventmob} >
                            <Paper elevation={2} className="paperStyles">
                                <Box pt={1} textAlign="center" fontWeight="bolder" id='fontStyle' style={{ fontSize: "20px" }} >
                                    {localStorage.getItem('event_name')}
                                </Box>

                                <Box className="box_detail" pl={5} pr={4} pt={2} >
                                    <Grid container style={{ borderTop: 'solid 1px #eeeeee', paddingTop: '10px' }} >
                                        <Grid item lg={3} md={2} sm={2} xs={12}>
                                            <Box pt={1} id='fontStyle' className={classes.titleBox} >TANGGAL</Box>
                                            {
                                                // {localStorage.getItem('event_start_datetime')} - {localStorage.getItem('event_end_datetime')}
                                                localStorage.getItem('event_start_datetime') !== localStorage.getItem('event_end_datetime') ?
                                                    <Box id='fontStyle' className={classes.captionBox} style={{ fontSize: "14px" }}>
                                                        {localStorage.getItem('event_start_datetime')} - {' '}
                                                        {localStorage.getItem('event_end_datetime')}
                                                    </Box> :
                                                    <Box id='fontStyle' className={classes.captionBox} style={{ fontSize: "14px" }}>
                                                        {localStorage.getItem('event_start_datetime')}
                                                    </Box>
                                            }

                                        </Grid>
                                        <Grid item lg={3} md={2} sm={2} xs={12} style={{ paddingTop: "10px" }}>
                                            <Box id='fontStyle' className={classes.titleBox}>PUKUL</Box>
                                            {/* <Box style={{ fontSize: "14px" }} id='fontStyle' className={classes.captionBox}>
                                                        {moment(dataEvent.event_start_datetime).format('LT')} – {' '}
                                                        {moment(dataEvent.event_end_datetime).format('LT')} WIB 
                                                        </Box> */}
                                            {
                                                localStorage.getItem('event_start_datetime_time') === localStorage.getItem('event_end_datetime_time') ?
                                                    <Box style={{ fontSize: "14px" }} id='fontStyle' className={classes.captionBox}>{localStorage.getItem('event_end_datetime_time')} WIB </Box> :
                                                    <Box style={{ fontSize: "14px" }} id='fontStyle' className={classes.captionBox}>{localStorage.getItem('event_start_datetime_time')} - {localStorage.getItem('event_end_datetime_time')} WIB </Box>
                                            }
                                        </Grid>

                                        <Grid item lg={4} md={5} sm={5} xs={12} style={{ paddingTop: "10px" }}>
                                            <Box id='fontStyle' className={classes.titleBox}>TEMPAT</Box>
                                            <Box style={{ fontSize: "14px" }} id='fontStyle' className={classes.captionBox}>{localStorage.getItem('event_place')}</Box>
                                        </Grid>
                                        <Grid item lg={2} md={3} sm={3} xs={12} style={{ paddingTop: "10px" }}>
                                            <Box id='fontStyle' className={classes.titleBox}>PENYELENGGARA</Box>
                                            <Box style={{ fontSize: "14px" }} id='fontStyle' className={classes.captionBox}>{localStorage.getItem('organizer_name')}</Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Paper>
                        </Grid>
                        {/* </Grid> */}



                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className={classes.timeOutExpire}>
                                <Box className={classes.titleExpire} id="fontStyle">
                                    Segera lakukan pembayaran sekarang
                                    {/* jangka waktu  <strong><Countdown date={dateNowNow + 900000} renderer={renderer} /></strong> */}
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item lg={12} md={12} sm={12} xs={12} >
                            <Box p={2}>
                                <Grid container spacing={2}>
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <Paper>
                                            <Box p={2}>

                                                {renderData()}
                                                {renderDataUnreserved()}

                                            </Box>

                                            <Box>
                                                <Midtrans
                                                    // clientKey={'Mid-client-klZ47HFSE5JZ8TnQ'}
                                                    clientKey={`${CLIENT_KEY}`}

                                                    // Mid-client-J9DrJKmAqpmYAJ6o
                                                    token={localStorage.getItem('_pay_tkn')}
                                                >
                                                    <div className={classes.btnCustom}>
                                                        Lanjutkan Beli Tiket
                                        </div>
                                                </Midtrans>

                                            </Box>


                                        </Paper>
                                    </Grid>
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <Paper>
                                            <Box p={2}>
                                                <Box className={classes.titleBoxBottom} id='fontStyle'>Syarat dan Ketentuan</Box>
                                            </Box>

                                            <Box p={2} pb={6} className={classes.kebijakan}>
                                                Dengan klik "Lanjut Beli Tiket", Saya telah membaca dan menyetujui <Link to='/syarat-ketentuan' target="_blank" ><span > Syarat & Ketentuan serta Kebijakan Privasi</span></Link>. Saya setuju bahwa Eventories dapat membagikan informasi saya dengan Pihak Penyelenggara Event.
                                    </Box>
                                        </Paper>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>

                    </Grid>
                </Container>
            </React.Fragment>
        )
    } else {
        // history.push('/dashboard')
        return (
            <Redirect to='/notfound' />
        )

    }
}

export default CheckoutPage;