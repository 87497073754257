import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Box, Paper, Divider, TextField } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Skeleton from '@material-ui/lab/Skeleton';
import Alert from '@material-ui/lab/Alert';

import Avatar from '@material-ui/core/Avatar';
import profile_default from './../../../assets/img/profile.png'
import { useSelector, useDispatch } from 'react-redux';
import Axios from 'axios'
import { API } from './../../../config'
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import * as SecureLs from 'secure-ls'
import jwt_decode from 'jwt-decode'
import swal from 'sweetalert'
// import Avatar from 'react-avatar-edit'

const ls = new SecureLs();


const useStyles = makeStyles((theme) => ({
    root: {
        [theme.breakpoints.up('xs')]: {
            paddingTop: theme.spacing(1),
        },
        [theme.breakpoints.up('sm')]: {
            paddingTop: theme.spacing(1),
        },
        [theme.breakpoints.up('md')]: {
            paddingTop: theme.spacing(15),
        },
        [theme.breakpoints.up('lg')]: {
            paddingTop: theme.spacing(15),
        },
        [theme.breakpoints.up('xl')]: {
            paddingTop: theme.spacing(15),
        },
    },
    rootdiv: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(5)
    },
    divmargin: {
        paddingLeft: '20px',
        paddingRight: '20px'
    },
    buttontab: {
        borderRadius: '30px'
    },
    paperproject: {
        height: 'fit-content'
    },
    title: {
        fontSize: '14px',
        color: '#707070'
    },
    titleBold: {
        fontSize: '16px',
        color: '#707070',
        fontWeight: 'bold'
    },
    imageprofile: {
        width: '130px',
        height: '130px'
    },
    btnCustom: {
        borderRadius: '20px',
        border: 'solid 1px #9e69d2',
        backgroundColor: '#fbfbfb',
        width: '120px',
        color: '#9e69d2',
        height: '40px',
        "&:hover": {
            backgroundColor: '#9e69d2',
            color: '#fbfbfb',
            border: 'solid 1px #fbfbfb',
        }
    },
    resize: {
        fontSize: '16px',
        color: '#707070',
        fontWeight: 'bold'
    },
    option: {
        fontSize: '16px',
        color: '#707070',
        fontWeight: 'bold',
        '& > span': {
            fontSize: '16px',
            color: '#707070',
            fontWeight: 'bold',
        },
    },

}));



const ProfileDetailComponent = () => {
    const classes = useStyles();


    const dispatch = useDispatch()
    const profile = useSelector(state => state.profile.get_profile)

    const [error, seterror] = useState();
    const [errorChoose, setErrorChoose] = useState(false)
    const [dataProfile, setdataProfile] = useState();

    const [subscribe, setsubscribe] = useState(false);
    const [name, setname] = useState();
    const [bio, setbio] = useState();
    const [city, setcity] = useState();

    const [kotaValue, setkotaValue] = React.useState(null);

    const [editProfile, setEditProfile] = useState({
        name: '',
        bio: '',
        subscribed_status: 0
    })

    const handleEditProfile = x => event => {
        setEditProfile({
            ...editProfile,
            [x]: event.target.value
        })
    }

    const [kotalist, setkotalist] = useState([]);
    const optionKota = {
        options: kotalist,
        getOptionLabel: (option) => option.ms_city_name,

    };

    const CheckToken = () => {
        let rtoken = ls.get('@_rtknid').data
        let tokenn = ls.get('@_tknid').data
        if (tokenn) {
            var jwt = jwt_decode(tokenn);
            var today = new Date().getTime();
            var jwt_exp = jwt.exp * 1000 - (1000 * 20); // sebelum 20 detik

            if (jwt_exp < today) {
                // request
                let formdata = new FormData()
                let options = {
                    headers: {
                        Authorization: `Bearer ${tokenn}`
                    }
                }
                formdata.append("refresh_token", rtoken)
                const refreshToken = Axios.post(`${API}/refresh_token`, formdata, options, null)
                if (refreshToken) {
                    ls.set('@_tknid', { data: refreshToken.data.data.token })
                    ls.set('@_rtknid', { data: refreshToken.data.data.refreshToken })
                }
            } else {
                console.log("tes")

            }
        }
    }

    useEffect(() => {

        if (ls.get('@_tknid').data) {
            const getstatus = async () => {
                try {
                    console.log("masukini")

                    const testing = await CheckToken()
                    let token = ls.get('@_tknid').data

                    const res = await Axios.get(`${API}/profile`, { headers: { Authorization: `Bearer ${token}` } })
                    if (res) {
                        if (res.data.data.subscribed_status === 1) {
                            setsubscribe(true)
                        } else if (res.data.data.subscribed_status === 0) {
                            setsubscribe(false)
                        }
                        setname(res.data.data.name)
                        setbio(res.data.data.bio)
                        setcity(res.data.data.ms_city_id)
                    }
                    Axios.get(`${API}/ms_city?limit=514`)
                        .then(result => {
                            let list = result.data.data
                            list.sort((a, b) => (a.ms_city_name > b.ms_city_name) ? 1 : ((b.ms_city_name > a.ms_city_name) ? -1 : 0))
                            setkotalist(list)

                            if (result.data.data) {
                                Axios.get(`${API}/profile`, { headers: { Authorization: `Bearer ${token}` } })
                                    .then(res => {
                                        // console.log(res.data.data)
                                        setsubscribe(res.data.data.subscribed_status)
                                        setdataProfile(res.data.data)
                                        setEditProfile({
                                            name: res.data.data.name,
                                            bio: res.data.data.bio === null ? '' : res.data.data.bio,
                                            subscribed_status: res.data.data.subscribed_status
                                        })
                                        let arr = result.data.data.filter(function (profile) {
                                            return profile.ms_city_id === res.data.data.ms_city_id
                                        });
                                        if (arr[0] === undefined) {
                                            setkotaValue(null)

                                        } else {
                                            setkotaValue(arr[0])

                                        }

                                    })
                            }

                        })
                } catch (error) {
                    ls.removeAll()
                    localStorage.clear()
                    sessionStorage.clear()
                    window.location.href = "/"
                }
            }
            getstatus()
        }
        // const getcity = async () => {
        //     try {
        //         console.log("masuk")
        //         // const testing = await CheckToken()
        //         let token = ls.get('@_tknid').data
        //         await Axios.get(`${API}/ms_city?limit=514`)
        //             .then(result => {
        //                 let list = result.data.data
        //                 list.sort((a, b) => (a.ms_city_name > b.ms_city_name) ? 1 : ((b.ms_city_name > a.ms_city_name) ? -1 : 0))
        //                 setkotalist(list)
        //                 console.lof(list)
        //                 if (result.data.data) {
        //                     Axios.get(`${API}/profile`, { headers: { Authorization: `Bearer ${token}` } })
        //                         .then(res => {
        //                             console.log(res)
        //                             setsubscribe(res.data.data.subscribed_status)
        //                             setdataProfile(res.data.data)
        //                             setEditProfile({
        //                                 name: res.data.data.name,
        //                                 bio: res.data.data.bio === null ? '' : res.data.data.bio,
        //                                 subscribed_status: res.data.data.subscribed_status
        //                             })
        //                             let arr = result.data.data.filter(function (profile) {
        //                                 return profile.ms_city_id === res.data.data.ms_city_id
        //                             });
        //                             if (arr[0] === undefined) {
        //                                 setkotaValue(null)

        //                             } else {
        //                                 setkotaValue(arr[0])

        //                             }

        //                         })
        //                 }

        //             })
        //     } catch (error) {
        //         ls.removeAll()
        //         localStorage.clear()
        //         sessionStorage.clear()
        //         window.location.href = "/"
        //     }
        //     getcity()
        // }
        // let token = ls.get('@_tknid').data

        // Axios.get(`${API}/ms_city?limit=514`)
        //     .then(result => {
        //         let list = result.data.data
        //         list.sort((a, b) => (a.ms_city_name > b.ms_city_name) ? 1 : ((b.ms_city_name > a.ms_city_name) ? -1 : 0))
        //         setkotalist(list)

        //         if (result.data.data) {
        //             Axios.get(`${API}/profile`, { headers: { Authorization: `Bearer ${token}` } })
        //                 .then(res => {
        //                     // console.log(res.data.data)
        //                     setsubscribe(res.data.data.subscribed_status)
        //                     setdataProfile(res.data.data)
        //                     setEditProfile({
        //                         name: res.data.data.name,
        //                         bio: res.data.data.bio === null ? '' : res.data.data.bio,
        //                         subscribed_status: res.data.data.subscribed_status
        //                     })
        //                     let arr = result.data.data.filter(function (profile) {
        //                         return profile.ms_city_id === res.data.data.ms_city_id
        //                     });
        //                     if (arr[0] === undefined) {
        //                         setkotaValue(null)

        //                     } else {
        //                         setkotaValue(arr[0])

        //                     }

        //                 })
        //         }

        //     })

    }, [])


    const onSave = async () => {

        try {
            const testing = await CheckToken()
            let formdata = new FormData();
            let token = ls.get('@_tknid').data

            let Headers = {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Authorization": `Bearer ${token}`
                },
            };

            let ms_city_id = `${kotaValue === "" || kotaValue === null ? "" : kotaValue.ms_city_id}`
            let name = editProfile.name
            let bio = editProfile.bio
            let subscribe = editProfile.subscribed_status


            formdata.append("name", name);
            formdata.append("bio", bio);
            formdata.append("ms_city_id", ms_city_id);
            formdata.append("subscribed_status", subscribe);

            Axios.put(`${API}/profile`, formdata, Headers)
                .then((res) => {
                    if (res.data.error) {
                        if (res.data.error === "City id is required") {
                            seterror("Input alamat terlebih dulu")
                        } else {
                            seterror(res.data.error)
                        }
                        setErrorChoose(true)
                        setTimeout(() => {
                            setErrorChoose(false)
                        }, 3000);
                    } else if (res.data.success) {
                        localStorage.setItem('bio', bio)
                        localStorage.setItem('name', name)
                        localStorage.setItem('city', ms_city_id)
                        // console.log("bisa")
                        swal({
                            title: "Yeaayy!",
                            text: `${res.data.success}`,
                            icon: "success",
                            button: "OK",
                        })
                            .then((redirect) => {
                                window.location.href = "/profile-detail";
                            });
                    }
                }).catch((err) => {
                    // swal("Error", `${err.response}`, "error");
                })
        } catch (error) {
            ls.removeAll()
            localStorage.clear()
            sessionStorage.clear()
            window.location.href = "/"
        }

    }

    // console.log('bio', dataProfile)
    return (
        <React.Fragment >
            <Container maxWidth="lg">

                <Box className={classes.root}>
                    <Grid container spacing={4}  >
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Paper elevation={2} className={classes.paperproject}>
                                <Box p={4}>
                                    {
                                        kotalist.length > 0 && dataProfile ?
                                            <>
                                                <Grid container direction="row"
                                                    alignItems="center">
                                                    <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>

                                                        <Box pt={2} pb={4}>
                                                            <Avatar className={classes.imageprofile} >
                                                                <img src={profile_default} alt='profile' width='100%' />
                                                            </Avatar>
                                                        </Box>
                                                    </Grid>

                                                    <Grid item xl={9} lg={9} md={9} sm={9} xs={12}>
                                                        <Box pt={4} id="fontStyle" className={classes.title}>Nama Akun</Box>
                                                        <Box pt={1} id="fontStyle" className={classes.titleBold}>{profile.name}</Box>

                                                        <Divider />

                                                        <Box pt={4} id="fontStyle" className={classes.title}>Email</Box>
                                                        <Box pt={1} id="fontStyle" className={classes.titleBold}>{profile.email}</Box>
                                                        <Divider />
                                                    </Grid>
                                                </Grid>




                                                <Box >
                                                    <Box pt={4} id="fontStyle" className={classes.title}>Kota</Box>

                                                    <Grid container spacing={2}
                                                        alignItems="center" className={classes.titleBold}>
                                                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                                            {/* <Box> */}
                                                            <Autocomplete
                                                                {...optionKota}
                                                                id="controlled-demo"
                                                                value={kotaValue}
                                                                classes={{
                                                                    option: classes.option
                                                                }}
                                                                onChange={(event, newValue) => {
                                                                    setkotaValue(newValue)
                                                                }}
                                                                renderInput={(params) => <TextField {...params} fullWidth label={kotaValue !== null ? '' : 'Input alamat (kota)'} margin="normal" />} />

                                                            {/* </Box> */}
                                                        </Grid>

                                                    </Grid>

                                                    <Box pt={4} id="fontStyle" className={classes.title}>Bio</Box>

                                                    <Box pt={1} pb={2} className={classes.titleBold}>
                                                        <TextField id="bio" value={editProfile.bio} label={(editProfile.bio).length > 0 ? '' : 'Input Bio'} multiline fullWidth onChange={handleEditProfile('bio')}
                                                            InputProps={{
                                                                classes: {
                                                                    input: classes.resize,
                                                                },
                                                            }} />
                                                    </Box>

                                                    <Box pt={3} id="fontStyle" className={classes.titleBold}>
                                                        <Box pb={1}>Berlangganan Newsletter</Box>
                                                        <FormControlLabel
                                                            style={{ fontWeight: 'bold' }}
                                                            value="end"
                                                            control={<Checkbox color="primary" />}
                                                            label="Dapatkan penawaran & info menarik dari Eventories."
                                                            labelPlacement="end"
                                                            checked={editProfile.subscribed_status}
                                                            onChange={(e) => {

                                                                if (e.target.checked === false) {

                                                                    setEditProfile({
                                                                        ...editProfile,
                                                                        subscribed_status: 0
                                                                    })
                                                                } else {
                                                                    setEditProfile({
                                                                        ...editProfile,
                                                                        subscribed_status: 1
                                                                    })
                                                                }

                                                            }}
                                                        />
                                                    </Box>


                                                    {
                                                        errorChoose ?
                                                            <Box>
                                                                <Alert textAlign='center' severity="error">{error}</Alert>
                                                            </Box>
                                                            : null
                                                    }
                                                    <Box pt={1} pb={4}>
                                                        <button className={classes.btnCustom}>
                                                            <Box id="fontStyle" className={classes.textBtn} onClick={onSave}>Simpan</Box>
                                                        </button>{' '}
                                                        <Box className="mobile" py={1}>{' '}</Box>

                                                    </Box>


                                                </Box>
                                            </>

                                            :

                                            <Box pt={4}>
                                                <Box pb={3}>
                                                    <Grid container spacing={2} container
                                                        direction="row"
                                                        justify="space-between"
                                                        alignItems="center">
                                                        <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
                                                            <Skeleton variant="circle" height={140} width={140} />

                                                        </Grid>
                                                        <Grid item xl={9} lg={9} md={9} sm={9} xs={12}>

                                                            <Box pb={2}>
                                                                <Skeleton variant="rect" height={60} style={{ borderRadius: '10px' }} />
                                                            </Box>
                                                            <Box >
                                                                <Skeleton variant="rect" height={60} style={{ borderRadius: '10px' }} />
                                                            </Box>

                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                                <Grid container spacing={2} container
                                                    direction="row"
                                                    justify="space-between"
                                                    alignItems="center">
                                                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                                        <Skeleton variant="rect" height={60} style={{ borderRadius: '10px' }} />

                                                    </Grid>

                                                </Grid>

                                                <Box pt={3} pb={2}>
                                                    <Skeleton variant="rect" height={100} style={{ borderRadius: '10px' }} />

                                                </Box>

                                                <Grid continer>
                                                    <Grid item xl={3} lg={4} md={4} sm={6} xs={6} >
                                                        <Box pt={1} pb={4}>
                                                            <Skeleton variant="rect" height={40} style={{ borderRadius: '10px' }} />
                                                        </Box>
                                                    </Grid>

                                                </Grid>

                                            </Box>
                                    }
                                </Box>
                            </Paper>
                        </Grid>

                    </Grid>

                </Box>



            </Container>

        </React.Fragment>

    )
}

export default ProfileDetailComponent;