import React, { useEffect, useState, useRef } from 'react';
import AppBar from '../AppBar'
import Footer from '../FooterDashboard'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom'
import { getEventByKeyword } from '../../redux'
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment'
import Axios from 'axios'
import { API } from '../../config'
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import {
    EmailShareButton,
    EmailIcon,
    FacebookIcon,
    FacebookShareButton,
    LineShareButton,
    LineIcon,
    TelegramShareButton,
    TelegramIcon,
    TwitterShareButton,
    TwitterIcon,
    WhatsappShareButton,
    WhatsappIcon
} from "react-share";
import LocationOnIcon from '@material-ui/icons/LocationOn';
import EventIcon from '@material-ui/icons/Event';
import Tidaktersedia from '../../assets/img/statekosong.png'
import Skeleton from '@material-ui/lab/Skeleton';
import { Container, Box, Typography, Grid, Card, Link, CardMedia, CardContent, Button, Tooltip, Zoom, Dialog, DialogTitle, List, ListItem, ListItemAvatar } from '@material-ui/core'
import { FaShare } from 'react-icons/fa';
import jwt_decode from "jwt-decode";

import Pagination from '@material-ui/lab/Pagination';

import * as SecureLs from 'secure-ls'
const ls = new SecureLs();

const useStyles = makeStyles((theme) => ({
    rootdiv: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(5)
    },
    divmargin: {
        paddingLeft: '20px',
        paddingRight: '20px',
        paddingTop: '20px'
    },
    buttontab: {
        borderRadius: '30px'
    },
    buttonCategory: {
        textTransform: "capitalize",
        borderRadius: '30px',
        fontSize: '12px',
    },
    root: {
        marginTop: "30px",
        margin: "10px 5px",
        // maxWidth: 380,
        borderRadius: '10px 10px 0 0',
        position: 'relative'
    },
    media: {
        height: 155,
        width: '100%',
        filter: 'brightness(0.8)'
    },
    info: {
        zIndex: 3,
        position: 'absolute',
        top: 0,
        left: 0
    },
    boxinfo: {

        opacity: 0.9,
        borderRadius: '15px',
    },
    share: {
        zIndex: 3,
        position: 'absolute',
        top: 0,
        right: 0
    },
    boxshare: {
        display: 'flex'
    },
    borderresult: {
        [theme.breakpoints.up('md')]: {
            borderLeft: '1px solid #eeee'
        },
        [theme.breakpoints.up('lg')]: {
            borderLeft: '1px solid #eeee'
        },
        [theme.breakpoints.up('xl')]: {
            borderLeft: '1px solid #eeee'
        }
    },
    rootcatevent: {
        [theme.breakpoints.up('xs')]: {
            display: 'none'
        },
        [theme.breakpoints.up('sm')]: {
            display: 'block'
        },
        [theme.breakpoints.up('md')]: {
            display: 'block'
        },
        [theme.breakpoints.up('lg')]: {
            display: 'block'
        },
        [theme.breakpoints.up('xl')]: {
            display: 'block'
        }
    },
    rootcateventmob: {
        [theme.breakpoints.up('xs')]: {
            display: 'block'
        },
        [theme.breakpoints.up('sm')]: {
            display: 'none'
        },
        [theme.breakpoints.up('md')]: {
            display: 'none'
        },
        [theme.breakpoints.up('lg')]: {
            display: 'none'
        },
        [theme.breakpoints.up('xl')]: {
            display: 'none'
        }
    },

    catDeks: {
        [theme.breakpoints.up('xs')]: {
            display: 'none'
        },
        [theme.breakpoints.up('sm')]: {
            display: 'none'
        },
        [theme.breakpoints.up('md')]: {
            display: 'block'
        },
        [theme.breakpoints.up('lg')]: {
            display: 'block'
        },
        [theme.breakpoints.up('xl')]: {
            display: 'block'
        }
    },
    catMob: {
        [theme.breakpoints.up('xs')]: {
            display: 'block'
        },
        [theme.breakpoints.up('sm')]: {
            display: 'block'
        },
        [theme.breakpoints.up('md')]: {
            display: 'none'
        },
        [theme.breakpoints.up('lg')]: {
            display: 'none'
        },
        [theme.breakpoints.up('xl')]: {
            display: 'none'
        }
    },
}))
const EventbyKeyword = () => {
    const classes = useStyles();
    const searchedEvent = useSelector(state => state.searched_event.searched_keyword_event)
    // const searchedEvent = useSelector(state => state.searched_event.searched_keyword_event.data)
    const lastPage = useSelector(state => state.searched_event.last_page_keyword)

    const [openDialogShare, setopenDialogShare] = useState(false);
    const [shareLink, setshareLink] = useState("");
    const [listCategory, setlistCategory] = useState();
    const [dataLoaded, setDataLoaded] = useState(false);
    const [catSelected, setcatSelected] = useState("");
    const [category, setcategory] = useState("Semua");
    const [openDialogFilter, setopenDialogFilter] = useState(false);
    const [page, setpage] = useState(1);
    const toup = useRef(null)

    const handleClose = () => {
        setopenDialogShare(false)
    }

    const handleCloseFilter = () => {
        setopenDialogFilter(false)
    }

    const key = useParams()
    const keyword = key.keyword
    const dispatch = useDispatch()
    const CheckToken = () => {
        let rtoken = ls.get('@_rtknid').data
        let tokenn = ls.get('@_tknid').data
        if (tokenn) {
            var jwt = jwt_decode(tokenn);
            var today = new Date().getTime();
            var jwt_exp = jwt.exp * 1000 - (1000 * 20); // sebelum 20 detik

            if (jwt_exp < today) {
                // request
                let formdata = new FormData()
                let options = {
                    headers: {
                        Authorization: `Bearer ${tokenn}`
                    }
                }
                formdata.append("refresh_token", rtoken)
                const refreshToken = Axios.post(`${API}/refresh_token`, formdata, options, null)
                if (refreshToken) {
                    ls.set('@_tknid', { data: refreshToken.data.data.token })
                    ls.set('@_rtknid', { data: refreshToken.data.data.refreshToken })
                }
            } else {
                console.log("tes")

            }
        }
    }

    useEffect(() => {
        if (keyword) {
            dispatch(getEventByKeyword(keyword, catSelected, 1))
        }
        const loadCategory = async () => {
            try {
                const testing = await CheckToken()

                const res = await Axios.get(`${API}/category_event`)
                    .then((res) => {
                        let data = res.data.data
                        let state = [{
                            name: "Semua",
                            id: "",
                            active: true
                        }]
                        for (let i = 0; i < data.length; i++) {
                            state.push({
                                name: data[i].category_event_name,
                                id: data[i].category_event_id,
                                active: false
                            })
                        }
                        setlistCategory(state)
                    })
                    .catch(err =>
                        console.log(err)
                    )
                return res;
            } catch (error) {
                // console.log(error);
            }
        }
        loadCategory()

    }, [])


    return (
        <React.Fragment>
            <AppBar />

            <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={openDialogShare}>
                <DialogTitle id="simple-dialog-title">Share to Friends</DialogTitle>

                <List>
                    <ListItem style={{ color: "black" }}>
                        <ListItemAvatar>
                            <FacebookShareButton url={`${shareLink}`}>
                                <FacebookIcon size={32} round={true} /> &nbsp; Facebook
                            </FacebookShareButton>
                        </ListItemAvatar>
                    </ListItem>
                </List>

                <List>
                    <ListItem style={{ color: "black" }}>
                        <ListItemAvatar>
                            <EmailShareButton url={`${shareLink}`}>
                                <EmailIcon size={32} round={true} />  &nbsp; Email
                            </EmailShareButton>
                        </ListItemAvatar>
                    </ListItem>
                </List>

                <List>
                    <ListItem style={{ color: "black" }}>
                        <ListItemAvatar>
                            <LineShareButton url={`${shareLink}`}>
                                <LineIcon size={32} round={true} />  &nbsp; Line
                            </LineShareButton>
                        </ListItemAvatar>
                    </ListItem>
                </List>

                <List>
                    <ListItem style={{ color: "black" }}>
                        <ListItemAvatar>
                            <TelegramShareButton url={`${shareLink}`}>
                                <TelegramIcon size={32} round={true} />  &nbsp; Telegram
                            </TelegramShareButton>
                        </ListItemAvatar>
                    </ListItem>
                </List>

                <List>
                    <ListItem style={{ color: "black" }}>
                        <ListItemAvatar>
                            <WhatsappShareButton url={`${shareLink}`}>
                                <WhatsappIcon size={32} round={true} />  &nbsp; Whatsapp
                            </WhatsappShareButton>
                        </ListItemAvatar>
                    </ListItem>
                </List>

                <List>
                    <ListItem style={{ color: "black" }}>
                        <ListItemAvatar>
                            <TwitterShareButton url={`${shareLink}`}>
                                <TwitterIcon size={32} round={true} />  &nbsp; Twitter
                            </TwitterShareButton>
                        </ListItemAvatar>
                    </ListItem>
                </List>
            </Dialog>

            <Dialog onClose={handleCloseFilter} aria-labelledby="simple-dialog-title" open={openDialogFilter}>

                {
                    searchedEvent !== null ?
                        <>
                            <Box pt={2} pl={2} pr={2} style={{ fontSize: "14px", fontWeight: 'bold' }}>Filter Category</Box>
                            <Box p={1}>
                                {
                                    listCategory ?
                                        <Box>
                                            {
                                                listCategory.map((data, index) => (
                                                    <Button fullWidth key={index}
                                                        style={{ fontSize: '13px', textTransform: 'capitalize', textAlign: 'left' }}
                                                        onClick={async () => {
                                                            setopenDialogFilter(false)
                                                            try {
                                                                const testing = await CheckToken()

                                                                let id = data.id
                                                                if (id === "" || id === undefined) {
                                                                    setcatSelected("")
                                                                    setcategory("Semua")
                                                                    dispatch(getEventByKeyword(keyword, "", page))
                                                                    Axios.get(`${API}/category_event`)
                                                                        .then((res) => {
                                                                            let data = res.data.data
                                                                            let state = [{
                                                                                name: "Semua",
                                                                                id: "",
                                                                                active: false
                                                                            }]
                                                                            for (let i = 0; i < data.length; i++) {
                                                                                state.push({
                                                                                    name: data[i].category_event_name,
                                                                                    id: data[i].category_event_id,
                                                                                    active: false
                                                                                })
                                                                            }
                                                                            state.find(e => e.id === id).active = true
                                                                            setlistCategory(state)
                                                                            setDataLoaded(true)
                                                                        })
                                                                        .catch(err => console.log(err))


                                                                } else {
                                                                    setcatSelected(id)
                                                                    setcategory(data.name)
                                                                    dispatch(getEventByKeyword(keyword, Number(id), page))
                                                                    Axios.get(`${API}/category_event`)
                                                                        .then((res) => {
                                                                            let data = res.data.data
                                                                            let state = [{
                                                                                name: "Semua",
                                                                                id: "",
                                                                                active: false
                                                                            }]
                                                                            for (let i = 0; i < data.length; i++) {
                                                                                state.push({
                                                                                    name: data[i].category_event_name,
                                                                                    id: data[i].category_event_id,
                                                                                    active: false
                                                                                })
                                                                            }
                                                                            state.find(e => e.id === id).active = true
                                                                            setlistCategory(state)
                                                                            setDataLoaded(true)
                                                                        })
                                                                        .catch(err => console.log(err))

                                                                }

                                                            } catch (error) {

                                                            }



                                                        }}
                                                    >
                                                        {data.name}
                                                    </Button>

                                                )
                                                )}
                                        </Box>
                                        :
                                        null
                                }
                            </Box>

                        </>
                        :
                        null

                }
            </Dialog>
            <div className={classes.rootdiv} >
                <Container maxWidth="lg" className="search-result" >
                    <Grid container >
                        {
                            listCategory ?
                                <Box pb={4} className={classes.catMob} style={{ width: '100%' }}>
                                    <Button className="button-filter" fullWidth onClick={() => setopenDialogFilter(true)} >Filter</Button>
                                </Box>
                                :
                                <Skeleton variant="rect" height={180} style={{ borderRadius: '10px' }} />
                        }

                        <Grid item xl={2} lg={2} md={2} sm={3} xs={5} className={classes.catDeks}>
                            <Box p={2} style={{ borderRight: '1px solid #eeee' }}>

                                <Box pb={2} mr={2} textAlign="center" fontWeight={600} style={{ borderBottom: '1px solid #eeee' }} color="#393755">Kategori</Box>
                                {
                                    listCategory ?
                                        listCategory.map((data, index) => (


                                            <Box pt={2}
                                                style={{ cursor: 'pointer', fontSize: '13px', textTransform: 'capitalize', textAlign: 'left', color: `${data.active ? '#393755' : '#393755'}`, fontWeight: `${data.active ? 'bold' : 'normal'}`, backgroundColor: 'white' }}
                                                fullwidth
                                                key={index}
                                                onClick={() => {
                                                    let id = data.id
                                                    if (id === "" || id === undefined) {
                                                        setcatSelected("")
                                                        setcategory("Semua")
                                                        setpage(1)

                                                        dispatch(getEventByKeyword(keyword, "", page))
                                                        Axios.get(`${API}/category_event`)
                                                            .then((res) => {
                                                                let data = res.data.data
                                                                let state = [{
                                                                    name: "Semua",
                                                                    id: "",
                                                                    active: false
                                                                }]
                                                                for (let i = 0; i < data.length; i++) {
                                                                    state.push({
                                                                        name: data[i].category_event_name,
                                                                        id: data[i].category_event_id,
                                                                        active: false
                                                                    })
                                                                }
                                                                state.find(e => e.id === id).active = true
                                                                setlistCategory(state)
                                                                setDataLoaded(true)
                                                            })
                                                            .catch(err => console.log(err))
                                                    } else {
                                                        setcatSelected(id)
                                                        // console.log('ID', id)
                                                        setcategory(data.name)
                                                        setpage(1)

                                                        dispatch(getEventByKeyword(keyword, Number(id), page))
                                                        Axios.get(`${API}/category_event`)
                                                            .then((res) => {
                                                                let data = res.data.data
                                                                let state = [{
                                                                    name: "Semua",
                                                                    id: "",
                                                                    active: false
                                                                }]
                                                                for (let i = 0; i < data.length; i++) {
                                                                    state.push({
                                                                        name: data[i].category_event_name,
                                                                        id: data[i].category_event_id,
                                                                        active: false
                                                                    })
                                                                }
                                                                state.find(e => e.id === id).active = true
                                                                setlistCategory(state)
                                                                setDataLoaded(true)
                                                            })
                                                            .catch(err => console.log(err))
                                                    }
                                                    toup.current.scrollIntoView()

                                                }}
                                            > {data.name}</Box >
                                        ))
                                        :
                                        <>
                                            <Box>
                                                <Skeleton variant="text" height={50} style={{ borderRadius: '20px' }} />
                                            </Box>
                                            <Box>
                                                <Skeleton variant="text" height={50} style={{ borderRadius: '20px' }} />
                                            </Box>
                                            <Box>
                                                <Skeleton variant="text" height={50} style={{ borderRadius: '20px' }} />
                                            </Box>
                                            <Box>
                                                <Skeleton variant="text" height={50} style={{ borderRadius: '20px' }} />
                                            </Box>
                                            <Box>
                                                <Skeleton variant="text" height={50} style={{ borderRadius: '20px' }} />
                                            </Box>
                                            <Box>
                                                <Skeleton variant="text" height={50} style={{ borderRadius: '20px' }} />
                                            </Box>
                                            <Box>
                                                <Skeleton variant="text" height={50} style={{ borderRadius: '20px' }} />
                                            </Box>
                                            <Box>
                                                <Skeleton variant="text" height={50} style={{ borderRadius: '20px' }} />
                                            </Box>
                                            <Box>
                                                <Skeleton variant="text" height={50} style={{ borderRadius: '20px' }} />
                                            </Box>
                                        </>
                                }
                            </Box>
                        </Grid>

                        <Grid item xl={10} lg={10} md={10} sm={12} xs={12} >
                            {/* --- */}

                            <Box pl={2} className={classes.borderresult}>

                                <Typography variant='h5' ref={toup}>
                                    {
                                        keyword && listCategory ?
                                            <Box className="title-search" fontWeight={600} color='#9e69d2'> Hasil Pencarian dari "{keyword}" di {category === "Semua" ? `Semua Kategori` : `Kategori ${category}`} </Box>
                                            :
                                            <Skeleton variant="text" height={50} style={{ borderRadius: '8px' }} />
                                    }
                                </Typography>
                                <Box pt={2} >
                                    {
                                        searchedEvent !== null && searchedEvent.length > 0 ?
                                            <Grid container>
                                                {
                                                    searchedEvent.map((data, index) => {
                                                        return (
                                                            <Grid item xl={4} lg={4} md={6} sm={6} xs={12} key={index}>
                                                                <Card className={classes.root} title={`${data.event_name} by ${data.organizer.organizer_name}`}>
                                                                    <Link href={`/detail-event/${data.event_slug}`}>
                                                                        {/* <Card style={{ height: "170px" }}>
                                                                            <Box textAlign="center" className="imgevent" style={{
                                                                                height: "170px", alignContent: "center", backgroundImage: `url(${data.event_image_url})`
                                                                                // background: `linear-gradient(rgba(138, 133, 133, 0.367), rgba(171, 170, 170, 0.222)), url(${data.event_image_url})`
                                                                            }}></Box></Card>
                                                                        <Box textAlign="center" className="imgsearch" style={{ height: "184px", alignContent: "center", zIndex: 1 }}>
                                                                            <img width="auto" height="100%" src={data.event_image_url} />
                                                                        </Box> */}
                                                                        <Box textAlign="center" style={{ alignContent: "center", zIndex: 1 }}>
                                                                            <img width="100%" maxHeight="175px" minHeight="175px" Height="175px" src={data.event_image_url} />
                                                                        </Box>
                                                                    </Link>
                                                                    <CardContent className={classes.info}>

                                                                        <Link style={{ cursor: 'pointer' }}>
                                                                            {
                                                                                data.current_auth_liked ?
                                                                                    <Tooltip TransitionComponent={Zoom} title="Hapus sebagai favorit" arrow placement="bottom">
                                                                                        <FavoriteIcon color="error"
                                                                                            onClick={async () => {
                                                                                                try {
                                                                                                    const testing = await CheckToken()

                                                                                                    let token = ls.get('@_tknid').data
                                                                                                    // console.log('token', token)
                                                                                                    const res = await Axios.delete(`${API}/event/like`, {
                                                                                                        headers: { Authorization: `Bearer ${token}` },
                                                                                                        data: { event_id: data.event_id }
                                                                                                    })
                                                                                                    if (res) {
                                                                                                        if (catSelected === "") {
                                                                                                            dispatch(getEventByKeyword(keyword, "", page))
                                                                                                        } else {
                                                                                                            dispatch(getEventByKeyword(keyword, catSelected, page))
                                                                                                        }
                                                                                                    }
                                                                                                } catch (error) {
                                                                                                }
                                                                                            }} />
                                                                                    </Tooltip>
                                                                                    :
                                                                                    <Tooltip TransitionComponent={Zoom} title="Tambah sebagai favorit" arrow placement="bottom">
                                                                                        <FavoriteBorderIcon style={{ color: "white" }}
                                                                                            onClick={async () => {
                                                                                                try {
                                                                                                    const testing = await CheckToken()

                                                                                                    let token = ls.get('@_tknid').data
                                                                                                    // console.log('token', token)

                                                                                                    if (!token) {
                                                                                                        window.location.href = "/login";
                                                                                                    } else {
                                                                                                        let formdata = new FormData()
                                                                                                        let options = {
                                                                                                            headers: {
                                                                                                                Authorization: `Bearer ${token}`
                                                                                                            }
                                                                                                        }
                                                                                                        formdata.append("event_id", data.event_id)
                                                                                                        const res = await Axios.post(`${API}/event/like`, formdata, options, null)
                                                                                                        if (res) {

                                                                                                            if (catSelected === "") {
                                                                                                                dispatch(getEventByKeyword(keyword, "", page))
                                                                                                            } else {
                                                                                                                dispatch(getEventByKeyword(keyword, catSelected, page))

                                                                                                            }

                                                                                                        }
                                                                                                    }
                                                                                                } catch (error) {
                                                                                                    // console.log(error)
                                                                                                    ls.removeAll()
                                                                                                    localStorage.clear()
                                                                                                    sessionStorage.clear()
                                                                                                    window.location.href = "/"

                                                                                                }
                                                                                            }} />
                                                                                    </Tooltip>

                                                                            }
                                                                        </Link>


                                                                    </CardContent>

                                                                    <CardContent className={classes.share} onClick={() => {
                                                                        setshareLink(data.event_full_url)
                                                                        setopenDialogShare(true)
                                                                    }}>
                                                                        <Tooltip TransitionComponent={Zoom} title="share" arrow placement="bottom">
                                                                            <Box className={classes.boxshare}>
                                                                                <Box pr={1} pl={1} >
                                                                                    <Link style={{ cursor: 'pointer' }}>
                                                                                        <FaShare style={{ color: 'white' }} />
                                                                                    </Link>
                                                                                </Box>
                                                                            </Box>
                                                                        </Tooltip>
                                                                    </CardContent>

                                                                    <Box pl={1} pr={1} pt={1} color='#000' style={{ width: '100%', whiteSpace: 'nowrap' }}>
                                                                        <Link href={`/detail-event/${data.event_slug}`}>
                                                                            <Box title={`${data.event_name} by ${data.organizer.organizer_name}`} fontSize={15} fontWeight={600} component="div" overflow="hidden" textOverflow="ellipsis" color="#393755">
                                                                                {data.event_name}
                                                                            </Box>
                                                                        </Link>
                                                                        {
                                                                            moment(data.event_start_datetime).format('DD MMMM YYYY') !== moment(data.event_end_datetime).format('DD MMMM YYYY') ?
                                                                                <Box color="#393755" fontSize={14} fontWeight={100} overflow="hidden" textOverflow="ellipsis">
                                                                                    <EventIcon style={{ fontSize: "14px" }} />{' '}
                                                                                    {moment(data.event_start_datetime).format('DD MMMM YYYY')}
                                                                                    {' '}- {' '}
                                                                                    {moment(data.event_end_datetime).format('DD MMMM YYYY')}
                                                                                </Box> :
                                                                                <Box color="#393755" fontSize={14} fontWeight={100} overflow="hidden" textOverflow="ellipsis">
                                                                                    <EventIcon style={{ fontSize: "14px" }} />{' '}
                                                                                    {moment(data.event_end_datetime).format('DD MMMM YYYY')}
                                                                                </Box>
                                                                        }
                                                                        {
                                                                            data.category_venue_id === 3 ?
                                                                                <Box color="#393755" fontSize={14} overflow="hidden" textOverflow="ellipsis"><LocationOnIcon style={{ fontSize: "14px" }} /> Online Event
                                                                                </Box> :
                                                                                // <Box color="#393755" fontSize={14} overflow="hidden" textOverflow="ellipsis"><LocationOnIcon style={{ fontSize: "14px" }} /> {data.event_location} {data.event_location_detail.length > 0 ? ` - ${data.event_location_detail}` : null}
                                                                                <Box color="#393755" fontSize={14} overflow="hidden" textOverflow="ellipsis"><LocationOnIcon style={{ fontSize: "14px" }} /> {data.event_location} {(data.event_location_detail && data.event_location_detail.length > 0) || data.event_location_detail !== null ? ` - ${data.event_location_detail}` : null}

                                                                                </Box>

                                                                        }
                                                                        <Box style={{ paddingTop: '10px' }}></Box>

                                                                    </Box>
                                                                </Card>
                                                            </Grid>
                                                        )
                                                    })
                                                }
                                            </Grid>
                                            :
                                            searchedEvent !== null && searchedEvent.length === 0 ?
                                                <>
                                                    <Grid container direction='row' justify='center'>
                                                        <Box textAlign="center" pt={7}>
                                                            <img alt='nothing here' src={Tidaktersedia} width='60%' />
                                                        </Box>
                                                    </Grid>
                                                    <Grid container direction='row' justify='center'>
                                                        <Box id="fontStyle" fontSize={13} pt={3} color="#393755">Maaf, Kami tidak dapat menemukan Event  dengan kata kunci "{keyword}", Mohon perhatikan kembali ejaan atau cari ulang</Box>
                                                    </Grid>
                                                </>
                                                :
                                                <>
                                                    <Grid container spacing={2} style={{ height: '260px', paddingTop: "10px" }} >
                                                        <Grid item lg={4} xl={4} md={4} sm={6} xs={12} >
                                                            <Skeleton variant="rect" height={180} style={{ borderRadius: '10px' }} />
                                                            <Skeleton variant="text" height={50} style={{ borderRadius: '10px' }} />

                                                        </Grid>
                                                        <Grid item lg={4} xl={4} md={4} className={classes.catDeks} >
                                                            <Skeleton variant="rect" height={180} style={{ borderRadius: '10px' }} />
                                                            <Skeleton variant="text" height={50} style={{ borderRadius: '10px' }} />

                                                        </Grid>
                                                        <Grid item lg={4} xl={4} md={4} className={classes.catDeks}>
                                                            <Skeleton variant="rect" height={180} style={{ borderRadius: '10px' }} />
                                                            <Skeleton variant="text" height={50} style={{ borderRadius: '10px' }} />

                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={2} style={{ height: '260px', paddingTop: "10px" }} >
                                                        <Grid item lg={4} xl={4} md={4} sm={6} xs={12} >
                                                            <Skeleton variant="rect" height={180} style={{ borderRadius: '10px' }} />
                                                            <Skeleton variant="text" height={50} style={{ borderRadius: '10px' }} />

                                                        </Grid>
                                                        <Grid item lg={4} xl={4} md={4} className={classes.catDeks}>
                                                            <Skeleton variant="rect" height={180} style={{ borderRadius: '10px' }} />
                                                            <Skeleton variant="text" height={50} style={{ borderRadius: '10px' }} />

                                                        </Grid>
                                                        <Grid item lg={4} xl={4} md={4} className={classes.catDeks}>
                                                            <Skeleton variant="rect" height={180} style={{ borderRadius: '10px' }} />
                                                            <Skeleton variant="text" height={50} style={{ borderRadius: '10px' }} />

                                                        </Grid>
                                                    </Grid>
                                                </>

                                    }
                                </Box>
                                <Grid container direction='row' justify='flex-end' >

                                    <Box pt={5} style={{ display: lastPage > 1 ? 'block' : 'none' }}>
                                        <Pagination count={lastPage}
                                            color="primary"
                                            page={page}
                                            onChange={(e, val) => {
                                                setpage(val)

                                                dispatch(getEventByKeyword(
                                                    keyword,
                                                    catSelected,
                                                    val
                                                ))
                                                toup.current.scrollIntoView()
                                            }} />
                                    </Box>
                                </Grid>

                            </Box>
                            {/* --- */}
                        </Grid>
                    </Grid>

                </Container>
            </div>
            <Footer />
        </React.Fragment >
    )
}

export default EventbyKeyword