import React from 'react';
import CreateEvent from '../assets/img/create_event.png'
import { Box, Button, Container, Link, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    rootdiv: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(5)
    }
}))

export default function Createevent() {

    const classes = useStyles();

    return (
        <div className='container-create' >
            <Container maxWidth="lg" >
                <Box pb={7} pt={7} mb={3}>

                    <Grid container>
                        <Grid item lg={6} md={6} sm={6} xs={12}>

                            <Box pt={2} pl={3} pr={3} className="imgcreate" style={{ backgroundImage: `url(${CreateEvent})` }}></Box>
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={12} className="createEvents" >
                            <Box className="title-create" fontWeight={600} fontSize={19}>Anda dapat membuat event di Mediacartz</Box>
                            <Box className="caption-create" color="gray" style={{marginTop: '20px'}}>Mediacartz adalah layanan otomatisasi periklanan yang membantu Anda untuk mengelola kampanye iklan Anda dengan mudah dan efektif. Anda dapat menetapkan target market sesuai kebutuhan, seperti lokasi, tempat tinggal, usia, jenis kelamin, dan pendapatan mereka hingga mengatur kapan iklan tersebut tayang secara bersamaan maupun berkala.</Box>
                            <Box className="caption-create" color="gray">
                                Dashboard Mediacartz memberikan laporan hasil kampanye secara langsung, sehingga Anda dapat memperoleh informasi dan mengaturnya di mana pun dan kapan pun.
                        </Box>
                            <Box pt={2} pb={3} display="flex"
                                justifyContent="center"
                                alignItems="center">
                                <Link target="_blank" href={'https://www.mediacartz.com/'}>
                                    <Button style={{ backgroundColor: "#9e69d2", color: "white", textTransform: "capitalize" }}>
                                        <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                                            Buat Event
                                    </div>
                                    </Button>
                                </Link>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                <Box>

                </Box>
            </Container>
        </div>
    )
}