import React, { useEffect, useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
    Card,
    Box,
    Grid,
    CardMedia,
    CardContent,
    Link,
    Container,
    Button
} from '@material-ui/core';
import "react-multi-carousel/lib/styles.css";
// import { useSelector } from 'react-redux';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import { FaShare } from 'react-icons/fa';
import './../../assets/css/main.css'
import moment from 'moment'
import { purple } from '@material-ui/core/colors';
import { API } from '../../config'
import Axios from 'axios'

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 380,
        borderRadius: '10px 10px 0 0',
        position: 'relative'
    },
    media: {
        height: 180,
        width: '100%',
    },
    info: {
        position: 'absolute',
        top: 0,
        left: 0
    },
    boxinfo: {

        opacity: 0.9,
        borderRadius: '15px',
    },
    share: {
        position: 'absolute',
        top: 0,
        right: 0
    },
    boxshare: {
        display: 'flex'
    },
    margin: {
        width: '100%',
        borderRadius: '50px',
        margin: theme.spacing(1),
    },

}));

const ColorButton = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText(purple[500]),
        backgroundColor: purple[500],
        '&:hover': {
            backgroundColor: purple[700],
        },
    },
}))(Button);

const CategoryAll = () => {
    const classes = useStyles();
    // const category_all = useSelector(state => state.allcat_event.allcat_event)
    const [Category_All, setCategory_All] = useState();

    useEffect(() => {
        Axios.get(`${API}/event/all?page=${1}&limit=4`)
            .then((res) => {
                setCategory_All(res.data.data)
            }).catch(err => {
                console.log(err)
            })
    }, [])


    const renderCategoryAll = () => {
        return Category_All && Category_All.map((data, index) => {
            return (
                <Grid item xl={4} lg={4} md={4} sm={6} xs={12} key={index}>
                    <Link href={`/detail-event/${data.event_slug}`}>
                        <Card className={classes.root} >
                            <CardMedia
                                className={classes.media}
                                image={data.event_image_url}
                                title="Contemplative Reptile"
                            />
                            <CardContent className={classes.info}>
                                <Box className={classes.boxinfo}>
                                    <Box >
                                        <Link style={{ cursor: 'pointer' }}>
                                            <FavoriteBorderIcon style={{ color: 'white' }} />
                                        </Link>
                                    </Box>
                                </Box>
                            </CardContent>
                            <CardContent className={classes.share}>
                                <Box className={classes.boxshare}>
                                    <Box pr={1} pl={1} >
                                        <Link style={{ cursor: 'pointer' }}>
                                            <FaShare style={{ color: 'white' }} />
                                        </Link>
                                    </Box>
                                </Box>
                            </CardContent>

                        </Card>
                        <Box pl={1} pr={1} pt={1} color='#000' >
                            <Box fontSize={17} fontWeight={600} >{data.event_name}</Box>
                            {
                                moment(data.event_start_datetime).format('DD MMMM YYYY') !== moment(data.event_end_datetime).format('DD MMMM YYYY') ?
                                    <Box fontSize={14} fontWeight={100}>
                                        {moment(data.event_start_datetime).format('DD MMMM YYYY')}
                                        {' '}- {' '}
                                        {moment(data.event_end_datetime).format('DD MMMM YYYY')}
                                    </Box> :
                                    <Box fontSize={14} fontWeight={100}>
                                        {moment(data.event_end_datetime).format('DD MMMM YYYY')}
                                    </Box>
                            }
                            <Box>{data.event_location_detail}</Box>
                            <Box style={{ borderBottom: 'solid 2px #d1d1d1', paddingTop: '10px' }}></Box>
                        </Box>
                    </Link>
                </Grid>
            )
        })
    }

    if (Category_All === undefined || Category_All.length === 0) return <div>loading..</div>
    else {
        return (
            <React.Fragment>
                <Container maxWidth='lg'>
                    <Box pt={4} >
                        <Grid container spacing={2}>
                            {renderCategoryAll()}
                        </Grid>
                    </Box>
                    <Box pt={2}>
                        <ColorButton variant="contained" color="primary" className={classes.margin}>
                            Load More Events
                    </ColorButton>
                    </Box>
                </Container>
            </React.Fragment>
        )
    }
}


export default CategoryAll