import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import {
  makeStyles,
  Box
} from '@material-ui/core';
import Container from '@material-ui/core/Container';
import { useSelector } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import Axios from 'axios'
import { API } from './../../config'
import swal from 'sweetalert'

import * as SecureLs from 'secure-ls'

const ls = new SecureLs();


const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up('xs')]: {
      paddingTop: '150px',
      height: 700
    },
    [theme.breakpoints.up('sm')]: {
      paddingTop: '150px',
      height: 750
    },
    [theme.breakpoints.up('md')]: {
      paddingTop: '150px',
      height: 700
    },
    [theme.breakpoints.up('lg')]: {
      paddingTop: '170px',
      height: 700
    },
    [theme.breakpoints.up('xl')]: {
      paddingTop: '200px',
      height: 900
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submitBtn: {
    borderRadius: '20px',
    backgroundColor: '#9e69d2',
    "&:hover": {
      backgroundColor: "#574bb3 !important",
    },
    paddingTop: '10px',
    paddingBottom: '10px',
    marginTop: '20px',
    marginBottom: '20px'
  },
  btnText: {
    textTransform: 'capitalize',
    fontSize: '15px',
    paddingLeft: '10px',
    paddingRight: '10px',
    fontWeight: 'bold',
    color: '#fbfbfb'
  },
  facebookclass: {
    width: '100%',
    height: '45px',
    backgroundColor: '#4267B2',
    color: '#fff',
    border: 'none',
    fontSize: '14px'
  }
}));

const ForgotPassComponent = () => {
  const classes = useStyles();

  const [emailRecover, setEmailRecover] = useState({
    email: ''
  })

  const handleChangeLogin = email => event => {
    setEmailRecover({ ...emailRecover, [email]: event.target.value })
  }

  // selector
  const response = useSelector(state => state.login)


  const showLoading = () => (
    response.loading && (
      <Box textAlign='center' pb={2} >
        <CircularProgress />
      </Box>
    )
  )


  const onClickSubmit = (e) => {
    e.preventDefault()

    let formdata = new FormData();

    // let token = localStorage.getItem('token')  
    let token = ls.get('@_tknid').data
    let Headers = {
      headers: {
        "Content-Type": "multipart/form-data",
        "Authorization": `Bearer ${token}`
      },
    };

    formdata.append("email", emailRecover.email);

    Axios.post(`${API}/recover_password`, formdata, Headers)
      .then((res) => {
        if (res.data.error) {
          swal("Error!", `${res.data.error}`, "error");
        } else if (res.data.success) {
          swal({
            title: "Success!",
            text: `${res.data.success}`,
            icon: "success",
            button: "Oke",
          })
            .then((redirect) => {
              if (redirect) {
                window.location.href = "/login";
              }
            });
        }
      }).catch((err) => {
        swal("Error", `${err.response}`, "error");
      })
  }

  return (
    <>

      <Container component="main" maxWidth="xs" className={classes.root}>
        <CssBaseline />
        <div className={classes.paper}>

          <Box pb={2} id='fontStyle' fontSize={35} fontWeight='bold' color='#9e69d2' >Lupa Password</Box>

          <form className={classes.form} onSubmit={onClickSubmit} >

            {showLoading()}
            <Grid container>
              <Grid item xs={12}>
                <Box pb={1}>Masukkan e-mail yang terdaftar. Kami akan mengirimkan link untuk atur ulang kata sandi. </Box>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email"
                  name="email"
                  onChange={handleChangeLogin('email')}
                  autoComplete="email"
                  autoFocus
                />
              </Grid>


            </Grid>


            <Button
              type='submit'
              fullWidth
              variant="contained"
              color="action"
              className={classes.submitBtn}

            >
              <Box id='fontStyle' className={classes.btnText} >Simpan</Box>
            </Button>


            <Grid container>
              <Grid item xs>
                <Link href="/login" variant="body2" style={{ color: '#9e69d2' }}>
                  Kembali ke Login
              </Link>
              </Grid>
            </Grid>
          </form>
        </div>

      </Container>

    </>
  );
}

export default ForgotPassComponent;