import Axios from 'axios'
import { API } from './../../config'
// import moment from 'moment'
import * as SecureLs from 'secure-ls'

import {
    // GET DATA UPCOMING EVENT
    GET_UPCOMING_EVENT_REQUEST,
    GET_UPCOMING_EVENT_FAILED,
    GET_UPCOMING_EVENT_SUCCESS,
    GET_ONLINE_EVENT_SUCCESS,
    GET_STORE_EVENT_SUCCESS,
    GET_PUBLIC_EVENT_SUCCESS

} from './UpcEventTypes'
import jwt_decode from 'jwt-decode'


const ls = new SecureLs();

const CheckToken = () => {
    let rtoken = ls.get('@_rtknid').data
    let tokenn = ls.get('@_tknid').data
    if (tokenn) {
        var jwt = jwt_decode(tokenn);
        var today = new Date().getTime();
        var jwt_exp = jwt.exp * 1000 - (1000 * 20); // sebelum 20 detik

        if (jwt_exp < today) {
            // request
            let formdata = new FormData()
            let options = {
                headers: {
                    Authorization: `Bearer ${tokenn}`
                }
            }
            formdata.append("refresh_token", rtoken)
            const refreshToken = Axios.post(`${API}/refresh_token`, formdata, options, null)
            if (refreshToken) {
                ls.set('@_tknid', { data: refreshToken.data.data.token })
                ls.set('@_rtknid', { data: refreshToken.data.data.refreshToken })
            }
        } else {
            console.log("tes")

        }
    }
}

const getUpcomingEventRequest = () => {
    return {
        type: GET_UPCOMING_EVENT_REQUEST
    }
}


const getUpcomingEventFailed = (message) => {
    return {
        type: GET_UPCOMING_EVENT_FAILED,
        payload: message
    }
}

const getUpcomingEventSuccess = (data) => {
    return {
        type: GET_UPCOMING_EVENT_SUCCESS,
        payload: data
    }
}

export const getUpcomingEvent = () => {
    let token = ls.get('@_tknid').data

    return (
        async (dispatch) => {
            try {
                const testing = await CheckToken()
                let token = ls.get('@_tknid').data
                if (token) {
                    await Axios.get(`${API}/event/all`, { headers: { Authorization: `Bearer ${token}` } })
                        .then((res) => {
                            let eventupcomingAll = res.data.data.filter(e => new Date(e.event_start_datetime) > new Date(Date.now()))
                            dispatch(getUpcomingEventSuccess(eventupcomingAll))
                        }).catch((err) => {
                            dispatch(getUpcomingEventFailed(err))
                            if (err.response.status === 401) {
                                let tokenn = ls.get('@_tknid').data
                                let rtokenn = ls.get('@_rtknid').data
                                let formdata = new FormData()
                                let options = {
                                    headers: {
                                        Authorization: `Bearer ${tokenn}`
                                    }
                                }
                                formdata.append("refresh_token", rtokenn)
                                Axios.post(`${API}/refresh_token`, formdata, options, null)
                                    .then(res => {
                                        ls.set('@_tknid', { data: res.data.data.token })
                                        ls.set('@_rtknid', { data: res.data.data.refreshToken })
                                    }).catch(err => {
                                    })
                            }
                        })
                } else {

                    await Axios.get(`${API}/event/all`)
                        .then((res) => {
                            let eventupcomingAll = res.data.data.filter(e => new Date(e.event_start_datetime) > new Date(Date.now()))
                            dispatch(getUpcomingEventSuccess(eventupcomingAll))
                        }).catch((err) => {
                            dispatch(getUpcomingEventFailed(err))
                        })
                }
            } catch (error) {
                ls.removeAll()
                localStorage.clear()
                sessionStorage.clear()
                window.location.href = "/"
            }
            // dispatch(getUpcomingEventRequest())

        }
    )
}

// ONLINE 
const getOnlineEventSuccess = (data) => {
    return {
        type: GET_ONLINE_EVENT_SUCCESS,
        payload: data
    }
}

export const getOnlineEvent = () => {
    // return (
    //     (dispatch) => {
    //         // dispatch(getUpcomingEventRequest())
    //         Axios.get(`${API}/event/all`)
    //             .then((res) => {
    //                 let eventOnline = res.data.data.filter(e => (new Date(e.event_start_datetime) > new Date(Date.now())) && e.category_venue.category_venue_name === "Online Events")
    //                 dispatch(getOnlineEventSuccess(eventOnline))
    //             }).catch((err) => {
    //                 dispatch(getUpcomingEventFailed(err))
    //             })
    //     }
    // )


    return (
        async (dispatch) => {
            try {
                const testing = await CheckToken()
                let token = ls.get('@_tknid').data
                if (token) {
                    await Axios.get(`${API}/event/all`, { headers: { Authorization: `Bearer ${token}` } })
                        .then((res) => {
                            let eventOnline = res.data.data.filter(e => (new Date(e.event_start_datetime) > new Date(Date.now())) && e.category_venue.category_venue_name === "Online Events")

                            dispatch(getOnlineEventSuccess(eventOnline))
                        }).catch((err) => {
                            dispatch(getUpcomingEventFailed(err))
                            if (err.response.status === 401) {
                                let tokenn = ls.get('@_tknid').data
                                let rtokenn = ls.get('@_rtknid').data
                                let formdata = new FormData()
                                let options = {
                                    headers: {
                                        Authorization: `Bearer ${tokenn}`
                                    }
                                }
                                formdata.append("refresh_token", rtokenn)
                                Axios.post(`${API}/refresh_token`, formdata, options, null)
                                    .then(res => {
                                        ls.set('@_tknid', { data: res.data.data.token })
                                        ls.set('@_rtknid', { data: res.data.data.refreshToken })
                                    }).catch(err => {
                                    })
                            }
                        })
                } else {
                    await Axios.get(`${API}/event/all`)
                        .then((res) => {
                            let eventOnline = res.data.data.filter(e => (new Date(e.event_start_datetime) > new Date(Date.now())) && e.category_venue.category_venue_name === "Online Events")
                            dispatch(getOnlineEventSuccess(eventOnline))
                        }).catch((err) => {
                            dispatch(getUpcomingEventFailed(err))
                        })
                }
            } catch (error) {
                ls.removeAll()
                localStorage.clear()
                sessionStorage.clear()
                window.location.href = "/"
            }
            // dispatch(getUpcomingEventRequest())

        }
    )
}

// STORE 
const getStoreEventSuccess = (data) => {
    return {
        type: GET_STORE_EVENT_SUCCESS,
        payload: data
    }
}

export const getStoreEvent = () => {

    return (
        async (dispatch) => {
            try {
                const testing = await CheckToken()
                let token = ls.get('@_tknid').data
                if (token) {
                    await Axios.get(`${API}/event/all/?upcoming_only=true&store_only=true`, { headers: { Authorization: `Bearer ${token}` } })
                        .then((res) => {
                            let storeEvent = res.data.data.filter(e => (new Date(e.event_start_datetime) > new Date(Date.now())) && e.category_venue.category_venue_name === "Store Event")
                            dispatch(getStoreEventSuccess(storeEvent))
                        }).catch((err) => {
                            dispatch(getUpcomingEventFailed(err))
                            if (err.response.status === 401) {
                                let tokenn = ls.get('@_tknid').data
                                let rtokenn = ls.get('@_rtknid').data
                                let formdata = new FormData()
                                let options = {
                                    headers: {
                                        Authorization: `Bearer ${tokenn}`
                                    }
                                }
                                formdata.append("refresh_token", rtokenn)
                                Axios.post(`${API}/refresh_token`, formdata, options, null)
                                    .then(res => {
                                        ls.set('@_tknid', { data: res.data.data.token })
                                        ls.set('@_rtknid', { data: res.data.data.refreshToken })
                                    }).catch(err => {
                                    })
                            }
                        })
                } else {
                    await Axios.get(`${API}/event/all/?upcoming_only=true&store_only=true`)
                        .then((res) => {
                            let storeEvent = res.data.data.filter(e => (new Date(e.event_start_datetime) > new Date(Date.now())) && e.category_venue.category_venue_name === "Store Event")
                            dispatch(getStoreEventSuccess(storeEvent))
                        }).catch((err) => {
                            dispatch(getUpcomingEventFailed(err))
                        })
                }
            } catch (error) {
                ls.removeAll()
                localStorage.clear()
                sessionStorage.clear()
                window.location.href = "/"
            }
            // dispatch(getUpcomingEventRequest())

        }
    )
}

// PUBLIC 
const getPublicEventSuccess = (data) => {
    return {
        type: GET_PUBLIC_EVENT_SUCCESS,
        payload: data
    }
}

export const getPublicEvent = () => {
    // return (
    //     (dispatch) => {
    //         // dispatch(getUpcomingEventRequest())
    //         Axios.get(`${API}/event/all`)
    //             .then((res) => {
    //                 let eventupcomingPublic = res.data.data.filter(e => (new Date(e.event_start_datetime) > new Date(Date.now())) && e.category_venue.category_venue_name === "Public Event")
    //                 dispatch(getPublicEventSuccess(eventupcomingPublic))
    //             }).catch((err) => {
    //                 dispatch(getUpcomingEventFailed(err))
    //                 // console.log(err)

    //             })
    //     }
    // )


    return (
        async (dispatch) => {
            try {
                const testing = await CheckToken()
                let token = ls.get('@_tknid').data
                if (token) {
                    await Axios.get(`${API}/event/all`, { headers: { Authorization: `Bearer ${token}` } })
                        .then((res) => {
                            let eventupcomingPublic = res.data.data.filter(e => (new Date(e.event_start_datetime) > new Date(Date.now())) && e.category_venue.category_venue_name === "Public Event")
                            dispatch(getPublicEventSuccess(eventupcomingPublic))
                        }).catch((err) => {
                            dispatch(getUpcomingEventFailed(err))
                            if (err.response.status === 401) {
                                let tokenn = ls.get('@_tknid').data
                                let rtokenn = ls.get('@_rtknid').data
                                let formdata = new FormData()
                                let options = {
                                    headers: {
                                        Authorization: `Bearer ${tokenn}`
                                    }
                                }
                                formdata.append("refresh_token", rtokenn)
                                Axios.post(`${API}/refresh_token`, formdata, options, null)
                                    .then(res => {
                                        ls.set('@_tknid', { data: res.data.data.token })
                                        ls.set('@_rtknid', { data: res.data.data.refreshToken })
                                    }).catch(err => {
                                    })
                            }
                        })
                } else {
                    await Axios.get(`${API}/event/all`)
                        .then((res) => {
                            let eventupcomingPublic = res.data.data.filter(e => (new Date(e.event_start_datetime) > new Date(Date.now())) && e.category_venue.category_venue_name === "Public Event")
                            dispatch(getPublicEventSuccess(eventupcomingPublic))
                        }).catch((err) => {
                            dispatch(getUpcomingEventFailed(err))
                        })
                }

            } catch (error) {
                ls.removeAll()
                localStorage.clear()
                sessionStorage.clear()
                window.location.href = "/"
            }
            // dispatch(getUpcomingEventRequest())

        }
    )
}

