import React from 'react';
import AppBarUser from '../../components/AppBarUser';
import InfoKontak from '../../components/DashboardUser/InfoKontak/InfoKontak'
import { Grid, Container } from '@material-ui/core';
import MenuSideUser from '../../components/DashboardUser/MenuSideUser';
import Meta from '../../components/Util/MetaDecorator'


const InfoKontakPage = () => {
    // document.title = "Info Kontak | Eventories.com "

    return (
        <React.Fragment>
            <Meta title="Info Detail | Eventories.com" description="Klik untuk melihat info kontak" />

            <AppBarUser />
            <Container maxWidth='lg'>
                <Grid container spacing={2}>
                    <Grid item lg={2} md={2} sm={12} xs={12} >
                        <MenuSideUser />

                    </Grid>
                    <Grid item lg={10} md={10} sm={12} xs={12}>

                        <InfoKontak />

                    </Grid>

                </Grid>
            </Container>
        </React.Fragment>
    )
}

export default InfoKontakPage;