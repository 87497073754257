import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { Link, withRouter } from 'react-router-dom'
import Linkto from '@material-ui/core/Link'


const isActive = (history, path) => {
    if (history.location.pathname === path) {
        return {
            borderRadius: '30px',
            backgroundColor: '#9e69d2',
            width: '100%',
            color: '#fbfbfb',
            height: 'auto',
            border: 'solid 1px #fbfbfb',
            fontWeight: 'bold',
            marginBottom: '10px',
            fontSize: '15px',
            textAlign: 'center',
            cursor: 'pointer',
            paddingTop: '7px',
            paddingBottom: '7px'
        };
    } else {
        return {
            borderRadius: '30px',
            backgroundColor: '#fbfbfb',
            width: '100%',
            color: '#707070',
            height: 'auto',
            border: 'solid 1px #fbfbfb',
            marginBottom: '15px',
            fontSize: '15px',
            textAlign: 'center',
            cursor: 'pointer',
            paddingTop: '7px',
            paddingBottom: '7px'
        };
    }
};

const isActiveMobile = (history, path) => {
    if (history.location.pathname === path) {
        return {
            borderRadius: '30px',
            backgroundColor: '#9e69d2',
            width: '100%',
            color: '#fbfbfb',
            height: '40px',
            border: 'solid 1px #fbfbfb',
            fontWeight: 'bold',
            marginBottom: '10px',
            fontSize: '13px',
            textAlign: 'center',
            cursor: 'pointer',
            paddingTop: '7px',
            paddingLeft: '20px',
            paddingRight: '20px'
        };
    } else {
        return {
            borderRadius: '30px',
            backgroundColor: '#fbfbfb',
            width: '100%',
            color: '#707070',
            height: '40px',
            border: 'solid 1px #fbfbfb',
            marginBottom: '15px',
            fontSize: '13px',
            textAlign: 'center',
            cursor: 'pointer',
            paddingTop: '7px',
            paddingLeft: '20px',
            paddingRight: '20px'
        };
    }
};

const useStyles = makeStyles((theme) => ({
    root: {
        [theme.breakpoints.up('xs')]: {
            display: 'none',
            paddingTop: theme.spacing(15),
        },
        [theme.breakpoints.up('sm')]: {
            display: 'none',
            paddingTop: theme.spacing(15),
        },
        [theme.breakpoints.up('md')]: {
            display: 'block',
            paddingTop: theme.spacing(15),
        },
        [theme.breakpoints.up('lg')]: {
            display: 'block',
            paddingTop: theme.spacing(15),
        },
        [theme.breakpoints.up('xl')]: {
            display: 'block',
            paddingTop: theme.spacing(15),
        },
    },
    menuMobile: {
        paddingTop: theme.spacing(15),
        [theme.breakpoints.up('xs')]: {
            display: 'flex'
        },
        [theme.breakpoints.up('sm')]: {
            display: 'flex'
        },
        [theme.breakpoints.up('md')]: {
            display: 'none'
        },
        [theme.breakpoints.up('lg')]: {
            display: 'none'
        },
        [theme.breakpoints.up('xl')]: {
            display: 'none'
        },
    }


}))


const MenuSideUser = ({ history }) => {
    const classes = useStyles();

    return (
        <>
            <Box className={classes.root} >
                <Linkto href='/profile-detail'>
                    <div style={isActive(history, "/profile-detail")}>
                        Profil
                </div>
                </Linkto>
                <Linkto href='/profile-password'>
                    <div style={isActive(history, "/profile-password")}>
                        Password & Keamanan
                </div>
                </Linkto>
            </Box>

            <Box className={classes.menuMobile} >
                <Linkto href='/profile-detail'>
                    <div style={isActiveMobile(history, "/profile-detail")}>
                        Profil
                </div>
                </Linkto>
                <Linkto href='/profile-password'>
                    <div style={isActiveMobile(history, "/profile-password")}>
                        Password & Keamanan
                </div>
                </Linkto>

            </Box>
        </>
    );
}

export default withRouter(MenuSideUser);

