import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(theme => ({
    rootDisplay: {
        [theme.breakpoints.up('xs')] : {
            display:'none'
        },
        [theme.breakpoints.up('sm')] : {
            display:'block'
        },
        [theme.breakpoints.up('md')] : {
            display:'block'
        },
        [theme.breakpoints.up('lg')] : {
            display:'block'
        },
        [theme.breakpoints.up('xl')] : {
            display:'block'
        }   
    },
    rootDisplayMobile: {
        [theme.breakpoints.up('xs')] : {
            display:'block'
        },
        [theme.breakpoints.up('sm')] : {
            display:'none'
        },
        [theme.breakpoints.up('md')] : {
            display:'none'
        },
        [theme.breakpoints.up('lg')] : {
            display:'none'
        },
        [theme.breakpoints.up('xl')] : {
            display:'none'
        }       
    },
    rootdiv: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3)
    },        
    buttonticket: {
        color: '#9e69d2',
        backgroundColor: '#fff',
        [theme.breakpoints.up('xs')] : {
            borderRadius: '20px',
            paddingLeft: '10px',
            paddingRight: '10px',
        },
        [theme.breakpoints.up('sm')] : {
            borderRadius: '20px',
            paddingLeft: '10px',
            paddingRight: '10px',
        },
        [theme.breakpoints.up('md')] : {
            borderRadius: '25px',
            paddingLeft: '30px',
            paddingRight: '30px',
        },
        [theme.breakpoints.up('lg')] : {
            borderRadius: '30px',
            paddingLeft: '40px',
            paddingRight: '40px',           
        },
        [theme.breakpoints.up('xl')] : {
            borderRadius: '30px',
            paddingLeft: '40px',
            paddingRight: '40px',
        }
    },
    boxSearch: {
        borderRadius: '10px',        
        backgroundColor: '#9e69d2',
        [theme.breakpoints.up('xs')] : {
            paddingBottom: '30px',
            paddingLeft: '20px',
            paddingRight: '20px',
            paddingTop: '20px',
        },
        [theme.breakpoints.up('sm')] : {
            paddingBottom: '30px',
            paddingLeft: '20px',
            paddingRight: '20px',
            paddingTop: '20px',
        },
        [theme.breakpoints.up('md')] : {
            paddingBottom: '30px',
            paddingLeft: '20px',
            paddingRight: '20px',
            paddingTop: '20px',
        },
        [theme.breakpoints.up('lg')] : {
            paddingBottom: '30px',
            paddingLeft: '20px',
            paddingRight: '20px',
            paddingTop: '20px',       
        },
        [theme.breakpoints.up('xl')] : {
            paddingBottom: '30px',
            paddingLeft: '20px',
            paddingRight: '20px',
            paddingTop: '20px',
        }
    },
    buttonSearch : {
        textAlign: 'center',
        [theme.breakpoints.up('xs')] : {
            paddingTop: '10px'
        },
        [theme.breakpoints.up('sm')] : {
            paddingTop: '32px'
        },
        [theme.breakpoints.up('md')] : {
            paddingTop: '32px'
        },
        [theme.breakpoints.up('lg')] : {
            paddingTop: '32px'            
        },
        [theme.breakpoints.up('xl')] : {
            paddingTop: '32px'
        }
    }
}));
