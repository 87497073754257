import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import {
  makeStyles,
  Box
} from '@material-ui/core';
import Container from '@material-ui/core/Container';
import { useSelector, useDispatch } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';

import {
  userLogin
} from './../../redux';
import IconGoogle from '../../assets/img/icon-google.png'
import ReactHtmlParser from 'react-html-parser';
import { GoogleLogin } from 'react-google-login';
import { GOOGLE, API } from '../../config'
import swal from 'sweetalert'
import Axios from 'axios';
import * as SecureLs from 'secure-ls'

const ls = new SecureLs();

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up('xs')]: {
      paddingTop: '150px',
      height: 700
    },
    [theme.breakpoints.up('sm')]: {
      paddingTop: '150px',
      height: 750
    },
    [theme.breakpoints.up('md')]: {
      paddingTop: '150px',
      height: 700
    },
    [theme.breakpoints.up('lg')]: {
      paddingTop: '170px',
      height: 700
    },
    [theme.breakpoints.up('xl')]: {
      paddingTop: '200px',
      height: 900
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submitBtn: {
    borderRadius: '20px',
    backgroundColor: '#9e69d2',
    "&:hover": {
      backgroundColor: "#574bb3 !important",
    },
    paddingTop: '10px',
    paddingBottom: '10px',
    marginTop: '20px',
    marginBottom: '20px'
  },
  BtnGoogle: {
    backgroundColor: 'whitesmoke',
    "&:hover": {
      backgroundColor: "#dedede !important",
    },
  },
  btnText: {
    textTransform: 'capitalize',
    fontSize: '15px',
    paddingLeft: '10px',
    paddingRight: '10px',
    fontWeight: 'bold',
    color: '#fbfbfb'
  },
  facebookclass: {
    width: '100%',
    height: '45px',
    backgroundColor: '#4267B2',
    color: '#fff',
    border: 'none',
    fontSize: '14px'
  }
}));

const LoginComponent = () => {
  const classes = useStyles();

  const [signinEmail, setSigninEmail] = useState({
    email: '',
    password: '',
    captcha: ''
  })

  const [closeGoogle, setcloseGoogle] = useState(false);

  const handleChangeLogin = email => event => {
    setSigninEmail({ ...signinEmail, [email]: event.target.value })
  }

  // dispatch action
  const dispatch = useDispatch()

  // selector
  const response = useSelector(state => state.login)
  const captcha = useSelector(state => state.login.captcha)


  const showLoading = () => (
    response.loading && (
      <Box textAlign='center' pb={2} >
        <CircularProgress />
      </Box>
    )
  )


  const onClickSubmit = (e) => {
    e.preventDefault()
    // console.log("ghj")
    dispatch(userLogin(signinEmail))
  }


  const responseGoogle = (response) => {
    setTimeout(async () => {
      // console.log(">", response)
      let id_token = response.tokenId
      let formdata = new FormData();

      formdata.append("id_token", id_token);
      const login = Axios.post(`${API}/login/google`, formdata)
        .then((res) => {
          if (res.data.data) {
            ls.set('@_tknid', { data: res.data.data.auth.token })
            ls.set('@_rtknid', { data: res.data.data.auth.refreshToken })
            localStorage.setItem('name', res.data.data.name)
            localStorage.setItem('id', res.data.data.id)
            swal("Success", "Now Redirecting..", "success");
            window.location.href = "/";
          }

        }).catch((res) => {
          swal("Error", `${res.data.error}`, "error");
          // let formdata = new FormData()
        })
      return login;
    }, 1000);
  }

  const responseFailure = (e) => {
    setcloseGoogle(true)
  }


  return (
    <>

      <Container component="main" maxWidth="xs" className={classes.root}>
        <CssBaseline />
        <div className={classes.paper}>

          <Box pb={2} id='fontStyle' fontSize={35} fontWeight='bold' color='#9e69d2' >Masuk</Box>

          <form className={classes.form} onSubmit={onClickSubmit} >

            {showLoading()}
            <Grid container>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email"
                  name="email"
                  onChange={handleChangeLogin('email')}
                  autoComplete="email"
                  autoFocus
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  onChange={handleChangeLogin('password')}
                />
              </Grid>
              {
                captcha ?
                  <>
                    <Grid item xs={12}>
                      <Grid container alignContent="center" alignItems="center" justify="space-between" >
                        <Grid xs={12} sm={6} md={6} lg={6} xl={6}>
                          <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Input captcha"
                            type="captcha"
                            id="captcha"
                            autoComplete="current-captcha"
                            onChange={handleChangeLogin('captcha')}
                          />
                        </Grid>
                        <Grid xs={12} sm={6} md={6} lg={6} xl={6}>
                          <div>
                            {ReactHtmlParser(captcha)}
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                  :
                  null
              }


            </Grid>

            <Button
              type='submit'
              fullWidth
              variant="contained"
              color="action"
              className={classes.submitBtn}
            >
              <Box id='fontStyle' className={classes.btnText} >Masuk</Box>
            </Button>
            <Box pb={3} textAlign="center" style={{ width: "100%" }}>
              <GoogleLogin
                clientId={GOOGLE}
                render={renderProps => (
                  <Box textAlign="center">
                    <Button className={classes.BtnGoogle} fullWidth onClick={renderProps.onClick} disabled={renderProps.disabled} >
                      <Grid container alignContent="center" spacing={2} alignItems="center">
                        <Grid item xl={4} lg={4} md={4} sm={4} xs={3} >
                          <Box py={1} textAlign="right">
                            <img src={IconGoogle} alt="icon_google" width="25px" />
                          </Box> {' '}
                        </Grid>
                        <Grid item xl={8} lg={8} md={8} sm={8} xs={9} >
                          <Box py={1} textAlign="left" style={{ textTransform: "capitalize" }}>Masuk dengan Google</Box>
                        </Grid>
                      </Grid>
                    </Button>
                  </Box>
                )}
                buttonText="Masuk dengan Google"
                onSuccess={responseGoogle}
                onFailure={responseFailure}
                cookiePolicy={'single_host_origin'}
                isSignedIn={true}
              />
            </Box>

            <Grid container>
              <Grid item xs>
                <Link href="/forgot-password" variant="body2" style={{ color: '#9e69d2' }}>
                  Lupa Password
              </Link>
              </Grid>
              <Grid item>
                Buat Akun Baru?
              <Link href="/register" variant="body2" style={{ color: '#9e69d2' }}>
                  {" Daftarkan disini"}
                </Link>
              </Grid>
            </Grid>
          </form>
        </div>

      </Container>

    </>
  );
}

export default LoginComponent;