import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, fade } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Linkto from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import './../assets/css/main.css'
import IconButton from '@material-ui/core/IconButton';
// import MenuIcon from '@material-ui/icons/Menu';
import Container from '@material-ui/core/Container'
import logowhite from './../assets/img/logowhite.png'
// import SearchIcon from '@material-ui/icons/Search';
// import InputBase from '@material-ui/core/InputBase';
import logocolor from './../assets/img/logocolor.png'
import MenuIcon from '@material-ui/icons/Menu';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
// import { useSelector } from 'react-redux';

import profileimg from './../assets/img/profile.png'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import profile_default from './../assets/img/profile.png'
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import { useSelector, useDispatch } from 'react-redux';
import {
  getDataProfile,
  klikUnshowSearch,
  getEventByKeyword,
  klikSearchByKeyword
} from './../redux';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
// import { Redirect } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import swal from 'sweetalert'

import Axios from 'axios'
import { API, GOOGLE } from '../config'

import SearchBar from "material-ui-search-bar";
import { GoogleLogout } from 'react-google-login';
import { gapi } from 'gapi-script';

import * as SecureLs from 'secure-ls'
const ls = new SecureLs();

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    color: 'white',
    paddingTop: '10px',
    paddingBottom: '10px'
  },
  buttonclass: {
    color: '#e0e0e0'
  },
  list: {
    width: 210,
    justifyContent: 'right'
  },
  fullList: {
    width: 'auto',
  },
  buttonMenu: {
    [theme.breakpoints.up('xs')]: {
      display: 'none'
    },

    [theme.breakpoints.up('sm')]: {
      display: 'none'

    },
    [theme.breakpoints.up('md')]: {
      display: 'block'

    },
    [theme.breakpoints.up('lg')]: {
      display: 'block',



    },
    [theme.breakpoints.up('xl')]: {
      display: 'block',
    },
  },
  menuMobile: {
    [theme.breakpoints.up('xs')]: {
      display: 'block'
    },

    [theme.breakpoints.up('sm')]: {
      display: 'block'

    },
    [theme.breakpoints.up('md')]: {
      display: 'none'

    },
    [theme.breakpoints.up('lg')]: {
      display: 'none'

    },
    [theme.breakpoints.up('xl')]: {
      display: 'none',
    },
    center: {
      justifyContent: 'center'
    },
    large: {
      width: theme.spacing(10),
      height: theme.spacing(10),
    },
    medium: {
      width: theme.spacing(4),
      height: theme.spacing(4),
      marginRight: '20px'
    },

  },
  rightmenu: {
    [theme.breakpoints.up('xs')]: {
      display: 'none'
    },

    [theme.breakpoints.up('sm')]: {
      display: 'none'

    },
    [theme.breakpoints.up('md')]: {
      display: 'block'

    },
    [theme.breakpoints.up('lg')]: {
      display: 'block',
    },
    [theme.breakpoints.up('xl')]: {
      display: 'block',
    }
  },

  textAccess: {
    [theme.breakpoints.up('xs')]: {
      fontSize: "14px"
    },

    [theme.breakpoints.up('sm')]: {
      fontSize: "14px"

    },
    [theme.breakpoints.up('md')]: {
      fontSize: "18px"

    },
    [theme.breakpoints.up('lg')]: {
      fontSize: "18px",
    },
  },

  textField: {
    [theme.breakpoints.up('xs')]: {
      fontSize: "14px"
    },

    [theme.breakpoints.up('sm')]: {
      fontSize: "14px"

    },
    [theme.breakpoints.up('md')]: {
      fontSize: "16px"

    },
    [theme.breakpoints.up('lg')]: {
      fontSize: "16px",
    }

  },

  btnStyles: {
    borderRadius: '20px',

  },
  btnText: {
    textTransform: 'capitalize',
    fontSize: '15px',
    paddingLeft: '10px',
    paddingRight: '10px',
    fontWeight: 'bold'
  },
  buttonwidth: {
    maxWidth: '300px',
    // minWidth: '300px',
    width: '300px',
    justifyContent: 'left',
    color: '#000',
    textTransform: 'capitalize',
  },


}));

export default function AppBarComponent() {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const [openPrivate, setOpenPrivate] = useState(false);
  const handleDialog = () => {
    setOpenPrivate(false)
    setprivateEvent({
      slug: '',
      access: ''
    })
  }

  const [keyword, setkeyword] = useState('');


  const [privateEvent, setprivateEvent] = useState({
    slug: '',
    access: ''
  })
  const [loading, setloading] = useState(false);

  const handlePrivate = i => event => {
    setprivateEvent({
      ...privateEvent
      , [i]: event.target.value
    })
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const profile = useSelector(state => state.profile.get_profile)
  // const searchedEvent = useSelector(state => state.searched_event.searched_keyword_event)
  // const showSearched = useSelector(state => state.searched_event.showbykey)
  // const color = useSelector(state => state.category_event.color)



  const dispatch = useDispatch()

  useEffect(() => {
    // if (localStorage.getItem('token')) {
    if (localStorage.getItem('@_tknid')) {

      dispatch(getDataProfile())
      // console.log(dispatch(getDataProfile()))
    }
  }, [dispatch]);

  useEffect(() => {
    document.addEventListener('scroll', () => {
      if (window.scrollY < 70) {
        setHeaderBackground('fixed')
      } else if (window.scrollY < 100) {
        setHeaderBackground('opacity25%')
      } else if (window.scrollY < 200) {
        setHeaderBackground('opacity50%')
      } else if (window.scrollY < 225) {
        setHeaderBackground('opacity60%')
      } else if (window.scrollY < 250) {
        setHeaderBackground('opacity75%')
      } else if (window.scrollY < 300) {
        setHeaderBackground('opacity80%')
      } else if (window.scrollY < 400) {
        setHeaderBackground('opacity90%')
      } else {
        setHeaderBackground('action')
      }
    })
  })


  // console.log("color", color)

  const [headerBackground, setHeaderBackground] = useState('fixed')

  const [state, setState] = React.useState({
    top: false,
    left: false,

  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };




  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List style={{ justifyContent: 'center' }}>
        <a href='/'>
          <ListItem className={classes.center}>
            <img alt="logo" src={logocolor} width='150px' />
          </ListItem>
        </a>
      </List>

      <Divider />
      {
        // localStorage.getItem('token') 
        localStorage.getItem('@_tknid') ?

          <List>


            <Link to='/dashboard' >
              <ListItem>
                <Button className={classes.buttonwidth}><Box id='fontStyle' >Dashboard</Box></Button>
              </ListItem>
            </Link>

            <Link to='/myevent' >
              <ListItem>
                <Button className={classes.buttonwidth}><Box id='fontStyle'>Tiket</Box></Button>
              </ListItem>
            </Link>
            <Link to='/event-disukai' >
              <ListItem>
                <Button className={classes.buttonwidth}><Box id='fontStyle'>Event Disukai</Box></Button>
              </ListItem>
            </Link>

            <Link to='/transaksi' >
              <ListItem>
                <Button className={classes.buttonwidth}><Box id='fontStyle'>Transaksi</Box></Button>
              </ListItem>
            </Link>

            {/* <Link >
              <ListItem>
                <Button onClick={() => {
                  setOpenPrivate(true)
                }} className={classes.buttonwidth}><Box id='fontStyle'>Private Event</Box></Button>
              </ListItem>
            </Link> */}


            <Link to='/profile-detail' >
              <ListItem>
                <Button className={classes.buttonwidth}><Box id='fontStyle'>Kelola Akun</Box></Button>
              </ListItem>
            </Link>


            {/* <a href='/' onClick={() => localStorage.clear()}> */}
            <a
            // onClick={async () => {
            //   let token = ls.get('@_tknid').data
            //   let r_token = ls.get('@_rtknid').data
            //   let formdata = new FormData();
            //   formdata.append("refresh_token", r_token)
            //   try {
            //     const res1 = await Axios.post(`${API}/token/own`, formdata, { headers: { Authorization: `Bearer ${token}` } })
            //     if (res1) {
            //       const found = res1.data.data.find(e => e.current_status);
            //       // console.log("d", found)
            //       if (found && found.current_status) {
            //         const res = await Axios.delete(`${API}/token/${found.id}`, { headers: { Authorization: `Bearer ${token}` } })
            //         if (res) {
            //           ls.removeAll()
            //           localStorage.clear()
            //           window.location.href = "/"
            //         }
            //       }
            //     }
            //   } catch (error) {

            //   }
            // }}
            >
              <ListItem>
                {/* <Button className={classes.buttonwidth}>
                  <Box id='fontStyle'

                  > */}
                <GoogleLogout
                  clientId={GOOGLE}
                  render={renderProps => (
                    <Box textAlign="center">
                      <Button className={classes.buttonwidth} onClick={renderProps.onClick} disabled={renderProps.disabled}>
                        <Box id='fontStyle'
                        >
                          Keluar</Box>
                      </Button>
                    </Box>
                  )}
                  buttonText="Keluar"
                  icon="false"
                  onLogoutSuccess={(r) => {
                    // console.log("<<", window.sessionStorage)
                    // console.log(">>", window.localStorage)
                    const auth2 = gapi.auth2.getAuthInstance();
                    if (auth2 != null) {
                      auth2.signOut().then(
                        auth2.disconnect().then(console.log('LOGOUT SUCCESSFUL'))
                      )
                      ls.removeAll()
                      localStorage.clear()
                      sessionStorage.clear()
                      window.location.href = "/"
                    }
                  }}
                >
                </GoogleLogout>
                {/* Keluar</Box>
                </Button> */}
              </ListItem>
            </a>


            <Divider />

            <Link to='/syarat-ketentuan' >
              <ListItem>
                <Button className={classes.buttonwidth}><Box id='fontStyle'>Syarat & Ketentuan</Box></Button>
              </ListItem>
            </Link>
            {/* 
            <Link to='/dashboard' >
              <ListItem>
                <Button className={classes.buttonwidth}><Box id='fontStyle'>Kebijakan Privasi</Box></Button>
              </ListItem>
            </Link> */}

          </List>
          :
          <List>
            <a target="_blank" rel="noopener noreferrer" href='https://www.mediacartz.com/' >
              <ListItem>
                <Button className={classes.buttonwidth}><Box id='fontStyle' >Mediacartz</Box></Button>
              </ListItem>
            </a>

            <Link to='/login' >
              <ListItem>
                <Button className={classes.buttonwidth}><Box id='fontStyle' >Masuk</Box></Button>
              </ListItem>
            </Link>

            <Link to='/register' >
              <ListItem>
                <Button className={classes.buttonwidth}><Box id='fontStyle'>Daftar</Box></Button>
              </ListItem>
            </Link>
          </List>

      }

    </div>
  );



  const getPrivateEvent = async () => {
    setloading(true)
    await Axios.get(`${API}/event/slug/${privateEvent.slug}/${privateEvent.access}`)
      .then(res => {
        if (res.data.error) {
          swal("Error!", `${res.data.error}`, "error");
          setloading(false)
        } else {
          setloading(false)
          if (loading === false) {
            // console.log("masuk")
            window.location.href = `/detail-event/${privateEvent.slug}/${privateEvent.access}`;
            handleDialog()
            // return <Redirect to={`/detail-event/${privateEvent.slug}/${privateEvent.access}`} />
          }
        }
      }).catch(err => {

      })
  }

  const getEvent = (e) => {
    // if ((/^\s*$/.test(keyword) === false)) {
    //   dispatch(klikSearchByKeyword(keyword))
    //   dispatch(getEventByKeyword(keyword, "", 1))
    //   window.location.href = `/search/${keyword}`
    // }

    // if (keyword) {
    // dispatch(klikSearchByKeyword(keyword))
    // dispatch(getEventByKeyword(keyword, "", 1))
    // window.location.href = `/search/${keyword}`
    // }
    // if (keyword) {
    //   const regexpEmojiPresentation = /[.,\/#!$%\^&\*;:][|{}=?\-_`~()]/g;
    //   console.log(regexpEmojiPresentation.test(keyword));

    //   console.log(keyword.match(regexpEmojiPresentation));
    // }
    if ((/^\s*$/.test(keyword) === false) && keyword.length > 0) {
      dispatch(klikSearchByKeyword(keyword))
      dispatch(getEventByKeyword(keyword, "", 1))
      window.location.href = `/search/${keyword}`
    }

  }

  return (
    <React.Fragment>

      <Dialog onClose={handleDialog} aria-labelledby="simple-dialog-title" open={openPrivate} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Private Event</DialogTitle>
        <DialogContent>
          <DialogContentText className={classes.textAccess}>
            Untuk melihat event, silakan masukkan Slug event dan Kode Access nya
          </DialogContentText>
          <TextField
            className={classes.textField}

            autoFocus
            margin="dense"
            id="slug"
            label="Slug Event"
            type="text"
            // onChange={(e) => setslug(e)}
            onChange={handlePrivate('slug')}

            fullWidth
          />
          <TextField
            className={classes.textField}
            margin="dense"
            id="access"
            label="Kode"
            type="text"
            onChange={handlePrivate('access')}
            // onChange={(e) => setaccess(e)}

            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialog} color="primary">
            Tutup
          </Button>
          {
            loading ?
              <Button style={{ backgroundColor: "#9E69D2" }} onClick={getPrivateEvent} color="primary">
                <ClipLoader color="#FFFF" size={20} />
              </Button> :
              <Button style={{ backgroundColor: "#9E69D2", color: "#ffff", }} onClick={getPrivateEvent} color="primary">
                Cari
              </Button>
          }
        </DialogActions>
      </Dialog>

      {['left'].map((anchor) => (
        <React.Fragment key={anchor}>

          <Drawer anchor={anchor} open={state[anchor]} color='secondary' onClose={toggleDrawer(anchor, false)}>
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}


      <div className={classes.root}>
        <AppBar
          position="fixed"
          style={{
            backgroundColor: headerBackground === 'fixed' ? '#9e69d2' : '#9e69d2',
            paddingTop: '5px',
            paddingBottom: '5px'
          }}
        >
          <Container maxWidth='lg'>
            <Toolbar>
              <Typography variant="h6" className={classes.title} style={{ color: `${headerBackground === 'fixed' ? 'black' : 'black'}` }}>
                <a href='/'>
                  {
                    headerBackground === 'fixed' ?
                      <img
                        src={logowhite}
                        width='150px'
                        alt='logo'
                      />

                      :
                      <img
                        src={logowhite}
                        width='150px'
                        alt='logo'
                      />
                  }
                </a>
                <button className="searchbar" style={{ border: "none", borderRadius: '20px', backgroundColor: `${headerBackground === 'fixed' ? '#9e69d2' : '#9e69d2'}` }}>
                  <SearchBar
                    placeholder="Cari Event.."
                    onChange={(e) => setkeyword(e)}
                    onRequestSearch={getEvent}
                    style={{
                      // margin: '0',
                      maxWidth: 270,
                      height: 35,
                      color: '#9e69d2',
                      backgroundColor: 'white',
                      borderRadius: "20px"
                    }}

                  />
                </button>

              </Typography>
              <Typography className={classes.menuMobile}>
                <SearchBar
                  placeholder="Cari.."

                  onChange={(e) => setkeyword(e)}
                  onRequestSearch={getEvent}
                  style={{
                    // margin: '0',
                    marginRight: "10px",
                    maxWidth: 150,
                    // width: 140,
                    height: 35,
                    color: '#9e69d2',
                    backgroundColor: 'white',
                    fontSize: 10,
                  }}

                />
              </Typography>


              {['left'].map((anchor, i) => (
                <div className={classes.menuMobile} key={i}>
                  <MenuIcon style={{ color: headerBackground === 'fixed' ? '#fff' : '#fff' }}
                    onClick={toggleDrawer(anchor, true)} />
                </div>
              ))}





              <div className={classes.buttonMenu}>
                {
                  // localStorage.getItem('token')
                  localStorage.getItem('@_tknid') ?
                    <>
                      {/* <button style={{ border: "none", borderRadius: '20px', backgroundColor: `${headerBackground === 'fixed' ? 'white' : '#9e69d2'}` }}>
                        <SearchBar
                          placeholder="Cari Event.."
                          onChange={(e) => setkeyword(e)}
                          onRequestSearch={getEvent}
                          style={{
                            // margin: '0',
                            maxWidth: 270,
                            height: 35,
                            color: '#9e69d2',
                            backgroundColor: 'white',
                            borderRadius: "20px"
                          }}

                        />
                      </button> */}
                      <Linkto href={'/dashboard'} style={{ marginRight: '10px' }}>
                        <Button
                          style={{ color: `${headerBackground === 'fixed' ? 'white' : 'white'}` }}
                          onClick={() => {
                            klikUnshowSearch(false)
                          }}
                        >
                          <Box fontWeight='bold' style={{ textTransform: 'capitalize', fontSize: '15px' }}>Dashboard</Box>
                        </Button>
                      </Linkto>
                      {/* <Link to='/dashboard' style={{ marginRight: '10px' }}>
                        <Button
                          style={{ color: `${headerBackground === 'fixed' ? 'white' : 'white'}` }}
                          onClick={() => {
                            klikUnshowSearch(false)
                          }}
                        >
                          <Box fontWeight='bold' style={{ textTransform: 'capitalize', fontSize: '15px' }}>Dashboard</Box>
                        </Button>
                      </Link> */}
                      <Link to='/myevent' style={{ marginRight: '10px' }}>
                        <Button
                          style={{ color: `${headerBackground === 'fixed' ? 'white' : 'white'}` }}
                        >
                          <Box fontWeight='bold' style={{ textTransform: 'capitalize', fontSize: '15px' }}>Tiket</Box>
                        </Button>
                      </Link>

                      <Link to='/event-disukai' style={{ marginRight: '10px' }}>
                        <Button
                          style={{ color: `${headerBackground === 'fixed' ? 'white' : 'white'}` }}
                        >
                          <Box fontWeight='bold' style={{ textTransform: 'capitalize', fontSize: '15px' }}>Event Disukai</Box>
                        </Button>
                      </Link>
                      {/* <Link to='/transaksi' style={{ marginRight: '10px' }}>
                        <Button
                          style={{ color: `${headerBackground === 'fixed' ? 'white' : 'white'}` }}
                        >
                          <Box fontWeight='bold' style={{ textTransform: 'capitalize', fontSize: '15px' }}>Transaksi</Box>
                        </Button>
                      </Link> */}

                      
                      <Button
                        style={{ color: `${headerBackground === 'fixed' ? 'white' : 'white'}` }}
                        ref={anchorRef}
                        aria-controls={open ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"
                        onClick={handleToggle}
                      >
                        <IconButton>
                          <Avatar alt={localStorage.getItem('name')} src={profileimg} />
                        </IconButton>
                        <Box style={{ textTransform: 'capitalize', fontSize: '17px' }} fontWeight='bold'>
                          {localStorage.getItem('name')} <ExpandMoreIcon />
                        </Box>
                      </Button>

                      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                        {({ TransitionProps, placement }) => (
                          <Grow
                            {...TransitionProps}
                            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                          >
                            <Paper>

                              <ClickAwayListener onClickAway={handleClose}>
                                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                  <Box style={{ paddingTop: '10px', paddingBottom: '20px' }}>
                                    <Grid container direction='column' justify='center' alignItems='center'  >
                                      <Box>
                                        <Avatar style={{ width: '100px', height: '100px' }}>
                                          <img src={profile_default} alt='profile' width='100%' />
                                        </Avatar>
                                      </Box>
                                      <Box pt={1} color='#707070' fontSize={17} fontWeight='bold' >{profile.name}</Box>
                                      <Box color='#7D4DB7' fontSize={12} >{profile.email}</Box>
                                    </Grid>
                                  </Box>
                                  <Divider />
                                  {/* <Link to='/dashboard' >
                                    <MenuItem >
                                      <Box pl={2} id='fontStyle' color='black' >Dashboard</Box>
                                    </MenuItem>
                                  </Link>
                                  <Link to='/myevent' >
                                    <MenuItem style={{ paddingTop: '10px' }} >
                                      <Box pl={2} id='fontStyle' color='black' >Tiket </Box>
                                    </MenuItem>
                                  </Link>
                                  <Link to='/event-disukai' >
                                    <MenuItem >
                                      <Box pl={2} id='fontStyle' color='black' >Event Disukai</Box>
                                    </MenuItem>
                                  </Link>
                                  <Link to='/transaksi' >
                                    <MenuItem >
                                      <Box pl={2} id='fontStyle' color='black' >Transaksi</Box>
                                    </MenuItem>
                                  </Link> */}
                                  {/* <Link >
                                    <MenuItem >
                                      <Box onClick={() => {
                                        setOpenPrivate(true)
                                      }} pl={2} id='fontStyle' color='black' >Private Event</Box>
                                    </MenuItem>
                                  </Link> */}

                                  {/* <Link to='/dashboard' >
                                    <MenuItem >
                                      <Box pl={2} id='fontStyle' color='black' >Poin</Box>
                                    </MenuItem>
                                  </Link> */}
                                  <Link to='/profile-detail' >
                                    <MenuItem >
                                      <Box pl={2} id='fontStyle' color='black' >Kelola Akun</Box>
                                    </MenuItem>
                                  </Link>
                                  {/* <a href='/' onClick={() => localStorage.clear()}>
                                    <MenuItem >
                                      <Box pl={2} pb={1} id='fontStyle' color='black' >Keluar</Box>
                                    </MenuItem>
                                  </a> */}

                                  <a
                                  // onClick={async () => {
                                  //   let token = ls.get('@_tknid').data
                                  //   let r_token = ls.get('@_rtknid').data
                                  //   let formdata = new FormData();
                                  //   formdata.append("refresh_token", r_token)
                                  //   try {
                                  //     const res1 = await Axios.post(`${API}/token/own`, formdata, { headers: { Authorization: `Bearer ${token}` } })
                                  //     if (res1) {
                                  //       const found = res1.data.data.find(e => e.current_status);
                                  //       if (found && found.current_status) {
                                  //         const res = await Axios.delete(`${API}/token/${found.id}`, { headers: { Authorization: `Bearer ${token}` } })
                                  //         if (res) {
                                  //           ls.removeAll()
                                  //           localStorage.clear()
                                  //           window.location.href = "/"
                                  //         }
                                  //       }
                                  //     }
                                  //   } catch (error) {

                                  //   }
                                  // }}
                                  >
                                    <MenuItem >
                                      {/* <Box pl={2} pb={1} id='fontStyle' color='black' >Keluar</Box> */}
                                      <GoogleLogout
                                        clientId={GOOGLE}
                                        render={renderProps => (
                                          <Box >
                                            <Box pl={2} pb={1} id='fontStyle' color='black' onClick={renderProps.onClick} disabled={renderProps.disabled} >Keluar</Box>
                                          </Box>
                                        )}
                                        buttonText="Keluar"
                                        icon="false"
                                        onLogoutSuccess={(r) => {
                                          // console.log("<<", window.sessionStorage)
                                          // console.log(">>", window.localStorage)
                                          const auth2 = gapi.auth2.getAuthInstance();
                                          if (auth2 != null) {
                                            auth2.signOut().then(
                                              auth2.disconnect().then(console.log('LOGOUT SUCCESSFUL'))
                                            )
                                            ls.removeAll()
                                            localStorage.clear()
                                            sessionStorage.clear()
                                            window.location.href = "/"
                                          }
                                        }}
                                      >
                                      </GoogleLogout>
                                    </MenuItem>
                                  </a>
                                  <Divider />

                                  <Link to='/syarat-ketentuan' >
                                    <MenuItem >
                                      <Box pl={2} pt={1} id='fontStyle' color='black' >Syarat & Ketentuan</Box>
                                    </MenuItem>
                                  </Link>
                                  {/* <Link to='/dashboard' >
                                    <MenuItem >
                                      <Box pl={2} id='fontStyle' color='black' >Kebijakan Privasi</Box>
                                    </MenuItem>
                                  </Link> */}
                                </MenuList>
                              </ClickAwayListener>
                            </Paper>
                          </Grow>
                        )}
                      </Popper>

                      {/* <Link>
                  <Box 
                    id='fontTamma'
                    ref={anchorRef}
                    aria-controls={open ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle} 
                    className={classes.menuRight} 
                    style={isActive(history, "/profile")}
                  > 
                    <Grid container direction='row' >
                      <Box><Avatar className={classes.medium}>
                        <img src={profile_default} alt='profile' width='100%' />
                      </Avatar></Box>
                      <Box className={classes.rightName} >{profile.name} </Box>
                      <Box style={{textTransform: 'capitalize', fontSize:'17px'}} fontWeight='bold'>
                      {localStorage.getItem('name')} 
                    </Box>
                    </Grid>
                  </Box>              
                </Link> */}

                    </>

                    :

                    <>
                      <Link to='/login'>
                        <Button
                          variant='contained'
                          style={{
                            color: `${headerBackground === 'fixed' ? '#9e69d2' : '#9e69d2'}`,
                            backgroundColor: headerBackground === 'fixed' ? '#fff' : '#fff',
                            marginRight: '15px'
                          }}
                          className={classes.btnStyles}
                        >
                          <Box id='fontStyle' className={classes.btnText} >Masuk</Box>

                        </Button>
                      </Link>

                      <Link to='/register'>

                        <Button
                          variant='contained'
                          style={{
                            backgroundColor: headerBackground === 'fixed' ? '#fff' : '#fff',
                            color: `${headerBackground === 'fixed' ? '#9e69d2' : '#9e69d2'}`
                          }}
                          className={classes.btnStyles}
                        >
                          <Box id='fontStyle' className={classes.btnText} >Daftar</Box>
                        </Button>

                      </Link>
                    </>

                }

              </div>
            </Toolbar>
          </Container>
        </AppBar>
      </div>
    </React.Fragment >
  );
  // }
}
