import Axios from 'axios'
import { API } from './../../config'
import swal from 'sweetalert'
import {
    // register user email
    REGISTER_USER_REQUEST,
    REGISTER_USER_FAILED,
    REGISTER_USER_SUCCESS,

    SEND_LOGINUSER_REQUEST,
    SEND_LOGINUSER_SUCCESS,
    SEND_LOGINUSER_FAILED,

    GET_PROFILE_REQUEST,
    GET_PROFILE_SUCCESS,
    GET_PROFILE_FAILED,

    INPUT_CAPTCHA

} from './AuthUserTypes'

import * as SecureLs from 'secure-ls'

const ls = new SecureLs();

// register user by email
const registerUserEmailRequest = () => {
    return {
        type: REGISTER_USER_REQUEST
    }
}

const registerUserEmailFailed = (message) => {
    return {
        type: REGISTER_USER_FAILED,
        payload: message
    }
}

const registerUserEmailSuccess = (data) => {
    return {
        type: REGISTER_USER_SUCCESS,
        payload: data
    }
}

export const registerUserEmail = (input) => {
    let formdata = new FormData();

    let Headers = {
        headers: {
            "Content-Type": "multipart/form-data"
        },
    };

    formdata.append("name", input.name);
    formdata.append("email", input.email);
    formdata.append("password", input.password);
    formdata.append("retype_password", input.retype_password);

    return (
        (dispatch) => {
            dispatch(registerUserEmailRequest())
            Axios.post(`${API}/register`, formdata, Headers)
                .then((res) => {
                    if (res.data.error) {
                        swal("Error!", `${res.data.error}`, "error");
                        dispatch(registerUserEmailFailed(res.data.error))
                    } else if (res.data.data) {
                        swal({
                            title: "Yeaayy!",
                            text: `${res.data.success}`,
                            icon: "success",
                            button: "Login",
                        })
                            .then((redirect) => {
                                if (redirect) {
                                    window.location.href = "/login";
                                }
                            });
                        dispatch(registerUserEmailSuccess(res.data))
                    }
                }).catch((err) => {
                    swal("Error", `${err.response}`, "error");
                    dispatch(registerUserEmailFailed(err.response))
                })
        }
    )
}

const userLoginRequest = () => {
    return {
        type: SEND_LOGINUSER_REQUEST
    }
}

const userLoginSuccess = (data) => {
    return {
        type: SEND_LOGINUSER_SUCCESS,
        payload: data
    }
}

const userLoginFailed = (message) => {
    return {
        type: SEND_LOGINUSER_FAILED,
        payload: message
    }
}

const InsertCaptcha = (captcha) => {
    return {
        type: INPUT_CAPTCHA,
        payload: captcha
    }
}

export const userLogin = (input) => {
    let formdata = new FormData();

    formdata.append("email", input.email);
    formdata.append("password", input.password);
    if (input.captcha.length > 0) {
        formdata.append("captcha", Number(input.captcha));
    }

    return (
        (dispatch) => {
            dispatch(userLoginRequest())
            setTimeout(async () => {

                const login = Axios.post(`${API}/login`, formdata)
                    .then((res) => {
                        if (res.data.error) {
                            if (res.data.data !== undefined) {
                                swal("Error", `${res.data.error}`, "error");
                                dispatch(InsertCaptcha(res.data.data.captcha))
                            } else {
                                swal("Error", `${res.data.error}`, "error");
                                dispatch(userLoginFailed(res.data.error))
                            }

                        } else if (res.data.data) {
                            console.log('res login', res)
                            ls.set('@_tknid', { data: res.data.data.auth.token })
                            ls.set('@_rtknid', { data: res.data.data.auth.refreshToken })
                            ls.set('@_companydata', { data: res.data.data.first_company?.company })
                            // localStorage.setItem('token', res.data.data.auth.token)
                            // localStorage.setItem('refreshToken', res.data.data.auth.refreshToken)
                            // console.log(res.data.data.name)
                            localStorage.setItem('name', res.data.data.name)
                            localStorage.setItem('id', res.data.data.id)
                            dispatch(userLoginSuccess(res.data))
                            swal("Success", "Now Redirecting..", "success");
                            setTimeout(() => {
                                swal.close()
                                // window.location.href = "/dashboard";
                                window.location.href = "/";
                            }, 1500);
                        }

                    }).catch((res) => {
                        swal("Error", `${res.data.error}`, "error");
                        dispatch(userLoginFailed(res.data))
                    })
                return login;
            }, 1000);
        }
    )
}



const getDataProfileRequest = () => {
    return {
        type: GET_PROFILE_REQUEST
    }
}

const getDataProfileSuccess = (detailuser) => {
    return {
        type: GET_PROFILE_SUCCESS,
        payload: detailuser
    }
}

const getDataProfileFailed = (message) => {
    return {
        type: GET_PROFILE_FAILED,
        payload: message
    }
}

export const getDataProfile = (input) => {
    return (
        (dispatch) => {
            let token = ls.get('@_tknid').data

            dispatch(getDataProfileRequest())
            let options = {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
            Axios.get(`${API}/profile`, options)

                .then((res) => {
                    // console.log(res.data, 'get profile')
                    if (res.data.error) {
                        dispatch(getDataProfileFailed(res.error))

                    } else {
                        dispatch(getDataProfileSuccess(res.data.data))
                        // console.log(res.data, 'ini dari get profile')
                    }
                }).catch((res) => {
                    // console.log("iniiii", res.response.status)
                    dispatch(getDataProfileFailed(res.error))
                    // let tokenn = ls.get('@_tknid').data
                    // let rtokenn = ls.get('@_rtknid').data
                    // let formdata = new FormData()
                    // let options = {
                    //     headers: {
                    //         Authorization: `Bearer ${tokenn}`
                    //     }
                    // }
                    // formdata.append("refresh_token", rtokenn)
                    // Axios.post(`${API}/refresh_token`, formdata, options, null)
                    //     .then(res => {
                    //         ls.set('@_tknid', { data: res.data.data.token })
                    //         ls.set('@_rtknid', { data: res.data.data.refreshToken })
                    //     }).catch(err => {
                    //         // console.log(err, 'di tok')
                    //     })

                    if (res.response.status === 401) {
                        let tokenn = ls.get('@_tknid').data
                        let rtokenn = ls.get('@_rtknid').data
                        let formdata = new FormData()
                        let options = {
                            headers: {
                                Authorization: `Bearer ${tokenn}`
                            }
                        }
                        formdata.append("refresh_token", rtokenn)
                        Axios.post(`${API}/refresh_token`, formdata, options, null)
                            .then(res => {
                                ls.set('@_tknid', { data: res.data.data.token })
                                ls.set('@_rtknid', { data: res.data.data.refreshToken })
                            }).catch(err => {
                            })
                    }

                })
        }
    )
}

