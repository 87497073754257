import React from "react";
import PropTypes from 'prop-types'
import { Helmet } from "react-helmet";

const MetaDecorator = ({ title, description }) => {
    return (
        <Helmet>
            <title>{title}</title>
            <meta name="title" content={title} />
            <meta property="og:title" content={title} />
            <meta property="keyword" content={title} />
            <meta name="description" content={description} />
            <meta property="og:description" content={description} />
        </Helmet>
    );
};

MetaDecorator.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
};

export default MetaDecorator;