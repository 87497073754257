import React, { useEffect, useState } from 'react';
import { Grid, Box, Button } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import './../assets/css/main.css'
import DatePicker from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import {
    getCategoryEvent,
    getKotaList,
    getSearchedEventAll,
    klikSearch
} from './../redux'
import Axios from 'axios'
import { API } from './../config'
import { Typeahead } from 'react-bootstrap-typeahead';
import "react-datepicker/dist/react-datepicker.css";
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { Form } from 'react-bootstrap'
import moment from 'moment'
// styles
import useStyles from "./stylesComponent/SearchStyle";

const SearchEvent = () => {

    const classes = useStyles();
    // dispatch action
    const dispatch = useDispatch()
    const [kotalist, setkotalist] = useState([]);

    const category = useSelector(state => state.category_event.category_event)
    // const kotalist = useSelector(state => state.category_event.kota_list)

    useEffect(() => {
        dispatch(getCategoryEvent())
        // dispatch(getKotaList())
        Axios.get(`${API}/ms_city?limit=514`)
            .then((res) => {
                if (res.data.data) {
                    let list = res.data.data
                    list.sort((a, b) => (a.ms_city_name > b.ms_city_name) ? 1 : ((b.ms_city_name > a.ms_city_name) ? -1 : 0))
                    setkotalist(list)
                }
            }).catch((err) => {
            })
    }, [dispatch]);



    // function compare(a, b) {
    //     if (a.ms_city_name < b.ms_city_name) return -1
    //     if (a.ms_city_name > b.ms_city_name) return 1
    //     return 0;
    // }

    // kotalist.sort(compare);


    const [CategoryID, setCategoryID] = useState()
    const [KotaID, setKotaID] = useState()
    const [DateValue, setDateValue] = useState()
    const [page, setpage] = useState(1);

    const onClickSearch = () => {
        dispatch(getSearchedEventAll({
            'category': CategoryID === undefined ? '' : CategoryID && CategoryID.length === 0 ? '' : CategoryID[0].category_event_id,
            'kota': KotaID === undefined ? '' : KotaID && KotaID.length === 0 ? '' : KotaID[0].ms_city_id,
            'date': DateValue === undefined ? '' : DateValue === null ? '' : moment(DateValue).format('YYYY-MM-DD'),
            'page': page
        }))
        dispatch(klikSearch(true))
    }

    return (
        <div className={classes.rootdiv} >
            <Container maxWidth="lg" className={classes.rootDisplay} >
                <div >
                    <Box className={classes.boxSearch}>
                        <Grid container spacing={2} >
                            <Grid item lg={3} md={3} sm={3} xs={3}>
                                <Box >
                                    <Form.Label style={{ color: '#fff' }} >CARI EVENT</Form.Label>
                                    <Typeahead
                                        labelKey={options => options.category_event_name}
                                        id="basic-example"
                                        onChange={e => setCategoryID(e)}
                                        options={category}
                                        placeholder="Semua Event"
                                    />
                                </Box>
                            </Grid>
                            <Grid item lg={3} md={3} sm={3} xs={3}>
                                <Box >
                                    <Form.Label style={{ color: '#fbfbfb' }}>AREA</Form.Label>
                                    <Typeahead
                                        labelKey={options => options.ms_city_name}
                                        id="basic-example"
                                        onChange={e => setKotaID(e)}
                                        options={kotalist}
                                        placeholder="Semua lokasi"
                                    />
                                </Box>
                            </Grid>
                            <Grid item lg={3} md={3} sm={3} xs={3}>
                                <Box className="customDatePickerWidth" >
                                    <Form.Label style={{ color: '#fff' }}>KAPAN</Form.Label> <br />
                                    {/* <Box style={{ zIndex: 4, position: 'relative',}}> */}

                                    <DatePicker
                                        className="form-control dateInput"
                                        onChange={(e) => setDateValue(e)}
                                        placeholderText="Kapan Aja"
                                        selected={DateValue}
                                        style={{ width: '100%' }}
                                    />
                                    {/* </Box> */}
                                </Box>
                            </Grid>
                            <Grid item lg={3} md={3} sm={3} xs={3}>
                                <Box className={classes.buttonSearch} >
                                    <Button
                                        onClick={onClickSearch}
                                        color='action'
                                        variant='contained'
                                        fullWidth
                                        className={classes.buttonticket} >CARI EVENT</Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </div>
            </Container>

            <Container maxWidth="lg" className={classes.rootDisplayMobile}>
                <div >
                    <Box className={classes.boxSearch}>
                        <Grid container spacing={2} >
                            <Grid item lg={3} md={3} sm={3} xs={12}>
                                <Box >
                                    <Form.Label style={{ color: '#fff' }} >CARI EVENT</Form.Label>
                                    <Typeahead
                                        labelKey={options => options.category_event_name}
                                        id="basic-example"
                                        onChange={e => setCategoryID(e)}
                                        options={category}
                                        placeholder="Konser"
                                    />
                                </Box>
                            </Grid>

                            <Grid item lg={3} md={3} sm={3} xs={12}>
                                <Box >
                                    <Form.Label style={{ color: '#fbfbfb' }}>AREA</Form.Label>
                                    <Typeahead
                                        labelKey={options => options.ms_city_name}
                                        id="basic-example"
                                        onChange={e => setKotaID(e)}
                                        options={kotalist}
                                        placeholder="Jakarta"
                                    />
                                </Box>
                            </Grid>

                            <Grid item lg={3} md={3} sm={3} xs={12}>
                                <Box className="customDatePickerWidth" >
                                    <Form.Label style={{ color: '#fff' }}>KAPAN</Form.Label> <br />
                                    <DatePicker
                                        className="form-control dateInput"
                                        onChange={(e) => setDateValue(e)}
                                        placeholderText="Kapan Aja"
                                        selected={DateValue}
                                        style={{ width: '100%' }}
                                    />
                                </Box>
                            </Grid>
                            <Grid item lg={3} md={3} sm={3} xs={12}>
                                <Box className={classes.buttonSearch} >
                                    <Button
                                        onClick={onClickSearch}
                                        color='action'
                                        variant='contained'
                                        fullWidth
                                        className={classes.buttonticket} >CARI EVENT</Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </div>
            </Container>


        </div>

    )
}

export default SearchEvent;