import React, { useEffect, useState, useRef } from 'react';
import AppBarComponent from './AppBar'
// import SearchBar from '../components/searchEventMob'
import Searchresult from './../components/SearchedEvent'
// import SearchBarLg from './../components/SearchEvent'
import Skeleton from '@material-ui/lab/Skeleton';
import FooterDashboard from './FooterDashboard'
import { useLocation } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment'
import swal from 'sweetalert'
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import { FaShare } from 'react-icons/fa';
import queryString from 'query-string'
import {
    Container,
    Typography,
    Box,
    Grid,
    Link,
    Card,
    CardContent,
    CardMedia,
    Dialog,
    ListItem,
    ListItemAvatar,
    List,
    DialogTitle,
    Tooltip,
    Zoom
} from '@material-ui/core';
import Axios from 'axios'
import { API } from './../config'
import Pagination from '@material-ui/lab/Pagination';
import {
    EmailShareButton,
    EmailIcon,
    FacebookIcon,
    FacebookShareButton,
    LineShareButton,
    LineIcon,
    TelegramShareButton,
    TelegramIcon,
    TwitterShareButton,
    TwitterIcon,
    WhatsappShareButton,
    WhatsappIcon
} from "react-share";
import * as SecureLs from 'secure-ls'
import Meta from '../components/Util/MetaDecorator'
import LocationOnIcon from '@material-ui/icons/LocationOn';
import EventIcon from '@material-ui/icons/Event';
import jwt_decode from "jwt-decode";

// import { FavoriteBorder } from '@material-ui/icons';
const ls = new SecureLs();


const useStyles = makeStyles((theme) => ({
    rootdiv: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(5)
    },
    divmargin: {
        paddingLeft: '20px',
        paddingRight: '20px',
        paddingTop: '20px'
    },
    buttontab: {
        borderRadius: '30px'
    },
    buttonCategory: {
        textTransform: "capitalize",
        borderRadius: '30px',
        fontSize: '12px',
    },
    root: {
        marginTop: "40px",
        // maxWidth: 380,
        borderRadius: '10px 10px 0 0',
        position: 'relative',
        margin: '5px'
    },
    media: {
        height: 180,
        width: '100%',
        filter: 'brightness(0.8)'
    },
    info: {
        zIndex: 3,
        position: 'absolute',
        top: 0,
        left: 0
    },
    boxinfo: {

        opacity: 0.9,
        borderRadius: '15px',
    },
    share: {
        zIndex: 3,
        position: 'absolute',
        top: 0,
        right: 0
    },
    boxshare: {
        display: 'flex'
    },
    rootcatevent: {
        [theme.breakpoints.up('xs')]: {
            display: 'none'
        },
        [theme.breakpoints.up('sm')]: {
            display: 'block'
        },
        [theme.breakpoints.up('md')]: {
            display: 'block'
        },
        [theme.breakpoints.up('lg')]: {
            display: 'block'
        },
        [theme.breakpoints.up('xl')]: {
            display: 'block'
        }
    },
    rootcateventmob: {
        [theme.breakpoints.up('xs')]: {
            display: 'block'
        },
        [theme.breakpoints.up('sm')]: {
            display: 'none'
        },
        [theme.breakpoints.up('md')]: {
            display: 'none'
        },
        [theme.breakpoints.up('lg')]: {
            display: 'none'
        },
        [theme.breakpoints.up('xl')]: {
            display: 'none'
        }
    },
}))

const MoreEvent = () => {
    const classes = useStyles();

    let location = useLocation()
    let prop = queryString.parse(location.search)
    let category = prop.category
    // console.log("cate", category)


    const [page, setpage] = useState(1);
    const [dataEvent, setdataEvent] = useState();
    // const [dataLoaded, setDataLoaded] = useState(false);
    const [lastPage, setlastPage] = useState(0);
    const [openDialogShare, setopenDialogShare] = useState(false);
    const [shareLink, setshareLink] = useState("");
    const [kategori, setkategori] = useState("");
    const toup = useRef(null)

    const handleClose = () => {
        setopenDialogShare(false)
    }

    const CheckToken = () => {
        let rtoken = ls.get('@_rtknid').data
        let tokenn = ls.get('@_tknid').data
        if (tokenn) {
            var jwt = jwt_decode(tokenn);
            var today = new Date().getTime();
            var jwt_exp = jwt.exp * 1000 - (1000 * 20); // sebelum 20 detik

            if (jwt_exp < today) {
                // request
                let formdata = new FormData()
                let options = {
                    headers: {
                        Authorization: `Bearer ${tokenn}`
                    }
                }
                formdata.append("refresh_token", rtoken)
                const refreshToken = Axios.post(`${API}/refresh_token`, formdata, options, null)
                if (refreshToken) {
                    ls.set('@_tknid', { data: refreshToken.data.data.token })
                    ls.set('@_rtknid', { data: refreshToken.data.data.refreshToken })
                }
            } else {
                console.log("tes")

            }
        }
    }

    useEffect(() => {
        const loadEvent = async () => {
            try {
                // console.log(category)
                const testing = await CheckToken()

                await Axios.get(`${API}/category_event/${category}`)
                    .then(res => {
                        // console.log(res)
                        setkategori(res.data.data.category_event_name)
                    })

                let token = ls.get('@_tknid').data
                if (token) {
                    var url = ""
                    if (category === "all") url = `${API}/event/all?limit=6&page=${page}`
                    else url = `${API}/event/all?category_event_id=${category}&limit=6&page=${page}`
                    const res = await Axios.get(url, { headers: { Authorization: `Bearer ${token}` } })
                        .then((res) => {
                            let data = res.data.data
                            setdataEvent(data)
                            setlastPage(res.data.last_page)
                        })
                        .catch(err => console.log(err))
                    return res;
                } else {

                    var url = ""
                    if (category === "all") url = `${API}/event/all?limit=6&page=${page}`
                    else url = `${API}/event/all?category_event_id=${category}&limit=6&page=${page}`
                    const res = await Axios.get(url)
                        .then((res) => {
                            let data = res.data.data
                            setdataEvent(data)
                            setlastPage(res.data.last_page)
                        })
                        .catch(err => console.log(err))
                    return res;
                }
            } catch (error) {
                // console.log(error);
                ls.removeAll()
                localStorage.clear()
                sessionStorage.clear()
                window.location.href = "/"
            }
        }
        loadEvent()
    }, [])

    // console.log(">>>", dataEvent)

    const loadEvent = async (val) => {
        // try {
        //     var url = ""
        //     if (category === "all") url = `${API}/event/all?limit=6&page=${val}`
        //     else url = `${API}/event/all?category_event_id=${category}&limit=6&page=${val}`
        //     const res = await Axios.get(url)
        //         .then((res) => {
        //             let data = res.data.data
        //             setdataEvent(data)
        //             setlastPage(res.data.last_page)
        //         })
        //         .catch(err => console.log(err))
        //     return res;
        // } catch (error) {

        // }
        try {
            // console.log(category)
            const testing = await CheckToken()

            let token = ls.get('@_tknid').data
            if (token) {
                var url = ""
                if (category === "all") url = `${API}/event/all?limit=6&page=${val}`
                else url = `${API}/event/all?category_event_id=${category}&limit=6&page=${val}`
                const res = await Axios.get(url, { headers: { Authorization: `Bearer ${token}` } })
                    .then((res) => {
                        let data = res.data.data
                        setdataEvent(data)
                        setlastPage(res.data.last_page)
                    })
                    .catch(err => {
                        // console.log(err)
                        // if (err.response.status === 401) {
                        //     let tokenn = ls.get('@_tknid').data
                        //     let rtokenn = ls.get('@_rtknid').data
                        //     let formdata = new FormData()
                        //     let options = {
                        //         headers: {
                        //             Authorization: `Bearer ${tokenn}`
                        //         }
                        //     }
                        //     formdata.append("refresh_token", rtokenn)
                        //     Axios.post(`${API}/refresh_token`, formdata, options, null)
                        //         .then(res => {
                        //             ls.set('@_tknid', { data: res.data.data.token })
                        //             ls.set('@_rtknid', { data: res.data.data.refreshToken })
                        //         }).catch(err => {
                        //         })
                        // }
                    })
                return res;
            } else {

                var url = ""
                if (category === "all") url = `${API}/event/all?limit=6&page=${val}`
                else url = `${API}/event/all?category_event_id=${category}&limit=6&page=${val}`
                const res = await Axios.get(url)
                    .then((res) => {
                        let data = res.data.data
                        setdataEvent(data)
                        setlastPage(res.data.last_page)
                    })
                    .catch(err => {
                        // console.log(err)
                        // if (err.response.status === 401) {
                        //     let tokenn = ls.get('@_tknid').data
                        //     let rtokenn = ls.get('@_rtknid').data
                        //     let formdata = new FormData()
                        //     let options = {
                        //         headers: {
                        //             Authorization: `Bearer ${tokenn}`
                        //         }
                        //     }
                        //     formdata.append("refresh_token", rtokenn)
                        //     Axios.post(`${API}/refresh_token`, formdata, options, null)
                        //         .then(res => {
                        //             ls.set('@_tknid', { data: res.data.data.token })
                        //             ls.set('@_rtknid', { data: res.data.data.refreshToken })
                        //         }).catch(err => {
                        //         })
                        // }
                    })
                return res;
            }
        } catch (error) {
            ls.removeAll()
            localStorage.clear()
            sessionStorage.clear()
            window.location.href = "/"
            // console.log(error);
        }
    }

    // document.title = 'Eventories | Eventories.com '

    // console.log('last page', lastPage)
    return (
        <React.Fragment>
            <Meta title="Semua Event | Eventories.com" description="Semua Event" />

            {/* <div style={{ marginTop: '80px' }} className={classes.rootcatevent}><SearchBarLg /></div> */}
            {/* <div style={{ marginTop: '60px' }} className={classes.rootcateventmob}><SearchBar /></div> */}
            <Searchresult />

            <AppBarComponent />
            <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={openDialogShare}>
                <DialogTitle id="simple-dialog-title">Share to Friends</DialogTitle>

                <List>
                    <ListItem style={{ color: "black" }}>
                        <ListItemAvatar>
                            <FacebookShareButton url={`${shareLink}`}>
                                <FacebookIcon size={32} round={true} /> &nbsp; Facebook
                            </FacebookShareButton>
                        </ListItemAvatar>
                    </ListItem>
                </List>

                <List>
                    <ListItem style={{ color: "black" }}>
                        <ListItemAvatar>
                            <EmailShareButton url={`${shareLink}`}>
                                <EmailIcon size={32} round={true} />  &nbsp; Email
                            </EmailShareButton>
                        </ListItemAvatar>
                    </ListItem>
                </List>

                <List>
                    <ListItem style={{ color: "black" }}>
                        <ListItemAvatar>
                            <LineShareButton url={`${shareLink}`}>
                                <LineIcon size={32} round={true} />  &nbsp; Line
                            </LineShareButton>
                        </ListItemAvatar>
                    </ListItem>
                </List>

                <List>
                    <ListItem style={{ color: "black" }}>
                        <ListItemAvatar>
                            <TelegramShareButton url={`${shareLink}`}>
                                <TelegramIcon size={32} round={true} />  &nbsp; Telegram
                            </TelegramShareButton>
                        </ListItemAvatar>
                    </ListItem>
                </List>

                <List>
                    <ListItem style={{ color: "black" }}>
                        <ListItemAvatar>
                            <WhatsappShareButton url={`${shareLink}`}>
                                <WhatsappIcon size={32} round={true} />  &nbsp; Whatsapp
                            </WhatsappShareButton>
                        </ListItemAvatar>
                    </ListItem>
                </List>

                <List>
                    <ListItem style={{ color: "black" }}>
                        <ListItemAvatar>
                            <TwitterShareButton url={`${shareLink}`}>
                                <TwitterIcon size={32} round={true} />  &nbsp; Twitter
                            </TwitterShareButton>
                        </ListItemAvatar>
                    </ListItem>
                </List>
            </Dialog>
            <div className={classes.rootdiv} >
                <Container maxWidth="lg" style={{ marginTop: '30px' }} ref={toup} >
                    {/* <div className={classes.divmargin}> */}
                    <Grid container >
                        <Typography variant='h5'>
                            {
                                category === "" ?
                                    <Box pt={9} fontWeight={600} color='#9e69d2'> Semua Event</Box>
                                    :
                                    <Box pt={9} fontWeight={600} color='#9e69d2' style={{ textTransform: "capitalize" }}>Semua Event di kategori {kategori}</Box>
                            }
                            <Box fontWeight={100} style={{ fontSize: "15px", color: "gray" }}>Page {page} of {lastPage}</Box>
                        </Typography>
                    </Grid>
                    <Box pt={1} >
                        {
                            dataEvent ?

                                <>
                                    <Grid container>
                                        {
                                            dataEvent && dataEvent.map((data, index) => {
                                                return (
                                                    <>
                                                        <Grid item xl={4} lg={4} md={4} sm={6} xs={12} key={index}>
                                                            <Card className={classes.root} title={`${data.event_name} by ${data.organizer.organizer_name}`}>
                                                                <Link href={`/detail-event/${data.event_slug}`}>
                                                                    {/* <Box textAlign="center" className="imgevent" style={{
                                                                        height: "210px", alignContent: "center", backgroundImage: `url(${data.event_image_url})`
                                                                        // background: `linear-gradient(rgba(138, 133, 133, 0.367), rgba(171, 170, 170, 0.222)), url(${data.event_image_url})`
                                                                    }}></Box>
                                                                    <Box textAlign="center" className="imge" style={{ height: "210px", alignContent: "center", zIndex: 1 }}>
                                                                        <img width="auto" height="100%" src={data.event_image_url} />
                                                                    </Box> */}
                                                                    <Box textAlign="center" className="imgbanner" style={{ alignContent: "center", zIndex: 1 }}>
                                                                        <img width="100%" height="180px" src={data.event_image_url} />
                                                                    </Box>
                                                                </Link>
                                                                {/* like event */}
                                                                <CardContent className={classes.info}>

                                                                    <Link style={{ cursor: 'pointer' }}>
                                                                        {
                                                                            data.current_auth_liked ?
                                                                                <Tooltip TransitionComponent={Zoom} title="Hapus sebagai favorit" arrow placement="bottom">
                                                                                    <FavoriteIcon color="error"
                                                                                        onClick={async () => {
                                                                                            try {
                                                                                                const testing = await CheckToken()

                                                                                                let token = ls.get('@_tknid').data
                                                                                                // console.log('token', token)
                                                                                                const res = await Axios.delete(`${API}/event/like`, {
                                                                                                    headers: { Authorization: `Bearer ${token}` },
                                                                                                    data: { event_id: data.event_id }
                                                                                                })
                                                                                                if (res) {
                                                                                                    var url = ""
                                                                                                    if (category === "all") url = `${API}/event/all?limit=6&page=${page}`
                                                                                                    else url = `${API}/event/all?category_event_id=${category}&limit=6&page=${page}`
                                                                                                    await Axios.get(url, { headers: { Authorization: `Bearer ${token}` } })
                                                                                                        .then((res) => {
                                                                                                            let data = res.data.data
                                                                                                            setdataEvent(data)
                                                                                                            setlastPage(res.data.last_page)
                                                                                                        })
                                                                                                        .catch(err => {
                                                                                                            // console.log(err)
                                                                                                            // if (err.response.status === 401) {
                                                                                                            //     let tokenn = ls.get('@_tknid').data
                                                                                                            //     let rtokenn = ls.get('@_rtknid').data
                                                                                                            //     let formdata = new FormData()
                                                                                                            //     let options = {
                                                                                                            //         headers: {
                                                                                                            //             Authorization: `Bearer ${tokenn}`
                                                                                                            //         }
                                                                                                            //     }
                                                                                                            //     formdata.append("refresh_token", rtokenn)
                                                                                                            //     Axios.post(`${API}/refresh_token`, formdata, options, null)
                                                                                                            //         .then(res => {
                                                                                                            //             ls.set('@_tknid', { data: res.data.data.token })
                                                                                                            //             ls.set('@_rtknid', { data: res.data.data.refreshToken })
                                                                                                            //         }).catch(err => {
                                                                                                            //         })
                                                                                                            // }
                                                                                                        })
                                                                                                }
                                                                                            } catch (error) {
                                                                                                // console.log(error)
                                                                                                ls.removeAll()
                                                                                                localStorage.clear()
                                                                                                sessionStorage.clear()
                                                                                                window.location.href = "/"

                                                                                            }
                                                                                        }} />
                                                                                </Tooltip>
                                                                                :
                                                                                <Tooltip TransitionComponent={Zoom} title="Tambah sebagai favorit" arrow placement="bottom">
                                                                                    <FavoriteBorderIcon style={{ color: "white" }}
                                                                                        onClick={async () => {
                                                                                            try {
                                                                                                const testing = await CheckToken()

                                                                                                let token = ls.get('@_tknid').data
                                                                                                // console.log('token', token)

                                                                                                if (!token) {
                                                                                                    swal({
                                                                                                        title: "Login!",
                                                                                                        text: "Anda harus login terlabih dauhulu!",
                                                                                                        icon: "warning",
                                                                                                        buttons: true,
                                                                                                    })
                                                                                                        .then((redirect) => {
                                                                                                            if (redirect) {
                                                                                                                window.location.href = "/login";
                                                                                                            }
                                                                                                        });
                                                                                                } else {
                                                                                                    let formdata = new FormData()
                                                                                                    let options = {
                                                                                                        headers: {
                                                                                                            Authorization: `Bearer ${token}`
                                                                                                        }
                                                                                                    }
                                                                                                    formdata.append("event_id", data.event_id)
                                                                                                    const res = await Axios.post(`${API}/event/like`, formdata, options, null)
                                                                                                    if (res) {
                                                                                                        var url = ""

                                                                                                        if (category === "all") url = `${API}/event/all?limit=6&page=${page}`
                                                                                                        else url = `${API}/event/all?category_event_id=${category}&limit=6&page=${page}`
                                                                                                        await Axios.get(url, { headers: { Authorization: `Bearer ${token}` } })
                                                                                                            .then((res) => {
                                                                                                                let data = res.data.data
                                                                                                                setdataEvent(data)
                                                                                                                setlastPage(res.data.last_page)
                                                                                                            })
                                                                                                            .catch((err) => {
                                                                                                                // console.log(err)
                                                                                                                // if (err.response.status === 401) {
                                                                                                                //     let tokenn = ls.get('@_tknid').data
                                                                                                                //     let rtokenn = ls.get('@_rtknid').data
                                                                                                                //     let formdata = new FormData()
                                                                                                                //     let options = {
                                                                                                                //         headers: {
                                                                                                                //             Authorization: `Bearer ${tokenn}`
                                                                                                                //         }
                                                                                                                //     }
                                                                                                                //     formdata.append("refresh_token", rtokenn)
                                                                                                                //     Axios.post(`${API}/refresh_token`, formdata, options, null)
                                                                                                                //         .then(res => {
                                                                                                                //             ls.set('@_tknid', { data: res.data.data.token })
                                                                                                                //             ls.set('@_rtknid', { data: res.data.data.refreshToken })
                                                                                                                //         }).catch(err => {
                                                                                                                //         })
                                                                                                                // }
                                                                                                            })
                                                                                                    }
                                                                                                }
                                                                                            } catch (error) {
                                                                                                // console.log(error)
                                                                                                ls.removeAll()
                                                                                                localStorage.clear()
                                                                                                sessionStorage.clear()
                                                                                                window.location.href = "/"

                                                                                            }
                                                                                        }} />
                                                                                </Tooltip>

                                                                        }
                                                                    </Link>


                                                                </CardContent>
                                                                <CardContent className={classes.share} onClick={() => {
                                                                    setshareLink(data.event_full_url)
                                                                    setopenDialogShare(true)
                                                                }}>
                                                                    <Tooltip TransitionComponent={Zoom} title="Share" arrow placement="bottom">
                                                                        <Box className={classes.boxshare}>
                                                                            <Box pr={1} pl={1} >
                                                                                <Link style={{ cursor: 'pointer' }}>
                                                                                    <FaShare style={{ color: 'white' }} />
                                                                                </Link>
                                                                            </Box>
                                                                        </Box>
                                                                    </Tooltip>
                                                                </CardContent>


                                                            </Card>
                                                            <Box pl={1} pr={1} color='#000' style={{ width: '100%', whiteSpace: 'nowrap' }} >
                                                                <Link href={`/detail-event/${data.event_slug}`}>
                                                                    <Box title={`${data.event_name} by ${data.organizer.organizer_name}`} fontSize={17} fontWeight={600} overflow="hidden" textOverflow="ellipsis" color="#393755">{data.event_name}</Box>
                                                                </Link>
                                                                {
                                                                    moment(data.event_start_datetime).format('DD MMMM YYYY') !== moment(data.event_end_datetime).format('DD MMMM YYYY') ?
                                                                        <Box fontSize={14} fontWeight={100} overflow="hidden" textOverflow="ellipsis" color="#393755">
                                                                            <EventIcon style={{ fontSize: "14px" }} />{' '}
                                                                            {moment(data.event_start_datetime).format('DD MMMM YYYY')}
                                                                            {' '}- {' '}
                                                                            {moment(data.event_end_datetime).format('DD MMMM YYYY')}
                                                                        </Box> :
                                                                        <Box fontSize={14} fontWeight={100} overflow="hidden" textOverflow="ellipsis" color="#393755">
                                                                            <EventIcon style={{ fontSize: "14px" }} />{' '}
                                                                            {moment(data.event_end_datetime).format('DD MMMM YYYY')}
                                                                        </Box>
                                                                }
                                                                {/* <Box color="#393755" fontSize={14} overflow="hidden" textOverflow="ellipsis">{data.event_location_detail}
                                                                </Box> */}
                                                                {
                                                                    data.category_venue_id === 3 ?
                                                                        <Box color="#393755" fontSize={14} overflow="hidden" textOverflow="ellipsis"><LocationOnIcon style={{ fontSize: "14px" }} /> Online Event
                                                                                </Box> :
                                                                        // <Box color="#393755" fontSize={14} overflow="hidden" textOverflow="ellipsis"><LocationOnIcon style={{ fontSize: "14px" }} /> {data.event_location} {data.event_location_detail.length > 0 ? ` - ${data.event_location_detail}` : null}
                                                                        <Box color="#393755" fontSize={14} overflow="hidden" textOverflow="ellipsis"><LocationOnIcon style={{ fontSize: "14px" }} /> {data.event_location} {(data.event_location_detail && data.event_location_detail.length > 0) || data.event_location_detail !== null ? ` - ${data.event_location_detail}` : null}

                                                                        </Box>

                                                                }
                                                                <Box style={{ borderBottom: 'solid 2px #d1d1d1', paddingTop: '10px' }}></Box>
                                                            </Box>
                                                        </Grid>
                                                    </>
                                                )
                                            }
                                            )
                                        }
                                    </Grid>
                                    <Box pt={3} pb={2} display="flex" justifyContent="center" alignItems="center">
                                        <Pagination
                                            count={lastPage}
                                            color="primary"
                                            page={page}
                                            // href="#toup"

                                            onChange={(e, val) => {
                                                setpage(val)
                                                loadEvent(val)
                                                toup.current.scrollIntoView()
                                            }} />
                                    </Box>
                                </>
                                :
                                <>
                                    <Box className={classes.rootcatevent}>


                                        <Grid container spacing={2} style={{ height: '260px', paddingTop: "10px" }} >
                                            <Grid item lg={4} xl={4} md={3} >
                                                <Skeleton variant="rect" height={180} style={{ borderRadius: '10px' }} />
                                                <Skeleton variant="text" height={50} style={{ borderRadius: '10px' }} />

                                            </Grid>
                                            <Grid item lg={4} xl={4} md={3} >
                                                <Skeleton variant="rect" height={180} style={{ borderRadius: '10px' }} />
                                                <Skeleton variant="text" height={50} style={{ borderRadius: '10px' }} />

                                            </Grid>
                                            <Grid item lg={4} xl={4} md={3}>
                                                <Skeleton variant="rect" height={180} style={{ borderRadius: '10px' }} />
                                                <Skeleton variant="text" height={50} style={{ borderRadius: '10px' }} />

                                            </Grid>
                                            <Grid item lg={4} xl={4} md={3} >
                                                <Skeleton variant="rect" height={180} style={{ borderRadius: '10px' }} />
                                                <Skeleton variant="text" height={50} style={{ borderRadius: '10px' }} />

                                            </Grid>
                                            <Grid item lg={4} xl={4} md={3} >
                                                <Skeleton variant="rect" height={180} style={{ borderRadius: '10px' }} />
                                                <Skeleton variant="text" height={50} style={{ borderRadius: '10px' }} />

                                            </Grid>
                                            <Grid item lg={4} xl={4} md={3}>
                                                <Skeleton variant="rect" height={180} style={{ borderRadius: '10px' }} />
                                                <Skeleton variant="text" height={50} style={{ borderRadius: '10px' }} />

                                            </Grid>
                                        </Grid>
                                    </Box>

                                    <Box className={classes.rootcateventmob} >


                                        <Grid container spacing={2} style={{ height: '260px', paddingTop: "10px" }} >
                                            <Grid item sm={6} xs={12} >
                                                <Skeleton variant="rect" height={180} style={{ borderRadius: '10px' }} />
                                                <Skeleton variant="text" height={50} style={{ borderRadius: '10px' }} />

                                            </Grid>

                                            <Grid item sm={6} xs={12} >
                                                <Skeleton variant="rect" height={180} style={{ borderRadius: '10px' }} />
                                                <Skeleton variant="text" height={50} style={{ borderRadius: '10px' }} />

                                            </Grid>

                                            <Grid item sm={6} xs={12} >
                                                <Skeleton variant="rect" height={180} style={{ borderRadius: '10px' }} />
                                                <Skeleton variant="text" height={50} style={{ borderRadius: '10px' }} />

                                            </Grid>

                                        </Grid>
                                    </Box>


                                </>
                        }
                    </Box>
                    {/* <Box pt={3} pb={2} display="flex" justifyContent="center" alignItems="center">
                        <Pagination
                            count={lastPage}
                            color="primary"
                            page={page}
                            onChange={(e, val) => {
                                setpage(val)
                                loadEvent(val)
                            }} />
                    </Box> */}
                    {/* </div> */}
                </Container>
            </div>
            {
                dataEvent ?
                    <FooterDashboard /> : null
            }
        </React.Fragment>
    )

}

export default MoreEvent