import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import {
  AppBar,
  Divider,
  Toolbar,
  Box,
  Grid,
  List,
  ListItem,
  Button
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { Link, withRouter } from 'react-router-dom'
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Linkto from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Drawer from '@material-ui/core/Drawer';
import Container from '@material-ui/core/Container';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Avatar from '@material-ui/core/Avatar';
import imglogo from './../assets/img/logocolor.png'
import profile_default from './../assets/img/profile.png'

import { useSelector, useDispatch } from 'react-redux';
import {
  getDataProfile,
  klikUnshowSearch,
  // klikSearch
} from './../redux';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
// import { Redirect } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import swal from 'sweetalert'

import Axios from 'axios'
import { API, GOOGLE } from '../config'
import { GoogleLogout } from 'react-google-login';
import { gapi } from 'gapi-script';
import * as SecureLs from 'secure-ls'
const ls = new SecureLs();

const isActive = (history, path) => {
  if (history.location.pathname === path) {
    return {
      backgroundColor: '#9e69d2',
      borderRadius: '25px',
      fontWeight: "600",
      color: '#fbfbfb',
      textTransform: 'capitalize',
    };
  } else {
    return { color: 'black', fontWeight: "600", textTransform: 'capitalize' };
  }
};


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  leftMenu: {
    display: 'flex',
    direction: 'row',
    flexGrow: 1,
    marginLeft: '35px',
    paddingTop: '5px'
  },
  buttonclass: {
    color: '#e0e0e0'
  },
  list: {
    width: 210,
    justifyContent: 'right'
  },
  containerAppBar: {
    [theme.breakpoints.up('xs')]: {

    },

    [theme.breakpoints.up('sm')]: {
      marginLeft: '50px',
      marginRight: '50px'
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: '60px',
      marginRight: '60px'
    },
    [theme.breakpoints.up('lg')]: {
      marginLeft: '120px',
      marginRight: '120px'
    },
    [theme.breakpoints.up('xl')]: {
      marginLeft: '300px',
      marginRight: '300px'
    },
  },
  buttonMenu: {
    [theme.breakpoints.up('xs')]: {
      display: 'none'
    },

    [theme.breakpoints.up('sm')]: {
      display: 'none'

    },
    [theme.breakpoints.up('md')]: {
      display: 'flex'

    },
    [theme.breakpoints.up('lg')]: {
      display: 'flex'

    },
    [theme.breakpoints.up('xl')]: {
      display: 'flex'

    },
  },
  menuMobile: {
    [theme.breakpoints.up('xs')]: {
      display: 'block'
    },

    [theme.breakpoints.up('sm')]: {
      display: 'block'

    },
    [theme.breakpoints.up('md')]: {
      display: 'none'

    },
    [theme.breakpoints.up('lg')]: {
      display: 'none'

    },
    [theme.breakpoints.up('xl')]: {
      display: 'none'

    },
    center: {
      justifyContent: 'center'
    },
    largeAva: {
      width: theme.spacing(5),
      height: theme.spacing(5),
      paddingTop: '20px'
    },
    imageProfile: {
      marginTop: '20px'
    },
    medium: {
      width: theme.spacing(2),
      height: theme.spacing(2),
      marginRight: '10px'
    },

  },
  buttonwidth: {
    width: '300px',
    justifyContent: 'left',
    color: '#000',
    textTransform: 'capitalize',
  },
  btnSpacing: {
    paddingTop: '10px',
    paddingLeft: '15px',
    paddingRight: '15px',
    fontWeight: 400,
    fontSize: '18px'
  },
  menuLeft: {
    marginTop: '10px',
    paddingLeft: '20px',
    paddingRight: '20px',
    marginRight: '10px',
    paddingTop: '7px',
    paddingBottom: '10px',
    fontSize: '17px'
  },
  menuRight: {
    paddingTop: '5px',
    marginTop: '5px',
    marginLeft: '40px'
  },
  rightName: {
    fontSize: '20px',
    paddingLeft: '10px',
    paddingTop: '5px'
  }
}));

const AppBarUser = ({ history }) => {
  const classes = useStyles();


  const [state, setState] = React.useState({
    top: false,
    left: false,

  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const [openPrivate, setOpenPrivate] = useState(false);
  const handleDialog = () => {
    setOpenPrivate(false)
  }


  const [privateEvent, setprivateEvent] = useState({
    slug: '',
    access: ''
  })
  const [loading, setloading] = useState(false);

  const handlePrivate = email => event => {
    setprivateEvent({
      ...privateEvent
      , [email]: event.target.value
    })
  }

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List style={{ justifyContent: 'center' }}>
        <a href='/'>
          <ListItem className={classes.center}>
            <img alt="logo" src={imglogo} width='150px' />
          </ListItem>
        </a>
      </List>
      <Divider />
      <List>

        <Link to='/dashboard' >
          <ListItem>
            <Button onClick={() => {
              klikUnshowSearch(false)
            }} className={classes.buttonwidth}><Box id='fontStyle' >Dashboard</Box></Button>
          </ListItem>
        </Link>


        <Link to='/myevent' >
          <ListItem>
            <Button className={classes.buttonwidth}><Box id='fontStyle'>Tiket</Box></Button>
          </ListItem>
        </Link>

        <Link to='/event-disukai' >
          <ListItem>
            <Button className={classes.buttonwidth}><Box id='fontStyle'>Event Disukai</Box></Button>
          </ListItem>
        </Link>

        <Link to='/transaksi' >
          <ListItem>
            <Button className={classes.buttonwidth}><Box id='fontStyle'>Transaksi</Box></Button>
          </ListItem>
        </Link>

        {/* <Link >
          <ListItem>
            <Button onClick={() => {
              setOpenPrivate(true)
            }} className={classes.buttonwidth}><Box id='fontStyle'>Private Event</Box></Button>
          </ListItem>
        </Link> */}
        {/* <Link to='/dashboard' >
          <ListItem>
            <Button className={classes.buttonwidth}><Box id='fontStyle'>Poin</Box></Button>
          </ListItem>
        </Link> */}
        <Link to='/profile-detail' >
          <ListItem>
            <Button className={classes.buttonwidth}><Box id='fontStyle'>Kelola Akun</Box></Button>
          </ListItem>
        </Link>

        {/* <a href='/' onClick={() => localStorage.clear()}>
          <ListItem>
            <Button className={classes.buttonwidth}><Box id='fontStyle'>Keluar</Box></Button>
          </ListItem>
        </a> */}

        <a
        // onClick={async () => {
        //   let token = ls.get('@_tknid').data
        //   let r_token = ls.get('@_rtknid').data
        //   let formdata = new FormData();
        //   formdata.append("refresh_token", r_token)
        //   try {
        //     const res1 = await Axios.post(`${API}/token/own`, formdata, { headers: { Authorization: `Bearer ${token}` } })
        //     if (res1) {
        //       const found = res1.data.data.find(e => e.current_status);
        //       // console.log("d", found)
        //       if (found && found.current_status) {
        //         const res = await Axios.delete(`${API}/token/${found.id}`, { headers: { Authorization: `Bearer ${token}` } })
        //         if (res) {
        //           ls.removeAll()
        //           localStorage.clear()
        //           window.location.href = "/"
        //         }
        //       }
        //     }
        //   } catch (error) {

        //   }
        // }}

        >
          <ListItem>
            {/* <Button className={classes.buttonwidth}>
              <Box id='fontStyle'

              >Keluar</Box>
            </Button> */}
            <GoogleLogout
              clientId={GOOGLE}
              render={renderProps => (
                <Box textAlign="center">
                  <Button className={classes.buttonwidth} onClick={renderProps.onClick} disabled={renderProps.disabled}>
                    <Box id='fontStyle'
                    >
                      Keluar</Box>
                  </Button>
                </Box>
              )}
              buttonText="Keluar"
              icon="false"
              onLogoutSuccess={(r) => {
                // console.log("<<", window.sessionStorage)
                // console.log(">>", window.localStorage)
                const auth2 = gapi.auth2.getAuthInstance();
                if (auth2 != null) {
                  auth2.signOut().then(
                    auth2.disconnect().then(console.log('LOGOUT SUCCESSFUL'))
                  )
                  ls.removeAll()
                  localStorage.clear()
                  sessionStorage.clear()
                  window.location.href = "/"
                }
              }}
            >
            </GoogleLogout>
          </ListItem>
        </a>

        <Divider />

        <Link to='/syarat-ketentuan' >
          <ListItem>
            <Button className={classes.buttonwidth}><Box id='fontStyle'>Syarat & Ketentuan</Box></Button>
          </ListItem>
        </Link>

        {/* <Link to='/dashboard' >
          <ListItem>
            <Button className={classes.buttonwidth}><Box id='fontStyle'>Kebijakan Privasi</Box></Button>
          </ListItem>
        </Link> */}

      </List>
    </div>
  );

  const profile = useSelector(state => state.profile.get_profile)

  // console.log(profile, 'profile data')

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getDataProfile())
  }, [dispatch]);


  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);


  const getPrivateEvent = async () => {
    setloading(true)
    await Axios.get(`${API}/event/slug/${privateEvent.slug}/${privateEvent.access}`)
      .then(res => {
        if (res.data.error) {
          swal("Error!", `${res.data.error}`, "error");
          setloading(false)
        } else {
          setloading(false)
          if (loading === false) {
            // console.log("masuk")
            window.location.href = `/detail-event/${privateEvent.slug}/${privateEvent.access}`;
            handleDialog()
            // return <Redirect to={`/detail-event/${privateEvent.slug}/${privateEvent.access}`} />
          }
        }
      }).catch(err => {

      })
  }

  return (


    <React.Fragment>

      <Dialog onClose={handleDialog} aria-labelledby="simple-dialog-title" open={openPrivate} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Private Event</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Untuk melihat event, silakan masukkan Slug event dan Kode Access nya
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="slug"
            label="Slug Event"
            type="text"
            // onChange={(e) => setslug(e)}
            onChange={handlePrivate('slug')}

            fullWidth
          />
          <TextField
            margin="dense"
            id="access"
            label="Kode"
            type="text"
            onChange={handlePrivate('access')}
            // onChange={(e) => setaccess(e)}

            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialog} color="primary">
            Tutup
          </Button>
          {
            loading ?
              <Button style={{ backgroundColor: "#9E69D2" }} onClick={getPrivateEvent} color="primary">
                <ClipLoader color="#FFFF" size={20} />
              </Button> :
              <Button style={{ backgroundColor: "#9E69D2", color: "#ffff", }} onClick={getPrivateEvent} color="primary">
                Cari
              </Button>
          }
        </DialogActions>
      </Dialog>

      {['left'].map((anchor) => (
        <React.Fragment key={anchor}>

          <Drawer anchor={anchor} open={state[anchor]} color='secondary' onClose={toggleDrawer(anchor, false)}>
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}

      <div className={classes.root}>
        <AppBar position="fixed" style={{ backgroundColor: '#fbfbfb', height: '70px' }} >
          <Container maxWidth='lg'>
            <Toolbar >
              <Box>
                <a href='/'>
                  <img src={imglogo} width='150px' alt='logo' />
                </a>
              </Box>

              <Box className={classes.leftMenu}>
              </Box>

              <div className={classes.buttonMenu}>

                <Linkto href={'/dashboard'}>
                  <Box id='fontTamma' className={classes.menuLeft} style={isActive(history, "/dashboard")} >Dashboard</Box>
                </Linkto>

                <Link to='/myevent' >
                  <Box id='fontTamma' className={classes.menuLeft} style={isActive(history, "/myevent")} >Tiket</Box>
                </Link>

                <Link to='/event-disukai' >
                  <Box id='fontTamma' className={classes.menuLeft} style={isActive(history, "/event-disukai")} >Event Disukai</Box>
                </Link>


                {/* <Link to='/transaksi'>
                  <Box id='fontTamma' className={classes.menuLeft} style={isActive(history, "/transaksi")} >Transaksi</Box>
                </Link> */}

                {/* <Link to='/transaksi'>
                  <Box id='fontTamma' className={classes.menuLeft} style={isActive(history, "/transaksi")} >Transaksi</Box>
                </Link> */}

                <Box
                  id='fontTamma'
                  ref={anchorRef}
                  aria-controls={open ? 'menu-list-grow' : undefined}
                  aria-haspopup="true"
                  onClick={handleToggle}
                  className={classes.menuRight}
                  style={isActive(history, "/profile")}
                >
                  <Grid container direction='row' style={{cursor:'pointer'}}>
                    <Box><Avatar className={classes.medium}>
                      <img src={profile_default} alt='profile' width='100%' />
                    </Avatar></Box>
                    <Box className={classes.rightName} >{profile.name} <ExpandMoreIcon /></Box>
                  </Grid>
                </Box>


                <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                    >
                      <Paper>

                        <ClickAwayListener onClickAway={handleClose}>
                          <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                            <Box style={{ paddingTop: '10px', paddingBottom: '20px' }}>
                              <Grid container direction='column' justify='center' alignItems='center'  >
                                <Box>
                                  <Avatar style={{ width: '100px', height: '100px' }}>
                                    <img src={profile_default} alt='profile' width='100%' />
                                  </Avatar>
                                </Box>
                                <Box pt={1} color='#707070' fontSize={17} fontWeight='bold' >{profile.name}</Box>
                                <Box color='#9e69d2' fontSize={12} >{profile.email}</Box>
                              </Grid>
                            </Box>
                            <Divider />
                            {/* <Link to='/myevent' >
                              <MenuItem style={{ paddingTop: '10px' }} >
                                <Box pl={2} id='fontStyle' color='black' >Tiket</Box>
                              </MenuItem>
                            </Link>
                            <Link to='/event-disukai' >
                              <MenuItem >
                                <Box pl={2} id='fontStyle' color='black' >Event Disukai</Box>
                              </MenuItem>
                            </Link>
                            <Link to='/transaksi' >
                              <MenuItem >
                                <Box pl={2} id='fontStyle' color='black' >Transaksi</Box>
                              </MenuItem>
                            </Link> */}
                            {/* <Link >
                              <MenuItem >
                                <Box onClick={() => {
                                  setOpenPrivate(true)
                                }} pl={2} id='fontStyle' color='black' >Private Event</Box>
                              </MenuItem>
                            </Link> */}
                            {/* <Link to='/dashboard' >
                            <MenuItem >
                              <Box pl={2} id='fontStyle' color='black' >Poin</Box>
                            </MenuItem>
                          </Link> */}
                            <Link to='/profile-detail' >
                              <MenuItem >
                                <Box pl={2} id='fontStyle' color='black' >Kelola Akun</Box>
                              </MenuItem>
                            </Link>
                            {/* onClick={() => localStorage.clear()} */}
                            <a
                            // onClick={async () => {
                            //   let token = ls.get('@_tknid').data
                            //   let r_token = ls.get('@_rtknid').data
                            //   let formdata = new FormData();
                            //   formdata.append("refresh_token", r_token)
                            //   try {
                            //     const res1 = await Axios.post(`${API}/token/own`, formdata, { headers: { Authorization: `Bearer ${token}` } })
                            //     if (res1) {
                            //       const found = res1.data.data.find(e => e.current_status);
                            //       if (found && found.current_status) {
                            //         const res = await Axios.delete(`${API}/token/${found.id}`, { headers: { Authorization: `Bearer ${token}` } })
                            //         if (res) {
                            //           // console.log("d", found)
                            //           ls.removeAll()
                            //           localStorage.clear()
                            //           window.location.href = "/"
                            //         }
                            //       }
                            //     }
                            //   } catch (error) {

                            //   }
                            // }} 
                            >
                              <MenuItem >
                                {/* <Box pl={2} pb={1} id='fontStyle' color='black' >Keluar</Box> */}
                                <GoogleLogout
                                  clientId={GOOGLE}
                                  render={renderProps => (
                                    <Box >
                                      <Box pl={2} pb={1} id='fontStyle' color='black' onClick={renderProps.onClick} disabled={renderProps.disabled} >Keluar</Box>
                                    </Box>
                                  )}
                                  buttonText="Keluar"
                                  icon="false"
                                  onLogoutSuccess={(r) => {
                                    // console.log("<<", window.sessionStorage)
                                    // console.log(">>", window.localStorage)
                                    const auth2 = gapi.auth2.getAuthInstance();
                                    if (auth2 != null) {
                                      auth2.signOut().then(
                                        auth2.disconnect().then(console.log('LOGOUT SUCCESSFUL'))
                                      )
                                      ls.removeAll()
                                      localStorage.clear()
                                      sessionStorage.clear()
                                      window.location.href = "/"
                                    }
                                  }}
                                >
                                </GoogleLogout>
                              </MenuItem>
                            </a>

                            <Divider />

                            <Link to='/syarat-ketentuan' >
                              <MenuItem >
                                <Box pl={2} pt={1} id='fontStyle' color='black' >Syarat & Ketentuan</Box>
                              </MenuItem>
                            </Link>
                            {/* <Link to='/dashboard' >
                              <MenuItem >
                                <Box pl={2} id='fontStyle' color='black' >Kebijakan Privasi</Box>
                              </MenuItem>
                            </Link> */}
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>

              </div>

              {['left'].map((anchor) => (
                <div className={classes.menuMobile}>
                  <MenuIcon style={{ color: '#9e69d2' }} onClick={toggleDrawer(anchor, true)} />
                </div>
              ))}

            </Toolbar>
          </Container>
        </AppBar>
      </div>
    </React.Fragment>
  );
}


export default withRouter(AppBarUser)