import {
    // GET DATAO UPCOMING EVENT
    GET_POPULAR_EVENT_REQUEST,
    GET_POPULAR_EVENT_FAILED,
    GET_POPULAR_EVENT_SUCCESS
        
} from './PopEventTypes.js'

const initialState = {
    loading: false,
    message: '',
    popular_event: []

}
 
const PopularEventReducers = ( state = initialState, action ) => {
    switch(action.type) {
        case GET_POPULAR_EVENT_REQUEST:
            return {
                ...state,
                loading: true
            }
        case GET_POPULAR_EVENT_FAILED:
            return {
                ...state,
                loading: false,
                message: action.payload                
            }
        case GET_POPULAR_EVENT_SUCCESS:
            return {
                ...state,
                loading: false,
                popular_event: action.payload
            }             
        
        default: return state
    }
}

export default PopularEventReducers