import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Card,
    Box,
    Grid,
    CardMedia,
    Link,
    CardContent,
    // Button,
    Dialog,
    ListItem,
    ListItemAvatar,
    List,
    DialogTitle,
    Zoom,
    Tooltip
} from '@material-ui/core';
import Carousel from "react-multi-carousel";
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';

import { FaShare } from 'react-icons/fa';
import { getUpcomingEvent } from './../../redux'
import {
    EmailShareButton,
    EmailIcon,
    FacebookIcon,
    FacebookShareButton,
    LineShareButton,
    LineIcon,
    TelegramShareButton,
    TelegramIcon,
    TwitterShareButton,
    TwitterIcon,
    WhatsappShareButton,
    WhatsappIcon
} from "react-share";
import { useSelector, useDispatch } from 'react-redux';
import Skeleton from '@material-ui/lab/Skeleton';
import moment from 'moment'
import Axios from 'axios'
import { API } from '../../config'
import Tidaktersedia from '../../assets/img/statekosong.png'
import swal from 'sweetalert'
import LocationOnIcon from '@material-ui/icons/LocationOn';
import EventIcon from '@material-ui/icons/Event';
import jwt_decode from 'jwt-decode'
import * as SecureLs from 'secure-ls'
const ls = new SecureLs();

const useStyles = makeStyles((theme) => ({
    root: {
        // maxWidth: 380,
        borderRadius: '10px 10px 0 0',
        position: 'relative'
    },
    media: {
        height: 180,
        width: '100%',
        filter: 'brightness(0.8)'

    },
    info: {
        zIndex: 3,
        position: 'absolute',
        top: 0,
        left: 0
    },
    boxinfo: {
        opacity: 0.9,
        borderRadius: '15px',
    },
    share: {
        zIndex: 3,
        position: 'absolute',
        top: 0,
        right: 0
    },
    boxshare: {
        display: 'flex'
    },
    rootcatevent: {
        [theme.breakpoints.up('xs')]: {
            display: 'none'
        },
        [theme.breakpoints.up('sm')]: {
            display: 'block'
        },
        [theme.breakpoints.up('md')]: {
            display: 'block'
        },
        [theme.breakpoints.up('lg')]: {
            display: 'block'
        },
        [theme.breakpoints.up('xl')]: {
            display: 'block'
        }
    },
    rootcateventmob: {
        [theme.breakpoints.up('xs')]: {
            display: 'block'
        },
        [theme.breakpoints.up('sm')]: {
            display: 'none'
        },
        [theme.breakpoints.up('md')]: {
            display: 'none'
        },
        [theme.breakpoints.up('lg')]: {
            display: 'none'
        },
        [theme.breakpoints.up('xl')]: {
            display: 'none'
        }
    },
    carousel: {
        [theme.breakpoints.up('xs')]: {
            // paddingRight
        },
        [theme.breakpoints.up('sm')]: {
            // display: 'none'
            paddingRight: '10px',

        },
        [theme.breakpoints.up('md')]: {
            // display: 'none'
            paddingRight: '11px',
        },
        [theme.breakpoints.up('lg')]: {
            paddingRight: '11px',

        },
        [theme.breakpoints.up('xl')]: {
            paddingRight: '11px',

        }
    },


}));

const UpcomingEventAll = () => {
    const classes = useStyles();

    // dispatch action
    const dispatch = useDispatch()

    // selector
    const data_event = useSelector(state => state.upcoming_event.upcoming_event)
    const loading = useSelector(state => state.upcoming_event)

    const [openDialogShare, setopenDialogShare] = useState(false);
    const [shareLink, setshareLink] = useState("");

    const handleClose = () => {
        setopenDialogShare(false)
    }

    const CheckToken = () => {
        let rtoken = ls.get('@_rtknid').data
        let tokenn = ls.get('@_tknid').data
        if (tokenn) {
            var jwt = jwt_decode(tokenn);
            var today = new Date().getTime();
            var jwt_exp = jwt.exp * 1000 - (1000 * 20); // sebelum 20 detik
            console.log("jwt", jwt_exp - today)

            if (jwt_exp < today) {
                // request
                let formdata = new FormData()
                let options = {
                    headers: {
                        Authorization: `Bearer ${tokenn}`
                    }
                }
                formdata.append("refresh_token", rtoken)
                const refreshToken = Axios.post(`${API}/refresh_token`, formdata, options, null)
                if (refreshToken) {
                    ls.set('@_tknid', { data: refreshToken.data.data.token })
                    ls.set('@_rtknid', { data: refreshToken.data.data.refreshToken })
                }
            } else {
                console.log("tes")

            }
        }
    }

    // console.log('data upcoming'.data_event)
    return (
        // <React.Fragment>
        // <Container maxWidth='lg'>
        <>
            <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={openDialogShare}>
                <DialogTitle id="simple-dialog-title">Share to Friends</DialogTitle>

                <List>
                    <ListItem style={{ color: "black" }}>
                        <ListItemAvatar>
                            <FacebookShareButton url={`${shareLink}`}>
                                <FacebookIcon size={32} round={true} /> &nbsp; Facebook
                            </FacebookShareButton>
                        </ListItemAvatar>
                    </ListItem>
                </List>

                <List>
                    <ListItem style={{ color: "black" }}>
                        <ListItemAvatar>
                            <EmailShareButton url={`${shareLink}`}>
                                <EmailIcon size={32} round={true} />  &nbsp; Email
                            </EmailShareButton>
                        </ListItemAvatar>
                    </ListItem>
                </List>

                <List>
                    <ListItem style={{ color: "black" }}>
                        <ListItemAvatar>
                            <LineShareButton url={`${shareLink}`}>
                                <LineIcon size={32} round={true} />  &nbsp; Line
                            </LineShareButton>
                        </ListItemAvatar>
                    </ListItem>
                </List>

                <List>
                    <ListItem style={{ color: "black" }}>
                        <ListItemAvatar>
                            <TelegramShareButton url={`${shareLink}`}>
                                <TelegramIcon size={32} round={true} />  &nbsp; Telegram
                            </TelegramShareButton>
                        </ListItemAvatar>
                    </ListItem>
                </List>

                <List>
                    <ListItem style={{ color: "black" }}>
                        <ListItemAvatar>
                            <WhatsappShareButton url={`${shareLink}`}>
                                <WhatsappIcon size={32} round={true} />  &nbsp; Whatsapp
                            </WhatsappShareButton>
                        </ListItemAvatar>
                    </ListItem>
                </List>

                <List>
                    <ListItem style={{ color: "black" }}>
                        <ListItemAvatar>
                            <TwitterShareButton url={`${shareLink}`}>
                                <TwitterIcon size={32} round={true} />  &nbsp; Twitter
                            </TwitterShareButton>
                        </ListItemAvatar>
                    </ListItem>
                </List>
            </Dialog>
            {
                loading.loading === false && data_event.length > 0 ?
                    <Carousel
                        additionalTransfrom={0}
                        arrows
                        autoPlaySpeed={1500}
                        centerMode={false}
                        className=""
                        containerClass="container-with-dots"
                        dotListClass=""
                        draggable
                        focusOnSelect={false}
                        infinite
                        itemClass=""
                        keyBoardControl
                        minimumTouchDrag={80}
                        renderButtonGroupOutside={false}
                        renderDotsOutside={false}
                        responsive={{
                            desktop: {
                                breakpoint: {
                                    max: 3000,
                                    min: 1024
                                },
                                items: 3,
                                partialVisibilityGutter: 40
                            },
                            mobile: {
                                breakpoint: {
                                    max: 464,
                                    min: 0
                                },
                                items: 1,
                                partialVisibilityGutter: 30
                            },
                            tablet: {
                                breakpoint: {
                                    max: 1024,
                                    min: 464
                                },
                                items: 2,
                                partialVisibilityGutter: 30
                            }
                        }}
                        showDots={false}
                        sliderClass=""
                        slidesToSlide={1}
                        swipeable
                    >

                        {
                            data_event.map((data, index) => (
                                <Box key={index} className={classes.carousel}>
                                    <Card className={classes.root} title={data.event_name} >
                                        <Link href={`/detail-event/${data.event_slug}`}>

                                            <Box textAlign="center" className="imgbanner" style={{ alignContent: "center", zIndex: 1 }}>
                                                <img width="100%" height="180px" src={data.event_image_url} />
                                            </Box>
                                        </Link>

                                        <CardContent className={classes.info}>
                                            <Link style={{ cursor: 'pointer' }}>
                                                {
                                                    data.current_auth_liked ?
                                                        <Tooltip TransitionComponent={Zoom} title="Hapus sebagai favorit" arrow placement="bottom">
                                                            <FavoriteIcon color="error"
                                                                onClick={async () => {
                                                                    try {
                                                                        const testing = await CheckToken()

                                                                        let token = ls.get('@_tknid').data
                                                                        const res = await Axios.delete(`${API}/event/like`, {
                                                                            headers: { Authorization: `Bearer ${token}` },
                                                                            data: { event_id: data.event_id }
                                                                        })
                                                                        if (res) {
                                                                            dispatch(getUpcomingEvent())
                                                                        }
                                                                    } catch (error) {
                                                                        // console.log(error)
                                                                        ls.removeAll()
                                                                        localStorage.clear()
                                                                        sessionStorage.clear()
                                                                        window.location.href = "/"
                                                                    }
                                                                }}

                                                            />

                                                        </Tooltip>

                                                        : <Tooltip TransitionComponent={Zoom} title="Tambah sebagai favorit" arrow placement="bottom">
                                                            <FavoriteBorderIcon style={{ color: "white" }}
                                                                onClick={async () => {
                                                                    try {
                                                                        const testing = await CheckToken()

                                                                        let token = ls.get('@_tknid').data
                                                                        if (!token) {
                                                                            // swal({
                                                                            //     title: "Login!",
                                                                            //     text: "Anda harus login terlabih dauhulu!",
                                                                            //     icon: "warning",
                                                                            //     buttons: true,
                                                                            // })
                                                                            //     .then((redirect) => {
                                                                            //         if (redirect) {
                                                                            //         }
                                                                            //     });
                                                                            window.location.href = "/login";
                                                                        } else {
                                                                            let formdata = new FormData()
                                                                            let options = {
                                                                                headers: {
                                                                                    Authorization: `Bearer ${token}`
                                                                                }
                                                                            }
                                                                            formdata.append("event_id", data.event_id)
                                                                            const res = await Axios.post(`${API}/event/like`, formdata, options, null)
                                                                            if (res) {
                                                                                dispatch(getUpcomingEvent())
                                                                            }
                                                                        }
                                                                    } catch (error) {
                                                                        ls.removeAll()
                                                                        localStorage.clear()
                                                                        sessionStorage.clear()
                                                                        window.location.href = "/"
                                                                    }
                                                                }}
                                                            />

                                                        </Tooltip>
                                                }
                                            </Link>

                                        </CardContent>

                                        <CardContent className={classes.share} onClick={() => {
                                            setshareLink(data.event_full_url)
                                            setopenDialogShare(true)
                                        }}>
                                            <Tooltip TransitionComponent={Zoom} title="Share" arrow placement="bottom">
                                                <Box className={classes.boxshare}>
                                                    <Box pr={1} pl={1} >
                                                        <Link style={{ cursor: 'pointer' }}>
                                                            <FaShare style={{ color: 'white' }} />
                                                        </Link>
                                                    </Box>
                                                </Box>
                                            </Tooltip>
                                        </CardContent>



                                    </Card>
                                    <Box pl={1} pr={1} pt={1} color='#000' style={{ width: '100%', whiteSpace: 'nowrap' }}>
                                        <Box fontSize={17} fontWeight={600} overflow="hidden" textOverflow="ellipsis" color="#393755">{data.event_name}</Box>
                                        {
                                            moment(data.event_start_datetime).format('DD MMMM YYYY') !== moment(data.event_end_datetime).format('DD MMMM YYYY') ?
                                                <Box color="#393755" fontSize={14} fontWeight={100} overflow="hidden" textOverflow="ellipsis">
                                                    <EventIcon style={{ fontSize: "14px" }} />{' '}
                                                    {moment(data.event_start_datetime).format('DD MMMM YYYY')}
                                                    {' '}- {' '}
                                                    {moment(data.event_end_datetime).format('DD MMMM YYYY')}
                                                </Box> :
                                                <Box color="#393755" fontSize={14} fontWeight={100} overflow="hidden" textOverflow="ellipsis">
                                                    <EventIcon style={{ fontSize: "14px" }} />
                                                    {' '}
                                                    {moment(data.event_end_datetime).format('DD MMMM YYYY')}
                                                </Box>
                                        }
                                        {
                                            data.category_venue_id === 3 ?
                                                <Box color="#393755" fontSize={14} overflow="hidden" textOverflow="ellipsis"><LocationOnIcon style={{ fontSize: "14px" }} /> Online Event
                                                                                </Box> :
                                                <Box color="#393755" fontSize={14} overflow="hidden" textOverflow="ellipsis"><LocationOnIcon style={{ fontSize: "14px" }} /> {data.event_location} {(data.event_location_detail && data.event_location_detail.length > 0) || data.event_location_detail !== null ? ` - ${data.event_location_detail}` : null}
                                                </Box>

                                        }
                                        {/* {
                                            moment(data.event_start_datetime).format('DD MMMM YYYY') !== moment(data.event_end_datetime).format('DD MMMM YYYY') ?
                                                <Box color="#393755" fontSize={14} fontWeight={100} overflow="hidden" textOverflow="ellipsis">
                                                    <EventIcon style={{ fonsize: '14px' }} /> {' '}
                                                    {moment(data.event_start_datetime).format('DD MMMM YYYY')}
                                                    {' '}- {' '}
                                                    {moment(data.event_end_datetime).format('DD MMMM YYYY')}
                                                </Box> :
                                                <Box color="#393755" fontSize={14} fontWeight={100} overflow="hidden" textOverflow="ellipsis">
                                                    <EventIcon style={{ fonsize: '14px' }} /> {' '}

                                                    {moment(data.event_end_datetime).format('DD MMMM YYYY')}
                                                </Box>
                                        } */}
                                        {/* <Box color="#393755" fontSize={14} fontWeight={100} overflow="hidden" textOverflow="ellipsis">
                                            {data.event_location_detail}

                                        </Box> */}


                                        {/*  
                                        {
                                            data.category_venue_id === 3 ?
                                                <Box color="#393755" fontSize={14} overflow="hidden" textOverflow="ellipsis"><LocationOnIcon style={{ fonsize: '14px' }} /> Online Event
                                                                                </Box> :
                                                // <Box color="#393755" fontSize={14} overflow="hidden" textOverflow="ellipsis"><LocationOnIcon style={{ fonsize: '14px' }} /> {data.event_location} {data.event_location_detail.length > 0 ? ` - ${data.event_location_detail}` : null}
                                                <Box color="#393755" fontSize={14} overflow="hidden" textOverflow="ellipsis"><LocationOnIcon style={{ fontSize: "14px" }} /> {data.event_location} {(data.event_location_detail && data.event_location_detail.length > 0) || data.event_location_detail !== null ? ` - ${data.event_location_detail}` : null}

                                                </Box>

                                        } */}
                                        <Box style={{ borderBottom: 'solid 2px #d1d1d1', paddingTop: '10px' }}></Box>
                                    </Box>
                                </Box>

                            ))}

                    </Carousel>

                    : loading.loading === false && data_event.length === 0 ? (
                        <>
                            <Grid container direction='row' justify='center'>
                                <Box textAlign="center" pt={2}>
                                    <img alt='nothing here' src={Tidaktersedia} width='70%' />
                                </Box>
                            </Grid>
                            <Grid container direction='row' justify='center'>
                                <Box color="#393755" id="fontStyle" pt={3}>Saat ini belum tersedia</Box>
                            </Grid>
                        </>
                    )
                        :
                        <>
                            {/* <Grid container spacing={2} style={{ height: '260px' }} className={classes.rootcatevent}>
                                <Grid item lg={4} xl={4} md={6} sm={6} xs={12} >
                                    <Skeleton variant="rect" height={180} style={{ borderRadius: '10px' }} />
                                    <Skeleton variant="text" height={50} style={{ borderRadius: '10px' }} />

                                </Grid>
                                <Grid item lg={4} xl={4} md={6} sm={6} xs={12} >
                                    <Skeleton variant="rect" height={180} style={{ borderRadius: '10px' }} />
                                    <Skeleton variant="text" height={50} style={{ borderRadius: '10px' }} />

                                </Grid>
                                <Grid item lg={4} xl={4} md={6} sm={6} xs={12}>
                                    <Skeleton variant="rect" height={180} style={{ borderRadius: '10px' }} />
                                    <Skeleton variant="text" height={50} style={{ borderRadius: '10px' }} />

                                </Grid>
                            </Grid> */}
                            <Grid container spacing={2} style={{ height: '260px' }} className={classes.rootcatevent}>
                                <Grid item lg={4} xl={4} md={6} sm={6} xs={12} >
                                    <Skeleton variant="rect" height={180} style={{ borderRadius: '10px' }} />
                                    <Skeleton variant="text" height={50} style={{ borderRadius: '10px' }} />

                                </Grid>
                            </Grid>
                        </>
            }
        </>
    )
}

export default UpcomingEventAll;