import React from 'react';
import AppBarComponent from '../../components/AppBar';
import FooterDashboard from '../../components/FooterDashboard';
import ForgotPassComponent from '../../components/AuthComponent/ForgotPassword';
import Meta from '../../components/Util/MetaDecorator'

const ForgotPasswordPage = () => {
    // document.title = "Recover Password | Eventories.com "
    return (
        <React.Fragment>
            <Meta title="Lupa Password | Eventories.com" description="Klik jika lupa password" />
            <AppBarComponent />
            <ForgotPassComponent />
            <FooterDashboard />
        </React.Fragment>
    )
}

export default ForgotPasswordPage;