import React from 'react';
import AppBarUser from '../../components/AppBarUser';
import NextEventUser from '../../components/DashboardUser/NextEvent/NextEventUser';
import FooterDashboard from '../../components/FooterDashboard';
import Meta from '../../components/Util/MetaDecorator'


const DashboardUser = () => {
    // document.title = "Dashboard User | Eventories.com "

    return (
        <React.Fragment>
            <Meta title="Dashboard User | Eventories.com" description="Halaman Dashboard User" />

            <AppBarUser />
            <NextEventUser />
            <FooterDashboard />

        </React.Fragment>
    )
}

export default DashboardUser;