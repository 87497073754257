import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Box, Paper, TextField, Divider, Button } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Axios from 'axios'
import { API } from './../../../config'
import swal from 'sweetalert'
import Devices from '../../../assets/img/devices.svg'
import jwt_decode from 'jwt-decode'
import * as SecureLs from 'secure-ls'
const ls = new SecureLs();

const useStyles = makeStyles((theme) => ({
    root: {
        [theme.breakpoints.up('xs')]: {
            paddingTop: theme.spacing(1),
        },
        [theme.breakpoints.up('sm')]: {
            paddingTop: theme.spacing(1),
        },
        [theme.breakpoints.up('md')]: {
            paddingTop: theme.spacing(15),
        },
        [theme.breakpoints.up('lg')]: {
            paddingTop: theme.spacing(15),
        },
        [theme.breakpoints.up('xl')]: {
            paddingTop: theme.spacing(15),
        },
    },
    deks: {
        [theme.breakpoints.up('xs')]: {
            display: 'none',
        },
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
        [theme.breakpoints.up('md')]: {
            display: 'block',
        },
        [theme.breakpoints.up('lg')]: {
            display: 'block',
        },
        [theme.breakpoints.up('xl')]: {
            display: 'block',
        },
    },
    mob: {
        [theme.breakpoints.up('xs')]: {
            display: 'block'
        },
        [theme.breakpoints.up('sm')]: {
            display: 'none'
        },
        [theme.breakpoints.up('md')]: {
            display: 'none'
        },
        [theme.breakpoints.up('lg')]: {
            display: 'none'
        },
        [theme.breakpoints.up('xl')]: {
            display: 'none'
        },
    },
    rootdiv: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(5)
    },
    divmargin: {
        paddingLeft: '20px',
        paddingRight: '20px'
    },
    buttontab: {
        borderRadius: '30px'
    },
    paperproject: {
        height: 'fit-content'
    },
    title: {
        fontSize: '14px',
        color: '#707070'
    },
    titleBold: {
        fontSize: '16px',
        color: '#707070',
    },
    imageprofile: {
        width: '130px',
        height: '130px'
    },
    btnCustom: {
        borderRadius: '5px',
        border: 'solid 1px #9e69d2',
        backgroundColor: '#9e69d2',
        width: '120px',
        color: '#fbfbfb',
        height: '40px',
        "&:hover": {
            backgroundColor: '#fbfbfb',
            color: '#9e69d2',
            border: 'solid 1px #9e69d2',
        }
    },
    delete: {
        '&:hover': {
            // fontWeight: 'bold',
            color: '#273276'
        }
    }


}));


const PasswordComponent = () => {

    const classes = useStyles();

    const [resetPassword, setResetPassword] = useState({
        password: '',
        passwordKonf: ''
    })
    let token = ls.get('@_tknid').data
    // console.log("Bearer", token)

    const [listDevice, setlistDevice] = useState([]);
    const CheckToken = () => {
        let rtoken = ls.get('@_rtknid').data
        let tokenn = ls.get('@_tknid').data
        if (tokenn) {
            var jwt = jwt_decode(tokenn);
            var today = new Date().getTime();
            var jwt_exp = jwt.exp * 1000 - (1000 * 20); // sebelum 20 detik

            if (jwt_exp < today) {
                // request
                let formdata = new FormData()
                let options = {
                    headers: {
                        Authorization: `Bearer ${tokenn}`
                    }
                }
                formdata.append("refresh_token", rtoken)
                const refreshToken = Axios.post(`${API}/refresh_token`, formdata, options, null)
                if (refreshToken) {
                    ls.set('@_tknid', { data: refreshToken.data.data.token })
                    ls.set('@_rtknid', { data: refreshToken.data.data.refreshToken })
                }
            } else {
                console.log("tes")

            }
        }
    }
    useEffect(async () => {
        try {
            const testing = await CheckToken()

            let token = ls.get('@_tknid').data
            let rtoken = ls.get('@_rtknid').data
            let formdata = new FormData();
            formdata.append("refresh_token", rtoken);

            const res = await Axios.post(`${API}/token/own`, formdata, { headers: { Authorization: `Bearer ${token}` } })
            if (res) {
                setlistDevice(res.data.data)
            }
        } catch (error) {
            ls.removeAll()
            localStorage.clear()
            sessionStorage.clear()
            window.location.href = "/"
        }
    }, [])


    const handleChangePassword = password => event => {
        setResetPassword({
            ...resetPassword,
            [password]: event.target.value
        })
    }

    const onClickSubmit = async (e) => {
        e.preventDefault()
        const testing = await CheckToken()

        let formdata = new FormData();
        let token = ls.get('@_tknid').data
        let id_user = localStorage.getItem('id')

        let Headers = {
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": `Bearer ${token}`
            },
        };

        formdata.append("password", resetPassword.password);
        formdata.append("retype_password", resetPassword.passwordKonf);

        await Axios.put(`${API}/user/${id_user}/password`, formdata, Headers)
            .then((res) => {
                if (res.data.error) {
                    swal("Error!", `${res.data.error}`, "error");
                } else if (res.data.success) {
                    swal({
                        title: "Yeaayy!",
                        text: `${res.data.success}`,
                        icon: "success",
                        button: "OK",
                    })
                        .then((redirect) => {
                            if (redirect) {
                                localStorage.clear()
                                window.location.href = "/login";
                            }
                        });
                }
            }).catch((err) => {
                swal("Error", `${err.response}`, "error");
            })
    }

    const deleteToken = async (id) => {
        try {
            const testing = await CheckToken()

            let token = ls.get('@_tknid').data
            let rtoken = ls.get('@_rtknid').data
            let formdata = new FormData();
            formdata.append("refresh_token", rtoken);
            const res = await Axios.delete(`${API}/token/${id}`, { headers: { Authorization: `Bearer ${token}` } })
            if (res) {
                const res1 = await Axios.post(`${API}/token/own`, formdata, { headers: { Authorization: `Bearer ${token}` } })
                if (res1) {
                    setlistDevice(res1.data.data)
                }
            }
        } catch (error) {
            ls.removeAll()
            localStorage.clear()
            sessionStorage.clear()
            window.location.href = "/"
        }
    }

    return (
        <React.Fragment >
            <Container maxWidth="lg">

                <Box className={classes.root}>
                    <Grid container spacing={4}  >
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Paper elevation={2} className={classes.paperproject}>
                                <Box p={4}>
                                    <form className={classes.form} onSubmit={onClickSubmit} validate >
                                        <Box id="fontStyle" className={classes.title} fontWeight={700} pb={2}>Ubah Sandi</Box>

                                        <Grid container spacing={2}>
                                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                                <Box pt={1} id="fontStyle" className={classes.titleBold}>Password Baru</Box>
                                                <Box pb={2}>
                                                    <TextField
                                                        variant="outlined"
                                                        margin="dense"
                                                        required
                                                        fullWidth
                                                        onChange={handleChangePassword('password')}
                                                        id="newpassword"
                                                        type='password'
                                                    // onChange={handleChangeLogin('email')}                                
                                                    />
                                                </Box>
                                            </Grid>
                                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                                <Box pt={1} id="fontStyle" className={classes.titleBold}>Konfirm Password Baru</Box>
                                                <Box pb={2}>
                                                    <TextField
                                                        variant="outlined"
                                                        margin="dense"
                                                        required
                                                        fullWidth
                                                        id="newkonfpassword"
                                                        onChange={handleChangePassword('passwordKonf')}
                                                        type='password'
                                                    // onChange={handleChangeLogin('email')}                                
                                                    />
                                                </Box>
                                            </Grid>

                                        </Grid>


                                        <Box pt={1} pb={4}>
                                            <button className={classes.btnCustom} type="submit">
                                                <Box id="fontStyle" className={classes.textBtn}>Simpan</Box>
                                            </button>
                                        </Box>

                                    </form>
                                    <Divider />
                                </Box>


                                <Box pb={4} px={4}>
                                    <Box id="fontStyle" className={classes.title} pb={2} fontWeight={700}>Aktifitas Login</Box>
                                    {
                                        listDevice && listDevice.length > 1 ?

                                            <Box fontSize={13} color="#9E69D2" className={classes.delete} style={{ cursor: 'pointer' }}
                                                onClick={() => {
                                                    swal({
                                                        title: "Hapus dari Semua Perangkat?",
                                                        text: "Akun Anda akan keluar dari semua perangkat yang ada kecuali perangkat yang digunakan saat ini",
                                                        buttons: true,
                                                    })
                                                        .then((redirect) => {
                                                            if (redirect) {
                                                                try {
                                                                    let token = ls.get('@_tknid').data
                                                                    let rtoken = ls.get('@_rtknid').data
                                                                    let formdata = new FormData();
                                                                    formdata.append("refresh_token", rtoken);
                                                                    Axios.post(`${API}/token/revoke/own`, formdata, { headers: { Authorization: `Bearer ${token}` } })
                                                                        .then(res => {
                                                                            Axios.post(`${API}/token/own`, formdata, { headers: { Authorization: `Bearer ${token}` } })
                                                                                .then(res1 => {
                                                                                    setlistDevice(res1.data.data)
                                                                                })
                                                                        })
                                                                } catch (error) {

                                                                }
                                                            }
                                                        });
                                                }}
                                            >Hapus dari Semua Perangkat</Box>
                                            : null
                                    }

                                    <Box pt={2}>
                                        {
                                            listDevice.length > 0 ?
                                                <Box>
                                                    {
                                                        listDevice.map((data, i) => (

                                                            <>
                                                                {
                                                                    data.browser === null || data.os === null || data.device === null ? null :

                                                                        <Box p={2}>
                                                                            <Grid container alignItems="center" alignContent="center" >
                                                                                <Grid item>
                                                                                    <Box pr={1} className={classes.deks}>
                                                                                        <img src={Devices} width="28px" />
                                                                                    </Box>
                                                                                </Grid>
                                                                                <Grid item>
                                                                                    <Grid container alignContent="center" alignItems="center" >
                                                                                        <Grid item>
                                                                                            <Box fontSize={14}>{data.browser} di {data.os} {data.device || data.device !== null && data.device.length > 0 ? `(${data.device})` : ''}</Box>
                                                                                        </Grid>
                                                                                        <Grid item>
                                                                                            <Box pl={2} className={classes.deks}>
                                                                                                {
                                                                                                    data.current_status ?
                                                                                                        <Box fontSize={13} fontWeight={600} color="#9E69D2" >
                                                                                                            <Button size="small"
                                                                                                                style={{
                                                                                                                    fontSize: "13px",
                                                                                                                    textTransform: "capitalize",
                                                                                                                    color: '#9e69d2',
                                                                                                                    // border: '1px solid #9e69d2',
                                                                                                                    borderRadius: '20px',
                                                                                                                    cursor: 'text',
                                                                                                                    fontWeight: 'bold',
                                                                                                                    backgroundColor: "#debbff9e"
                                                                                                                }}>
                                                                                                                <Box px={1}>
                                                                                                                    Sedang Aktif
                                                                                                        </Box>
                                                                                                            </Button>
                                                                                                        </Box>
                                                                                                        :
                                                                                                        <Box fontSize={13} fontWeight={600} color="#9E69D2" style={{ cursor: 'pointer' }} onClick={() => deleteToken(data.id)}>Hapus</Box>
                                                                                                }
                                                                                            </Box>
                                                                                        </Grid>
                                                                                    </Grid>

                                                                                    <Box color="gray" fontSize={12}>{data.ip}</Box>
                                                                                    <Box color="gray" fontSize={12}>Masuk: {data.created_at}</Box>
                                                                                </Grid>

                                                                            </Grid>
                                                                            <Box className={classes.mob}>
                                                                                {
                                                                                    data.current_status ?
                                                                                        <Box fontSize={13} fontWeight={600} color="#9E69D2" >
                                                                                            <Button size="small" style={{ fontSize: "13px", textTransform: "capitalize", color: '#9e69d2', border: '1px solid #9e69d2', borderRadius: '20px' }}>Sedang Aktif</Button>
                                                                                        </Box>
                                                                                        :
                                                                                        <Box fontSize={13} color="#9E69D2" className={classes.delete} style={{ cursor: 'pointer' }} onClick={() => deleteToken(data.id)}>Hapus</Box>
                                                                                }
                                                                            </Box>
                                                                            <Box pt={1}>
                                                                                <Divider />
                                                                            </Box>
                                                                        </Box>
                                                                }
                                                            </>
                                                        ))
                                                    }
                                                </Box>
                                                :
                                                null
                                        }
                                    </Box>

                                </Box>
                            </Paper>
                        </Grid>

                    </Grid>
                </Box>

            </Container>

        </React.Fragment>

    )
}

export default PasswordComponent;