import React from 'react';
import AppBarCongrats from '../../components/AppBarCongrats';
import FooterDashboard from '../../components/FooterDashboard';
import { makeStyles } from '@material-ui/core/styles';
import {
  Container,
  CssBaseline,
  Grid,
  Typography,
  Box,
  Button,
  Link
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root : {
      marginBottom: '250px',
      marginTop: '300px'  
    },
    containerdiv: {
      textAlign: 'center'
    }
    
  }));

const RegCongrats = () => {
    const classes = useStyles();

    return (
    <React.Fragment>
    <AppBarCongrats/>           
        <Container component="main" maxWidth="sm" className={classes.root}>
          <CssBaseline />

          <Grid container direction='column' alignItems='center' justify='space-between'   >
            <Typography variant='h6' mt={5} >
              <Box style={{color:'green'}} textAlign="center"> Congratulations!</Box>
              <Box textAlign="center">You have successfully registered.</Box>
              
              <Box textAlign="center" pt={2} > 
                <Link href='/login'  >
                  <Button onClick={() => localStorage.clear()} variant='contained' color='secondary' style={{backgroundColor: '#9e69d2'}} >Login Here</Button>
                </Link>              
              </Box>
            </Typography>            
          </Grid>
        
        </Container>
    <FooterDashboard/>
    </React.Fragment>
    )
}

export default RegCongrats;