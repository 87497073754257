import Axios from 'axios'
import { API } from '../../config'

import {
    // GET DATA EVENT BY CATEGORY
    GET_EVENT_ALLCAT_REQUEST,
    GET_EVENT_ALLCAT_FAILED,
    GET_EVENT_ALLCAT_SUCCESS,
    GET_EVENT_CAT1_SUCCESS,
    GET_EVENT_CAT2_SUCCESS,
    GET_EVENT_CAT3_SUCCESS,
    GET_EVENT_CAT4_SUCCESS,
    GET_EVENT_CAT5_SUCCESS

} from './EventByCatTypes'


import jwt_decode from 'jwt-decode'
import * as SecureLs from 'secure-ls'
const ls = new SecureLs();
const CheckToken = () => {
    let rtoken = ls.get('@_rtknid').data
    let tokenn = ls.get('@_tknid').data
    if (tokenn) {
        var jwt = jwt_decode(tokenn);
        var today = new Date().getTime();
        var jwt_exp = jwt.exp * 1000 - (1000 * 20); // sebelum 20 detik

        if (jwt_exp < today) {
            // request
            let formdata = new FormData()
            let options = {
                headers: {
                    Authorization: `Bearer ${tokenn}`
                }
            }
            formdata.append("refresh_token", rtoken)
            const refreshToken = Axios.post(`${API}/refresh_token`, formdata, options, null)
            if (refreshToken) {
                ls.set('@_tknid', { data: refreshToken.data.data.token })
                ls.set('@_rtknid', { data: refreshToken.data.data.refreshToken })
            }
        } else {
            console.log("tes")

        }
    }
}

const getEventByCategoryAllRequest = () => {
    return {
        type: GET_EVENT_ALLCAT_REQUEST
    }
}

const getEventByCategoryAllFailed = (message) => {
    return {
        type: GET_EVENT_ALLCAT_FAILED,
        payload: message
    }
}

const getEventByCategoryAllSuccess = (data) => {
    return {
        type: GET_EVENT_ALLCAT_SUCCESS,
        payload: data
    }
}

export const getEventByCategoryAll = () => {
    return (
        async (dispatch) => {
            try {
                const testing = await CheckToken()
                dispatch(getEventByCategoryAllRequest())
                await Axios.get(`${API}/event/all`)
                    .then((res) => {
                        // console.log('category all event', res.data)
                        // console.log('loginheaders', res.response)                
                        dispatch(getEventByCategoryAllSuccess(res.data.data))
                    }).catch((err) => {
                        dispatch(getEventByCategoryAllFailed(err))
                        // console.log(err)

                    })
            } catch (error) {

            }

        }
    )
}

// cat ID 7 = PRODUCT LAUNCH
const getEventByCategoryOneSuccess = (data) => {
    return {
        type: GET_EVENT_CAT1_SUCCESS,
        payload: data
    }
}

export const getEventByCategoryOne = () => {
    return (
        async (dispatch) => {
            try {
                const testing = await CheckToken()
                dispatch(getEventByCategoryAllRequest())
                await Axios.get(`${API}/event/all?category_event_id=7`)
                    .then((res) => {
                        // console.log('MUSIK CONCERT', res.data.data)                
                        // console.log('loginheaders', res.response)                
                        dispatch(getEventByCategoryOneSuccess(res.data.data))
                    }).catch((err) => {
                        dispatch(getEventByCategoryAllFailed(err))
                        // console.log(err)

                    })
            } catch (error) {

            }

        }
    )
}

// cat ID 8 = MUSIC CONCERT 
const getEventByCategoryTwoSuccess = (data) => {
    return {
        type: GET_EVENT_CAT2_SUCCESS,
        payload: data
    }
}

export const getEventByCategoryTwo = () => {
    return (
        async (dispatch) => {
            try {
                const testing = await CheckToken()
                dispatch(getEventByCategoryAllRequest())
                await Axios.get(`${API}/event/all?category_event_id=8`)
                    .then((res) => {
                        // console.log('MUSIK CONCERT', res.data.data)                
                        // console.log('loginheaders', res.response)                
                        dispatch(getEventByCategoryTwoSuccess(res.data.data))
                    }).catch((err) => {
                        dispatch(getEventByCategoryAllFailed(err))
                        // console.log(err)

                    })
            } catch (error) {

            }

        }
    )
}

// cat ID 9 = SPORT MATCH 
const getEventByCategoryThreeSuccess = (data) => {
    return {
        type: GET_EVENT_CAT3_SUCCESS,
        payload: data
    }
}

export const getEventByCategoryThree = () => {
    return (
        async (dispatch) => {
            try {
                const testing = await CheckToken()
                dispatch(getEventByCategoryAllRequest())
                await Axios.get(`${API}/event/all?category_event_id=9`)
                    .then((res) => {
                        // console.log('SPORT MATCH', res.data.data)                
                        // console.log('loginheaders', res.response)                
                        dispatch(getEventByCategoryThreeSuccess(res.data.data))
                    }).catch((err) => {
                        dispatch(getEventByCategoryAllFailed(err))
                        // console.log(err)

                    })

            } catch (error) {

            }
        }
    )
}

// cat ID 10 = BAZAAR
const getEventByCategoryFourSuccess = (data) => {
    return {
        type: GET_EVENT_CAT4_SUCCESS,
        payload: data
    }
}

export const getEventByCategoryFour = () => {
    return (
        async (dispatch) => {
            try {
                const testing = await CheckToken()
                dispatch(getEventByCategoryAllRequest())
                await Axios.get(`${API}/event/all?category_event_id=10`)
                    .then((res) => {
                        // console.log('BAZZAR', res.data.data)                
                        // console.log('loginheaders', res.response)                
                        dispatch(getEventByCategoryFourSuccess(res.data.data))
                    }).catch((err) => {
                        dispatch(getEventByCategoryAllFailed(err))
                        // console.log(err)

                    })

            } catch (error) {

            }
        }
    )
}



// cat ID 11 = SEMINARS
const getEventByCategoryFiveSuccess = (data) => {
    return {
        type: GET_EVENT_CAT5_SUCCESS,
        payload: data
    }
}

export const getEventByCategoryFive = () => {
    return (
        async (dispatch) => {
            try {
                const testing = await CheckToken()

                dispatch(getEventByCategoryAllRequest())
                Axios.get(`${API}/event/all?category_event_id=11`)
                    .then((res) => {
                        // console.log('SEMINARS', res.data.data)                
                        // console.log('loginheaders', res.response)                
                        dispatch(getEventByCategoryFiveSuccess(res.data.data))
                    }).catch((err) => {
                        dispatch(getEventByCategoryAllFailed(err))
                        // console.log(err)

                    })
            } catch (error) {

            }
        }
    )
}

