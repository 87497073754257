import { combineReducers } from 'redux'
import upcoming_event from './UpcomingEvent/UpcEventReducers'
import popular_event from './PopularEvent/PopEventReducers'
import category_event from './CategoryEvent/CategoryEventReducers'
import allcat_event from './EventByCategory/EventByCatReducers'
import searched_event from './SearchedEvent/SearchedReducers'
import login from './AuthUser/AuthUserReducers'
import register from './AuthUser/AuthUserReducers'
import profile from './AuthUser/AuthUserReducers'
import event from './SingleEvent/SingleEventReducers'
import mspayment from './PaymentList/MsPaymentReducers'
import purchaseticket from './PurchaseTicket/PurchaseTicketReducers'

const rootReducer = combineReducers({
    upcoming_event,
    popular_event,
    category_event,
    allcat_event,
    searched_event,
    login,
    register,
    profile,
    event,
    mspayment,
    purchaseticket
})

export default rootReducer