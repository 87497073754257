
import React, { useEffect, useState, } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Alert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Box, Paper, TextField, Button } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Skeleton from '@material-ui/lab/Skeleton';

import Axios from 'axios'
import { API } from './../../../config'
import swal from 'sweetalert'
import * as SecureLs from 'secure-ls'
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import NotificationsOffIcon from '@material-ui/icons/NotificationsOff';

const ls = new SecureLs();



const useStyles = makeStyles((theme) => ({
    root: {
        [theme.breakpoints.up('xs')]: {
            paddingTop: theme.spacing(1),
        },
        [theme.breakpoints.up('sm')]: {
            paddingTop: theme.spacing(1),
        },
        [theme.breakpoints.up('md')]: {
            paddingTop: theme.spacing(15),
        },
        [theme.breakpoints.up('lg')]: {
            paddingTop: theme.spacing(15),
        },
        [theme.breakpoints.up('xl')]: {
            paddingTop: theme.spacing(15),
        },
    },
    rootdiv: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(5)
    },
    divmargin: {
        paddingLeft: '20px',
        paddingRight: '20px'
    },
    buttontab: {
        borderRadius: '30px'
    },
    paperproject: {
        height: 'fit-content'
    },
    title: {
        fontSize: '22px',
        color: '#707070'
    },
    titleBold: {
        fontSize: '15px',
        color: '#707070',
    },
    imageprofile: {
        width: '130px',
        height: '130px'
    },
    btnCustom: {
        borderRadius: '5px',
        border: 'solid 1px #9e69d2',
        backgroundColor: '#9e69d2',
        width: '120px',
        color: '#fbfbfb',
        height: '40px',
        "&:hover": {
            backgroundColor: '#fbfbfb',
            color: '#7C71D5',
            border: 'solid 1px #7C71D5',
        }
    },
    btnSubs: {
        borderRadius: '5px',
        border: 'solid 1px #9e69d2',
        backgroundColor: '#9e69d2',
        // width: '120px',
        // textTransform:'la'
        color: 'white',
        height: '40px',
        "&:hover": {
            backgroundColor: '#7C71D5',
            color: 'white',
            border: 'solid 1px #7C71D5',
        }
    },

    option: {
        color: "black"
    }
}));


const InfoKontak = () => {

    const classes = useStyles();
    const dispatch = useDispatch()


    const [error, seterror] = useState();
    const [errorChoose, setErrorChoose] = useState(false)
    const [dataProfile, setdataProfile] = useState();
    const [subscribe, setsubscribe] = useState(0);
    const [loading, setloading] = useState(false);

    const [kotaValue, setkotaValue] = React.useState(null);

    const [editProfile, setEditProfile] = useState({
        name: '',
        bio: ''
    })

    const handleEditProfile = x => event => {
        setEditProfile({
            ...editProfile,
            [x]: event.target.value
        })
    }

    const [kotalist, setkotalist] = useState([]);
    const optionKota = {
        options: kotalist,
        getOptionLabel: (option) => option.ms_city_name,

    };


    useEffect(() => {
        let token = ls.get('@_tknid').data
        Axios.get(`${API}/ms_city?limit=514`)
            .then(result => {
                setkotalist(result.data.data)

                if (result.data.data) {
                    Axios.get(`${API}/profile`, { headers: { Authorization: `Bearer ${token}` } })
                        .then(res => {
                            setsubscribe(res.data.data.subscribed_status)
                            setdataProfile(res.data.data)
                            setEditProfile({
                                name: res.data.data.name,
                                bio: res.data.data.bio === null ? '' : res.data.data.bio,
                                subscribed_status: res.data.data.subscribed_status
                            })
                            let arr = result.data.data.filter(function (profile) {
                                return profile.ms_city_id === res.data.data.ms_city_id
                            });
                            if (arr[0] === undefined) {
                                setkotaValue(null)

                            } else {
                                setkotaValue(arr[0])
                            }
                        }).catch((err) => {
                            if (err.response.status === 401) {
                                let tokenn = ls.get('@_tknid').data
                                let rtokenn = ls.get('@_rtknid').data
                                let formdata = new FormData()
                                let options = {
                                    headers: {
                                        Authorization: `Bearer ${tokenn}`
                                    }
                                }
                                formdata.append("refresh_token", rtokenn)
                                Axios.post(`${API}/refresh_token`, formdata, options, null)
                                    .then(res => {
                                        ls.set('@_tknid', { data: res.data.data.token })
                                        ls.set('@_rtknid', { data: res.data.data.refreshToken })
                                    }).catch(err => {
                                    })
                            }
                        })
                }

            })

    }, [dispatch]);



    const onSave = () => {
        let formdata = new FormData();
        let token = ls.get('@_tknid').data

        let Headers = {
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": `Bearer ${token}`
            },
        };
        let ms_city_id = `${kotaValue === null ? "" : kotaValue.ms_city_id}`
        let name = editProfile.name
        let bio = editProfile.bio
        let subscribe = editProfile.subscribed_status


        formdata.append("name", name);
        formdata.append("bio", bio);
        formdata.append("ms_city_id", ms_city_id);
        formdata.append("subscribed_status", subscribe);

        Axios.put(`${API}/profile`, formdata, Headers)
            .then((res) => {
                if (res.data.error) {
                    seterror(res.data.error)
                    setErrorChoose(true)
                    setTimeout(() => {
                        setErrorChoose(false)
                    }, 3000);
                } else if (res.data.success) {
                    localStorage.setItem('bio', bio)
                    localStorage.setItem('name', name)
                    localStorage.setItem('city', ms_city_id)
                    swal({
                        title: "Yeaayy!",
                        text: `${res.data.success}`,
                        icon: "success",
                        button: "OK",
                    })
                        .then((redirect) => {
                            if (redirect) {
                                window.location.href = "/info-kontak";
                            }
                        });
                }
            }).catch((err) => {
                swal("Error", `${err.response}`, "error");
            })
    }
    return (
        <React.Fragment >
            <Container maxWidth="lg">

                <Box className={classes.root}>
                    <Grid container spacing={4}  >
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Paper elevation={2} className={classes.paperproject}>
                                {
                                    kotalist.length > 0 && dataProfile ?
                                        <>

                                            <Box p={4}>
                                                <Box pb={3} id="fontStyle" className={classes.title}>Info Detail</Box>
                                                <Grid container spacing={2} container
                                                    direction="row"
                                                    justify="space-between"
                                                    alignItems="center">

                                                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                                        <Box style={{ paddingTop: "5px" }}>
                                                            <TextField value={editProfile.name} id="name" label="Nama Lengkap" variant="outlined" fullWidth onChange={handleEditProfile('name')} />
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                                        {/* <Box> */}
                                                        <Autocomplete
                                                            {...optionKota}
                                                            id="controlled-demo"
                                                            value={kotaValue}
                                                            classes={{
                                                                option: classes.option
                                                            }}
                                                            onChange={(event, newValue) => {
                                                                setkotaValue(newValue);
                                                                // console.log("new value", newValue)
                                                            }}
                                                            renderInput={(params) => <TextField {...params} fullWidth variant="outlined" label="Alamat(Kota)" margin="normal" />} />
                                                        {/* </Box> */}
                                                    </Grid>

                                                </Grid>

                                                <Box pt={3} pb={2}>
                                                    <TextField id="bio" value={editProfile.bio} label="Bio" multiline variant="outlined" fullWidth onChange={handleEditProfile('bio')} />
                                                </Box>
                                                {
                                                    errorChoose ?
                                                        <Box>
                                                            <Alert textAlign='center' severity="error">{error}</Alert>
                                                        </Box>
                                                        : null
                                                }
                                                <Box pt={1} pb={4}>
                                                    <button className={classes.btnCustom}>
                                                        <Box id="fontStyle" className={classes.textBtn} onClick={onSave}>Simpan</Box>
                                                    </button>{' '}
                                                    <Box className="mobile" py={1}>{' '}</Box>
                                                </Box>


                                            </Box>
                                        </>

                                        :

                                        <Box p={4}>
                                            <Box pb={3} id="fontStyle" className={classes.title}>Info Detail</Box>
                                            <Grid container spacing={2} container
                                                direction="row"
                                                justify="space-between"
                                                alignItems="center">
                                                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                                    <Skeleton variant="rect" height={60} style={{ borderRadius: '10px' }} />
                                                </Grid>
                                                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                                    <Skeleton variant="rect" height={60} style={{ borderRadius: '10px' }} />

                                                </Grid>

                                            </Grid>

                                            <Box pt={3} pb={2}>
                                                <Skeleton variant="rect" height={100} style={{ borderRadius: '10px' }} />

                                            </Box>

                                            <Grid continer>
                                                <Grid item xl={3} lg={4} md={4} sm={6} xs={6} >
                                                    <Box pt={1} pb={4}>
                                                        <Skeleton variant="rect" height={40} style={{ borderRadius: '10px' }} />
                                                    </Box>
                                                </Grid>

                                            </Grid>

                                        </Box>
                                }
                            </Paper>
                        </Grid>

                    </Grid>
                </Box>

            </Container>

        </React.Fragment>

    )
}

export default InfoKontak;