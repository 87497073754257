import React from 'react';
import AppBarComponent from '../../components/AppBar';
import FooterDashboard from '../../components/FooterDashboard';
import ResetPassComponent from '../../components/AuthComponent/ResetPassword';
import Meta from '../../components/Util/MetaDecorator'
const ResetPasswordPage = () => {
    // document.title = "Reset Password | Eventories.com "
    return (
        <React.Fragment>
            <Meta title="Ganti Password | Eventories.com" description="Klik untuk ganti password akun Eventories" />

            <AppBarComponent />
            <ResetPassComponent />
            <FooterDashboard />
        </React.Fragment>
    )
}

export default ResetPasswordPage;