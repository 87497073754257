// import 'date-fns';
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Grid, Box, Button } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import './../assets/css/main.css'
// import SectionPillsUpcoming from './SectionPillsUpcoming';
import UpcomingEventAll from '../components/UpcomingEvent/CategoryAll'
import UpcomingEventOnline from '../components/UpcomingEvent/CategoryOnline'
import UpcomingEventStore from '../components/UpcomingEvent/CategoryStore'
import UpcomingEventPublic from '../components/UpcomingEvent/CategoryPublic'


const useStyles = makeStyles((theme) => ({
    rootdiv: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(5)
    },
    divmargin: {
        paddingLeft: '20px',
        paddingRight: '20px'
    },
    buttontab: {
        borderRadius: '30px'
    }
}));


const UpcomingEvent = () => {

    const classes = useStyles();

    const [allTab, setallTab] = useState(true);
    const [onlineTab, setonlineTab] = useState(false);
    const [tokoTab, settokoTab] = useState(false);
    const [umumTab, setumumTab] = useState(false);


    return (
        <div className={classes.rootdiv} >
            <Container maxWidth="lg">
                {/* <div className={classes.divmargin}> */}
                <Grid container >
                    <Typography variant='h5'>
                        <Box fontWeight={600} color='#7D4DB7'>Event Yang Akan Datang</Box>
                    </Typography>
                </Grid>
                <Box pt={2}>
                    {/* <SectionPillsUpcoming /> */}
                    <Box pt={2}>
                        <Button className="buttonCategory"
                            variant={allTab ? 'contained' : 'outlined'}
                            style={{ backgroundColor: `${allTab ? '#9E69D2' : ''} `, color: `${allTab ? '#fff' : '#000'}`, marginRight: "15px", marginLeft: "15x" }}
                            onClick={() => {
                                setallTab(true)
                                setonlineTab(false)
                                settokoTab(false)
                                setumumTab(false)
                            }}>Semua</Button>
                        <Button className="buttonCategory"
                            variant={onlineTab ? 'contained' : 'outlined'}
                            style={{ backgroundColor: `${onlineTab ? '#9E69D2' : ''} `, color: `${onlineTab ? '#fff' : '#000'}`, marginRight: "15px", marginLeft: "15x" }}
                            onClick={() => {
                                setonlineTab(true)
                                setallTab(false)
                                settokoTab(false)
                                setumumTab(false)
                            }}>Online</Button>
                        <Button className="buttonCategory"
                            variant={tokoTab ? 'contained' : 'outlined'}
                            style={{ backgroundColor: `${tokoTab ? '#9E69D2' : ''} `, color: `${tokoTab ? '#fff' : '#000'}`, marginRight: "15px", marginLeft: "15x" }}
                            onClick={() => {
                                settokoTab(true)
                                setallTab(false)
                                setonlineTab(false)
                                setumumTab(false)
                            }}>Toko</Button>
                        <Button className="buttonCategory"
                            variant={umumTab ? 'contained' : 'outlined'}
                            style={{ backgroundColor: `${umumTab ? '#9E69D2' : ''} `, color: `${umumTab ? '#fff' : '#000'}`, marginRight: "15px", marginLeft: "15x" }}
                            onClick={() => {
                                setumumTab(true)
                                setallTab(false)
                                setonlineTab(false)
                                settokoTab(false)
                            }}>Umum</Button>
                    </Box>
                </Box>
                <Box pt={2}>
                    {
                        allTab ? <UpcomingEventAll />
                            : onlineTab ? <UpcomingEventOnline />
                                : tokoTab ? <UpcomingEventStore />
                                    : umumTab ? < UpcomingEventPublic />
                                        : null
                    }
                </Box>

                {/* </div> */}
            </Container>
        </div>

    )
}

export default UpcomingEvent;