import {
    // GET DATA EVENT BY CATEGORY
    GET_EVENT_ALLCAT_REQUEST, 
    GET_EVENT_ALLCAT_FAILED,
    GET_EVENT_ALLCAT_SUCCESS,
    GET_EVENT_CAT1_SUCCESS,
    GET_EVENT_CAT2_SUCCESS,
    GET_EVENT_CAT3_SUCCESS,
    GET_EVENT_CAT4_SUCCESS,
    GET_EVENT_CAT5_SUCCESS
        
} from './EventByCatTypes'

const initialState = {
    loading: false,
    message: '',
    allcat_event: [],
    cat1_event: [],
    cat2_event: [],
    cat3_event: [],
    cat4_event: [],
    cat5_event: [],

}
 
const EventByCategory = ( state = initialState, action ) => {
    switch(action.type) {
        case GET_EVENT_ALLCAT_REQUEST:
            return {
                ...state,
                loading: true
            }
        case GET_EVENT_ALLCAT_FAILED:
            return {
                ...state,
                loading: false,
                message: action.payload                
            }
        case GET_EVENT_ALLCAT_SUCCESS:
            return {
                ...state,
                loading: false,
                allcat_event: action.payload
            } 
        case GET_EVENT_CAT1_SUCCESS:
            return {
                ...state,
                loading: false,
                cat1_event: action.payload
            }        
        case GET_EVENT_CAT2_SUCCESS:
            return {
                ...state,
                loading: false,
                cat2_event: action.payload
            }   
        case GET_EVENT_CAT3_SUCCESS:
            return {
                ...state,
                loading: false,
                cat3_event: action.payload
            }   
        case GET_EVENT_CAT4_SUCCESS:
            return {
                ...state,
                loading: false,
                cat4_event: action.payload
            }   
        case GET_EVENT_CAT5_SUCCESS:
            return {
                ...state,
                loading: false,
                cat5_event: action.payload
            }        
        
        default: return state
    }
}

export default EventByCategory;