import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import {
    Card,
    Box,
    Grid,
    CardContent,
    Link,
    Container,
    Button,
    Dialog,
    ListItem,
    ListItemAvatar,
    List,
    DialogTitle,
} from '@material-ui/core';
import './../assets/css/main.css'
import Axios from 'axios'
import { API } from '../config'
import moment from 'moment'
import { FaShare } from 'react-icons/fa';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import Tidaktersedia from '../assets/img/statekosong.png'
import Skeleton from '@material-ui/lab/Skeleton';
import {
    EmailShareButton,
    EmailIcon,
    FacebookIcon,
    FacebookShareButton,
    LineShareButton,
    LineIcon,
    TelegramShareButton,
    TelegramIcon,
    TwitterShareButton,
    TwitterIcon,
    WhatsappShareButton,
    WhatsappIcon
} from "react-share";
// import { getUpcomingEvent } from '../redux'
import { changecolor } from '../redux'
import { useDispatch } from 'react-redux';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import EventIcon from '@material-ui/icons/Event';

import Zoom from '@material-ui/core/Zoom';
import Tooltip from '@material-ui/core/Tooltip'
import jwt_decode from "jwt-decode";
import * as SecureLs from 'secure-ls'
import { resolve } from 'path';
const ls = new SecureLs();



const useStyles = makeStyles((theme) => ({
    rootdiv: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(5)
    },
    divmargin: {
        paddingLeft: '20px',
        paddingRight: '20px'
    },
    buttontab: {
        borderRadius: '30px'
    },
    buttonCategory: {
        textTransform: "capitalize",
        borderRadius: '30px',
        fontSize: '12px',
    },
    root: {
        marginTop: "30px",
        // maxWidth: 380,
        borderRadius: '10px 10px 0 0',
        position: 'relative'
    },
    media: {
        height: 180,
        width: '100%',
        filter: 'brightness(0.8)'
    },
    info: {
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 3
    },
    boxinfo: {
        zIndex: 3,
        opacity: 0.9,
        borderRadius: '15px',
    },
    gridList: {
        flexWrap: 'nowrap',
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
    },
    share: {
        position: 'absolute',
        zIndex: 3,
        top: 0,
        right: 0
    },
    boxshare: {
        display: 'flex'
    },

    ended: {
        position: 'absolute',
        zIndex: 3,
        top: '50px',
        right: 0
    },
    rootcatevent: {
        [theme.breakpoints.up('xs')]: {
            display: 'none'
        },
        [theme.breakpoints.up('sm')]: {
            display: 'none'
        },
        [theme.breakpoints.up('md')]: {
            display: 'block'
        },
        [theme.breakpoints.up('lg')]: {
            display: 'block'
        },
        [theme.breakpoints.up('xl')]: {
            display: 'block'
        }
    },
    rootcateventmob: {
        [theme.breakpoints.up('xs')]: {
            display: 'block'
        },
        [theme.breakpoints.up('sm')]: {
            display: 'block'
        },
        [theme.breakpoints.up('md')]: {
            display: 'none'
        },
        [theme.breakpoints.up('lg')]: {
            display: 'none'
        },
        [theme.breakpoints.up('xl')]: {
            display: 'none'
        }
    },



}));


const CategoryEvent = () => {
    const classes = useStyles();
    const [dataCategoryEvent, setdataCategoryEvent] = useState([]);
    const [listCategory, setlistCategory] = useState([]);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [catSelected, setcatSelected] = useState("");
    const [openDialogShare, setopenDialogShare] = useState(false);
    const [shareLink, setshareLink] = useState("");
    const [totalpage, settotalpage] = useState(0);
    // const [like, setlike] = useState(false);
    // const [dislike, setdislike] = useState(false);
    const handleClose = () => {
        setopenDialogShare(false)
    }

    const dispatch = useDispatch()

    const CheckToken = () => {
        let rtoken = ls.get('@_rtknid').data
        let tokenn = ls.get('@_tknid').data
        if (tokenn) {
            var jwt = jwt_decode(tokenn);
            var today = new Date().getTime();
            var jwt_exp = jwt.exp * 1000 - (1000 * 20); // sebelum 20 detik

            if (jwt_exp < today) {
                // request
                let formdata = new FormData()
                let options = {
                    headers: {
                        Authorization: `Bearer ${tokenn}`
                    }
                }
                formdata.append("refresh_token", rtoken)
                const refreshToken = Axios.post(`${API}/refresh_token`, formdata, options, null)
                if (refreshToken) {
                    ls.set('@_tknid', { data: refreshToken.data.data.token })
                    ls.set('@_rtknid', { data: refreshToken.data.data.refreshToken })
                }
            } else {
                console.log("tes")

            }
        }
    }



    useEffect(() => {

        const loadCategory = async () => {
            try {
                const testing = await CheckToken()
                const res = await Axios.get(`${API}/category_event`)
                    .then((res) => {
                        let data = res.data.data
                        let state = [{
                            name: "All",
                            id: "",
                            active: true
                        }]
                        for (let i = 0; i < data.length; i++) {
                            state.push({
                                name: data[i].category_event_name,
                                id: data[i].category_event_id,
                                active: false
                            })
                        }
                        setlistCategory(state)
                        if (ls.get('@_tknid').data) {
                            const getEventCategory = async () => {
                                let token = ls.get('@_tknid').data

                                let getEvent = await Axios.get(`${API}/event/all?page=1&limit=6`, { headers: { Authorization: `Bearer ${token}` } })
                                if (getEvent) {
                                    setdataCategoryEvent(getEvent.data.data)
                                    settotalpage(getEvent.data.last_page)
                                    setDataLoaded(true)
                                    // console.log("masuk")
                                }
                                // console.log("getE", getEvent)
                                // await Axios.get(`${API}/event/all?page=1&limit=6`, { headers: { Authorization: `Bearer ${token}` } })
                                //     .then((res) => {
                                //         let data = res.data.data
                                //         setdataCategoryEvent(data)
                                //         settotalpage(res.data.last_page)
                                //         setDataLoaded(true)

                                //     })
                                //     .catch(err => console.log(err))
                            }
                            getEventCategory()
                        } else {
                            Axios.get(`${API}/event/all?page=1&limit=6`)
                                .then((res) => {
                                    let data = res.data.data
                                    setdataCategoryEvent(data)
                                    settotalpage(res.data.last_page)

                                    setDataLoaded(true)
                                })
                                .catch(err => console.log(err))

                        }
                    })
                    .catch(err => console.log(err))
                return res;
            } catch (error) {
                // console.log(error);
                ls.removeAll()
                localStorage.clear()
                sessionStorage.clear()
                window.location.href = "/"
            }
        }
        loadCategory()
    }, [])

    // klik data kategori
    const onClickGetData = async (id) => {
        try {
            const testing = await CheckToken()
            let token = ls.get('@_tknid').data
            let data_id = id

            if (token) {
                const res = await Axios.get(`${API}/event/all?limit=6&page=1&category_event_id=${id}`, { headers: { Authorization: `Bearer ${token}` } })
                    .then((res) => {
                        let data = res.data.data
                        setdataCategoryEvent(data)
                        setDataLoaded(true)
                        settotalpage(res.data.last_page)

                        Axios.get(`${API}/category_event`)
                            .then((res1) => {
                                let data = res1.data.data
                                let state = [{
                                    name: "All",
                                    id: "",
                                    active: false
                                }]
                                for (let i = 0; i < data.length; i++) {
                                    state.push({
                                        name: data[i].category_event_name,
                                        id: data[i].category_event_id,
                                        active: false
                                    })
                                }
                                state.find(e => e.id === data_id).active = true

                                setlistCategory(state)
                                setDataLoaded(true)
                            })
                            .catch(err => console.log(err))
                    })
                    .catch(err => console.log(err))
                return res;
            } else {
                const res = await Axios.get(`${API}/event/all?limit=6&page=1&category_event_id=${id}`)
                    .then((res) => {
                        let data = res.data.data
                        setdataCategoryEvent(data)
                        setDataLoaded(true)
                        settotalpage(res.data.last_page)

                        Axios.get(`${API}/category_event`)
                            .then((res1) => {
                                let data = res1.data.data
                                let state = [{
                                    name: "All",
                                    id: "",
                                    active: false
                                }]
                                for (let i = 0; i < data.length; i++) {
                                    state.push({
                                        name: data[i].category_event_name,
                                        id: data[i].category_event_id,
                                        active: false
                                    })
                                }
                                state.find(e => e.id === data_id).active = true

                                setlistCategory(state)
                                setDataLoaded(true)
                            })
                            .catch(err => console.log(err))
                    })
                    .catch(err => console.log(err))
                return res;
            }
        } catch (error) {
            // console.log(error);
            ls.removeAll()
            localStorage.clear()
            sessionStorage.clear()
            window.location.href = "/"
        }
    }

    let settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 8,
        slidesToScroll: 1
    };

    // let cekk = jwt_decode("eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1aWQiOjkwLCJpYXQiOjE2Mjc4Njk4MjgsImV4cCI6MTYyNzg3MzQyOH0.ohp40RkhP9mna78_uWW0aYFwIKOJyxblY-MGBp3ggDE")
    // console.log("jwt", cekk.exp)
    return (
        <div className={classes.rootdiv} >
            <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={openDialogShare}>
                <DialogTitle id="simple-dialog-title">Share to Friends</DialogTitle>

                <List>
                    <ListItem style={{ color: "black" }}>
                        <ListItemAvatar>
                            <FacebookShareButton url={`${shareLink}`}>
                                <FacebookIcon size={32} round={true} /> &nbsp; Facebook
                            </FacebookShareButton>
                        </ListItemAvatar>
                    </ListItem>
                </List>

                <List>
                    <ListItem style={{ color: "black" }}>
                        <ListItemAvatar>
                            <EmailShareButton url={`${shareLink}`}>
                                <EmailIcon size={32} round={true} />  &nbsp; Email
                            </EmailShareButton>
                        </ListItemAvatar>
                    </ListItem>
                </List>

                <List>
                    <ListItem style={{ color: "black" }}>
                        <ListItemAvatar>
                            <LineShareButton url={`${shareLink}`}>
                                <LineIcon size={32} round={true} />  &nbsp; Line
                            </LineShareButton>
                        </ListItemAvatar>
                    </ListItem>
                </List>

                <List>
                    <ListItem style={{ color: "black" }}>
                        <ListItemAvatar>
                            <TelegramShareButton url={`${shareLink}`}>
                                <TelegramIcon size={32} round={true} />  &nbsp; Telegram
                            </TelegramShareButton>
                        </ListItemAvatar>
                    </ListItem>
                </List>

                <List>
                    <ListItem style={{ color: "black" }}>
                        <ListItemAvatar>
                            <WhatsappShareButton url={`${shareLink}`}>
                                <WhatsappIcon size={32} round={true} />  &nbsp; Whatsapp
                            </WhatsappShareButton>
                        </ListItemAvatar>
                    </ListItem>
                </List>

                <List>
                    <ListItem style={{ color: "black" }}>
                        <ListItemAvatar>
                            <TwitterShareButton url={`${shareLink}`}>
                                <TwitterIcon size={32} round={true} />  &nbsp; Twitter
                            </TwitterShareButton>
                        </ListItemAvatar>
                    </ListItem>
                </List>
            </Dialog>
            <Container maxWidth="lg">
                {
                    dataLoaded && listCategory ?
                        // <div className={classes.divmargin}> BF382D
                        <div>
                            <Grid container >
                                <Typography variant='h5'>
                                    <Box pt={3} fontWeight={600} color='#7D4DB7'>Kategori Event</Box>
                                </Typography>
                            </Grid>

                            <Box pt={1}>
                                <Box pt={2} pb={3} >
                                    {
                                        listCategory.map((data, index) => (
                                            <Button
                                                variant={data.active ? 'contained' : 'outlined'}
                                                style={{ backgroundColor: `${data.active && (data.name).toUpperCase() === "POWERMATCH" ? '#BF382D' : data.active ? '#9E69D2' : ''}`, color: `${data.active ? '#fff' : '#000'}` }}
                                                // className="buttonCategory"
                                                className={data.id === 21 ? 'buttonCategory21' : 'buttonCategory'}
                                                // :hover
                                                // onmousehob
                                                key={index}
                                                onClick={() => {
                                                    let id = data.id
                                                    if (id === "" || id === undefined) {
                                                        setcatSelected("")
                                                    } else {
                                                        setcatSelected(id)
                                                    }
                                                    onClickGetData(id)
                                                    dispatch(changecolor(true))

                                                }}
                                            >{data.name}
                                            </Button >
                                        ))
                                    }
                                </Box>

                                <Box >
                                    <Grid container spacing={2}  >
                                        {
                                            dataCategoryEvent && dataCategoryEvent.length > 0 ? (
                                                < >
                                                    {
                                                        dataCategoryEvent && dataCategoryEvent.map((data, index) => {
                                                            return (
                                                                <Grid item xl={4} lg={4} md={4} sm={6} xs={12} key={index}  >
                                                                    <Card className={classes.root} title={`${data.event_name} by ${data.organizer.organizer_name}`}>
                                                                        <Link href={`/detail-event/${data.event_slug}`}>
                                                                            {/* <CardMedia
                                                                                className={classes.media}
                                                                                image={data.event_image_url}
                                                                                title={data.event_name}
                                                                            // style={{ opacity: '0.9' }}
                                                                            /> noo*/}

                                                                            {/* <Box textAlign="center" className="imgevent" style={{
                                                                                height: "210px", alignContent: "center", backgroundImage: `url(${data.event_image_url})`
                                                                                // background: `linear-gradient(rgba(138, 133, 133, 0.367), rgba(171, 170, 170, 0.222)), url(${data.event_image_url})`
                                                                            }}></Box> */}

                                                                            {/* <Box textAlign="center" className="imge" style={{ height: "210px", alignContent: "center", zIndex: 1 }}>
                                                                                <img width="auto" height="100%" src={data.event_image_url} />
                                                                            </Box> */}
                                                                            <Box textAlign="center" className="imgbanner" style={{ alignContent: "center", zIndex: 1 }}>
                                                                                <img width="100%" height="180px" src={data.event_image_url} />
                                                                            </Box>
                                                                        </Link>
                                                                        {/* like event */}
                                                                        <CardContent className={classes.info}>

                                                                            <Box className={classes.boxinfo}>
                                                                                <Box >
                                                                                    <Link style={{ cursor: 'pointer' }}>
                                                                                        {
                                                                                            data.current_auth_liked ?
                                                                                                <Tooltip TransitionComponent={Zoom} title="Hapus sebagai favorit" arrow placement="bottom">
                                                                                                    <FavoriteIcon color="error" style={{ zIndex: 5 }}
                                                                                                        onClick={async () => {
                                                                                                            try {
                                                                                                                const testing = await CheckToken()

                                                                                                                let token = ls.get('@_tknid').data
                                                                                                                const res = await Axios.delete(`${API}/event/like`, {
                                                                                                                    headers: { Authorization: `Bearer ${token}` },
                                                                                                                    data: { event_id: data.event_id }
                                                                                                                })
                                                                                                                if (res) {
                                                                                                                    await Axios.get(`${API}/event/all?page=1&limit=6&category_event_id=${catSelected}`, { headers: { Authorization: `Bearer ${token}` } })
                                                                                                                        .then((res) => {
                                                                                                                            let data = res.data.data
                                                                                                                            setdataCategoryEvent(data)
                                                                                                                            settotalpage(res.data.last_page)
                                                                                                                        })
                                                                                                                        .catch((err) => {
                                                                                                                            console.log(err)
                                                                                                                            if (err.response.status === 401) {
                                                                                                                                let tokenn = ls.get('@_tknid').data
                                                                                                                                let rtokenn = ls.get('@_rtknid').data
                                                                                                                                let formdata = new FormData()
                                                                                                                                let options = {
                                                                                                                                    headers: {
                                                                                                                                        Authorization: `Bearer ${tokenn}`
                                                                                                                                    }
                                                                                                                                }
                                                                                                                                formdata.append("refresh_token", rtokenn)
                                                                                                                                Axios.post(`${API}/refresh_token`, formdata, options, null)
                                                                                                                                    .then(res => {
                                                                                                                                        ls.set('@_tknid', { data: res.data.data.token })
                                                                                                                                        ls.set('@_rtknid', { data: res.data.data.refreshToken })
                                                                                                                                    }).catch(err => {
                                                                                                                                    })
                                                                                                                            }
                                                                                                                        })
                                                                                                                }
                                                                                                            } catch (error) {
                                                                                                                // console.log(error)
                                                                                                                ls.removeAll()
                                                                                                                localStorage.clear()
                                                                                                                sessionStorage.clear()
                                                                                                                window.location.href = "/"
                                                                                                            }
                                                                                                        }} />
                                                                                                </Tooltip>
                                                                                                :
                                                                                                <Tooltip TransitionComponent={Zoom} title="Tambah sebagai favorit" arrow placement="bottom">
                                                                                                    <FavoriteBorderIcon style={{ color: "white", zIndex: 5 }}
                                                                                                        onClick={async () => {
                                                                                                            try {
                                                                                                                const testing = await CheckToken()

                                                                                                                let token = ls.get('@_tknid').data
                                                                                                                if (!token) {
                                                                                                                    window.location.href = "/login";
                                                                                                                } else {
                                                                                                                    let formdata = new FormData()
                                                                                                                    let options = {
                                                                                                                        headers: {
                                                                                                                            Authorization: `Bearer ${token}`
                                                                                                                        }
                                                                                                                    }
                                                                                                                    formdata.append("event_id", data.event_id)
                                                                                                                    const res = await Axios.post(`${API}/event/like`, formdata, options, null)
                                                                                                                    if (res) {
                                                                                                                        await Axios.get(`${API}/event/all?page=1&limit=6&category_event_id=${catSelected}`, { headers: { Authorization: `Bearer ${token}` } })
                                                                                                                            .then((res) => {
                                                                                                                                let data = res.data.data
                                                                                                                                setdataCategoryEvent(data)
                                                                                                                                settotalpage(res.data.last_page)

                                                                                                                                // dispatch(getUpcomingEvent)
                                                                                                                                // setDataLoaded(true)
                                                                                                                            })
                                                                                                                            .catch((err) => {
                                                                                                                                console.log(err)
                                                                                                                                if (err.response.status === 401) {
                                                                                                                                    let tokenn = ls.get('@_tknid').data
                                                                                                                                    let rtokenn = ls.get('@_rtknid').data
                                                                                                                                    let formdata = new FormData()
                                                                                                                                    let options = {
                                                                                                                                        headers: {
                                                                                                                                            Authorization: `Bearer ${tokenn}`
                                                                                                                                        }
                                                                                                                                    }
                                                                                                                                    formdata.append("refresh_token", rtokenn)
                                                                                                                                    Axios.post(`${API}/refresh_token`, formdata, options, null)
                                                                                                                                        .then(res => {
                                                                                                                                            ls.set('@_tknid', { data: res.data.data.token })
                                                                                                                                            ls.set('@_rtknid', { data: res.data.data.refreshToken })
                                                                                                                                        }).catch(err => {
                                                                                                                                        })
                                                                                                                                }
                                                                                                                            })
                                                                                                                    }
                                                                                                                }
                                                                                                            } catch (error) {
                                                                                                                ls.removeAll()
                                                                                                                localStorage.clear()
                                                                                                                sessionStorage.clear()
                                                                                                                window.location.href = "/"
                                                                                                            }
                                                                                                        }}
                                                                                                    />
                                                                                                </Tooltip>
                                                                                        }
                                                                                    </Link>
                                                                                </Box>
                                                                            </Box>


                                                                        </CardContent>
                                                                        <CardContent className={classes.share} onClick={() => {
                                                                            setshareLink(data.event_full_url)
                                                                            setopenDialogShare(true)
                                                                        }}>
                                                                            <Tooltip TransitionComponent={Zoom} title="share" arrow placement="bottom">
                                                                                <Box className={classes.boxshare}>
                                                                                    <Box pr={1} pl={1} >
                                                                                        <Link style={{ cursor: 'pointer' }}>
                                                                                            <FaShare style={{ color: 'white' }} />
                                                                                        </Link>
                                                                                    </Box>
                                                                                </Box>
                                                                            </Tooltip>
                                                                        </CardContent>

                                                                    </Card>
                                                                    <Box pl={1} pr={1} pt={1} pb={1} color='#000' style={{
                                                                        width: '100%', whiteSpace: 'nowrap',
                                                                        borderBottom: 'solid 2px #d1d1d1',
                                                                    }}>
                                                                        <Link href={`/detail-event/${data.event_slug}`}>
                                                                            <Box fontSize={17} fontWeight={600} overflow="hidden" textOverflow="ellipsis" color="#393755" title={`${data.event_name} by ${data.organizer.organizer_name}`}>{data.event_name}</Box>
                                                                        </Link>


                                                                        {
                                                                            moment(data.event_start_datetime).format('DD MMMM YYYY') !== moment(data.event_end_datetime).format('DD MMMM YYYY') ?
                                                                                <Box color="#393755" fontSize={14} fontWeight={100} overflow="hidden" textOverflow="ellipsis">
                                                                                    <EventIcon style={{ fontSize: "14px" }} />{' '}
                                                                                    {moment(data.event_start_datetime).format('DD MMMM YYYY')}
                                                                                    {' '}- {' '}
                                                                                    {moment(data.event_end_datetime).format('DD MMMM YYYY')}
                                                                                </Box> :
                                                                                <Box color="#393755" fontSize={14} fontWeight={100} overflow="hidden" textOverflow="ellipsis">
                                                                                    <EventIcon style={{ fontSize: "14px" }} />
                                                                                    {' '}
                                                                                    {moment(data.event_end_datetime).format('DD MMMM YYYY')}
                                                                                </Box>
                                                                        }
                                                                        {
                                                                            data.category_venue_id === 3 ?
                                                                                <Box color="#393755" fontSize={14} overflow="hidden" textOverflow="ellipsis"><LocationOnIcon style={{ fontSize: "14px" }} /> Online Event
                                                                                </Box> :
                                                                                <Box color="#393755" fontSize={14} overflow="hidden" textOverflow="ellipsis"><LocationOnIcon style={{ fontSize: "14px" }} /> {data.event_location} {(data.event_location_detail && data.event_location_detail.length > 0) || data.event_location_detail !== null ? ` - ${data.event_location_detail}` : null}
                                                                                </Box>

                                                                        }

                                                                        {/* <Box pt={1} color="#393755" fontSize={14} overflow="hidden" textOverflow="ellipsis" style={{ borderTop: 'solid 1px #d1d1d1' }}>{data.organizer.organizer_name}</Box> */}
                                                                        {/* <Box style={{ borderBottom: 'solid 2px #d1d1d1', paddingTop: '10px' }}></Box> */}
                                                                    </Box>
                                                                </Grid>
                                                            )
                                                        })}

                                                </>
                                            )
                                                : dataLoaded && dataCategoryEvent.length === 0 ?
                                                    (<>
                                                        <Grid container direction='row' justify='center'>
                                                            <Box textAlign="center" pt={4}>
                                                                <img alt='nothing here' src={Tidaktersedia} width='80%' />
                                                            </Box>
                                                        </Grid>
                                                        <Grid container direction='row' justify='center'>
                                                            <Box id="fontStyle" color="#393755" pt={3}>Saat ini belum tersedia</Box>
                                                        </Grid>
                                                    </>
                                                    ) :
                                                    <>
                                                        <Grid container spacing={2} style={{ height: '260px' }} className={classes.rootcateventmob} >
                                                            <Grid item lg={4} xl={4} md={6} sm={6} xs={12} >
                                                                <Skeleton variant="rect" height={180} style={{ borderRadius: '10px' }} />
                                                                <Skeleton variant="text" height={50} style={{ borderRadius: '10px' }} />
                                                            </Grid>
                                                        </Grid>
                                                        {/* <Grid container spacing={2} style={{ height: '260px' }} className={classes.rootcatevent} >
                                                            <Grid item lg={4} xl={4} md={6} sm={6} xs={12}>
                                                                <Skeleton variant="rect" height={180} style={{ borderRadius: '10px' }} />
                                                                <Skeleton variant="text" height={50} style={{ borderRadius: '10px' }} />

                                                            </Grid>
                                                            <Grid item lg={4} xl={4} md={6} sm={6} xs={12} >
                                                                <Skeleton variant="rect" height={180} style={{ borderRadius: '10px' }} />
                                                                <Skeleton variant="text" height={50} style={{ borderRadius: '10px' }} />

                                                            </Grid>
                                                            <Grid item lg={4} xl={4} md={6} sm={6} xs={12}>
                                                                <Skeleton variant="rect" height={180} style={{ borderRadius: '10px' }} />
                                                                <Skeleton variant="text" height={50} style={{ borderRadius: '10px' }} />

                                                            </Grid>
                                                        </Grid> */}
                                                    </>
                                        }
                                    </Grid>
                                    {/* {
                                        dataLoaded && dataCategoryEvent.length > 5 ? (
                                            <Box pt={2} className="boxmore">
                                                <Link href={`/event?category=${catSelected}`}>
                                                    <Button className="buttonmore"
                                                    >
                                                        <div style={{ paddingLeft: "10px", paddingRight: "10px" }} >
                                                            Tampilkan Semua
                                                        </div>
                                                    </Button>
                                                </Link>
                                            </Box>
                                        ) : null
                                    } */}
                                    {
                                        totalpage > 1 ?
                                            <Box pt={2} className="boxmore">
                                                <Link href={`/event?category=${catSelected}`}>
                                                    <Button className="buttonmore"
                                                    >
                                                        <div style={{ paddingLeft: "10px", paddingRight: "10px" }} >
                                                            Tampilkan Semua
                                                        </div>
                                                    </Button>
                                                </Link>
                                            </Box>
                                            : null
                                    }
                                </Box>
                            </Box>
                        </div >
                        :
                        <>
                            <Box className={classes.rootcatevent}>
                                <Grid container spacing={2}>
                                    <Grid item lg={2} xl={2} md={2} sm={2} xs={2} >
                                        <Skeleton variant="text" height={50} style={{ borderRadius: "20px" }} />

                                    </Grid>
                                    <Grid item lg={2} xl={2} md={2} sm={2} xs={2} >
                                        <Skeleton variant="text" height={50} style={{ borderRadius: "20px" }} />

                                    </Grid>
                                    <Grid item lg={2} xl={2} md={2} sm={2} xs={2} >
                                        <Skeleton variant="text" height={50} style={{ borderRadius: "20px" }} />

                                    </Grid>
                                    <Grid item lg={2} xl={2} md={2} sm={2} xs={2} >
                                        <Skeleton variant="text" height={50} style={{ borderRadius: "20px" }} />

                                    </Grid>
                                    <Grid item lg={2} xl={2} md={2} sm={2} xs={2} >
                                        <Skeleton variant="text" height={50} style={{ borderRadius: "20px" }} />

                                    </Grid>
                                    <Grid item lg={2} xl={2} md={2} sm={2} xs={2} >
                                        <Skeleton variant="text" height={50} style={{ borderRadius: "20px" }} />

                                    </Grid>

                                </Grid>


                                <Grid container spacing={2} style={{ height: '260px', paddingTop: "10px" }} >
                                    <Grid item lg={4} xl={4} md={3} >
                                        <Skeleton variant="rect" height={180} style={{ borderRadius: '10px' }} />
                                        <Skeleton variant="text" height={50} style={{ borderRadius: '10px' }} />

                                    </Grid>
                                    <Grid item lg={4} xl={4} md={3} >
                                        <Skeleton variant="rect" height={180} style={{ borderRadius: '10px' }} />
                                        <Skeleton variant="text" height={50} style={{ borderRadius: '10px' }} />

                                    </Grid>
                                    <Grid item lg={4} xl={4} md={3}>
                                        <Skeleton variant="rect" height={180} style={{ borderRadius: '10px' }} />
                                        <Skeleton variant="text" height={50} style={{ borderRadius: '10px' }} />

                                    </Grid>
                                </Grid>
                            </Box>

                            <Box className={classes.rootcateventmob} >
                                <Grid container spacing={2}>
                                    <Grid item lg={2} xl={2} md={2} sm={2} xs={2} >
                                        <Skeleton variant="text" height={50} style={{ borderRadius: "20px" }} />

                                    </Grid>
                                    <Grid item lg={2} xl={2} md={2} sm={2} xs={2} >
                                        <Skeleton variant="text" height={50} style={{ borderRadius: "20px" }} />

                                    </Grid>
                                    <Grid item lg={2} xl={2} md={2} sm={2} xs={2} >
                                        <Skeleton variant="text" height={50} style={{ borderRadius: "20px" }} />

                                    </Grid>
                                    <Grid item lg={2} xl={2} md={2} sm={2} xs={2} >
                                        <Skeleton variant="text" height={50} style={{ borderRadius: "20px" }} />

                                    </Grid>
                                    <Grid item lg={2} xl={2} md={2} sm={2} xs={2} >
                                        <Skeleton variant="text" height={50} style={{ borderRadius: "20px" }} />

                                    </Grid>
                                    <Grid item lg={2} xl={2} md={2} sm={2} xs={2} >
                                        <Skeleton variant="text" height={50} style={{ borderRadius: "20px" }} />

                                    </Grid>

                                </Grid>

                                <Grid container spacing={2} style={{ height: '260px', paddingTop: "10px" }} >
                                    <Grid item sm={6} xs={12} >
                                        <Skeleton variant="rect" height={180} style={{ borderRadius: '10px' }} />
                                        <Skeleton variant="text" height={50} style={{ borderRadius: '10px' }} />

                                    </Grid>

                                </Grid>
                            </Box>


                        </>
                }
            </Container >
        </div >

    )
    // }
}

export default CategoryEvent;