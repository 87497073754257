import { cloneElement, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { SNAP } from './../config'


const { oneOfType, arrayOf, node, func, string } = PropTypes

class Midtrans extends PureComponent {


  static getDerivedStateFromProps(nextProps, prevState) {
    return nextProps.token !== prevState.token
      ? { token: nextProps.token }
      : null
  }

  constructor(props) {
    super(props)


    this.state = {
      children: null,
      token: ''
    }


    this.mergeWithChildren = this.mergeWithChildren.bind(this)

    this.currentViewport = document
      .getElementsByTagName('meta')
      .hasOwnProperty('viewport')
      ? document.getElementsByTagName('meta').viewport
      : ''
    this.snapScript = document.createElement('script')

    // checking mode

    this.snapScript.src = `${SNAP}`

    // this.snapScript.src = 'https://app.midtrans.com/snap/snap.js'
    // https://app.sandbox.midtrans.com/snap/snap.js


    this.snapScript.type = 'text/javascript'
    this.snapScript.onload = this.onLoad.bind(this)
    this.snapScript.dataset.clientKey = props.clientKey
  }

  onLoad(e) {
    if ('snap' in window) {
      const { snap } = window
      this.setState({ snap })
    }
  }

  componentDidMount() {
    document.head.appendChild(this.snapScript)
    this.mergeWithChildren(this.props.children)
  }



  mergeWithChildren(children) {

    const redirectToTicket = () => {
      const { history } = this.props;
      if (history) history.push('/myevent');
    }

    const redirectToTransaction = () => {
      const { history } = this.props;
      if (history) history.push('/transaksi');
    }

    children = cloneElement(
      children,

      {
        onClick: () => {

          children.onClick && children.onClick()

          if (this.state.token && this.state.token !== '') {

            this.state.snap.pay(
              this.state.token, {
              onSuccess: function (result) {
                // console.log('success');console.log(result);
                redirectToTicket()
              },
              onPending: function (result) {
                // console.log('pending');console.log(result);
                redirectToTransaction()
              },
              onError: function (result) {
                // console.log('error');console.log(result);
                redirectToTransaction()
              },
              onClose: function () {
                // console.log('customer closed the popup without finishing the payment');
                redirectToTransaction()
              }
            }

            )
          }
          this.props.onClick && this.props.onClick()
        },

      },

    )

    this.setState({
      children,
    })
  }

  render() {
    return this.state.children
  }
}

Midtrans.propTypes = {
  children: oneOfType([arrayOf(node), node]).isRequired,
  clientKey: string.isRequired,
  token: string,
  onSuccess: func,
  onPending: func,
  onError: func,
  onClose: func,
  onClick: func,
}


export default withRouter(Midtrans)