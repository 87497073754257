import Axios from 'axios'
import { API } from '../../config'

import {
    // GET DATA CATEGORY EVENT
    GET_CATEGORY_EVENT_REQUEST,
    GET_CATEGORY_EVENT_FAILED,
    GET_CATEGORY_EVENT_SUCCESS,
    GET_KOTA_VENUE_SUCCESS,
    CHANGE_COLOR_SUCCESS

} from './CategoryEventTypes'

import jwt_decode from 'jwt-decode'
import * as SecureLs from 'secure-ls'
const ls = new SecureLs();
const CheckToken = () => {
    let rtoken = ls.get('@_rtknid').data
    let tokenn = ls.get('@_tknid').data
    if (tokenn) {
        var jwt = jwt_decode(tokenn);
        var today = new Date().getTime();
        var jwt_exp = jwt.exp * 1000 - (1000 * 20); // sebelum 20 detik

        if (jwt_exp < today) {
            // request
            let formdata = new FormData()
            let options = {
                headers: {
                    Authorization: `Bearer ${tokenn}`
                }
            }
            formdata.append("refresh_token", rtoken)
            const refreshToken = Axios.post(`${API}/refresh_token`, formdata, options, null)
            if (refreshToken) {
                ls.set('@_tknid', { data: refreshToken.data.data.token })
                ls.set('@_rtknid', { data: refreshToken.data.data.refreshToken })
            }
        } else {
            console.log("tes")

        }
    }
}


const getCategoryEventRequest = () => {
    return {
        type: GET_CATEGORY_EVENT_REQUEST
    }
}


const getCategoryEventFailed = (message) => {
    return {
        type: GET_CATEGORY_EVENT_FAILED,
        payload: message
    }
}

const getCategoryEventSuccess = (data) => {
    return {
        type: GET_CATEGORY_EVENT_SUCCESS,
        payload: data
    }
}

export const getCategoryEvent = () => {
    return (
        async (dispatch) => {
            try {
                const testing = await CheckToken()
                dispatch(getCategoryEventRequest())
                await Axios.get(`${API}/category_event`)
                    .then((res) => {
                        // console.log('category event', res)                 
                        dispatch(getCategoryEventSuccess(res.data.data))
                    }).catch((err) => {
                        dispatch(getCategoryEventFailed(err))
                        // console.log(err)

                    })
            } catch (error) {

            }

        }
    )
}


const getKotaListSuccess = (data) => {
    return {
        type: GET_KOTA_VENUE_SUCCESS,
        payload: data
    }
}

export const getKotaList = () => {
    return (
        async (dispatch) => {
            try {
                const testing = await CheckToken()
                dispatch(getCategoryEventRequest())
                await Axios.get(`${API}/ms_city?limit=514`)
                    .then((res) => {
                        // console.log('kota list', res)
                        if (res.data.data) {
                            let kotalist = res.data.data
                            kotalist.sort((a, b) => (a.ms_city_name > b.ms_city_name) ? 1 : ((b.ms_city_name > a.ms_city_name) ? -1 : 0))

                            dispatch(getKotaListSuccess(kotalist))
                        }
                    }).catch((err) => {
                        dispatch(getCategoryEventFailed(err))
                        // console.log(err)

                    })
            } catch (error) {

            }

        }
    )
}

const changecolorsuccess = (data) => {
    return {
        type: CHANGE_COLOR_SUCCESS,
        payload: data
    }
}

export const changecolor = () => {
    return (
        (dispatch) => {
            dispatch(changecolorsuccess(true))
        }
    )

}
