import React from 'react';
import { Route, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux';



const UserRoute = ({ component: Component, ...rest }) => {
    const profile = useSelector(state => state.profile.get_profile)

    const isAuthenticated = () => {
        if (typeof window == 'undefined') {
            return false
        }

        // if(localStorage.getItem('token')) {
        if (localStorage.getItem('@_tknid')) {

            return localStorage.getItem('@_tknid')
        } else {
            return false
        }
    }

    return (
        <Route
            {...rest}
            render={props => {
                if (profile.id || isAuthenticated()) {
                    return <Component {...props} />
                }
                else {
                    return <Redirect
                        to={{
                            pathname: "*",
                            state: { from: props.location }
                        }}
                    />
                }

            }}
        />
    )

}

export default UserRoute;