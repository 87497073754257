import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons

import { Grid, Box } from '@material-ui/core'
// import NavPills from "./NavPills.js";
import SearchedEventAll from "./SearchedEvent/CategoryAll.js";
// import SearchedEventStore from "./SearchedEvent/CategoryStore.js";
// import SearchedEventPublic from "./SearchedEvent/CategoryPublic.js";
// import SearchedEventOnline from './SearchedEvent/CategoryOnline.js'

// import styles from "assets/jss/material-kit-react/views/componentsSections/pillsStyle.js";
const useStyles = makeStyles((theme) => ({
  section: {
    overflow: 'hidden !important'
  }
}));

export default function SectionPillsSearched() {
  const classes = useStyles();


  return (
    <div className={classes.section}>
      <div >
        {/* <div id="navigation-pills"> */}

        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12} >
            <Box pt={2}>
              <SearchedEventAll />
            </Box>
            {/* <NavPills
                
                color="primary"
                tabs={[
                  {
                    tabButton: "SEMUA",
                    tabContent: (
                      <span >
                      
                      <SearchedEventAll/>
                      </span>
                    )
                  },
                  // {
                  //   tabButton: "Online",
                  //   tabContent: (
                  //     <span>
                  //       <SearchedEventAll/>
                  //     </span>
                  //   )
                  // },
                  // {
                  //   tabButton: "TOKO",
                  //   tabContent: (
                  //     <span>
                  //       <SearchedEventAll/>
                  //     </span>
                  //   )
                  // },
                  // {
                  //   tabButton: "UMUM",
                  //   tabContent: (
                  //     <span>
                  //       <SearchedEventAll/>
                  //     </span>
                  //   )
                  // }
                ]}
              /> */}
          </Grid>

        </Grid>
      </div>
    </div>
    // </div>
  );
}
