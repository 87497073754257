import React from 'react';
import AppBarUser from '../../components/AppBarUser';
import PasswordComponent from '../../components/DashboardUser/Password/Password'
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Container } from '@material-ui/core';
import MenuSideUser from '../../components/DashboardUser/MenuSideUser';
import Meta from '../../components/Util/MetaDecorator'

const useStyles = makeStyles((theme) => ({
    menuSide: {

    },

}));



const PasswordPage = () => {
    const classes = useStyles();

    // document.title = "Ganti Password | Eventories.com "
    return (
        <React.Fragment>
            <Meta title="Ganti Password | Eventories.com" description="Klik untuk ganti password Akun Eventories" />

            <AppBarUser />
            <Container maxWidth='lg'>
                <Grid container spacing={2}>
                    <Grid item lg={2} md={2} className={classes.menuSide} >
                        <MenuSideUser />

                    </Grid>
                    <Grid item lg={10} md={10} sm={12} xs={12}>

                        <PasswordComponent />

                    </Grid>

                </Grid>
            </Container>
        </React.Fragment>
    )
}

export default PasswordPage;