import React from 'react';
import AppBarUser from '../../components/AppBarUser';
import MyTransaksiComponent from '../../components/DashboardUser/Transaksi/MyTransactionComponent';

const MyTransaksiUser = () => {
    document.title = "Transaksi Saya | Eventories.com "
    return (
        <React.Fragment>
            <AppBarUser />
            <MyTransaksiComponent />
        </React.Fragment>
    )
}

export default MyTransaksiUser;