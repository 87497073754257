import React from 'react';
import AppBarUser from '../../components/AppBarUser';
import MyEventComponent from '../../components/DashboardUser/MyEvent/MyEventComponent';
import Meta from '../../components/Util/MetaDecorator'

const MyEventUser = () => {
    // document.title = "Tiket Saya | Eventories.com "
    return (
        <React.Fragment>
            <Meta title="Tiket Saya | Eventories.com" description="Daftar Tiket Saya" />

            <AppBarUser />
            <MyEventComponent />
        </React.Fragment>
    )
}

export default MyEventUser;