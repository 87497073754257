import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Grid, Box, Paper, Button } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment'
import Skeleton from '@material-ui/lab/Skeleton';
import Pagination from '@material-ui/lab/Pagination';
import QRCode from 'qrcode.react'
import stateempty from './../../../assets/img/statekosong.png'

import {
    getTicketPending
} from '../../../redux'

const useStyles = makeStyles((theme) => ({
    rootcontainer: {
        paddingTop: theme.spacing(12),
        paddingBottom: theme.spacing(8)
    },
    rootdiv: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(5)
    },
    divmargin: {
        paddingLeft: '20px',
        paddingRight: '20px'
    },
    buttontab: {
        borderRadius: '30px'
    },
    root: {
        maxWidth: 380,
        borderRadius: '10px 10px 0 0',
        position: 'relative'
    },
    media: {
        height: 180,
        width: '100%',
    },
    info: {
        position: 'absolute',
        top: 0,
        left: 0
    },
    boxinfo: {

        opacity: 0.9,
        borderRadius: '15px',
    },
    share: {
        position: 'absolute',
        top: 0,
        right: 0
    },
    boxshare: {
        display: 'flex'
    },
    boxTiket: {
        height: 'fit-content',
        backgroundColor: '#9e69d2',
        borderRadius: '15px'
    },
    boxTop: {
        width: '100%',
        height: 'fit-content'
    },
    boxBottom: {
        width: '100%',
        backgroundColor: '#fff',
        borderRadius: '0 0 15px 15px'


    }
}));


const MyTransaksiComponent = () => {

    const classes = useStyles();

    const ticket_pending = useSelector(state => state.purchaseticket.ticket_pending.data)
    const ticket_pending_data = useSelector(state => state.purchaseticket.ticket_pending)
    const loading = useSelector(state => state.purchaseticket)


    const [page, setPage] = React.useState(1);
    const handleChange = (event, value) => {
        setPage(value);
        dispatch(getTicketPending(value))
    };


    // dispatch action
    const dispatch = useDispatch()

    useEffect(() => {
        let page = 1
        dispatch(getTicketPending(page))
    }, [dispatch]);

    const showData = () => {
        if (ticket_pending_data.total > 0) {
            return (
                <Grid lg={12} md={12} sm={12} xs={12}>

                    <Box pb={4}>
                        <Grid container spacing={2} >
                            {ticket_pending.map((data, index) => (

                                <Grid item lg={3} md={3} sm={6} xs={12}>
                                    <Box>
                                        <Paper elevation={3} className={classes.boxTiket} >
                                            <Grid
                                                container
                                                direction="column"
                                                justify="flex-end"
                                                alignItems="center"
                                            >
                                                <Box className={classes.boxTop} p={4} >
                                                    <Grid container>

                                                        <Grid item lg={6} md={6} sm={6} xs={6}>
                                                            <Box p={1} textAlign='center' color="#fff">
                                                                <Box fontWeight={600} fontSize={12} textAlign="center" style={{ textTransform: 'capitalize' }}>{data.ticket_name}</Box>
                                                                <Box pt={1} pb={1} fontWeight={100} fontSize={13} textAlign="center">Seat Number</Box>
                                                                <Box fontWeight={600} fontSize={20} textAlign="center">{data.ticket_seat_number}</Box>
                                                                {/* <Box fontWeight={100} fontSize={12} textAlign="center">29-06-2020 | 19:00</Box> */}
                                                            </Box>
                                                        </Grid>
                                                        <Grid item lg={6} md={6} sm={6} xs={6}>
                                                            <QRCode bgColor="#9E69D2" fgColor="white" value={data.ticket_booking_number} />

                                                            {/* <img src={qrcode} alt="qrcode" width='100%' /> */}
                                                        </Grid>
                                                    </Grid>
                                                </Box>

                                                <Box className={classes.boxBottom} p={2}>
                                                    <Box fontWeight={600} textAlign="center">{data.event.event_name}</Box>
                                                    <Box fontWeight={100} fontSize={13} textAlign="center">
                                                        {moment(data.ticket_datetime).format('D MMM YYYY | h:mm a')}
                                                    </Box>

                                                    <Box pt={2} pb={1} textAlign="center">
                                                        {
                                                            data.ticket_status_name === 'pending' &&

                                                            <Button
                                                                style={{ borderRadius: '15px' }}
                                                                size='small' variant="contained"
                                                                color='secondary'
                                                            >
                                                                <Box pr={2} pl={2} style={{ textTransform: 'capitalize' }}>Menunggu Pembayaran</Box>
                                                            </Button>
                                                        }
                                                    </Box>
                                                </Box>
                                            </Grid>
                                        </Paper>
                                    </Box>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>

                    <Grid container direction='row' justify='flex-end' >
                        <Box pt={2} style={{ display: ticket_pending_data.last_page > 1 ? 'asdff' : 'none' }}>
                            <Pagination color='secondary' count={ticket_pending_data.last_page} page={page} onChange={handleChange} />
                        </Box>
                    </Grid>

                </Grid>
            )
        } else {
            return (
                <>

                    <Grid container direction='row' justify='center'>
                        <Box textAlign="center" pt={4}>
                            <img alt='nothing here' src={stateempty} width='80%' />
                        </Box>
                    </Grid>

                    <Grid container direction='row' justify='center'>
                        <Box id="fontStyle" pt={3}>Kamu tidak mempunyai Transaksi yang belum dibayar.</Box>
                    </Grid>

                </>
            )

        }
    }


    return (
        <React.Fragment >
            <Container maxWidth="lg" className={classes.rootcontainer}>

                <Grid container >
                    <Typography variant='h5'>
                        <Box fontWeight={600} color='#9e69d2'>Transaksi Tertunda</Box>
                        <Box fontWeight={100} fontSize={13} color='#979797'>Keterangan</Box>
                    </Typography>
                </Grid>

                <Box pt={4}>
                    <Grid container>
                        {loading.loading === false ?
                            showData()
                            :
                            <>
                                <Grid container spacing={2} style={{ height: '260px' }} >
                                    <Grid item lg={3} >
                                        <Skeleton variant="rect" height={180} style={{ borderRadius: '10px' }} />
                                        <Skeleton variant="text" height={50} style={{ borderRadius: '10px' }} />


                                    </Grid>
                                    <Grid item lg={3} >
                                        <Skeleton variant="rect" height={180} style={{ borderRadius: '10px' }} />
                                        <Skeleton variant="text" height={50} style={{ borderRadius: '10px' }} />

                                    </Grid>
                                    <Grid item lg={3}>
                                        <Skeleton variant="rect" height={180} style={{ borderRadius: '10px' }} />
                                        <Skeleton variant="text" height={50} style={{ borderRadius: '10px' }} />

                                    </Grid>
                                    <Grid item lg={3}>
                                        <Skeleton variant="rect" height={180} style={{ borderRadius: '10px' }} />
                                        <Skeleton variant="text" height={50} style={{ borderRadius: '10px' }} />

                                    </Grid>
                                </Grid>

                                <Grid container spacing={2} style={{ height: '260px' }} >
                                    <Grid item lg={3} >
                                        <Skeleton variant="rect" height={180} style={{ borderRadius: '10px' }} />
                                        <Skeleton variant="text" height={50} style={{ borderRadius: '10px' }} />

                                    </Grid>
                                    <Grid item lg={3} >
                                        <Skeleton variant="rect" height={180} style={{ borderRadius: '10px' }} />
                                        <Skeleton variant="text" height={50} style={{ borderRadius: '10px' }} />

                                    </Grid>
                                    <Grid item lg={3}>
                                        <Skeleton variant="rect" height={180} style={{ borderRadius: '10px' }} />
                                        <Skeleton variant="text" height={50} style={{ borderRadius: '10px' }} />

                                    </Grid>
                                    <Grid item lg={3}>
                                        <Skeleton variant="rect" height={180} style={{ borderRadius: '10px' }} />
                                        <Skeleton variant="text" height={50} style={{ borderRadius: '10px' }} />

                                    </Grid>
                                </Grid>

                            </>
                        }

                    </Grid>
                </Box>


            </Container>
        </React.Fragment>

    )
}

export default MyTransaksiComponent;