import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Grid, Box, Paper, Button, Dialog, DialogActions, DialogContent, DialogTitle, Link } from '@material-ui/core';

import Container from '@material-ui/core/Container';
import { useDispatch, useSelector } from 'react-redux';
import Skeleton from '@material-ui/lab/Skeleton';
import Tidaktersedia from './../../../assets/img/statekosong.png'
import Midtrans from '../../../components/Midtrans';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {
    getTicketPending
} from '../../../redux'
import Axios from 'axios';
import { API } from './../../../config'
import moment from 'moment'
import * as SecureLs from 'secure-ls'
import Select from 'react-select'
import jwt_decode from 'jwt-decode'


const ls = new SecureLs();
const useStyles = makeStyles((theme) => ({
    rootcontainer: {
        paddingTop: theme.spacing(12),
        paddingBottom: theme.spacing(8)
    },
    rootdiv: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(5)
    },
    divmargin: {
        paddingLeft: '20px',
        paddingRight: '20px'
    },
    buttontab: {
        borderRadius: '30px'
    },
    root: {
        maxWidth: 380,
        borderRadius: '10px 10px 0 0',
        position: 'relative'
    },
    media: {
        height: 180,
        width: '100%',
    },
    info: {
        position: 'absolute',
        top: 0,
        left: 0
    },
    boxinfo: {

        opacity: 0.9,
        borderRadius: '15px',
    },
    share: {
        position: 'absolute',
        top: 0,
        right: 0
    },
    boxshare: {
        display: 'flex'
    },
    boxTiket: {
        height: 'fit-content',
        backgroundColor: '#9e69d2',
        // borderRadius: '20px'
        borderColor: "#eeee"
    },
    boxTop: {
        width: '100%',
        height: 'fit-content'
    },
    boxBottom: {
        width: '100%',
        backgroundColor: '#fff',
        // borderRadius: '0 0 15px 15px'


    },
    btnCustom: {
        textAlign: 'center',
        cursor: 'pointer',
        borderRadius: '5px',
        border: 'solid 1px #fbfbfb',
        backgroundColor: '#9e69d2',
        width: '100%',
        color: '#fbfbfb',
        height: '40px',
        paddingTop: '7px',
        paddingRight: '7px',
        paddingLeft: '7px',
        "&:hover": {
            backgroundColor: '#fbfbfb',
            color: '#9e69d2',
            border: 'solid 1px #9e69d2',
            paddingTop: '7px',
            paddingRight: '7px',
            paddingLeft: '7px',
        }
    },
    dialog: {
        minWidth: '150px'
    },
    desc: {
        [theme.breakpoints.up('xs')]: {
            fontSize: '9px'
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '13px'
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '14px'
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '14px'
        },
        [theme.breakpoints.up('xl')]: {
            fontSize: '14px'
        }
    },
    details: {
        [theme.breakpoints.up('xs')]: {
            fontSize: '10px'
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '13px'
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '14px'
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '14px'
        },
        [theme.breakpoints.up('xl')]: {
            fontSize: '14px'
        }
    }
}));


const MyTransaksiComponent = () => {

    const classes = useStyles();
    const loading = useSelector(state => state.purchaseticket)

    const [detailklik, setdetailklik] = useState("");



    const [listpending, setlistpending] = useState();
    const [listapproved, setlistapproved] = useState();
    const [listrejected, setlistrejected] = useState();
    const [listtransaksi, setlisttransaksi] = useState();

    const [tokenmidtrans, settokenmidtrans] = useState('');
    const [openDetail, setopenDetail] = useState(false);

    const [detail, setdetail] = useState([]);
    const [totalbayar, settotalbayar] = useState(0);
    const [harga, setharga] = useState(0);
    const [donasi, setdonasi] = useState(0);
    const [discount, setdiscount] = useState(0);
    const [datatransaksi, setdatatransaksi] = useState();
    const [name, setname] = useState("");
    const [detailtransakasi, setdetailtransakasi] = useState([]);

    const handleCloseDetail = () => {
        setopenDetail(false)
    }

    // dispatch action
    const dispatch = useDispatch()
    const CheckToken = () => {
        let rtoken = ls.get('@_rtknid').data
        let tokenn = ls.get('@_tknid').data
        if (tokenn) {
            var jwt = jwt_decode(tokenn);
            var today = new Date().getTime();
            var jwt_exp = jwt.exp * 1000 - (1000 * 20); // sebelum 20 detik

            if (jwt_exp < today) {
                // request
                let formdata = new FormData()
                let options = {
                    headers: {
                        Authorization: `Bearer ${tokenn}`
                    }
                }
                formdata.append("refresh_token", rtoken)
                const refreshToken = Axios.post(`${API}/refresh_token`, formdata, options, null)
                if (refreshToken) {
                    ls.set('@_tknid', { data: refreshToken.data.data.token })
                    ls.set('@_rtknid', { data: refreshToken.data.data.refreshToken })
                }
            } else {
                console.log("tes")

            }
        }
    }

    useEffect(() => {
        let page = 1
        dispatch(getTicketPending(page))


        const transaction = async () => {
            try {
                const testing = await CheckToken()
                let token = ls.get('@_tknid').data
                let rtokenn = ls.get('@_rtknid').data
                let id = localStorage.getItem('id')
                const res = await Axios.get(`${API}/transaction/buyer?transaction_created_by_user_id=${id}&ms_payment_id=4`, { headers: { Authorization: `Bearer ${token}` } })
                if (res) {
                    setlistpending(res.data)
                    if (res.data.data.length > 0) {
                        setlisttransaksi(res.data.data)

                    }

                }
            } catch (error) {
                // let formdata = new FormData()
                // let options = {
                //     headers: {
                //         Authorization: `Bearer ${token}`
                //     }
                // }
                // formdata.append("refresh_token", rtokenn)
                // Axios.post(`${API}/refresh_token`, formdata, options, null)
                //     .then(res => {
                //         ls.set('@_tknid', { data: res.data.data.token })
                //         ls.set('@_rtknid', { data: res.data.data.refreshToken })
                //     }).catch(err => {
                //         // console.log(err, 'di tok')
                //     })
                ls.removeAll()
                localStorage.clear()
                sessionStorage.clear()
                window.location.href = "/"
            }
            // return res; 
        }
        transaction()

        const pendingTransaction = async () => {
            try {
                const testing = await CheckToken()
                let token = ls.get('@_tknid').data
                let rtokenn = ls.get('@_rtknid').data
                let id = localStorage.getItem('id')
                const res = await Axios.get(`${API}/transaction/buyer?transaction_approve_status_name=pending&transaction_created_by_user_id=${id}&ms_payment_id=4`, { headers: { Authorization: `Bearer ${token}` } })
                if (res) {
                    setlistpending(res.data.data)
                }
            } catch (error) {
                // let formdata = new FormData()
                // let options = {
                //     headers: {
                //         Authorization: `Bearer ${token}`
                //     }
                // }
                // formdata.append("refresh_token", rtokenn)
                // Axios.post(`${API}/refresh_token`, formdata, options, null)
                //     .then(res => {
                //         ls.set('@_tknid', { data: res.data.data.token })
                //         ls.set('@_rtknid', { data: res.data.data.refreshToken })
                //     }).catch(err => {
                //         // console.log(err, 'di tok')
                //     })
                ls.removeAll()
                localStorage.clear()
                sessionStorage.clear()
                window.location.href = "/"
            }
            // return res; 
        }
        pendingTransaction()

        const approvedTransaction = async () => {
            try {
                const testing = await CheckToken()
                let token = ls.get('@_tknid').data
                let rtokenn = ls.get('@_rtknid').data
                let id = localStorage.getItem('id')
                const res = await Axios.get(`${API}/transaction/buyer?transaction_approve_status_name=approved&transaction_created_by_user_id=${id}&ms_payment_id=4`, { headers: { Authorization: `Bearer ${token}` } })
                if (res) {
                    setlistapproved(res.data.data)
                }
            } catch (error) {
                // let formdata = new FormData()
                // let options = {
                //     headers: {
                //         Authorization: `Bearer ${token}`
                //     }
                // }
                // formdata.append("refresh_token", rtokenn)
                // Axios.post(`${API}/refresh_token`, formdata, options, null)
                //     .then(res => {
                //         ls.set('@_tknid', { data: res.data.data.token })
                //         ls.set('@_rtknid', { data: res.data.data.refreshToken })
                //     }).catch(err => {
                //         // console.log(err, 'di tok')
                //     })
                ls.removeAll()
                localStorage.clear()
                sessionStorage.clear()
                window.location.href = "/"
            }
        }
        approvedTransaction()

        const rejectedTransaction = async () => {
            try {
                const testing = await CheckToken()
                let token = ls.get('@_tknid').data
                let rtokenn = ls.get('@_rtknid').data
                let id = localStorage.getItem('id')
                const res = await Axios.get(`${API}/transaction/buyer?transaction_approve_status_name=rejected&transaction_created_by_user_id=${id}&ms_payment_id=4`, { headers: { Authorization: `Bearer ${token}` } })
                if (res) {
                    setlistrejected(res.data.data)
                }
            } catch (error) {
                // let formdata = new FormData()
                // let options = {
                //     headers: {
                //         Authorization: `Bearer ${token}`
                //     }
                // }
                // formdata.append("refresh_token", rtokenn)
                // Axios.post(`${API}/refresh_token`, formdata, options, null)
                //     .then(res => {
                //         ls.set('@_tknid', { data: res.data.data.token })
                //         ls.set('@_rtknid', { data: res.data.data.refreshToken })
                //     }).catch(err => {
                //         // console.log(err, 'di tok')
                //     })
                ls.removeAll()
                localStorage.clear()
                sessionStorage.clear()
                window.location.href = "/"
            }
            // return res; 
        }
        rejectedTransaction()

    }, [dispatch]);


    const options = [
        { value: 'semua', label: 'Semua' },
        { value: 'pending', label: 'Pending' },
        { value: 'approved', label: 'Completed' },
        { value: 'rejected', label: 'Canceled' },
    ];

    return (
        <React.Fragment >
            <Dialog fullWidth maxWidth="md" className="dialog" onClose={handleCloseDetail} aria-labelledby="simple-dialog-title" open={openDetail} aria-labelledby="form-dialog-title" className="popup_private" style={{ borderRadius: "10px" }}>
                <Box>
                    <DialogTitle id="form-dialog-title" >
                        <Box fontWeight={700} fontSize={16}>Detail Transaksi</Box>
                    </DialogTitle>
                    {
                        detail.length > 0 && detailtransakasi ?
                            <DialogContent>

                                <Box className={classes.details} py={2} color="#666666" fontWeight={600} style={{ borderBottom: '2px solid #eeee' }}>
                                    <Grid container>
                                        <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
                                            <Box>Nama User</Box>
                                            <Box>Nama Event</Box>
                                            <Box>Jadwal</Box>
                                            <Box>Lokasi</Box>
                                        </Grid>
                                        <Grid item xl={8} lg={8} md={8} sm={8} xs={8}>
                                            <Box>:  {name}</Box>
                                            <Link target="_blank" href={`/detail-event/${detail[0].event.event_slug}`}>
                                                <Box className="detail_name" fontWeight={600} color="#b285dfec" ><span style={{ textDecoration: "none", color: "#666666" }}>: </span> {detailtransakasi.event_name}</Box>
                                            </Link>
                                            <Box>:  {moment(detailtransakasi.seat_detail_datetime).format("DD MMMM YYYY h:mm A")}</Box>
                                            <Box>:  {detailtransakasi.event_location}</Box>
                                        </Grid>
                                    </Grid>
                                </Box>

                                <Box className={classes.details} py={2} color="#666666" fontWeight={600} style={{ borderBottom: '2px solid #eeee' }}>
                                    <Grid container>
                                        <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
                                            <Box>ID Transaksi</Box>
                                            <Box>Tipe Seat</Box>
                                            <Box>Tipe Price</Box>
                                        </Grid>
                                        <Grid item xl={8} lg={8} md={8} sm={8} xs={8}>
                                            <Box>:  {detail[0].transaction_id}</Box>
                                            <Box>:  {detailtransakasi.ms_seat_name}</Box>
                                            <Box>:  {detailtransakasi.ms_price_name}</Box>
                                        </Grid>
                                    </Grid>
                                </Box>


                                <Box pt={3} className={classes.details} pb={2} color="#666666">

                                    <TableContainer>
                                        <Table className={classes.table} aria-label="spanning table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell className={classes.details} style={{ color: "#666666", fontWeight: 'bold' }}>Nama Ticket</TableCell>
                                                    <TableCell className={classes.details} style={{ color: "#666666", fontWeight: 'bold' }} align="center">Jumlah</TableCell>
                                                    <TableCell className={classes.details} style={{ color: "#666666", fontWeight: 'bold' }} align="center">No. Seat</TableCell>
                                                    <TableCell className={classes.details} style={{ color: "#666666", fontWeight: 'bold' }} align="right">Harga</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            {
                                                detailtransakasi.ms_seat_name === "Unreserved" ?
                                                    <TableBody>
                                                        <TableRow >
                                                            <TableCell className={classes.details} style={{ color: "#666666" }}>{detail[0].ticket_name}</TableCell>

                                                            <TableCell className={classes.details} style={{ color: "#666666" }} align="center">{detail.length}</TableCell>
                                                            <TableCell className={classes.details} style={{ color: "#666666" }} align="center">{detail[0].ticket_seat_number === null ? "-" : `${detail[0].ticket_seat_number}`}</TableCell>
                                                            <TableCell className={classes.details} style={{ color: "#666666", fontWeight: 'bold' }} align="right">IDR. {detail[0].ticket_price.toLocaleString()}</TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell className={classes.details} style={{ color: "#666666" }} rowSpan={3} />
                                                            <TableCell className={classes.details} style={{ color: "#666666" }} colSpan={2}>Donasi</TableCell>
                                                            <TableCell className={classes.details} style={{ color: "#666666", fontWeight: 'bold' }} align="right">IDR. {donasi.toLocaleString()}</TableCell>
                                                        </TableRow>
                                                        <TableRow>

                                                            <TableCell className={classes.details} style={{ color: "#666666" }}>Diskon</TableCell>
                                                            <TableCell className={classes.details} style={{ color: "#666666" }} align="right"></TableCell>
                                                            <TableCell className={classes.details} style={{ color: "#666666", fontWeight: 'bold' }} align="right">IDR. {discount.toLocaleString()}</TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell className={classes.details} style={{ color: "#666666" }}>Total</TableCell>
                                                            <TableCell className={classes.details} style={{ color: "#666666" }} align="right"></TableCell>
                                                            <TableCell className={classes.details} style={{ color: "#666666", fontWeight: 'bold', color: "#39364F" }} align="right">IDR. {totalbayar.toLocaleString()}</TableCell>
                                                        </TableRow>

                                                    </TableBody>

                                                    :

                                                    <TableBody>
                                                        {detail.map((data) => (
                                                            <TableRow key={data.ticket_name}>
                                                                <TableCell className={classes.details} style={{ color: "#666666" }}>{data.ticket_name}</TableCell>

                                                                <TableCell className={classes.details} style={{ color: "#666666" }} align="center">1</TableCell>
                                                                <TableCell className={classes.details} style={{ color: "#666666" }} align="center">{data.ticket_seat_number === null ? "-" : `${data.ticket_seat_number}`}</TableCell>
                                                                <TableCell className={classes.details} style={{ color: "#666666", fontWeight: 'bold' }} align="right">IDR. {data.ticket_price.toLocaleString()}</TableCell>
                                                            </TableRow>
                                                        ))}

                                                        <TableRow>
                                                            <TableCell className={classes.details} style={{ color: "#666666" }} rowSpan={3} />
                                                            <TableCell className={classes.details} style={{ color: "#666666" }} colSpan={2}>Donasi</TableCell>
                                                            <TableCell className={classes.details} style={{ color: "#666666", fontWeight: 'bold' }} align="right">IDR. {donasi.toLocaleString()}</TableCell>
                                                        </TableRow>
                                                        <TableRow>

                                                            <TableCell className={classes.details} style={{ color: "#666666" }}>Diskon</TableCell>
                                                            <TableCell className={classes.details} style={{ color: "#666666" }} align="right"></TableCell>
                                                            <TableCell className={classes.details} style={{ color: "#666666", fontWeight: 'bold' }} align="right">IDR. {discount.toLocaleString()}</TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell className={classes.details} style={{ color: "#666666" }}>Total</TableCell>
                                                            <TableCell className={classes.details} style={{ color: "#666666" }} align="right"></TableCell>
                                                            <TableCell className={classes.details} style={{ color: "#666666", fontWeight: 'bold', color: "#39364F" }} align="right">IDR. {totalbayar.toLocaleString()}</TableCell>
                                                        </TableRow>
                                                    </TableBody>

                                            }
                                        </Table>
                                    </TableContainer>
                                </Box>


                            </DialogContent>
                            :

                            null
                    }
                    <Box pb={1} style={{ borderBottom: '2px solid #eeee' }}>
                    </Box>
                    {
                        detailklik === "selesai" || detailklik === "batal" ?
                            <DialogActions>
                                <Box py={2}>
                                    <div className={classes.btnCustom} onClick={handleCloseDetail}>
                                        Tutup
                                </div>

                                </Box>

                            </DialogActions>
                            :
                            <DialogActions>
                                <Box py={2}>
                                    <Midtrans
                                        clientKey={'Mid-client-klZ47HFSE5JZ8TnQ'}
                                        token={tokenmidtrans}
                                    >
                                        <div className={classes.btnCustom}>
                                            Lanjutkan Pembayaran
                                        </div>
                                    </Midtrans>

                                </Box>

                            </DialogActions>
                    }
                </Box>
            </Dialog>



            <Container maxWidth="lg" className={classes.rootcontainer}>
                <Box >
                    <Grid container>
                        <Grid container >
                            <Typography variant='h5'>
                                <Box fontWeight={600} color='#9e69d2'>Transaksi</Box>
                                <Box pb={3} fontWeight={100} fontSize={13} color='#979797'>
                                    {
                                        loading.loading === false && listtransaksi && listtransaksi.length >= 0 ?
                                            <>
                                                Total Transaksi({listtransaksi.length})
                                        </>
                                            :
                                            null
                                    }

                                </Box>
                            </Typography>
                        </Grid>
                        <Box py={2} style={{ width: '200px' }}>

                            <Select options={options}
                                onChange={async (selected) => {
                                    let token = ls.get('@_tknid').data
                                    let rtokenn = ls.get('@_rtknid').data
                                    let id = localStorage.getItem('id')

                                    try {
                                        let url = ""
                                        if (selected.value === "semua") {
                                            url = `${API}/transaction/buyer?transaction_created_by_user_id=${id}&ms_payment_id=4`
                                        }
                                        else {
                                            url = `${API}/transaction/buyer?transaction_approve_status_name=${selected.value}&transaction_created_by_user_id=${id}&ms_payment_id=4`
                                        }
                                        const res = await Axios.get(url, { headers: { Authorization: `Bearer ${token}` } })
                                        if (res) {
                                            setlisttransaksi(res.data.data)
                                            let state = []
                                            if (res.data.data.length > 0) {
                                                for (var i = 0; i < res.data.data.length; i++) {
                                                    if (res.data.data.transaction_data !== null) {

                                                        state.push(JSON.parse(res.data.data[i].transaction_data))
                                                    } else {
                                                        state.push("")
                                                    }
                                                }

                                                setdatatransaksi(state)

                                            } else {
                                                setdatatransaksi([])
                                                setlisttransaksi(res.data.data)
                                            }
                                        }
                                    } catch (error) {
                                        let formdata = new FormData()
                                        let options = {
                                            headers: {
                                                Authorization: `Bearer ${token}`
                                            }
                                        }
                                        formdata.append("refresh_token", rtokenn)
                                        Axios.post(`${API}/refresh_token`, formdata, options, null)
                                            .then(res => {
                                                ls.set('@_tknid', { data: res.data.data.token })
                                                ls.set('@_rtknid', { data: res.data.data.refreshToken })
                                            }).catch(err => {
                                                ls.removeAll()
                                                localStorage.clear()
                                                sessionStorage.clear()
                                                window.location.href = "/"
                                            })
                                    }
                                }}
                            />
                        </Box>


                        {
                            loading.loading === false && listtransaksi && listtransaksi.length > 0 ?
                                <>
                                    <Grid
                                        container
                                        direction="row"
                                        justify="space-between"
                                        alignItems="center"
                                        spacing={3}>
                                        {
                                            listtransaksi.map((data, index) => {
                                                return (
                                                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12} alignItems="center" alignContent="center">
                                                        <Paper elevation={2} variant="outlined"   >
                                                            <Box p={2} style={{ backgroundColor: 'whitesmoke' }} className="transaksi"
                                                                onClick={() => {
                                                                    let token = ls.get('@_tknid').data
                                                                    let formdata = new FormData()
                                                                    let options = {
                                                                        headers: {
                                                                            Authorization: `Bearer ${token}`
                                                                        }
                                                                    }
                                                                    formdata.append("transaction_id", data.transaction_id)
                                                                    // let list = datatransaksi[index]
                                                                    // setdetailtransakasi(list)

                                                                    if (data.transaction_approve_status_name === "pending") {
                                                                        console.log("dbsdhfcb")

                                                                        Axios.put(`${API}/transaction/resume`, formdata, options, null)
                                                                            .then(res => {
                                                                                if (res.data) {
                                                                                    settokenmidtrans(res.data.token)
                                                                                }

                                                                                Axios.get(`${API}/ticket?transaction_id=${data.transaction_id}`, { headers: { Authorization: `Bearer ${token}` } })
                                                                                    .then(res1 => {
                                                                                        Axios.get(`${API}/transaction/${data.transaction_id}`, { headers: { Authorization: `Bearer ${token}` } })
                                                                                            .then(res2 => {
                                                                                                let state = []
                                                                                                if (res2.data.data.transaction_data !== null) {
                                                                                                    state.push(JSON.parse(res2.data.data.transaction_data))
                                                                                                } else {
                                                                                                    state.push("")
                                                                                                }
                                                                                                setdetailtransakasi(state[0])
                                                                                                setname(res2.data.data.transaction_created_by.name)
                                                                                                if (res1.data.data) {
                                                                                                    setdetail(res1.data.data)
                                                                                                    setopenDetail(true)
                                                                                                    setdetailklik("pending")
                                                                                                    settotalbayar(res2.data.data.transaction_total_amount)
                                                                                                    setharga(res2.data.data.transaction_amount)
                                                                                                    setdonasi(res2.data.data.transaction_donation)

                                                                                                    setdiscount(res2.data.data.transaction_discount)
                                                                                                }
                                                                                            })

                                                                                    }).catch(err => {
                                                                                        let token = ls.get('@_tknid').data
                                                                                        let rtokenn = ls.get('@_rtknid').data
                                                                                        let formdata = new FormData()
                                                                                        let options = {
                                                                                            headers: {
                                                                                                Authorization: `Bearer ${token}`
                                                                                            }
                                                                                        }
                                                                                        formdata.append("refresh_token", rtokenn)
                                                                                        Axios.post(`${API}/refresh_token`, formdata, options, null)
                                                                                            .then(res => {
                                                                                                ls.set('@_tknid', { data: res.data.data.token })
                                                                                                ls.set('@_rtknid', { data: res.data.data.refreshToken })
                                                                                            }).catch(err => {
                                                                                            })
                                                                                    })
                                                                            }).catch(err => {

                                                                                let token = ls.get('@_tknid').data
                                                                                let rtokenn = ls.get('@_rtknid').data
                                                                                let formdata = new FormData()
                                                                                let options = {
                                                                                    headers: {
                                                                                        Authorization: `Bearer ${token}`
                                                                                    }
                                                                                }
                                                                                formdata.append("refresh_token", rtokenn)
                                                                                Axios.post(`${API}/refresh_token`, formdata, options, null)
                                                                                    .then(res => {
                                                                                        ls.set('@_tknid', { data: res.data.data.token })
                                                                                        ls.set('@_rtknid', { data: res.data.data.refreshToken })
                                                                                    }).catch(err => {
                                                                                    })
                                                                            })
                                                                    } else {
                                                                        Axios.get(`${API}/ticket?transaction_id=${data.transaction_id}`, { headers: { Authorization: `Bearer ${token}` } })
                                                                            .then(res1 => {
                                                                                Axios.get(`${API}/transaction/${data.transaction_id}`, { headers: { Authorization: `Bearer ${token}` } })
                                                                                    .then(res2 => {
                                                                                        let state = []
                                                                                        if (res2.data.data.transaction_data !== null) {
                                                                                            state.push(JSON.parse(res2.data.data.transaction_data))
                                                                                        } else {
                                                                                            state.push("")
                                                                                        }
                                                                                        setdetailtransakasi(state[0])
                                                                                        setname(res2.data.data.transaction_created_by.name)
                                                                                        if (res1.data.data) {
                                                                                            setdetail(res1.data.data)
                                                                                            setopenDetail(true)
                                                                                            setdetailklik("selesai")
                                                                                            settotalbayar(res2.data.data.transaction_total_amount)
                                                                                            setharga(res2.data.data.transaction_amount)
                                                                                            setdonasi(res2.data.data.transaction_donation)

                                                                                            setdiscount(res2.data.data.transaction_discount)
                                                                                        }
                                                                                    })

                                                                            }).catch(err => {
                                                                                let token = ls.get('@_tknid').data
                                                                                let rtokenn = ls.get('@_rtknid').data
                                                                                let formdata = new FormData()
                                                                                let options = {
                                                                                    headers: {
                                                                                        Authorization: `Bearer ${token}`
                                                                                    }
                                                                                }
                                                                                formdata.append("refresh_token", rtokenn)
                                                                                Axios.post(`${API}/refresh_token`, formdata, options, null)
                                                                                    .then(res => {
                                                                                        ls.set('@_tknid', { data: res.data.data.token })
                                                                                        ls.set('@_rtknid', { data: res.data.data.refreshToken })
                                                                                    }).catch(err => {
                                                                                    })
                                                                            })
                                                                    }
                                                                }}

                                                            >
                                                                <Grid container
                                                                    direction="row"
                                                                    justify="space-between"
                                                                    alignItems="center" >
                                                                    <Grid item xl={2} lg={2} md={2} sm={2} xs={1}  >
                                                                        <Box className={classes.desc}>{moment(data.transaction_created_datetime).format('DD MMM YYYY')}</Box>
                                                                        <Box className={`${classes.desc} deks`}>{moment(data.transaction_created_datetime).format('h:mm:ss a')}</Box>
                                                                    </Grid>
                                                                    <Grid item xl={2} lg={2} md={2} sm={2} xs={2}  >
                                                                        <Box className={classes.desc} fontWeight={600} >ID {data.transaction_id}</Box>
                                                                    </Grid>
                                                                    {
                                                                        datatransaksi === null ?
                                                                            null :
                                                                            datatransaksi === undefined ?
                                                                                null
                                                                                :
                                                                                <Grid item xl={4} lg={4} md={4} sm={4} xs={4}  >
                                                                                    {
                                                                                        datatransaksi[index] === null || datatransaksi[index] === undefined ? null :

                                                                                            <Box className={classes.desc} fontWeight={600} >{datatransaksi[index].event_name}</Box>
                                                                                    }

                                                                                </Grid>
                                                                    }
                                                                    <Grid item xl={2} lg={2} md={2} sm={2} xs={2}  >
                                                                        <Box className={classes.desc} >IDR. {data.transaction_total_amount.toLocaleString()}</Box>
                                                                    </Grid>
                                                                    <Grid item xl={2} lg={2} md={2} sm={2} xs={3}  >
                                                                        <Box >
                                                                            {
                                                                                data.transaction_approve_status_name === "pending" ?
                                                                                    <Button className={classes.desc} variant="outlined" style={{ textTransform: "capitalize", borderRadius: "25px", backgroundColor: '#FDEBDF', color: "#F59C60", border: "none" }}>Pending</Button>
                                                                                    :
                                                                                    data.transaction_approve_status_name === "approved" ?
                                                                                        <Button className={classes.desc} variant="outlined" style={{ textTransform: "capitalize", borderRadius: "25px", backgroundColor: '#CFF8EB', color: "#4BB09C", border: "none" }}>Completed</Button>
                                                                                        :
                                                                                        <Button className={classes.desc} variant="outlined" style={{ textTransform: "capitalize", borderRadius: "25px", backgroundColor: '#FADDE2', color: "#F0788A", border: "none" }}>Canceled</Button>

                                                                            }
                                                                        </Box>
                                                                    </Grid>

                                                                </Grid>
                                                            </Box>
                                                        </Paper>
                                                    </Grid>
                                                )
                                            })
                                        }
                                    </Grid>
                                </>
                                :
                                loading.loading === false && listtransaksi && listtransaksi.length === 0 ?
                                    <>
                                        <Grid container direction='row' justify='center'>
                                            <Box textAlign="center" pt={4}>
                                                <img alt='nothing here' src={Tidaktersedia} width='80%' />
                                            </Box>
                                        </Grid>
                                        <Grid container direction='row' justify='center'>
                                            <Box id="fontStyle" color="#393755" pt={3}>Transaksi belum ada</Box>
                                        </Grid>
                                    </>
                                    :
                                    <>
                                        <Grid container spacing={2} style={{ height: '260px' }} >
                                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} >
                                                <Skeleton variant="rect" height={60} style={{ marginTop: "20px", borderRadius: '4px', width: '100%' }} />

                                            </Grid>
                                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} >
                                                <Skeleton variant="rect" height={60} style={{ marginTop: "20px", borderRadius: '4px', width: '100%' }} />
                                            </Grid>
                                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                                <Skeleton variant="rect" height={60} style={{ marginTop: "20px", borderRadius: '4px', width: '100%' }} />
                                            </Grid>
                                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                                <Skeleton variant="rect" height={60} style={{ marginTop: "20px", borderRadius: '4px', width: '100%' }} />
                                            </Grid>
                                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                                <Skeleton variant="rect" height={60} style={{ marginTop: "20px", borderRadius: '4px', width: '100%' }} />
                                            </Grid>
                                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                                <Skeleton variant="rect" height={60} style={{ marginTop: "20px", borderRadius: '4px', width: '100%' }} />
                                            </Grid>
                                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                                <Skeleton variant="rect" height={60} style={{ marginTop: "20px", borderRadius: '4px', width: '100%' }} />
                                            </Grid>
                                        </Grid>
                                    </>
                        }
                    </Grid>
                </Box>
            </Container>
        </React.Fragment >

    )
}

export default MyTransaksiComponent;