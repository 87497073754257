import React from 'react';
import { Route, Redirect} from 'react-router-dom'
import { useSelector } from 'react-redux';


const RegSuccess = ({component: Component, ...rest}) => {    
    const register = useSelector( state => state.register.response_register_email.data )

    // const isAuthenticated = () => {
    //     if(typeof window == 'undefined') {
    //         return false
    //     }

    //     if(localStorage.getItem('usrreg')) {
    //         return localStorage.getItem('usrreg')
    //     } else {
    //         return false
    //     }
    // }

    return (
            <Route 
                {...rest} 
                render={props => {
                    if(register.id) {
                        return <Component {...props} />
                    }
                        else {
                            return   <Redirect 
                                        to={{
                                            pathname: "*",
                                            state: { from: props.location }
                                        }}
                                    />
                        }
                
                } }
            />
)

}

export default RegSuccess;