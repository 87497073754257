import React from 'react';
import AppBarComponent from '../../components/AppBar';
import FooterDashboard from '../../components/FooterDashboard';
import LoginComponent from '../../components/AuthComponent/LoginComponent';
import Meta from '../../components/Util/MetaDecorator'

const LoginPage = () => {
    // document.title = 'Login Page | Eventories.com '

    return (
        <React.Fragment>
            <Meta title="Login | Eventories.com" description="Silakan login/masuk ke Eventories" />
            <AppBarComponent />
            <LoginComponent />
            <FooterDashboard />
        </React.Fragment>
    )
}

export default LoginPage;