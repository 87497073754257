
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Grid, Box, Button } from '@material-ui/core';
import Popularevent from './PopularEvent/PopularEvent'
import Container from '@material-ui/core/Container';
import './../assets/css/main.css'
import { useSelector } from 'react-redux';


const useStyles = makeStyles((theme) => ({
    rootdiv: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(5)
    },
    divmargin: {
        paddingLeft: '20px',
        paddingRight: '20px'
    },
    buttontab: {
        borderRadius: '30px'
    }
}));


const PopularEvent = () => {

    const classes = useStyles();
    const popular = useSelector(state => state.popular_event.popular_event)
    const data = useSelector(state => state.popular_event)

    // console.log(popular)

    return (
        <div className={classes.rootdiv} >
            <Container maxWidth="lg">
                {/* <div className={classes.divmargin}> */}
                <Grid container >
                    <Typography variant='h5'>
                        <Box fontWeight={600} color='#7D4DB7'>Popular Event</Box>
                    </Typography>
                </Grid>

                <Box pt={2} pb={4}>
                    <Popularevent />
                </Box>

                {/* </div> */}
            </Container>
        </div>

    )
}

export default PopularEvent;