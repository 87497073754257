// GET DATA UPCOMING EVENT
export const GET_EVENT_ALLCAT_REQUEST = 'GET_EVENT_ALLCAT_REQUEST'
export const GET_EVENT_ALLCAT_FAILED = 'GET_EVENT_ALLCAT_FAILED'
export const GET_EVENT_ALLCAT_SUCCESS = 'GET_EVENT_ALLCAT_SUCCESS'
export const GET_EVENT_CAT1_SUCCESS = 'GET_EVENT_CAT1_SUCCESS'
export const GET_EVENT_CAT2_SUCCESS = 'GET_EVENT_CAT2_SUCCESS'
export const GET_EVENT_CAT3_SUCCESS = 'GET_EVENT_CAT3_SUCCESS'
export const GET_EVENT_CAT4_SUCCESS = 'GET_EVENT_CAT4_SUCCESS'
export const GET_EVENT_CAT5_SUCCESS = 'GET_EVENT_CAT5_SUCCESS'

    