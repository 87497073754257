import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import {
    Card,
    Box,
    Grid,
    CardMedia,
    CardContent,
    Link,
    Container,
    // Button,
    Dialog,
    ListItem,
    ListItemAvatar,
    List,
    DialogTitle
} from '@material-ui/core';
import stateempty from './../assets/img/statekosong.png'
import Footer from '../components/FooterDashboard'
import LocationOnIcon from '@material-ui/icons/LocationOn';
import EventIcon from '@material-ui/icons/Event';
import * as SecureLs from 'secure-ls'
import AppBarUser from './../components/AppBarUser'
import Axios from 'axios';
import { API } from '../config'
import Zoom from '@material-ui/core/Zoom';
import Tooltip from '@material-ui/core/Tooltip'
import moment from 'moment'
import { FaShare } from 'react-icons/fa';
import {
    EmailShareButton,
    EmailIcon,
    FacebookIcon,
    FacebookShareButton,
    LineShareButton,
    LineIcon,
    TelegramShareButton,
    TelegramIcon,
    TwitterShareButton,
    TwitterIcon,
    WhatsappShareButton,
    WhatsappIcon
} from "react-share";
import Pagination from '@material-ui/lab/Pagination';
import Skeleton from '@material-ui/lab/Skeleton';
import FavoriteIcon from '@material-ui/icons/Favorite';
import Meta from '../components/Util/MetaDecorator'
import jwt_decode from "jwt-decode";

// import { Helmet } from 'react-helmet'
// import RemoveCircleIcon from '@material-ui/icons/Remove';

const ls = new SecureLs();
const useStyles = makeStyles((theme) => ({
    rootcontainer: {
        paddingTop: theme.spacing(12),
        paddingBottom: theme.spacing(8)
    },
    rootdiv: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(5)
    },
    divmargin: {
        paddingLeft: '20px',
        paddingRight: '20px'
    },
    buttontab: {
        borderRadius: '30px'
    },
    root: {
        maxWidth: 380,
        borderRadius: '10px 10px 0 0',
        position: 'relative'
    },
    media: {
        height: 180,
        width: '100%',
        filter: 'brightness(0.8)'
    }, info: {
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 3
    },
    boxinfo: {

        opacity: 0.9,
        borderRadius: '15px',
    },
    share: {
        position: 'absolute',
        top: 0,
        right: 0,
        zIndex: 3

    },
    boxshare: {
        display: 'flex'
    },
    boxTiket: {
        height: 'fit-content',
        backgroundColor: '#9e69d2',
        borderRadius: '15px'
    },
    boxTop: {
        width: '100%',
        height: 'fit-content'
    },
    boxBottom: {
        width: '100%',
        backgroundColor: '#fff',
        borderRadius: '0 0 15px 15px'
    },
    rootcatevent: {
        [theme.breakpoints.up('xs')]: {
            display: 'none'
        },
        [theme.breakpoints.up('sm')]: {
            display: 'block'
        },
        [theme.breakpoints.up('md')]: {
            display: 'block'
        },
        [theme.breakpoints.up('lg')]: {
            display: 'block'
        },
        [theme.breakpoints.up('xl')]: {
            display: 'block'
        }
    },
    rootcateventmob: {
        [theme.breakpoints.up('xs')]: {
            display: 'block'
        },
        [theme.breakpoints.up('sm')]: {
            display: 'none'
        },
        [theme.breakpoints.up('md')]: {
            display: 'none'
        },
        [theme.breakpoints.up('lg')]: {
            display: 'none'
        },
        [theme.breakpoints.up('xl')]: {
            display: 'none'
        }
    },
}));


function LikedEvents() {
    // document.title = "Event Disukai | Eventories.com "

    const classes = useStyles();
    const [likedEvents, setlikedEvents] = useState([]);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [openDialogShare, setopenDialogShare] = useState(false);
    const [shareLink, setshareLink] = useState("");
    const [page, setpage] = useState(1);
    const [lastPage, setlastPage] = useState(0);
    const [dataevent, setdataevent] = useState();
    // const [loading, setloading] = useState(false);
    // // const [, set] = useState();



    const handleClose = () => {
        setopenDialogShare(false)
    }

    const CheckToken = () => {
        let rtoken = ls.get('@_rtknid').data
        let tokenn = ls.get('@_tknid').data
        if (tokenn) {
            var jwt = jwt_decode(tokenn);
            var today = new Date().getTime();
            var jwt_exp = jwt.exp * 1000 - (1000 * 20); // sebelum 20 detik

            if (jwt_exp < today) {
                // request
                let formdata = new FormData()
                let options = {
                    headers: {
                        Authorization: `Bearer ${tokenn}`
                    }
                }
                formdata.append("refresh_token", rtoken)
                const refreshToken = Axios.post(`${API}/refresh_token`, formdata, options, null)
                if (refreshToken) {
                    ls.set('@_tknid', { data: refreshToken.data.data.token })
                    ls.set('@_rtknid', { data: refreshToken.data.data.refreshToken })
                }
            } else {
                console.log("tes")

            }
        }
    }

    useEffect(() => {
        // let token = ls.get('@_tknid').data

        // console.log("token", token)
        const loadEvent = async () => {
            try {
                const testing = await CheckToken()
                let token = ls.get('@_tknid').data

                const res = await Axios.get(`${API}/event/like?limit=6&page=${page}`, { headers: { Authorization: `Bearer ${token}` } })
                if (res) {
                    // console.log("liked event", res.data)
                    setlikedEvents(res.data.data)
                    setlastPage(res.data.last_page)
                    setdataevent(res.data.data)
                    setDataLoaded(true)
                }
            } catch (error) {
                ls.removeAll()
                localStorage.clear()
                sessionStorage.clear()
                window.location.href = "/"
            }
            // return res; 
        }
        loadEvent()
    }, [])

    const loadEvent = async (val) => {
        try {
            const testing = await CheckToken()
            let token = ls.get('@_tknid').data

            const res = await Axios.get(`${API}/event/like?limit=6&page=${val}`, { headers: { Authorization: `Bearer ${token}` } })
            if (res) {
                // console.log("liked event", res.data)
                setlikedEvents(res.data.data)
                setDataLoaded(true)
            }
        } catch (error) {
            ls.removeAll()
            localStorage.clear()
            sessionStorage.clear()
            window.location.href = "/"
        }
    }

    return (
        <React.Fragment>
            <Meta title="Event Disuka | Eventories.com" description="Klik untuk melihat daftar event yang disukai" />

            <AppBarUser />
            <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={openDialogShare}>
                <DialogTitle id="simple-dialog-title">Share to Friends</DialogTitle>

                <List>
                    <ListItem style={{ color: "black" }}>
                        <ListItemAvatar>
                            <FacebookShareButton url={`${shareLink}`}>
                                <FacebookIcon size={32} round={true} /> &nbsp; Facebook
                            </FacebookShareButton>
                        </ListItemAvatar>
                    </ListItem>
                </List>

                <List>
                    <ListItem style={{ color: "black" }}>
                        <ListItemAvatar>
                            <EmailShareButton url={`${shareLink}`}>
                                <EmailIcon size={32} round={true} />  &nbsp; Email
                            </EmailShareButton>
                        </ListItemAvatar>
                    </ListItem>
                </List>

                <List>
                    <ListItem style={{ color: "black" }}>
                        <ListItemAvatar>
                            <LineShareButton url={`${shareLink}`}>
                                <LineIcon size={32} round={true} />  &nbsp; Line
                            </LineShareButton>
                        </ListItemAvatar>
                    </ListItem>
                </List>

                <List>
                    <ListItem style={{ color: "black" }}>
                        <ListItemAvatar>
                            <TelegramShareButton url={`${shareLink}`}>
                                <TelegramIcon size={32} round={true} />  &nbsp; Telegram
                            </TelegramShareButton>
                        </ListItemAvatar>
                    </ListItem>
                </List>

                <List>
                    <ListItem style={{ color: "black" }}>
                        <ListItemAvatar>
                            <WhatsappShareButton url={`${shareLink}`}>
                                <WhatsappIcon size={32} round={true} />  &nbsp; Whatsapp
                            </WhatsappShareButton>
                        </ListItemAvatar>
                    </ListItem>
                </List>

                <List>
                    <ListItem style={{ color: "black" }}>
                        <ListItemAvatar>
                            <TwitterShareButton url={`${shareLink}`}>
                                <TwitterIcon size={32} round={true} />  &nbsp; Twitter
                            </TwitterShareButton>
                        </ListItemAvatar>
                    </ListItem>
                </List>
            </Dialog>
            <Container maxWidth="lg" className={classes.rootcontainer}>

                <Grid container >
                    <Typography variant='h5'>
                        <Box fontWeight={600} color='#9e69d2'>Event Disukai</Box>
                        <Box fontWeight={100} fontSize={13} color='#979797'>Keterangan</Box>
                    </Typography>
                </Grid>

                <Box pt={4}>
                    <Grid container spacing={2}>
                        {dataLoaded && likedEvents.length > 0 ? (
                            likedEvents.map((data, index) => {
                                return (
                                    <Grid item xl={4} lg={4} md={4} sm={6} xs={12} key={index} >
                                        <Card className={classes.root} title={data.event.event_name}>
                                            <Link href={`/detail-event/${data.event.event_slug}`}>
                                                {/* <CardMedia
                                                    className={classes.media}
                                                    image={data.event.event_image_url}
                                                    title={data.event.event_name}
                                                /> */}
                                                <Box textAlign="center" className="imgevent" style={{
                                                    height: "210px", alignContent: "center", backgroundImage: `url(${data.event.event_image_url})`
                                                    // background: `linear-gradient(rgba(138, 133, 133, 0.367), rgba(171, 170, 170, 0.222)), url(${data.event.event_image_url})`
                                                }}></Box>
                                                <Box textAlign="center" className="imge" style={{ height: "210px", alignContent: "center", zIndex: 1 }}>
                                                    <img width="auto" height="100%" src={data.event.event_image_url} />
                                                </Box>
                                            </Link>

                                            <CardContent className={classes.info}>
                                                <Tooltip TransitionComponent={Zoom} title="Hapus dari Favorit" arrow placement="bottom">
                                                    <Box className={classes.boxinfo}>
                                                        <Box >
                                                            <Link style={{ cursor: 'pointer' }}>
                                                                <FavoriteIcon color="error"
                                                                    onClick={async () => {
                                                                        try {
                                                                            const testing = await CheckToken()

                                                                            let token = ls.get('@_tknid').data
                                                                            // console.log(token)
                                                                            const res = await Axios.delete(`${API}/event/like`, {
                                                                                headers: { Authorization: `Bearer ${token}` },
                                                                                data: { event_id: data.event_id }
                                                                            })
                                                                            if (res) {
                                                                                let events = likedEvents.filter(e => e.event_id !== data.event_id)
                                                                                setlikedEvents(events)
                                                                            }
                                                                        } catch (error) {
                                                                            ls.removeAll()
                                                                            localStorage.clear()
                                                                            sessionStorage.clear()
                                                                            window.location.href = "/"
                                                                        }
                                                                    }}
                                                                />
                                                            </Link>
                                                        </Box>
                                                    </Box>
                                                </Tooltip>
                                            </CardContent>

                                            <CardContent className={classes.share} onClick={() => {
                                                setshareLink(data.event.event_full_url)
                                                setopenDialogShare(true)
                                            }}>
                                                <Tooltip TransitionComponent={Zoom} title="Share" arrow placement="bottom">
                                                    <Box className={classes.boxshare}>
                                                        <Box pr={1} pl={1} >
                                                            <Link style={{ cursor: 'pointer' }}>
                                                                <FaShare style={{ color: 'white' }} />
                                                            </Link>
                                                        </Box>
                                                    </Box>
                                                </Tooltip>
                                            </CardContent>

                                        </Card>
                                        <Box pl={1} pr={1} pt={1} color='#000' style={{ width: '100%', whiteSpace: 'nowrap' }} >
                                            <Link href={`/detail-event/${data.event.event_slug}`}>
                                                <Box fontSize={17} fontWeight={600} overflow="hidden" textOverflow="ellipsis" color="#393755">{data.event.event_name}</Box>
                                            </Link>
                                            {
                                                moment(data.event.event_start_datetime).format('DD MMMM YYYY') !== moment(data.event.event_end_datetime).format('DD MMMM YYYY') ?
                                                    <Box color="#393755" fontSize={14} fontWeight={100} overflow="hidden" textOverflow="ellipsis">
                                                        <EventIcon style={{ fontSize: "14px" }} />{' '}

                                                        {moment(data.event.event_start_datetime).format('DD MMMM YYYY')}
                                                        {' '}- {' '}
                                                        {moment(data.event.event_end_datetime).format('DD MMMM YYYY')}
                                                    </Box> :
                                                    <Box color="#393755" fontSize={14} fontWeight={100} overflow="hidden" textOverflow="ellipsis">
                                                        <EventIcon style={{ fontSize: "14px" }} />{' '}

                                                        {moment(data.event.event_end_datetime).format('DD MMMM YYYY')}
                                                    </Box>
                                            }
                                            {/* <Box color="gray" fontSize={14} className={classes.rootcatevent}>
                                                {(data.event.event_location).substring(0, 47)}
                                                {
                                                    data.event.event_location.length > 50 ? '...' : null
                                                }
                                            </Box> */}
                                            {/* <Box color="#393755" fontSize={14} overflow="hidden" textOverflow="ellipsis">{data.event.event_location}</Box> */}

                                            {
                                                data.event.category_venue_id === 3 ?
                                                    <Box color="#393755" fontSize={14} overflow="hidden" textOverflow="ellipsis">
                                                        <LocationOnIcon style={{ fontSize: "14px" }} /> Online Event
                                                                                </Box> :
                                                    <Box color="#393755" fontSize={14} overflow="hidden" textOverflow="ellipsis">
                                                        <LocationOnIcon style={{ fontSize: "14px" }} /> {data.event.event_location}
                                                    </Box>

                                            }
                                            <Box style={{ borderBottom: 'solid 2px #d1d1d1', paddingTop: '10px' }}></Box>
                                        </Box>
                                    </Grid>
                                )
                            })
                        ) :
                            dataLoaded && likedEvents.length === 0 ?
                                <>

                                    <Grid container direction='row' justify='center'>
                                        <Box textAlign="center" pt={4}>
                                            <img alt='nothing here' src={stateempty} width='80%' />
                                        </Box>
                                    </Grid>

                                    <Grid container direction='row' justify='center'>
                                        <Box id="fontStyle" pt={3}>Anda belum menambahkan event apapun sebagai favorit.</Box>
                                    </Grid>

                                </>
                                :
                                <>
                                    <Grid container spacing={2} style={{ height: '260px' }} >
                                        <Grid item lg={3} xs={12} className={classes.rootcateventmob}>
                                            <Skeleton variant="rect" height={180} style={{ borderRadius: '10px' }} />
                                            <Skeleton variant="text" height={50} style={{ borderRadius: '10px' }} />
                                        </Grid>
                                        <Grid item lg={3} xs={12} className={classes.rootcateventmob}>
                                            <Skeleton variant="rect" height={180} style={{ borderRadius: '10px' }} />
                                            <Skeleton variant="text" height={50} style={{ borderRadius: '10px' }} />
                                        </Grid>
                                        <Grid item lg={4} className={classes.rootcatevent}>
                                            <Skeleton variant="rect" height={180} style={{ borderRadius: '10px' }} />
                                            <Skeleton variant="text" height={50} style={{ borderRadius: '10px' }} />

                                        </Grid>
                                        <Grid item lg={4} className={classes.rootcatevent}>
                                            <Skeleton variant="rect" height={180} style={{ borderRadius: '10px' }} />
                                            <Skeleton variant="text" height={50} style={{ borderRadius: '10px' }} />

                                        </Grid>
                                        <Grid item lg={4} className={classes.rootcatevent}>
                                            <Skeleton variant="rect" height={180} style={{ borderRadius: '10px' }} />
                                            <Skeleton variant="text" height={50} style={{ borderRadius: '10px' }} />

                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={2} style={{ height: '260px' }} >

                                        <Grid item lg={4} className={classes.rootcatevent} >
                                            <Skeleton variant="rect" height={180} style={{ borderRadius: '10px' }} />
                                            <Skeleton variant="text" height={50} style={{ borderRadius: '10px' }} />

                                        </Grid>
                                        <Grid item lg={4} className={classes.rootcatevent}>
                                            <Skeleton variant="rect" height={180} style={{ borderRadius: '10px' }} />
                                            <Skeleton variant="text" height={50} style={{ borderRadius: '10px' }} />

                                        </Grid>
                                        <Grid item lg={4} className={classes.rootcatevent}>
                                            <Skeleton variant="rect" height={180} style={{ borderRadius: '10px' }} />
                                            <Skeleton variant="text" height={50} style={{ borderRadius: '10px' }} />

                                        </Grid>
                                    </Grid>

                                </>

                        }

                        {/* {
                            pag
                        } */}
                        <Grid container direction='row' justify='flex-end' >
                            <Box pt={2} textAlign="right" style={{ display: lastPage > 1 ? 'block' : 'none' }}>
                                <Pagination
                                    count={lastPage}
                                    color="primary"
                                    page={page}
                                    onChange={(e, val) => {
                                        setpage(val)
                                        loadEvent(val)
                                    }}
                                />
                            </Box>
                        </Grid>

                    </Grid>
                </Box>
            </Container>
            <Footer />
        </React.Fragment>
    )


}
export default LikedEvents;
