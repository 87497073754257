import React, { useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Linkto from '@material-ui/core/Link';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Box, Button, Grid } from '@material-ui/core';
import Logo from '../assets/img/logocolor.png'
import { Instagram, Facebook, Twitter } from '@material-ui/icons';
import Axios from 'axios';
import { API } from '../config'
import * as SecureLs from 'secure-ls'
import ClipLoader from "react-spinners/ClipLoader";
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import NotificationsOffIcon from '@material-ui/icons/NotificationsOff';


const ls = new SecureLs();

const useStyles = makeStyles((theme) => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: 'none',
      color: '#fff !important'
    },
    color: '#fff !important',
    textSecondary: 'white'

  },

  link: {
    margin: theme.spacing(1, 1.5),
  },
  rootfooter: {

  },
  footer: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.up('lg')]: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(4)
    },
    textAlign: 'left'
  },
  overlay: {
    // background: '#eeeeee',
    background: '#ECEAF9'

  },
  white: {
    color: 'black'
  },
  copyright: {
    [theme.breakpoints.up('xs')]: {
      textAlign: "center"
    },

    [theme.breakpoints.up('sm')]: {
      textAlign: "center"

    },
    [theme.breakpoints.up('md')]: {
      textAlign: "left"

    },
    [theme.breakpoints.up('lg')]: {
      textAlign: "left"
    },

  },
  sosmed: {
    [theme.breakpoints.up('xs')]: {
      textAlign: "center"
    },

    [theme.breakpoints.up('sm')]: {
      textAlign: "center"

    },
    [theme.breakpoints.up('md')]: {
      textAlign: "right"

    },
    [theme.breakpoints.up('lg')]: {
      textAlign: "right"
    }
  }
}));

function Copyright() {
  return (
    <Typography variant="body2" style={{ color: '#000', fontSize: "12px" }}>
      {'©'} {new Date().getFullYear()}
      {' '}All Rights Reserved, {' '}
      <Linkto style={{ color: '#000' }} href="#">
        Eventories.
      </Linkto>{' '}
    </Typography>
  );
}


export default function FooterDashboard() {
  const classes = useStyles();

  const [subscribe, setsubscribe] = useState(0);
  const [name, setname] = useState();
  const [bio, setbio] = useState();
  const [city, setcity] = useState();
  const [load, setload] = useState(false);

  useEffect(() => {
    let token = ls.get('@_tknid').data

    if (token) {
      const getstatus = async () => {
        try {
          const res = await Axios.get(`${API}/profile`, { headers: { Authorization: `Bearer ${token}` } })
          if (res) {
            setsubscribe(res.data.data.subscribed_status)
            setname(res.data.data.name)
            setbio(res.data.data.bio)
            setcity(res.data.data.ms_city_id)
          }
        } catch (error) {

        }
      }
      getstatus()
    }
  }, [])

  const getsubscribe = async () => {
    if (name, bio, city) {
      setload(true)
      let formdata = new FormData();
      let token = ls.get('@_tknid').data
      formdata.append("name", name);
      formdata.append("bio", bio);
      formdata.append("ms_city_id", city);
      formdata.append("subscribed_status", 1)
      let Headers = {
        headers: {
          "Content-Type": "multipart/form-data",
          "Authorization": `Bearer ${token}`
        },
      };
      try {
        const res = await Axios.put(`${API}/profile`, formdata, Headers)

        if (res) {
          setsubscribe(res.data.data.subscribed_status)
          setload(false)
        }
      } catch (error) {
      }
    }
  }

  const getunsubscribe = async () => {
    if (name, bio, city) {
      setload(true)
      let formdata = new FormData();
      let token = ls.get('@_tknid').data
      formdata.append("name", name);
      formdata.append("bio", bio);
      formdata.append("ms_city_id", city);
      formdata.append("subscribed_status", 0)
      let Headers = {
        headers: {
          "Content-Type": "multipart/form-data",
          "Authorization": `Bearer ${token}`
        },
      };
      try {
        const res = await Axios.put(`${API}/profile`, formdata, Headers)
        if (res) {
          setsubscribe(res.data.data.subscribed_status)
          setload(false)

        }
      } catch (error) {
      }
    }
  }


  return (
    <React.Fragment>
      <div className={classes.rootfooter}>
        <div className={classes.overlay}>

          {/* Footer */}
          <Container maxWidth="lg" component="footer" className={classes.footer}>

            <Box
              // mt={5} pl={6} pr={6} 
              className="footer-container">
              {/* <Copyright /> */}
              <Box>
                <Box pb={3} style={{ borderBottom: "solid 1px gray" }}>
                  <Box pb={1}>
                    <img src={Logo} alt="eventories.com" width="140px" />
                  </Box>

                  <Grid container spacing={4} >
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                      <Box className="footer-tentang">Eventories.com adalah layanan yang disediakan, dikelola dan dimiliki oleh PT. Exel Integrasi Solusindo untuk Anda Event creator/organizer berupa penyediaan platform untuk menunjang Event yang memberikan kemudahan dengan penggunaan teknologi untuk Event creator/organizer guna membuat, memasarkan, menjual, dan/atau mendistribusikan Event secara mandiri, dimana layanan tersebut dapat berubah dari waktu ke waktu berdasarkan kebijakan kami sendiri.</Box>
                    </Grid>

                    <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
                      <Linkto href={`/event?category=`} style={{ textDecoration: "none", color: 'rgb(36, 36, 36)' }}>
                        <Box className="footer-list">Temukan Event</Box>
                      </Linkto>
                      {
                        ls.get('@_tknid').data ?
                          <Linkto href={'/event-disukai'} style={{ cursor: 'pointer', color: 'rgb(36, 36, 36)' }}>
                            <Box className="footer-list">Event Disukai</Box>
                          </Linkto>
                          : <Linkto href={'/login'} style={{ cursor: 'pointer', color: 'rgb(36, 36, 36)' }}>
                            <Box className="footer-list">Event Disukai</Box>
                          </Linkto>
                      }
                      {
                        ls.get('@_tknid').data ?
                          <Linkto href={'/profile-detail'} style={{ cursor: 'pointer', color: 'rgb(36, 36, 36)' }}>
                            <Box className="footer-list">Akun Saya</Box>
                          </Linkto>
                          : <Linkto href={'/login'} style={{ cursor: 'pointer', color: 'rgb(36, 36, 36)' }}>
                            <Box className="footer-list">Akun Saya</Box>
                          </Linkto>
                      }


                      <Linkto href={'/syarat-ketentuan'} style={{ cursor: 'pointer', color: 'rgb(36, 36, 36)' }}>
                        <Box className="footer-list">Syarat & Ketentuan</Box>
                      </Linkto>
                      <Linkto href={'/panduan'} style={{ cursor: 'pointer', color: 'rgb(36, 36, 36)' }}>
                        <Box className="footer-list">FAQ</Box>
                      </Linkto>
                      <Linkto target="_blank" href={'https://www.mediacartz.com/'} style={{ textDecoration: "none", color: 'rgb(36, 36, 36)' }}>
                        <Box className="footer-list">Buat Event</Box>
                      </Linkto>

                    </Grid>
                    {/* #132461 */}

                    <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
                      <Box className="footer-list-pym" fontWeight="bold">Metode Pembayaran</Box>
                      <Box className="footer-list-pym">Kartu Kredit/Debit</Box>
                      <Box className="footer-list-pym">ATM/Bank Transfer</Box>
                      <Box className="footer-list-pym">Gopay /E-wallet lainnya</Box>



                    </Grid>
                  </Grid>
                </Box>
                <Box pt={3}>
                  <Grid container direction="row" justify="space-between" >
                    <Grid item item xl={6} lg={6} md={6} sm={6} xs={12}>
                      <Box className={classes.copyright}>
                        <Copyright />
                      </Box>
                    </Grid>
                    <Grid item item xl={6} lg={6} md={6} sm={6} xs={12}>
                      <Box className={classes.sosmed}>
                        <Facebook fontSize="small" /> {` `}
                        <Twitter fontSize="small" /> {` `}
                        <Instagram fontSize="small" /> {` `}
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Box>
          </Container>
          {/* End footer */}
        </div>
      </div>
    </React.Fragment>
  );
}