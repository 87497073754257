import {
    // GET DATAO UPCOMING EVENT
    GET_CATEGORY_EVENT_REQUEST,
    GET_CATEGORY_EVENT_FAILED,
    GET_CATEGORY_EVENT_SUCCESS,
    GET_KOTA_VENUE_SUCCESS,
    CHANGE_COLOR_SUCCESS

} from './CategoryEventTypes.js'

const initialState = {
    loading: false,
    message: '',
    category_event: [],
    kota_list: [],
    color: false

}

const PopularEventReducers = (state = initialState, action) => {
    switch (action.type) {
        case GET_CATEGORY_EVENT_REQUEST:
            return {
                ...state,
                loading: true
            }
        case GET_CATEGORY_EVENT_FAILED:
            return {
                ...state,
                loading: false,
                message: action.payload
            }
        case GET_CATEGORY_EVENT_SUCCESS:
            return {
                ...state,
                loading: false,
                category_event: action.payload
            }
        case GET_KOTA_VENUE_SUCCESS:
            return {
                ...state,
                loading: false,
                kota_list: action.payload
            }
        case CHANGE_COLOR_SUCCESS:
            return {
                ...state,
                color: action.payload
            }
        default: return state
    }
}

export default PopularEventReducers