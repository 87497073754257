import React, { useEffect, useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import {
    Grid, Box, Link, CardContent, Card, Paper, Dialog,
    ListItem,
    ListItemAvatar,
    List,
    DialogTitle
} from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Tooltip from '@material-ui/core/Tooltip'
import Zoom from '@material-ui/core/Zoom'
import moment from 'moment'
import Skeleton from '@material-ui/lab/Skeleton';
import { FaShare } from 'react-icons/fa';
import Axios from 'axios';
import { API } from './../../../config'
import LinkIcon from '@material-ui/icons/Link';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import SearchEvent from '../../../components/SearchEvent';
import SearchEventMob from '../../../components/searchEventMob'
import SearchedEventDashboard from '../../../components/SearchedEventDashboard';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';

import ReceiptIcon from '@material-ui/icons/Receipt';
import EventNoteIcon from '@material-ui/icons/EventNote';
import Carousel from "react-multi-carousel";

import { uniqBy } from 'lodash'
import stateempty from './../../../assets/img/statekosong.png'
import {
    EmailShareButton,
    EmailIcon,
    FacebookIcon,
    FacebookShareButton,
    LineShareButton,
    LineIcon,
    TelegramShareButton,
    TelegramIcon,
    TwitterShareButton,
    TwitterIcon,
    WhatsappShareButton,
    WhatsappIcon
} from "react-share";
import { klikUnshowSearch } from '../../../redux'
import * as SecureLs from 'secure-ls'
import LocationOnIcon from '@material-ui/icons/LocationOn';
import jwt_decode from 'jwt-decode'
import EventIcon from '@material-ui/icons/Event';
const ls = new SecureLs();

const useStyles = makeStyles((theme) => ({
    BottomBox: {
        paddingTop: theme.spacing(13),
        paddingBottom: theme.spacing(4)
    },
    Bottom: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(2)
    },
    rootcontainerBottom: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(15)
    },

    buttontab: {
        borderRadius: '30px'
    },
    root: {
        // maxWidth: 380,
        borderRadius: '10px 10px 0 0',
        position: 'relative'
    },
    media: {
        height: 180,
        width: '100%',
        filter: 'brightness(0.8)'
    },
    info: {
        zIndex: 3,
        position: 'absolute',
        top: 0,
        left: 0
    },
    boxinfo: {

        opacity: 0.9,
        borderRadius: '15px',
    },
    share: {
        zIndex: 3,
        position: 'absolute',
        top: 0,
        right: 0
    },
    boxshare: {
        display: 'flex'
    },
    icon: {
        [theme.breakpoints.up('xs')]: {
            fontSize: '50px'
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '50px'
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '50px'
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '50px'
        },
        [theme.breakpoints.up('xl')]: {
            fontSize: '50px'
        }
    },
    rootcatevent: {
        [theme.breakpoints.up('xs')]: {
            display: 'none'
        },
        [theme.breakpoints.up('sm')]: {
            display: 'block'
        },
        [theme.breakpoints.up('md')]: {
            display: 'block'
        },
        [theme.breakpoints.up('lg')]: {
            display: 'block'
        },
        [theme.breakpoints.up('xl')]: {
            display: 'block'
        }
    },
    rootcateventmob: {
        [theme.breakpoints.up('xs')]: {
            display: 'block'
        },
        [theme.breakpoints.up('sm')]: {
            display: 'none'
        },
        [theme.breakpoints.up('md')]: {
            display: 'none'
        },
        [theme.breakpoints.up('lg')]: {
            display: 'none'
        },
        [theme.breakpoints.up('xl')]: {
            display: 'none'
        }
    }
}));


const NextEventUser = () => {

    const classes = useStyles();

    const [loading, setLoading] = useState(false)
    const [dataLoaded, setDataLoaded] = useState(false)
    const [dataEvent, setDataEvent] = useState([])
    const [jumlahtransaski, setjumlahtransaski] = useState(0);

    // NEXT EVENT
    const [pastEvent, setPastEvent] = useState([])
    // PAST EVENT
    const [nextEvent, setNextEvent] = useState([])

    const [totalTiket, setTotalTiket] = useState([])

    const [openDialogShare, setopenDialogShare] = useState(false);
    const [shareLink, setshareLink] = useState("");

    const handleClose = () => {
        setopenDialogShare(false)
    }

    const toup = useRef(null)

    const CheckToken = () => {
        let rtoken = ls.get('@_rtknid').data
        let tokenn = ls.get('@_tknid').data
        if (tokenn) {
            var jwt = jwt_decode(tokenn);
            var today = new Date().getTime();
            var jwt_exp = jwt.exp * 1000 - (1000 * 20); // sebelum 20 detik

            if (jwt_exp < today) {
                // request
                let formdata = new FormData()
                let options = {
                    headers: {
                        Authorization: `Bearer ${tokenn}`
                    }
                }
                formdata.append("refresh_token", rtoken)
                const refreshToken = Axios.post(`${API}/refresh_token`, formdata, options, null)
                if (refreshToken) {
                    ls.set('@_tknid', { data: refreshToken.data.data.token })
                    ls.set('@_rtknid', { data: refreshToken.data.data.refreshToken })
                }
            } else {
                console.log("tes")

            }
        }
    }
    useEffect(() => {

        let token = ls.get('@_tknid').data
        let options = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        let rtokenn = ls.get('@_rtknid').data
        let id = localStorage.getItem('id')

        const transaction = async () => {
            try {
                const testing = await CheckToken()

                const res = await Axios.get(`${API}/transaction/buyer?transaction_created_by_user_id=${id}&ms_payment_id=4`, { headers: { Authorization: `Bearer ${token}` } })
                if (res) {
                    // setlistpending(res.data.data)
                    setjumlahtransaski(res.data.data.length)
                }
            } catch (error) {
                // let formdata = new FormData()
                // let options = {
                //     headers: {
                //         Authorization: `Bearer ${token}`
                //     }
                // }
                // formdata.append("refresh_token", rtokenn)
                // Axios.post(`${API}/refresh_token`, formdata, options, null)
                //     .then(res => {
                //         ls.set('@_tknid', { data: res.data.data.token })
                //         ls.set('@_rtknid', { data: res.data.data.refreshToken })
                //     }).catch(err => {
                //         // console.log(err, 'di tok')
                //     })
                ls.removeAll()
                localStorage.clear()
                sessionStorage.clear()
                window.location.href = "/"
            }
            // return res; 
        }
        transaction()


        const loadNextEvent = async () => {
            setLoading(true)
            try {
                const testing = await CheckToken()

                const event = await Axios.get(`${API}/ticket?ticket_status_name=issued&limit=1000`, options, null)
                    .then((res) => {
                        if (res.data.error) {
                            setLoading(false)
                            window.location.href = "/notfound"
                        } else if (res.data.success) {
                            setTotalTiket(res.data.data)
                            let datatiket = res.data.data
                            let state = []

                            if (datatiket && datatiket.length !== 0) {
                                for (var i = 0; i < datatiket.length; i++) {
                                    state.push(datatiket[i].event)
                                }
                            }
                            let data_event = uniqBy(state, function (o) {
                                return o.event_name;
                            });
                            // console.log("data", data_event)
                            let ticket_issued_past = datatiket.filter(e => moment() > moment(e.ticket_datetime))
                            let ticket_issued_next = datatiket.filter(e => moment(e.ticket_datetime) > moment())
                            let state_past = []
                            let list_past = []
                            let state_next = []
                            let list_next = []
                            if (ticket_issued_past) {
                                for (var i = 0; i < ticket_issued_past.length; i++) {
                                    state_past.push(ticket_issued_past[i].event)
                                }
                                list_past = uniqBy(state_past, function (o) {
                                    return o.event_name;
                                });
                                setPastEvent(list_past)
                            }
                            if (ticket_issued_next) {
                                for (var i = 0; i < ticket_issued_next.length; i++) {
                                    state_next.push(ticket_issued_next[i].event)
                                }
                                list_next = uniqBy(state_next, function (o) {
                                    return o.event_name;
                                });
                                setNextEvent(list_next)
                            }

                            if (data_event) {
                                setDataEvent(data_event)
                            }

                            setLoading(false)
                            setDataLoaded(true)
                        }
                    }).catch((err) => {
                        setLoading(false)
                        // if (err.response.status === 401) {
                        //     let tokenn = ls.get('@_tknid').data
                        //     let rtokenn = ls.get('@_rtknid').data
                        //     let formdata = new FormData()
                        //     let options = {
                        //         headers: {
                        //             Authorization: `Bearer ${tokenn}`
                        //         }
                        //     }
                        //     formdata.append("refresh_token", rtokenn)
                        //     Axios.post(`${API}/refresh_token`, formdata, options, null)
                        //         .then(res => {
                        //             ls.set('@_tknid', { data: res.data.data.token })
                        //             ls.set('@_rtknid', { data: res.data.data.refreshToken })
                        //         }).catch(err => {
                        //             ls.removeAll()
                        //             localStorage.clear()
                        //             sessionStorage.clear()
                        //             window.location.href = "/"
                        //         })
                        // }
                    })
                return event;
            } catch (error) {
                ls.removeAll()
                localStorage.clear()
                sessionStorage.clear()
                window.location.href = "/"
            }
        }
        loadNextEvent()
    }, []);

    klikUnshowSearch(false)
    return (
        <React.Fragment >
            <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={openDialogShare}>
                <DialogTitle id="simple-dialog-title">Share to Friends</DialogTitle>

                <List>
                    <ListItem style={{ color: "black" }}>
                        <ListItemAvatar>
                            <FacebookShareButton url={`${shareLink}`}>
                                <FacebookIcon size={32} round={true} /> &nbsp; Facebook
                            </FacebookShareButton>
                        </ListItemAvatar>
                    </ListItem>
                </List>

                <List>
                    <ListItem style={{ color: "black" }}>
                        <ListItemAvatar>
                            <EmailShareButton url={`${shareLink}`}>
                                <EmailIcon size={32} round={true} />  &nbsp; Email
                            </EmailShareButton>
                        </ListItemAvatar>
                    </ListItem>
                </List>

                <List>
                    <ListItem style={{ color: "black" }}>
                        <ListItemAvatar>
                            <LineShareButton url={`${shareLink}`}>
                                <LineIcon size={32} round={true} />  &nbsp; Line
                            </LineShareButton>
                        </ListItemAvatar>
                    </ListItem>
                </List>

                <List>
                    <ListItem style={{ color: "black" }}>
                        <ListItemAvatar>
                            <TelegramShareButton url={`${shareLink}`}>
                                <TelegramIcon size={32} round={true} />  &nbsp; Telegram
                            </TelegramShareButton>
                        </ListItemAvatar>
                    </ListItem>
                </List>

                <List>
                    <ListItem style={{ color: "black" }}>
                        <ListItemAvatar>
                            <WhatsappShareButton url={`${shareLink}`}>
                                <WhatsappIcon size={32} round={true} />  &nbsp; Whatsapp
                            </WhatsappShareButton>
                        </ListItemAvatar>
                    </ListItem>
                </List>

                <List>
                    <ListItem style={{ color: "black" }}>
                        <ListItemAvatar>
                            <TwitterShareButton url={`${shareLink}`}>
                                <TwitterIcon size={32} round={true} />  &nbsp; Twitter
                            </TwitterShareButton>
                        </ListItemAvatar>
                    </ListItem>
                </List>
            </Dialog>

            <Container maxWidth="lg" className={classes.BottomBox}>
                <Box className={classes.rootBox}>
                    <Grid container spacing={4}  >
                        <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
                            <Paper elevation={3} className={classes.paperprojectBox}>
                                <Grid container spacing={2} direction='row' alignItems='center' >
                                    <Grid item xl={3} lg={3} md={3} sm={3} xs={3}>

                                        <Box pl={2} >
                                            <LinkIcon className={classes.icon} style={{ color: '#9e69d2' }} />
                                        </Box>
                                    </Grid>

                                    <Grid item xl={9} lg={9} md={9} sm={9} xs={9}>
                                        <Box p={2}>
                                            <Box>Event Terdaftar </Box>
                                            <Box style={{ color: '#9e69d2', fontSize: '30px' }}>
                                                {dataLoaded && dataEvent.length !== 0 ? dataEvent.length : '0'}
                                            </Box>
                                            <Box style={{ borderBottom: 'solid 2px #9e69d2', width: '15px' }}></Box>
                                        </Box>

                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                        <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
                            <Paper elevation={3} className={classes.paperprojectBox} style={{ cursor: "pointer" }}>
                                <Grid container direction='row' alignItems='center' >
                                    <Grid item xl={3} lg={3} md={3} sm={3} xs={3}>

                                        <Link href={'/myevent'} >
                                            <Box pl={2} >
                                                <ConfirmationNumberIcon className={classes.icon} style={{ color: '#9e69d2' }} />
                                            </Box>
                                        </Link>
                                    </Grid>
                                    <Grid item xl={9} lg={9} md={9} sm={9} xs={9}>

                                        <Link href={'/myevent'} >
                                            <Box p={2} style={{ textDecoration: "none" }}>
                                                <Box style={{ color: 'black' }}>Tiket Saya</Box>
                                                <Box style={{ color: '#9e69d2', fontSize: '30px' }}>
                                                    {dataLoaded && totalTiket.length !== 0 ? totalTiket.length : '0'}
                                                </Box>
                                                <Box style={{ borderBottom: 'solid 2px #9e69d2', width: '15px' }}></Box>
                                            </Box>
                                        </Link>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                        <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
                            <Paper elevation={3} className={classes.paperproject}>
                                <Grid container direction='row' alignItems='center' >
                                    <Grid item xl={3} lg={3} md={3} sm={3} xs={3}>

                                        <Box pl={2} >
                                            <EventAvailableIcon className={classes.icon} style={{ color: '#9e69d2' }} />
                                        </Box>
                                    </Grid>

                                    <Grid item xl={9} lg={9} md={9} sm={9} xs={9}>

                                        <Box p={2} onClick={() => toup.current.scrollIntoView()} style={{ cursor: 'pointer', scrollBehavior: 'smooth' }}>
                                            <Box>Sudah Lewat </Box>
                                            <Box style={{ color: '#9e69d2', fontSize: '30px' }}>
                                                {dataLoaded && pastEvent.length !== 0 ? pastEvent.length : '0'}
                                            </Box>
                                            <Box style={{ borderBottom: 'solid 2px #9e69d2', width: '15px' }}></Box>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                        <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
                            <Paper elevation={3} className={classes.paperproject}>
                                <Grid container direction='row' alignItems='center' >
                                    <Grid item xl={3} lg={3} md={3} sm={3} xs={3}>

                                        <Link style={{ textDecoration: "none" }} href={'/transaksi'} >
                                            <Box pl={2} style={{ textDecoration: "none" }} >
                                                <EventNoteIcon className={classes.icon} style={{ color: '#9e69d2' }} />
                                            </Box>
                                        </Link>
                                    </Grid>
                                    <Grid item xl={9} lg={9} md={9} sm={9} xs={9}>

                                        <Link style={{ textDecoration: "none" }} href={'/transaksi'} >
                                            <Box p={2} style={{ textDecoration: "none" }}>
                                                <Box style={{ color: 'black' }}>Transaksi </Box>
                                                <Box style={{ color: '#9e69d2', fontSize: '30px' }}>
                                                    {jumlahtransaski}
                                                </Box>
                                                <Box style={{ borderBottom: 'solid 2px #9e69d2', width: '15px' }}></Box>
                                            </Box>
                                        </Link>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>
                </Box>
            </Container>

            <div className={classes.rootcatevent}><SearchEvent /></div>
            <div className={classes.rootcateventmob}><SearchEventMob /></div>
            {/* <SearchEvent /> */}

            <SearchedEventDashboard />

            <Container maxWidth="lg" className={classes.Bottom}>
                <Grid container >
                    <Typography variant='h5'>
                        <Box fontWeight={600} pb={3} color='#9e69d2'>Event Akan Datang</Box>
                    </Typography>
                </Grid>
                {loading === false ?
                    <>
                        {dataLoaded && nextEvent.length !== 0 ?
                            <Carousel
                                additionalTransfrom={0}
                                arrows
                                autoPlaySpeed={3000}
                                centerMode={false}
                                className=""
                                containerClass="container-with-dots"
                                dotListClass=""
                                draggable
                                focusOnSelect={false}
                                // infinite
                                itemClass=""
                                keyBoardControl
                                minimumTouchDrag={80}
                                renderButtonGroupOutside={false}
                                renderDotsOutside={false}
                                responsive={{
                                    desktop: {
                                        breakpoint: {
                                            max: 3000,
                                            min: 1024
                                        },
                                        items: 3,
                                        partialVisibilityGutter: 40
                                    },
                                    mobile: {
                                        breakpoint: {
                                            max: 464,
                                            min: 0
                                        },
                                        items: 1,
                                        partialVisibilityGutter: 30
                                    },
                                    tablet: {
                                        breakpoint: {
                                            max: 1024,
                                            min: 464
                                        },
                                        items: 2,
                                        partialVisibilityGutter: 30
                                    }
                                }}
                                showDots={false}
                                sliderClass=""
                                slidesToSlide={1}
                                swipeable
                            >
                                {
                                    nextEvent.map((data, index) => (
                                        <Box key={index} mr={2}>
                                            <Card className={classes.root} title={data.event_name}>
                                                <Link href={`/detail-event/${data.event_slug}`}>
                                                    {/* <CardMedia
                                                        className={classes.media}
                                                        image={data.event_image_url}
                                                        title={data.event_name}
                                                    /> */}
                                                    <Box textAlign="center" className="imgevent" style={{
                                                        height: "190px", alignContent: "center", backgroundImage: `url(${data.event_image_url})`
                                                        // background: `linear-gradient(rgba(138, 133, 133, 0.367), rgba(171, 170, 170, 0.222)), url(${data.event_image_url})`
                                                    }}></Box>
                                                    <Box textAlign="center" className="imge" style={{ height: "190px", alignContent: "center", zIndex: 1 }}>
                                                        <img width="auto" height="100%" src={data.event_image_url} />
                                                    </Box>
                                                </Link>

                                                <CardContent className={classes.share} onClick={() => {
                                                    setshareLink(data.event_full_url)
                                                    setopenDialogShare(true)
                                                }}>
                                                    <Tooltip TransitionComponent={Zoom} title="Share" arrow placement="bottom">
                                                        <Box className={classes.boxshare}>
                                                            <Box pr={1} pl={1} >
                                                                <Link style={{ cursor: 'pointer' }}>
                                                                    <FaShare style={{ color: 'white' }} />
                                                                </Link>
                                                            </Box>
                                                        </Box>
                                                    </Tooltip>
                                                </CardContent>



                                            </Card>
                                            <Box pl={1} pr={1} pt={1} color='#000' style={{ width: '100%', whiteSpace: 'nowrap' }}>
                                                <Box fontSize={17} fontWeight={600} overflow="hidden" color="#393755" textOverflow="ellipsis" color="#393755">{data.event_name}</Box>
                                                {
                                                    moment(data.event_start_datetime).format('DD MMMM YYYY') !== moment(data.event_end_datetime).format('DD MMMM YYYY') ?
                                                        <Box color="#393755" fontSize={14} fontWeight={100} overflow="hidden" textOverflow="ellipsis">
                                                            <EventIcon style={{ fontSize: "14px" }} />{' '}
                                                            {moment(data.event_start_datetime).format('DD MMMM YYYY')}
                                                            {' '}- {' '}
                                                            {moment(data.event_end_datetime).format('DD MMMM YYYY')}
                                                        </Box> :
                                                        <Box color="#393755" fontSize={14} fontWeight={100} overflow="hidden" textOverflow="ellipsis">
                                                            <EventIcon style={{ fontSize: "14px" }} />{' '}
                                                            {moment(data.event_end_datetime).format('DD MMMM YYYY')}
                                                        </Box>
                                                }
                                                {/* </Box> */}
                                                {
                                                    data.category_venue_id === 3 ?
                                                        <Box color="#393755" fontSize={14} overflow="hidden" textOverflow="ellipsis"><LocationOnIcon style={{ fontSize: "14px" }} /> Online Event
                                                                                </Box> :
                                                        <Box color="#393755" fontSize={14} overflow="hidden" textOverflow="ellipsis"><LocationOnIcon style={{ fontSize: "14px" }} />  {data.event_location}
                                                        </Box>

                                                }
                                                <Box style={{ borderBottom: 'solid 2px #d1d1d1', paddingTop: '10px' }}></Box>
                                            </Box>
                                        </Box>

                                    ))}

                            </Carousel>

                            :
                            <Box textAlign="center" pt={2}>
                                <Grid container direction='row' justify='center'>
                                    <Box textAlign="center" pt={4}>
                                        <img alt='nothing here' src={stateempty} width='60%' />
                                        <Box id="fontStyle" pt={3}>Belum ada event</Box>
                                    </Box>
                                </Grid>
                                <Grid container direction='row' justify='center'>
                                </Grid>
                            </Box>

                        }

                    </>
                    :
                    <Grid container spacing={2} style={{ height: '260px' }} >
                        <Grid item lg={4} >
                            <Skeleton variant="rect" height={180} style={{ borderRadius: '10px' }} />
                            <Skeleton variant="text" height={50} style={{ borderRadius: '10px' }} />

                        </Grid>
                        <Grid item lg={4} >
                            <Skeleton variant="rect" height={180} style={{ borderRadius: '10px' }} />
                            <Skeleton variant="text" height={50} style={{ borderRadius: '10px' }} />

                        </Grid>
                        <Grid item lg={4}>
                            <Skeleton variant="rect" height={180} style={{ borderRadius: '10px' }} />
                            <Skeleton variant="text" height={50} style={{ borderRadius: '10px' }} />

                        </Grid>
                    </Grid>
                }
            </Container>

            <Container maxWidth="lg" className={classes.Bottom} >

                <Grid container >
                    <Typography variant='h5' >
                        <Box fontWeight={600} pb={3} color='#9e69d2' ref={toup}>Event Sudah Lewat</Box>
                    </Typography>
                </Grid>
                {loading === false ?
                    <Box pb={8}>
                        {dataLoaded && pastEvent.length !== 0 ?

                            <Carousel
                                additionalTransfrom={0}
                                arrows
                                autoPlaySpeed={3000}
                                centerMode={false}
                                className=""
                                containerClass="container-with-dots"
                                dotListClass=""
                                draggable
                                focusOnSelect={false}
                                // infinite
                                itemClass=""
                                keyBoardControl
                                minimumTouchDrag={80}
                                renderButtonGroupOutside={false}
                                renderDotsOutside={false}
                                responsive={{
                                    desktop: {
                                        breakpoint: {
                                            max: 3000,
                                            min: 1024
                                        },
                                        items: 3,
                                        partialVisibilityGutter: 40
                                    },
                                    mobile: {
                                        breakpoint: {
                                            max: 464,
                                            min: 0
                                        },
                                        items: 1,
                                        partialVisibilityGutter: 30
                                    },
                                    tablet: {
                                        breakpoint: {
                                            max: 1024,
                                            min: 464
                                        },
                                        items: 2,
                                        partialVisibilityGutter: 30
                                    }
                                }}
                                showDots={false}
                                sliderClass=""
                                slidesToSlide={1}
                                swipeable
                            >
                                {
                                    pastEvent.map((data, index) => (
                                        <Box key={index} mr={3}>
                                            <Card className={classes.root} title={data.event_name}>
                                                <Link href={`/detail-event/${data.event_slug}`}>
                                                    {/* <CardMedia
                                                        className={classes.media}
                                                        image={data.event_image_url}
                                                        title={data.event_name}
                                                    /> */}
                                                    <Box textAlign="center" className="imgevent" style={{
                                                        height: "190px", alignContent: "center", backgroundImage: `url(${data.event_image_url})`
                                                        // background: `linear-gradient(rgba(138, 133, 133, 0.367), rgba(171, 170, 170, 0.222)), url(${data.event_image_url})`
                                                    }}></Box>
                                                    <Box textAlign="center" className="imge" style={{ height: "190px", alignContent: "center", zIndex: 1 }}>
                                                        <img width="auto" height="100%" src={data.event_image_url} />
                                                    </Box>
                                                </Link>

                                                <CardContent className={classes.share} onClick={() => {
                                                    setshareLink(data.event_full_url)
                                                    setopenDialogShare(true)
                                                }}>
                                                    <Tooltip TransitionComponent={Zoom} title="Share" arrow placement="bottom">
                                                        <Box className={classes.boxshare}>
                                                            <Box pr={1} pl={1} >
                                                                <Link style={{ cursor: 'pointer' }}>
                                                                    <FaShare style={{ color: 'white' }} />
                                                                </Link>
                                                            </Box>
                                                        </Box>
                                                    </Tooltip>
                                                </CardContent>



                                            </Card>
                                            <Box pl={1} pr={1} pt={1} color='#000' style={{ width: '100%', whiteSpace: 'nowrap' }}>
                                                <Box fontSize={17} fontWeight={600} overflow="hidden" color="#393755" textOverflow="ellipsis">{data.event_name}</Box>
                                                {
                                                    moment(data.event_start_datetime).format('DD MMMM YYYY') !== moment(data.event_end_datetime).format('DD MMMM YYYY') ?
                                                        <Box color="#393755" fontSize={14} fontWeight={100} overflow="hidden" textOverflow="ellipsis">
                                                            <EventIcon style={{ fontSize: "14px" }} />{' '}
                                                            {moment(data.event_start_datetime).format('DD MMMM YYYY')}
                                                            {' '}- {' '}
                                                            {moment(data.event_end_datetime).format('DD MMMM YYYY')}
                                                        </Box> :
                                                        <Box color="#393755" fontSize={14} fontWeight={100} overflow="hidden" textOverflow="ellipsis">
                                                            <EventIcon style={{ fontSize: "14px" }} />{' '}
                                                            {moment(data.event_end_datetime).format('DD MMMM YYYY')}
                                                        </Box>
                                                }
                                                {
                                                    data.category_venue_id === 3 ?
                                                        <Box color="#393755" fontSize={14} overflow="hidden" textOverflow="ellipsis"><LocationOnIcon style={{ fontSize: "14px" }} /> Online Event
                                                                                </Box> :
                                                        <Box color="#393755" fontSize={14} overflow="hidden" textOverflow="ellipsis"><LocationOnIcon style={{ fontSize: "14px" }} /> {data.event_location}
                                                        </Box>

                                                }
                                                <Box style={{ borderBottom: 'solid 2px #d1d1d1', paddingTop: '10px' }}></Box>
                                            </Box>
                                        </Box>

                                    ))}

                            </Carousel>
                            :
                            <Box textAlign="center" pt={2} pb={8}>
                                <Grid container direction='row' justify='center'>
                                    <Box textAlign="center" pt={4}>
                                        <img alt='nothing here' src={stateempty} width='60%' />
                                        <Box id="fontStyle" pt={3}>Belum ada event</Box>
                                    </Box>
                                </Grid>
                                <Grid container direction='row' justify='center'>
                                </Grid>
                            </Box>
                        }

                    </Box>
                    :
                    <Grid container spacing={2} style={{ height: '260px' }} >
                        <Grid item lg={4} >
                            <Skeleton variant="rect" height={180} style={{ borderRadius: '10px' }} />
                            <Skeleton variant="text" height={50} style={{ borderRadius: '10px' }} />

                        </Grid>
                        <Grid item lg={4} >
                            <Skeleton variant="rect" height={180} style={{ borderRadius: '10px' }} />
                            <Skeleton variant="text" height={50} style={{ borderRadius: '10px' }} />

                        </Grid>
                        <Grid item lg={4}>
                            <Skeleton variant="rect" height={180} style={{ borderRadius: '10px' }} />
                            <Skeleton variant="text" height={50} style={{ borderRadius: '10px' }} />

                        </Grid>
                    </Grid>
                }
            </Container>
        </React.Fragment>

    )
}

export default NextEventUser;