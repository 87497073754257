// GET DATA UPCOMING EVENT
export const GET_SEARCHED_ALL_EVENT_REQUEST = 'GET_SEARCHED_ALL_EVENT_REQUEST'
export const GET_SEARCHED_ALL_EVENT_FAILED = 'GET_SEARCHED_ALL_EVENT_FAILED'
export const GET_SEARCHED_ALL_EVENT_SUCCESS = 'GET_SEARCHED_ALL_EVENT_SUCCESS'
export const GET_SEARCHED_ONLINE_EVENT_SUCCESS = 'GET_SEARCHED_ONLINE_EVENT_SUCCESS'
export const GET_SEARCHED_STORE_EVENT_SUCCESS = 'GET_SEARCHED_STORE_EVENT_SUCCESS'
export const GET_SEARCHED_PUBLIC_EVENT_SUCCESS = 'GET_SEARCHED_PUBLIC_EVENT_SUCCESS'
export const GET_FILTER_SEARCHED = 'GET_FILTER_SEARCHED'
export const KLIK_SEARCH_TO_SHOW_CONTENT = 'KLIK_SEARCH_TO_SHOW_CONTENT'
export const GET_SEARCHED_BY_KEYWORD = 'GET_SEARCHED_BY_KEYWORD'
export const KLIK_TO_SHOW_SEARCHED_BY_KEYWORD = 'KLIK_TO_SHOW_SEARCHED_BY_KEYWORD'
export const GET_EVENT_BY_TAG = 'GET_EVENT_BY_TAG'
export const KLIK_TO_SHOW_SEARCHED_BY_TAG = 'KLIK_TO_SHOW_SEARCHED_BY_TAG'

