import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import {
    Card,
    Box,
    Grid,
    Link,
    Container,
    Button,
    Paper,
} from '@material-ui/core';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import EventIcon from '@material-ui/icons/Event';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment'
import swal from 'sweetalert'
import Skeleton from '@material-ui/lab/Skeleton';
import Axios from 'axios'
import { API } from './../../../config'
import stateempty from './../../../assets/img/statekosong.png'
import QRCode from 'qrcode.react'
import { uniqBy, groupBy } from 'lodash'
import Carousel from "react-multi-carousel";

import * as SecureLs from 'secure-ls'

import {
    getticketIssued
} from './../../../redux'

const ls = new SecureLs();
const useStyles = makeStyles((theme) => ({
    rootcontainer: {
        paddingTop: theme.spacing(12),
        paddingBottom: theme.spacing(8)
    },
    rootdiv: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(5)
    },
    divmargin: {
        paddingLeft: '20px',
        paddingRight: '20px'
    },
    buttontab: {
        borderRadius: '30px'
    },
    root: {
        borderRadius: '10px 10px 0 0',
        position: 'relative',
        [theme.breakpoints.up('md')]: {
            maxWidth: 380,

        },
        [theme.breakpoints.up('lg')]: {
            maxWidth: 380,

        },
        [theme.breakpoints.up('xl')]: {
            maxWidth: 380,

        }
    },
    media: {
        height: 180,
        width: '100%',
    },
    info: {
        position: 'absolute',
        top: 0,
        left: 0
    },
    boxinfo: {

        opacity: 0.9,
        borderRadius: '15px',
    },
    share: {
        position: 'absolute',
        top: 0,
        right: 0
    },
    boxshare: {
        display: 'flex'
    },
    boxTiket: {
        height: 'fit-content',
        backgroundColor: '#9e69d2',
        borderRadius: '15px'
    },
    boxTop: {
        width: '100%',
        height: 'fit-content'
    },
    boxBottom: {
        width: '100%',
        backgroundColor: '#fff',
        borderRadius: '0 0 15px 15px'
    },
    rootcatevent: {
        [theme.breakpoints.up('xs')]: {
            display: 'none'
        },
        [theme.breakpoints.up('sm')]: {
            display: 'block'
        },
        [theme.breakpoints.up('md')]: {
            display: 'block'
        },
        [theme.breakpoints.up('lg')]: {
            display: 'block'
        },
        [theme.breakpoints.up('xl')]: {
            display: 'block'
        }
    },
    rootcateventmob: {
        [theme.breakpoints.up('xs')]: {
            display: 'block'
        },
        [theme.breakpoints.up('sm')]: {
            display: 'none'
        },
        [theme.breakpoints.up('md')]: {
            display: 'none'
        },
        [theme.breakpoints.up('lg')]: {
            display: 'none'
        },
        [theme.breakpoints.up('xl')]: {
            display: 'none'
        }
    },
}));


const MyEventComponent = () => {

    const classes = useStyles();

    const ticket_issued = useSelector(state => state.purchaseticket.ticket_issued.data)
    const ticket_issued_data = useSelector(state => state.purchaseticket.ticket_issued)
    const loading = useSelector(state => state.purchaseticket)
    const [listEvent, setlistEvent] = useState([]);


    const [page, setPage] = React.useState(1);

    // const handleChange = (event, value) => {
    //     setPage(value);
    //     // console.log(value, 'ini paginate')
    //     dispatch(getticketIssued(value))
    // };

    const downloadTicket = (input) => {
        // console.log(input.ticket_id, 'input')
        let data = {
            'ticket_id': input.ticket_id,
            "ticket_booking_number": input.ticket_booking_number
        }

        let token = ls.get('@_tknid').data

        Axios(`${API}/ticket/${data.ticket_id}/download`, {
            method: 'GET',
            responseType: 'blob',
            headers: {
                ContentType: 'application/pdf',
                Authorization: `Bearer ${token}`
            }
        })
            .then(response => {
                if (response.data.size > 150) {
                    swal("Yeayy!", "File is being download..!", "success");
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `${data.ticket_booking_number}.pdf`);
                    document.body.appendChild(link);
                    link.click();
                    setTimeout(() => {
                        swal.close()
                    }, 3000);
                } else if (response.data.size < 150) {
                    swal("Try Again!", "File is being generating.. Plase try again in 5 seconds!", "error");
                    setTimeout(() => {
                        swal.close()
                    }, 3000);
                }
            })
            .catch((err) => {
                // console.log(error);
                // if (err.response.status === 401) {
                //     let tokenn = ls.get('@_tknid').data
                //     let rtokenn = ls.get('@_rtknid').data
                //     let formdata = new FormData()
                //     let options = {
                //         headers: {
                //             Authorization: `Bearer ${tokenn}`
                //         }
                //     }
                //     formdata.append("refresh_token", rtokenn)
                //     Axios.post(`${API}/refresh_token`, formdata, options, null)
                //         .then(res => {
                //             ls.set('@_tknid', { data: res.data.data.token })
                //             ls.set('@_rtknid', { data: res.data.data.refreshToken })
                //         }).catch(err => {
                //         })
                // }
            });

    }

    // dispatch action
    const dispatch = useDispatch()

    useEffect(() => {
        let page = 1
        dispatch(getticketIssued(page))
    }, [dispatch]);


    const showData = () => {
        if (ticket_issued_data.total > 0) {
            return (
                <Grid item lg={12} md={12} sm={12} xs={12}>

                    <Box pb={4}>
                        <Grid container spacing={2} >
                            {ticket_issued.map((data, index) => (



                                <Grid item lg={3} md={3} sm={6} xs={12} key={index}>
                                    {/* <Box pb={4}>
                                        
                                        <Box p={3} className="tiket" style={{
                                            backgroundImage: `url(${ticket})`
                                        }}>
                                            <Grid container justify="space-between">
                                                <Grid item lg={6}>
                                                    <Box fontWeight={600} textAlign="center">{data.event.event_name}</Box>
                                                    <Grid container>
                                                        <Grid item><Box pt={1} color="gray"></Box>
                                                            <Box pt={1} fontSize={12} color="gray">Tanggal Mulai Event</Box>
                                                            <Box pt={1} fontSize={13} style={{ fontWeight: "bold", color: "gray" }}>{moment(data.event.event_start_datetime).format('D MMM YYY | h:mm A')}</Box>
                                                        </Grid>
                                                        <Grid fontSize={12} item>
                                                            <Box pt={2} fontSize={12} color="gray">Tanggal Akhir Event</Box>
                                                            <Box pt={1} fontSize={13} style={{ fontWeight: "bold", color: "gray" }}>{moment(data.event.event_end_datetime).format('D MMM YYY | h:mm A')}</Box>
                                                        </Grid>
                                                    </Grid>



                                                </Grid>
                                                <Grid item lg={6}>
                                                    <Box pl={10} pt={4}>
                                                        <QRCode bgColor="#9E69D2" fgColor="white" value={data.ticket_booking_number} size={105} />
                                                    </Box>
                                                </Grid>
                                            </Grid>

                                        </Box> 
                                         </Box> */}



                                    <Paper elevation={3} className={classes.boxTiket} >
                                        <Grid
                                            container
                                            direction="column"
                                            justify="flex-end"
                                            alignItems="center"
                                        >
                                            <Box className={classes.boxTop} p={4} >
                                                <Grid container>

                                                    <Grid item lg={6} md={6} sm={6} xs={6}>
                                                        <Box p={1} textAlign='center' color="#fff">
                                                            <Box fontWeight={600} fontSize={12} textAlign="center">{data.ticket_name}</Box>
                                                            {
                                                                data.ticket_seat_number !== null ? (
                                                                    <><Box pt={1} pb={1} fontWeight={100} fontSize={13} textAlign="center">Seat Number</Box>
                                                                        <Box fontWeight={600} fontSize={20} textAlign="center">{data.ticket_seat_number}</Box> </>
                                                                ) : <><Box pt={2} pb={1} fontWeight={100} fontSize={14} textAlign="center">No Seat Number </Box> </>
                                                            }
                                                        </Box>
                                                    </Grid>
                                                    {/* // size={128} */}
                                                    <Grid item lg={6} md={6} sm={6} xs={6}>
                                                        <QRCode className="qrcode" bgColor="#9E69D2" fgColor="white" value={data.ticket_booking_number} className="qrcode" />
                                                    </Grid>
                                                </Grid>
                                            </Box>

                                            <Box className={classes.boxBottom} p={2}>
                                                <Box fontWeight={600} textAlign="center">{data.event.event_name}</Box>
                                                <Box fontWeight={100} fontSize={13} textAlign="center">
                                                    {moment(data.ticket_datetime).format('D MMM YYYY | h:mm a')}
                                                </Box>

                                                <Box pt={2} pb={1} textAlign="center">
                                                    {
                                                        data.ticket_status_name === 'issued' ?

                                                            <Button
                                                                style={{ borderRadius: '15px' }}
                                                                size='small' variant="contained"
                                                                color='primary'
                                                                onClick={() => {
                                                                    let input = {
                                                                        "ticket_id": data.ticket_id,
                                                                        "ticket_booking_number": data.ticket_booking_number
                                                                    }
                                                                    downloadTicket(input)
                                                                }}
                                                            >Download Tiket</Button>

                                                            :
                                                            <Button
                                                                style={{ borderRadius: '15px' }}
                                                                size='small' variant="contained"
                                                                color='secondary'
                                                            >
                                                                <Box pr={2} pl={2} style={{ textTransform: 'capitalize' }}>Menunggu Pembayaran</Box>
                                                            </Button>
                                                    }

                                                </Box>
                                            </Box>

                                        </Grid>
                                    </Paper>


                                </Grid>
                            ))}

                        </Grid>
                    </Box>

                    {/* <Grid container direction='row' justify='flex-end' >
                        <Box pt={2} style={{ display: ticket_issued_data.last_page > 1 ? 'asdff' : 'none' }}>
                            <Pagination color='primary' count={ticket_issued_data.last_page} page={page} onChange={handleChange} />
                        </Box>
                    </Grid> */}

                </Grid>
            )
        } else {
            return (
                <>

                    <Grid container direction='row' justify='center'>
                        <Box textAlign="center" pt={4}>
                            <img alt='nothing here' src={stateempty} width='80%' />
                        </Box>
                    </Grid>

                    <Grid container direction='row' justify='center'>
                        <Box id="fontStyle" pt={3}>Kamu tidak mempunyai Tiket yang Aktif.</Box>
                    </Grid>

                </>
            )

        }
    }


    let data_event = [];
    let ticket = []
    let list = []
    var count = 0;

    // let data_event
    let data_past = []
    let data_next = []

    // let ticket
    let ticket_past = []
    let ticket_next = []

    // list
    let list_past = []
    let list_next = []

    var count_past = 0
    var count_next = 0


    if (ticket_issued && ticket_issued.length > 0) {
        let state = []
        for (var i = 0; i < ticket_issued.length; i++) {
            state.push(ticket_issued[i].event)
        }
        list = uniqBy(state, function (o) {
            return o.event_name;
        });

        data_event = groupBy(ticket_issued, function (ticket) {
            return ticket.event.event_name;
        });
        for (var i in data_event) {
            if (data_event.hasOwnProperty(i)) {
                count++;
            }
        }

        for (var key in data_event) {
            ticket.push(Object.assign(data_event[key], { name: key }));
        }

        // past next
        let state_past = []
        let state_next = []
        let ticket_issued_past = ticket_issued.filter(e => moment() > moment(e.ticket_datetime))
        let ticket_issued_next = ticket_issued.filter(e => moment(e.ticket_datetime) > moment())

        count_past = ticket_issued_past.length
        count_next = ticket_issued_next.length

        if (ticket_issued_past && ticket_issued_past.length > 0) {
            for (var i = 0; i < ticket_issued_past.length; i++) {
                state_past.push(ticket_issued_past[i].event)
            }
            list_past = uniqBy(state_past, function (o) {
                return o.event_name;
            });
            data_past = groupBy(ticket_issued_past, function (ticket) {
                return ticket.event.event_name;
            });
            for (var key in data_past) {
                ticket_past.push(Object.assign(data_past[key], { name: key }));
            }
        }


        if (ticket_issued_next && ticket_issued_next.length > 0) {
            for (var i = 0; i < ticket_issued_next.length; i++) {
                state_next.push(ticket_issued_next[i].event)
            }
            list_next = uniqBy(state_next, function (o) {
                return o.event_name;
            });
            data_next = groupBy(ticket_issued_next, function (ticket) {
                return ticket.event.event_name;
            });

            for (var key in data_next) {
                ticket_next.push(Object.assign(data_next[key], { name: key }));
            }
        }
    }


    return (
        <React.Fragment >
            <Container maxWidth="lg" className={classes.rootcontainer}>
                <Typography variant='h5'>
                    <Box fontWeight={600} color='#9e69d2'>Tiket Saya</Box>
                </Typography>


                {/* next/active ticket*/}
                <Box pt={6} >
                    <Box fontWeight={600} color='#9e69d2' fontSize={19}>Registered Event</Box>
                    <Box fontWeight={100} fontSize={13} color='#979797'>Tiket aktif ({count_next}) </Box>


                    {
                        list_next.length > 0 && ticket_next.length > 0 && loading.loading === false ?
                            <Box>
                                {
                                    list_next.map((event, i) => (
                                        <>
                                            {/* deks version */}
                                            <Box key={i} pt={2} pb={4} className="deks">
                                                <Grid container spacing={1}>
                                                    <Grid item xl={4} lg={4} md={4} sm={4} >
                                                        <Box>

                                                            <Card className={classes.root} title={event.event_name}>
                                                                <Link href={`/detail-event/${event.event_slug}`}>
                                                                    <Box textAlign="center" className="imgevent" style={{
                                                                        height: "190px", alignContent: "center", backgroundImage: `url(${event.event_image_url})`
                                                                    }}></Box>
                                                                    <Box textAlign="center" className="imge" style={{ height: "190px", alignContent: "center", zIndex: 1 }}>
                                                                        <img width="auto" height="100%" src={event.event_image_url} />
                                                                    </Box>
                                                                </Link>
                                                            </Card>
                                                            <Box pl={1} pr={1} pt={1} color='#000' style={{ width: '100%', whiteSpace: 'nowrap' }} >
                                                                <Link href={`/detail-event/${event.event_slug}`}>
                                                                    <Box fontSize={17} fontWeight={600} overflow="hidden" textOverflow="ellipsis" color="#393755">{event.event_name}</Box>
                                                                </Link>
                                                                {
                                                                    moment(event.event_start_datetime).format('DD MMMM YYYY') !== moment(event.event_end_datetime).format('DD MMMM YYYY') ?
                                                                        <Box color="#393755" fontSize={14} fontWeight={100} overflow="hidden" textOverflow="ellipsis">
                                                                            <EventIcon style={{ fontSize: "14px" }} />{' '}

                                                                            {moment(event.event_start_datetime).format('DD MMMM YYYY')}
                                                                            {' '}- {' '}
                                                                            {moment(event.event_end_datetime).format('DD MMMM YYYY')}
                                                                        </Box> :
                                                                        <Box color="#393755" fontSize={14} fontWeight={100} overflow="hidden" textOverflow="ellipsis">
                                                                            <EventIcon style={{ fontSize: "14px" }} />{' '}

                                                                            {moment(event.event_end_datetime).format('DD MMMM YYYY')}
                                                                        </Box>
                                                                }

                                                                {
                                                                    event.category_venue_id === 3 ?
                                                                        <Box color="#393755" fontSize={14} overflow="hidden" textOverflow="ellipsis"><LocationOnIcon style={{ fontSize: "14px" }} /> Online Event
                                                                                </Box> :
                                                                        <Box color="#393755" fontSize={14} overflow="hidden" textOverflow="ellipsis"><LocationOnIcon style={{ fontSize: "14px" }} /> {event.event_location}
                                                                        </Box>

                                                                }
                                                                <Box style={{ borderBottom: 'solid 2px #d1d1d1', paddingTop: '10px' }}></Box>
                                                            </Box>
                                                            {/* ============ */}
                                                        </Box>
                                                    </Grid>

                                                    <Grid item xl={8} lg={8} md={8} sm={8}>
                                                        <>
                                                            {ticket_next.length > 0 && ticket_next[i].length > 0 ?
                                                                <Carousel
                                                                    additionalTransfrom={0}
                                                                    arrows
                                                                    autoPlaySpeed={3000}
                                                                    centerMode={false}
                                                                    className=""
                                                                    containerClass="container-with-dots"
                                                                    dotListClass=""
                                                                    draggable
                                                                    focusOnSelect={false}
                                                                    // infinite
                                                                    itemClass=""
                                                                    keyBoardControl
                                                                    minimumTouchDrag={80}
                                                                    renderButtonGroupOutside={false}
                                                                    renderDotsOutside={false}
                                                                    responsive={{
                                                                        desktop: {
                                                                            breakpoint: {
                                                                                max: 3000,
                                                                                min: 1024
                                                                            },
                                                                            items: 4,
                                                                            partialVisibilityGutter: 40
                                                                        },
                                                                        mobile: {
                                                                            breakpoint: {
                                                                                max: 464,
                                                                                min: 0
                                                                            },
                                                                            items: 1,
                                                                            partialVisibilityGutter: 30
                                                                        },
                                                                        tablet: {
                                                                            breakpoint: {
                                                                                max: 1024,
                                                                                min: 464
                                                                            },
                                                                            items: 3,
                                                                            partialVisibilityGutter: 30
                                                                        }
                                                                    }}
                                                                    showDots={false}
                                                                    sliderClass=""
                                                                    slidesToSlide={1}
                                                                    swipeable
                                                                >
                                                                    {/* <Grid container spacing={2}> */}
                                                                    {
                                                                        ticket[i].map((data, index) => (
                                                                            // <Grid item lg={3} md={4} sm={6} xs={12} key={index}>
                                                                            <Paper elevation={3} className={classes.boxTiket} style={{ marginRight: '10px', marginBottom: '10px' }}>
                                                                                <Grid
                                                                                    container
                                                                                    direction="column"
                                                                                    justify="flex-end"
                                                                                    alignItems="center"
                                                                                >
                                                                                    <Box p={2} className={classes.boxTop} alignItems="center" textAlign="center">
                                                                                        <QRCode bgColor="#9E69D2" fgColor="white" value={data.ticket_booking_number} className="qrcode" />
                                                                                    </Box>

                                                                                    <Box className={classes.boxBottom} p={2} style={{ width: '100%', whiteSpace: 'nowrap' }}>
                                                                                        <Box title={data.ticket_name} fontWeight={600} fontSize={13} textAlign="center" overflow="hidden" textOverflow="ellipsis">{data.ticket_name}</Box>
                                                                                        {
                                                                                            data.ticket_seat_number === null || data.ticket_seat_number === undefined ?
                                                                                                // null
                                                                                                <Box fontSize={13} textAlign="center">-</Box>

                                                                                                :
                                                                                                <Box fontSize={13} textAlign="center">Seat {data.ticket_seat_number}</Box>
                                                                                        }
                                                                                        <Box fontWeight={100} fontSize={12} textAlign="center">
                                                                                            {moment(data.ticket_datetime).format('DD MMM YYYY | h:mm a')}
                                                                                        </Box>

                                                                                        <Box pt={1} pb={2} textAlign="center">
                                                                                            {
                                                                                                data.ticket_status_name === 'issued' ?

                                                                                                    <Button
                                                                                                        style={{ borderRadius: '15px' }}
                                                                                                        size='small' variant="contained"
                                                                                                        color='primary'
                                                                                                        onClick={() => {
                                                                                                            let input = {
                                                                                                                "ticket_id": data.ticket_id,
                                                                                                                "ticket_booking_number": data.ticket_booking_number
                                                                                                            }
                                                                                                            downloadTicket(input)
                                                                                                        }}
                                                                                                    >Download </Button>

                                                                                                    :
                                                                                                    <Button
                                                                                                        style={{ borderRadius: '15px' }}
                                                                                                        size='small' variant="contained"
                                                                                                        color='secondary'
                                                                                                    >
                                                                                                        <Box pr={2} pl={2} style={{ textTransform: 'capitalize' }}>Menunggu Pembayaran</Box>
                                                                                                    </Button>
                                                                                            }

                                                                                        </Box>
                                                                                    </Box>

                                                                                </Grid>
                                                                            </Paper>

                                                                        ))
                                                                    }

                                                                </Carousel>

                                                                : null
                                                            }
                                                        </>

                                                    </Grid>

                                                </Grid>
                                            </Box>

                                            {/* mob version */}
                                            <Link href={`/detail-event/${event.event_slug}`}>
                                                <Box pt={3} fontWeight={600} fontSize={18} color="#9E69D2" className="mobile">
                                                    {event.event_name} |{' '}
                                                    {
                                                        moment(event.event_start_datetime).format('DD MMMM YYYY') !== moment(event.event_end_datetime).format('DD MMMM YYYY') ?
                                                            <>
                                                                {moment(event.event_start_datetime).format('DD/MM/YYYY')}
                                                                {' '}- {' '}
                                                                {moment(event.event_end_datetime).format('DD/MM/YYYY')}
                                                            </>
                                                            :
                                                            <>

                                                                {moment(event.event_end_datetime).format('DD/MM/YYYY')}
                                                            </>

                                                    }

                                                </Box>

                                            </Link>
                                            {
                                                event.category_venue_id === 3 ?
                                                    <Box className="mobile" pb={3} color="#393755" fontSize={13} overflow="hidden" textOverflow="ellipsis"><LocationOnIcon style={{ fontSize: "14px" }} /> Online Event
                                                                                </Box> :
                                                    <Box className="mobile" pb={3} color="#393755" fontSize={13} overflow="hidden" textOverflow="ellipsis"><LocationOnIcon style={{ fontSize: "14px" }} /> {event.event_location}
                                                    </Box>

                                            }
                                            <Box className="mobile">
                                                {ticket_next.length > 0 && ticket_next[i].length > 0 ?
                                                    <Grid container spacing={2}>
                                                        {
                                                            ticket_next[i].map((data, index) => (
                                                                <Grid item lg={3} md={4} sm={4} xs={12} key={index}>
                                                                    <Paper elevation={3} className={classes.boxTiket} >
                                                                        <Grid
                                                                            container
                                                                            direction="column"
                                                                            justify="flex-end"
                                                                            alignItems="center"
                                                                        >
                                                                            <Box p={2} className={classes.boxTop} alignItems="center" textAlign="center">
                                                                                <QRCode bgColor="#9E69D2" fgColor="white" value={data.ticket_booking_number} className="qrcode" />
                                                                            </Box>

                                                                            <Box className={classes.boxBottom} p={2}>
                                                                                {/* <Box fontWeight={600} textAlign="center">{data.event.event_name}</Box> */}
                                                                                <Box fontWeight={600} fontSize={12} textAlign="center">{data.ticket_name}</Box>
                                                                                {
                                                                                    data.ticket_seat_number === null || data.ticket_seat_number === undefined ?
                                                                                        // null
                                                                                        <Box fontSize={13} textAlign="center">-</Box>

                                                                                        :
                                                                                        <Box fontSize={13} textAlign="center">Seat {data.ticket_seat_number}</Box>
                                                                                }
                                                                                <Box fontWeight={100} fontSize={13} textAlign="center">
                                                                                    {moment(data.ticket_datetime).format('D MMM YYYY | h:mm a')}
                                                                                </Box>

                                                                                <Box pt={2} pb={1} textAlign="center">
                                                                                    {
                                                                                        data.ticket_status_name === 'issued' ?

                                                                                            <Button
                                                                                                style={{ borderRadius: '15px' }}
                                                                                                size='small' variant="contained"
                                                                                                color='primary'
                                                                                                onClick={() => {
                                                                                                    let input = {
                                                                                                        "ticket_id": data.ticket_id,
                                                                                                        "ticket_booking_number": data.ticket_booking_number
                                                                                                    }
                                                                                                    downloadTicket(input)
                                                                                                }}
                                                                                            >Download Tiket</Button>

                                                                                            :
                                                                                            <Button
                                                                                                style={{ borderRadius: '15px' }}
                                                                                                size='small' variant="contained"
                                                                                                color='secondary'
                                                                                            >
                                                                                                <Box pr={2} pl={2} style={{ textTransform: 'capitalize' }}>Menunggu Pembayaran</Box>
                                                                                            </Button>
                                                                                    }

                                                                                </Box>
                                                                            </Box>

                                                                        </Grid>
                                                                    </Paper>


                                                                </Grid>
                                                            ))
                                                        }

                                                    </Grid>

                                                    : null
                                                }
                                            </Box>

                                        </>
                                    ))
                                }
                            </Box>
                            :
                            list_next.length === 0 && loading.loading === false ?
                                <>

                                    <Grid container direction='row' justify='center'>
                                        <Box textAlign="center" pt={4}>
                                            <img alt='nothing here' src={stateempty} width='80%' />
                                        </Box>
                                    </Grid>

                                    <Grid container direction='row' justify='center'>
                                        <Box id="fontStyle" pt={3}>Tidak mempunyai Tiket yang Aktif.</Box>
                                    </Grid>

                                </>
                                :
                                <>
                                    <Grid container spacing={2} style={{ height: '260px' }} >
                                        <Grid item lg={3} md={4} sm={4} xs={12} className={classes.rootcateventmob}>
                                            <Skeleton variant="rect" height={180} style={{ borderRadius: '10px' }} />
                                            <Skeleton variant="text" height={50} style={{ borderRadius: '10px' }} />
                                        </Grid>
                                        <Grid item lg={3} md={4} sm={4} className={classes.rootcatevent}>
                                            <Skeleton variant="rect" height={180} style={{ borderRadius: '10px' }} />
                                            <Skeleton variant="text" height={50} style={{ borderRadius: '10px' }} />

                                        </Grid>
                                        <Grid item lg={3} md={4} sm={4} className={classes.rootcatevent}>
                                            <Skeleton variant="rect" height={180} style={{ borderRadius: '10px' }} />
                                            <Skeleton variant="text" height={50} style={{ borderRadius: '10px' }} />

                                        </Grid>
                                        <Grid item lg={3} md={4} sm={4} className={classes.rootcatevent}>
                                            <Skeleton variant="rect" height={180} style={{ borderRadius: '10px' }} />
                                            <Skeleton variant="text" height={50} style={{ borderRadius: '10px' }} />

                                        </Grid>
                                        <Grid item lg={3} md={4} sm={4} className={classes.rootcatevent}>
                                            <Skeleton variant="rect" height={180} style={{ borderRadius: '10px' }} />
                                            <Skeleton variant="text" height={50} style={{ borderRadius: '10px' }} />

                                        </Grid>
                                    </Grid>

                                    {/* <Grid container spacing={2} style={{ height: '260px' }} >
                                        <Grid item lg={3} md={4} sm={4} className={classes.rootcatevent} >
                                            <Skeleton variant="rect" height={180} style={{ borderRadius: '10px' }} />
                                            <Skeleton variant="text" height={50} style={{ borderRadius: '10px' }} />

                                        </Grid>
                                        <Grid item lg={3} md={4} sm={4} className={classes.rootcatevent} >
                                            <Skeleton variant="rect" height={180} style={{ borderRadius: '10px' }} />
                                            <Skeleton variant="text" height={50} style={{ borderRadius: '10px' }} />

                                        </Grid>
                                        <Grid item lg={3} md={4} sm={4} className={classes.rootcatevent}>
                                            <Skeleton variant="rect" height={180} style={{ borderRadius: '10px' }} />
                                            <Skeleton variant="text" height={50} style={{ borderRadius: '10px' }} />

                                        </Grid>
                                        <Grid item lg={3} md={4} sm={4} className={classes.rootcatevent}>
                                            <Skeleton variant="rect" height={180} style={{ borderRadius: '10px' }} />
                                            <Skeleton variant="text" height={50} style={{ borderRadius: '10px' }} />

                                        </Grid>
                                    </Grid> */}

                                </>
                    }
                </Box>


                {/* past ticket */}
                <Box pt={8}>
                    <Box fontWeight={600} fontSize={20} color='#9e69d2'>Past Event</Box>
                    <Box fontWeight={100} fontSize={13} color='#979797'>Tiket event sudah lewat ({count_past}) </Box>


                    {
                        list_past.length > 0 && loading.loading === false ?
                            <Box>
                                {
                                    list_past.map((event, i) => (
                                        <>
                                            {/* deks version */}
                                            <Box key={i} pt={2} pb={4} className="deks">
                                                <Grid container spacing={1}>
                                                    <Grid item xl={4} lg={4} md={4} sm={4} >
                                                        <Box>
                                                            {/* ========= */}

                                                            <Card className={classes.root} title={event.event_name}>
                                                                <Link href={`/detail-event/${event.event_slug}`}>
                                                                    <Box textAlign="center" className="imgevent" style={{
                                                                        height: "190px", alignContent: "center", backgroundImage: `url(${event.event_image_url})`
                                                                    }}></Box>
                                                                    <Box textAlign="center" className="imge" style={{ height: "190px", alignContent: "center", zIndex: 1 }}>
                                                                        <img width="auto" height="100%" src={event.event_image_url} />
                                                                    </Box>
                                                                </Link>
                                                            </Card>
                                                            <Box pl={1} pr={1} pt={1} color='#000' style={{ width: '100%', whiteSpace: 'nowrap' }} >
                                                                <Link href={`/detail-event/${event.event_slug}`}>
                                                                    <Box fontSize={17} fontWeight={600} overflow="hidden" textOverflow="ellipsis" color="#393755">{event.event_name}</Box>
                                                                </Link>

                                                                {/* {
                                                                    ticket_past[i].ticket_seat_number === null || ticket_past[i].ticket_seat_number === undefined ?
                                                                        null :
                                                                        <Box>{ticket_past[i].ticket_seat_number}</Box>
                                                                } */}
                                                                {
                                                                    moment(event.event_start_datetime).format('DD MMMM YYYY') !== moment(event.event_end_datetime).format('DD MMMM YYYY') ?
                                                                        <Box color="#393755" fontSize={14} fontWeight={100} overflow="hidden" textOverflow="ellipsis">
                                                                            <EventIcon style={{ fontSize: "14px" }} />{' '}

                                                                            {moment(event.event_start_datetime).format('DD MMMM YYYY')}
                                                                            {' '}- {' '}
                                                                            {moment(event.event_end_datetime).format('DD MMMM YYYY')}
                                                                        </Box> :
                                                                        <Box color="#393755" fontSize={14} fontWeight={100} overflow="hidden" textOverflow="ellipsis">
                                                                            <EventIcon style={{ fontSize: "14px" }} />{' '}

                                                                            {moment(event.event_end_datetime).format('DD MMMM YYYY')}
                                                                        </Box>
                                                                }

                                                                {
                                                                    event.category_venue_id === 3 ?
                                                                        <Box color="#393755" fontSize={14} overflow="hidden" textOverflow="ellipsis"><LocationOnIcon style={{ fontSize: "14px" }} /> Online Event
                                                                                </Box> :
                                                                        <Box color="#393755" fontSize={14} overflow="hidden" textOverflow="ellipsis"><LocationOnIcon style={{ fontSize: "14px" }} /> {event.event_location}
                                                                        </Box>

                                                                }
                                                                <Box style={{ borderBottom: 'solid 2px #d1d1d1', paddingTop: '10px' }}></Box>
                                                            </Box>
                                                            {/* ============ */}
                                                        </Box>
                                                    </Grid>

                                                    <Grid item xl={8} lg={8} md={8} sm={8}>
                                                        <>
                                                            {ticket_past.length > 0 && ticket_past[i].length > 0 ?
                                                                <Carousel
                                                                    additionalTransfrom={0}
                                                                    arrows
                                                                    autoPlaySpeed={3000}
                                                                    centerMode={false}
                                                                    className=""
                                                                    containerClass="container-with-dots"
                                                                    dotListClass=""
                                                                    draggable
                                                                    focusOnSelect={false}
                                                                    // infinite
                                                                    itemClass=""
                                                                    keyBoardControl
                                                                    minimumTouchDrag={80}
                                                                    renderButtonGroupOutside={false}
                                                                    renderDotsOutside={false}
                                                                    responsive={{
                                                                        desktop: {
                                                                            breakpoint: {
                                                                                max: 3000,
                                                                                min: 1024
                                                                            },
                                                                            items: 4,
                                                                            partialVisibilityGutter: 40
                                                                        },
                                                                        mobile: {
                                                                            breakpoint: {
                                                                                max: 464,
                                                                                min: 0
                                                                            },
                                                                            items: 1,
                                                                            partialVisibilityGutter: 30
                                                                        },
                                                                        tablet: {
                                                                            breakpoint: {
                                                                                max: 1024,
                                                                                min: 464
                                                                            },
                                                                            items: 3,
                                                                            partialVisibilityGutter: 30
                                                                        }
                                                                    }}
                                                                    showDots={false}
                                                                    sliderClass=""
                                                                    slidesToSlide={1}
                                                                    swipeable
                                                                >
                                                                    {/* <Grid container spacing={2}> */}
                                                                    {
                                                                        ticket_past[i].map((data, index) => (
                                                                            // <Grid item lg={3} md={4} sm={6} xs={12} key={index}>
                                                                            <Paper elevation={3} className={classes.boxTiket} style={{ marginRight: '10px', marginBottom: '10px' }}>
                                                                                <Grid
                                                                                    container
                                                                                    direction="column"
                                                                                    justify="flex-end"
                                                                                    alignItems="center"
                                                                                >
                                                                                    <Box p={2} className={classes.boxTop} alignItems="center" textAlign="center">
                                                                                        <QRCode bgColor="#9E69D2" fgColor="white" value={data.ticket_booking_number} className="qrcode" />
                                                                                    </Box>

                                                                                    <Box className={classes.boxBottom} p={2} style={{ width: '100%', whiteSpace: 'nowrap' }} >

                                                                                        <Box title={data.ticket_name} fontWeight={600} fontSize={13} textAlign="center" overflow="hidden" textOverflow="ellipsis">{data.ticket_name}</Box>
                                                                                        {
                                                                                            data.ticket_seat_number === null || data.ticket_seat_number === undefined ?
                                                                                                // null
                                                                                                <Box fontSize={13} textAlign="center">-</Box>

                                                                                                :
                                                                                                <Box fontSize={13} textAlign="center">Seat {data.ticket_seat_number}</Box>
                                                                                        }
                                                                                        <Box fontWeight={100} fontSize={12} textAlign="center">
                                                                                            {moment(data.ticket_datetime).format('DD MMM YYYY | h:mm a')}
                                                                                        </Box>

                                                                                        <Box pt={1} pb={2} textAlign="center">
                                                                                            {
                                                                                                data.ticket_status_name === 'issued' ?

                                                                                                    <Button
                                                                                                        style={{ borderRadius: '15px' }}
                                                                                                        size='small' variant="contained"
                                                                                                        color='primary'
                                                                                                        onClick={() => {
                                                                                                            let input = {
                                                                                                                "ticket_id": data.ticket_id,
                                                                                                                "ticket_booking_number": data.ticket_booking_number
                                                                                                            }
                                                                                                            downloadTicket(input)
                                                                                                        }}
                                                                                                    >Download </Button>

                                                                                                    :
                                                                                                    <Button
                                                                                                        style={{ borderRadius: '15px' }}
                                                                                                        size='small' variant="contained"
                                                                                                        color='secondary'
                                                                                                    >
                                                                                                        <Box pr={2} pl={2} style={{ textTransform: 'capitalize' }}>Menunggu Pembayaran</Box>
                                                                                                    </Button>
                                                                                            }

                                                                                        </Box>
                                                                                    </Box>

                                                                                </Grid>
                                                                            </Paper>

                                                                        ))
                                                                    }

                                                                </Carousel>

                                                                : null
                                                            }
                                                        </>

                                                    </Grid>

                                                </Grid>
                                            </Box>
                                            {/* mob version */}
                                            <Link target="_blank" href={`/detail-event/${event.event_slug}`}>
                                                <Box pt={4} fontWeight={600} color="#9E69D2" fontSize={18} className="mobile">
                                                    {event.event_name} |{' '}
                                                    {
                                                        moment(event.event_start_datetime).format('DD MMMM YYYY') !== moment(event.event_end_datetime).format('DD MMMM YYYY') ?
                                                            <>
                                                                {moment(event.event_start_datetime).format('DD/MM/YYYY')}
                                                                {' '}- {' '}
                                                                {moment(event.event_end_datetime).format('DD/MM/YYYY')}
                                                            </>
                                                            :
                                                            <>

                                                                {moment(event.event_end_datetime).format('DD/MM/YYYY')}
                                                            </>

                                                    }

                                                </Box>

                                            </Link>
                                            {
                                                event.category_venue_id === 3 ?
                                                    <Box className="mobile" pb={3} color="#393755" fontSize={13} overflow="hidden" textOverflow="ellipsis"><LocationOnIcon style={{ fontSize: "14px" }} /> Online Event
                                                                                </Box> :
                                                    <Box className="mobile" pb={3} color="#393755" fontSize={13} overflow="hidden" textOverflow="ellipsis"><LocationOnIcon style={{ fontSize: "14px" }} /> {event.event_location}
                                                    </Box>

                                            }
                                            <Box className="mobile">
                                                {ticket_past.length > 0 && ticket_past[i].length > 0 ?
                                                    <Grid container spacing={2}>
                                                        {
                                                            ticket_past[i].map((data, index) => (
                                                                <Grid item lg={3} md={4} sm={4} xs={12} key={index}>
                                                                    <Paper elevation={3} className={classes.boxTiket} >
                                                                        <Grid
                                                                            container
                                                                            direction="column"
                                                                            justify="flex-end"
                                                                            alignItems="center"
                                                                        >
                                                                            <Box p={2} className={classes.boxTop} alignItems="center" textAlign="center">
                                                                                <QRCode bgColor="#9E69D2" fgColor="white" value={data.ticket_booking_number} className="qrcode" />
                                                                            </Box>

                                                                            <Box className={classes.boxBottom} p={2}>
                                                                                {/* <Box fontWeight={600} textAlign="center">{data.event.event_name}</Box> */}
                                                                                <Box fontWeight={600} fontSize={12} textAlign="center">{data.ticket_name}</Box>
                                                                                {
                                                                                    data.ticket_seat_number === null || data.ticket_seat_number === undefined ?
                                                                                        // null
                                                                                        <Box fontSize={13} textAlign="center">-</Box>

                                                                                        :
                                                                                        <Box fontSize={13} textAlign="center">Seat {data.ticket_seat_number}</Box>
                                                                                }
                                                                                <Box fontWeight={100} fontSize={13} textAlign="center">
                                                                                    {moment(data.ticket_datetime).format('D MMM YYYY | h:mm a')}
                                                                                </Box>

                                                                                <Box pt={2} pb={1} textAlign="center">
                                                                                    {
                                                                                        data.ticket_status_name === 'issued' ?

                                                                                            <Button
                                                                                                style={{ borderRadius: '15px' }}
                                                                                                size='small' variant="contained"
                                                                                                color='primary'
                                                                                                onClick={() => {
                                                                                                    let input = {
                                                                                                        "ticket_id": data.ticket_id,
                                                                                                        "ticket_booking_number": data.ticket_booking_number
                                                                                                    }
                                                                                                    downloadTicket(input)
                                                                                                }}
                                                                                            >Download Tiket</Button>

                                                                                            :
                                                                                            <Button
                                                                                                style={{ borderRadius: '15px' }}
                                                                                                size='small' variant="contained"
                                                                                                color='secondary'
                                                                                            >
                                                                                                <Box pr={2} pl={2} style={{ textTransform: 'capitalize' }}>Menunggu Pembayaran</Box>
                                                                                            </Button>
                                                                                    }

                                                                                </Box>
                                                                            </Box>

                                                                        </Grid>
                                                                    </Paper>


                                                                </Grid>
                                                            ))
                                                        }

                                                    </Grid>

                                                    : null
                                                }
                                            </Box>

                                        </>
                                    ))
                                }
                            </Box>
                            :
                            list_past.length === 0 && loading.loading === false ?
                                <>

                                    <Grid container direction='row' justify='center'>
                                        <Box textAlign="center" pt={4}>
                                            <img alt='nothing here' src={stateempty} width='80%' />
                                        </Box>
                                    </Grid>

                                    <Grid container direction='row' justify='center'>
                                        <Box id="fontStyle" pt={3}>Tidak mempunyai Tiket Event yang sudah berakhir.</Box>
                                    </Grid>

                                </>
                                :
                                <>
                                    <Grid container spacing={2} style={{ height: '260px' }} >
                                        <Grid item lg={3} md={4} sm={4} xs={12} className={classes.rootcateventmob}>
                                            <Skeleton variant="rect" height={180} style={{ borderRadius: '10px' }} />
                                            <Skeleton variant="text" height={50} style={{ borderRadius: '10px' }} />
                                        </Grid>
                                        <Grid item lg={3} md={4} sm={4} className={classes.rootcatevent}>
                                            <Skeleton variant="rect" height={180} style={{ borderRadius: '10px' }} />
                                            <Skeleton variant="text" height={50} style={{ borderRadius: '10px' }} />

                                        </Grid>
                                        <Grid item lg={3} md={4} sm={4} className={classes.rootcatevent}>
                                            <Skeleton variant="rect" height={180} style={{ borderRadius: '10px' }} />
                                            <Skeleton variant="text" height={50} style={{ borderRadius: '10px' }} />

                                        </Grid>
                                        <Grid item lg={3} md={4} sm={4} className={classes.rootcatevent}>
                                            <Skeleton variant="rect" height={180} style={{ borderRadius: '10px' }} />
                                            <Skeleton variant="text" height={50} style={{ borderRadius: '10px' }} />

                                        </Grid>
                                        <Grid item lg={3} md={4} sm={4} className={classes.rootcatevent}>
                                            <Skeleton variant="rect" height={180} style={{ borderRadius: '10px' }} />
                                            <Skeleton variant="text" height={50} style={{ borderRadius: '10px' }} />

                                        </Grid>
                                    </Grid>
                                </>
                    }
                </Box>




            </Container>
        </React.Fragment >

    )
}

export default MyEventComponent;