import React from 'react';
import AppBarComponent from '../../components/AppBar';
import FooterDashboard from '../../components/FooterDashboard';
// import SingleEventComponent from '../../components/SingleEvent/SingleEventPrivate'
// import SingleEventMob from '../../components/SingleEvent/singleEventPrivateMob'
import SinglePrivate from '../../components/SingleEvent/SinglePrivate'
import SimpleReactLightbox from "simple-react-lightbox";
import { makeStyles } from '@material-ui/core/styles';



const useStyles = makeStyles((theme) => ({
    singleevent: {
        [theme.breakpoints.up('xs')]: {
            display: 'none'
        },
        [theme.breakpoints.up('sm')]: {
            display: 'block'
        },
        [theme.breakpoints.up('md')]: {
            display: 'block'
        },
        [theme.breakpoints.up('lg')]: {
            display: 'block'
        },
        [theme.breakpoints.up('xl')]: {
            display: 'block'
        }
    },
    singleeventmob: {
        [theme.breakpoints.up('xs')]: {
            display: 'block'
        },
        [theme.breakpoints.up('sm')]: {
            display: 'none'
        },
        [theme.breakpoints.up('md')]: {
            display: 'none'
        },
        [theme.breakpoints.up('lg')]: {
            display: 'none'
        },
        [theme.breakpoints.up('xl')]: {
            display: 'none'
        }
    }
}));

const SingleEvent = () => {
    const classes = useStyles();
    return (
        <React.Fragment>
            <AppBarComponent />
            <SimpleReactLightbox>
                {/* <div className={classes.singleevent} >
                    <SingleEventComponent />
                </div>
                <div className={classes.singleeventmob}>
                    <SingleEventMob />
                </div> */}
                <SinglePrivate />
            </SimpleReactLightbox>
            <FooterDashboard />
        </React.Fragment>
    )
}

export default SingleEvent;