import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(theme => ({
    rootdivtitle: {
        paddingTop: theme.spacing(8),
        [theme.breakpoints.up('xs')]: {
            display: 'block'
        },
        [theme.breakpoints.up('sm')]: {
            display: 'block'
        },
        [theme.breakpoints.up('md')]: {
            display: 'block'
        },
        [theme.breakpoints.up('lg')]: {
            display: 'block'
        },
        [theme.breakpoints.up('xl')]: {
            display: 'block'
        }
    },
    // rootdivtitleMobile: {
    //     paddingTop: theme.spacing(8), 
    //     [theme.breakpoints.up('xs')] : {
    //         display:'block',
    //         paddingLeft: '0px !important',
    //         paddingRight: '0px !important',
    //     },
    //     [theme.breakpoints.up('sm')] : {
    //         display:'none'
    //     },
    //     [theme.breakpoints.up('md')] : {
    //         display:'none'
    //     },
    //     [theme.breakpoints.up('lg')] : {
    //         display:'none'
    //     },
    //     [theme.breakpoints.up('xl')] : {
    //         display:'none'
    //     }       
    // },
    imgstyle: {
        width: '100%',
        [theme.breakpoints.up('xs')]: {
            height: '185px',
            borderRadius: '0 0 10px 10px',
        },
        [theme.breakpoints.up('sm')]: {
            height: 'auto',
            borderRadius: '0 0 10px 10px',
        },
        [theme.breakpoints.up('md')]: {
            height: '480px',
            borderRadius: '0 0 10px 10px',
        },
        [theme.breakpoints.up('lg')]: {
            height: '480px',
            borderRadius: '0 0 10px 10px',
        },
        [theme.breakpoints.up('xl')]: {
            height: '480px',
            borderRadius: '0 0 10px 10px',
        }
    },
    boxCaption: {
        position: 'absolute',
        left: 0,
        bottom: 0,
        width: '100%',
        height: 'fit-content',

        [theme.breakpoints.up('xs')]: {
            // borderRadius: '30px 30px 0 0',
            // height: '30px',
            // paddingTop: '20px',
            // backgroundColor: 'white',
            paddingTop: '10px',
            paddingBottom: '15px',
        },
        [theme.breakpoints.up('sm')]: {
            paddingTop: '10px',
            paddingBottom: '15px',
        },
        [theme.breakpoints.up('md')]: {
            paddingTop: '20px',
            paddingBottom: '20px',
        },
        [theme.breakpoints.up('lg')]: {
            paddingTop: '30px',
            paddingBottom: '30px',
        },
        [theme.breakpoints.up('xl')]: {
            paddingTop: '30px',
            paddingBottom: '30px',
        }

    },
    nameEvent: {
        color: '#707070',
        textTransform: 'capitalize',
        [theme.breakpoints.up('xs')]: {
            fontSize: '10px',
            fontWeight: 600,
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '20px',
            fontWeight: 600,
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '20px',
            fontWeight: 600,
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '25px',
            fontWeight: 700,
        },
        [theme.breakpoints.up('xl')]: {
            fontSize: '25px',
            fontWeight: 700,
        }
    },
    locationEvent: {
        color: '#707070',
        [theme.breakpoints.up('xs')]: {
            fontSize: '5px',
            fontWeight: 100,
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '10px',
            fontWeight: 100,
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '12px',
            fontWeight: 100,
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '12px',
            fontWeight: 100,
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '15px',
            fontWeight: 100,
        }
    },
    eventDate: {
        color: '#707070',
        [theme.breakpoints.up('xs')]: {
            fontSize: '8px',
            fontWeight: 600,
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '10px',
            fontWeight: 400,
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '17px',
            fontWeight: 600,
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '20px',
            fontWeight: 700,
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '20px',
            fontWeight: 700,
        }
    },
    eventMonth: {
        color: '#707070',
        textTransform: 'uppercase',
        [theme.breakpoints.up('xs')]: {
            fontSize: '7px',
            fontWeight: 400,
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '8px',
            fontWeight: 400,
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '12px',
            fontWeight: 400,
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '15px',
            fontWeight: 400,
        },
        [theme.breakpoints.up('xl')]: {
            fontSize: '15px',
            fontWeight: 400,
        }
    },
    buttonRight: {
        [theme.breakpoints.up('xs')]: {
            display: 'none'
        },
        [theme.breakpoints.up('sm')]: {
            display: 'none'
        },
        [theme.breakpoints.up('md')]: {
            display: 'flex'
        },
        [theme.breakpoints.up('lg')]: {
            display: 'flex'
        },
        [theme.breakpoints.up('xl')]: {
            display: 'flex'
        }
    },
    boxContent: {
        backgroundColor: '#fff',
        marginLeft: '15px',
        marginRight: '15px',
        height: 'fit-content',
        borderRadius: '50px',
        marginBottom: '15px',
        [theme.breakpoints.up('xs')]: {
            paddingTop: '10px',
            paddingBottom: '10px',
        },
        [theme.breakpoints.up('sm')]: {
            paddingTop: '10px',
            paddingBottom: '10px',
        },
        [theme.breakpoints.up('md')]: {
            paddingTop: '10px',
            paddingBottom: '20px',
        },
        [theme.breakpoints.up('lg')]: {
            paddingTop: '20px',
            paddingBottom: '20px',
        },
        [theme.breakpoints.up('xl')]: {
            paddingTop: '20px',
            paddingBottom: '20px',
        }
    },
    buttonticket: {
        borderRadius: '30px',
        color: '#fff',
        backgroundColor: '#9e69d2',
        [theme.breakpoints.up('xs')]: {
            paddingLeft: '10px',
            paddingRight: '10px',
            fontSize: '10px'
        },
        [theme.breakpoints.up('sm')]: {
            paddingLeft: '20px',
            paddingRight: '20px',
            fontSize: 'small'

        },
        [theme.breakpoints.up('md')]: {
            paddingLeft: '30px',
            paddingRight: '30px',
            fontSize: 'small'

        },
        [theme.breakpoints.up('lg')]: {
            paddingLeft: '40px',
            paddingRight: '40px',
            fontSize: 'small',
            marginBottom: '10px'

        },
        [theme.breakpoints.up('xl')]: {
            paddingLeft: '40px',
            paddingTop: '10px',
            paddingBottom: '10px',
            paddingRight: '40px',
            fontSize: 'small',
            marginBottom: '15px'
        }
    },
    captionTitle: {
        bottom: 0,
        [theme.breakpoints.up('xs')]: {
            fontSize: "11px",
            fontWeight: 'bold'
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: "11px",
            fontWeight: 'bold'
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '17px',
            fontWeight: 'bold'
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '17px',
            fontWeight: 'bold'
        },
        [theme.breakpoints.up('xl')]: {
            fontSize: '17px',
            fontWeight: 'bold'
        }
    },
    captionDesc: {
        bottom: 0,
        [theme.breakpoints.up('xs')]: {
            fontSize: "8px"
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: "8px"
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '13px'
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '13px'
        },
        [theme.breakpoints.up('xl')]: {
            fontSize: '13px'
        }
    },


}));
