import {
    // GET DATAO UPCOMING EVENT
   POST_PURCHASE_TICKET_REQUEST,
   POST_PURCHASE_TICKET_FAILED,
   POST_PURCHASE_TICKET_SUCCESS,
   GET_PURCHASE_TICKET_REQUEST,
   GET_PURCHASE_TICKET_FAILED,
   GET_PURCHASE_TICKET_SUCCESS,

    GET_PENDING_TICKET_REQUEST,
    GET_PENDING_TICKET_FAILED,
    GET_PENDING_TICKET_SUCCESS
        
} from './PurchaseTicketTypes.js'

const initialState = {
    loading: false,
    message: '',
    purchase_ticket: [],
    ticket_issued: [],
    ticket_pending: []
}
 
const purchaseTicketReducers = ( state = initialState, action ) => {
    switch(action.type) {
        case POST_PURCHASE_TICKET_REQUEST:
            return {
                ...state,
                loading: true
            }
        case POST_PURCHASE_TICKET_FAILED:
            return {
                ...state,
                loading: false,
                message: action.payload                
            }
        case POST_PURCHASE_TICKET_SUCCESS:
            return {
                ...state,
                loading: false,
                purchase_ticket: action.payload
            }    
        case GET_PURCHASE_TICKET_REQUEST:
            return {
                ...state,
                loading: true
            }
        case GET_PURCHASE_TICKET_FAILED:
            return {
                ...state,
                loading: false,
                message: action.payload                
            }
        case GET_PURCHASE_TICKET_SUCCESS:
            return {
                ...state,
                loading: false,
                ticket_issued: action.payload
            } 
        case GET_PENDING_TICKET_REQUEST:
            return {
                ...state,
                loading: true
            }
        case GET_PENDING_TICKET_FAILED:
            return {
                ...state,
                loading: false,
                message: action.payload                
            }
        case GET_PENDING_TICKET_SUCCESS:
            return {
                ...state,
                loading: false,
                ticket_pending: action.payload
            }             
        
        default: return state
    }
}

export default purchaseTicketReducers