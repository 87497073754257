import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import HomePage from './pages/landingpage/HomePage';
import RegisterPage from './pages/auth/RegisterPage';
import LoginPage from './pages/auth/LoginPage';
import ForgotPasswordPage from './pages/auth/ForgotPasswordPage';
import ResetPasswordPage from './pages/auth/ResetPassword';
import RegCongrats from './pages/auth/RegCongrats';
import DashboardUser from './pages/dashboard/DashboardUser';
import ErrorPage from './pages/landingpage/ErrorPage';
import RegSuccess from './protectroute/RegSuccess'
import UserRoute from './protectroute/UserRoute'
import SingleEvent from './pages/landingpage/SingleEvent';
import SingleEventPrivate from './pages/landingpage/SingleEventPrivate';
import MyEventUser from './pages/dashboard/MyEventUser';
import MyTransaksiUser from './pages/dashboard/MyTransaksiUser';
import MyTransaction from './pages/dashboard/MyTransaction';
import ProfileDetail from './pages/dashboard/ProfileDetail';
import PasswordPage from './pages/dashboard/PasswordPage';
import InfoKontakPage from './pages/dashboard/InfoKontakPage';
import CheckoutPage from './pages/dashboard/CheckoutPage';
// import Single from './components/SingleEvent/singleEvent'
import AllMob from './components/CategoryEvent/CategoryAllMob'
import MoreEvent from './components/MoreEvent'
import LikedEvents from './components/LikedEvents'
import Test from './pages/test'
import SK from './pages/landingpage/SyaratKetentuan'
import Eventbykeyword from './components/SearchedEvent/EventbyKeyword'
import EventbyTag from './components/SearchedEvent/EventbyTag'
import TopBarProgress from "react-topbar-progress-indicator";
import FAQ from "./pages/landingpage/Faq"
import IctPage from './pages/ict2023/IctPage';

TopBarProgress.config({
  barColors: {
    "0": "#9e69d2",
    "0.5": "#9e69d2",
    "1.0": "#9e69d2"
  },
  shadowBlur: 4
});

const ICT_REGEX = new RegExp(process.env.REACT_APP_ICT_REGEX, "g")

function AppRoute() {
  const [isLoading, setLoading] = useState(true);

  function fakeRequest() {
    return new Promise(resolve => setTimeout(() => resolve(), 300));
  }

  useEffect(() => {
    fakeRequest().then(() => {
      setLoading(!isLoading);
    });

  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // if(window.location.href.match(ICT_REGEX)){ // for ICT 2023
  //   return(
  //     <BrowserRouter>
  //       <Switch>
  //         <Route path='/' component={IctPage} exact />
  //       </Switch>
  //     </BrowserRouter>
  //   )
  // }

  if (isLoading) {
    return (
      <TopBarProgress />
    )
  } else {

    return (
      <>
        <BrowserRouter>
          <Switch>
            <Route path='/' component={HomePage} exact />
            <Route path='/register' component={RegisterPage} exact />
            <Route path='/login' component={LoginPage} exact />
            <Route path='/forgot-password' component={ForgotPasswordPage} exact />
            <Route path='/reset-password' component={ResetPasswordPage} exact />
            <RegSuccess path='/register/congrats' component={RegCongrats} exact />
            <Route path='/detail-event/:slug' component={SingleEvent} exact />
            <Route path='/detail-event/:slug/:access' component={SingleEventPrivate} exact />
            <Route path='/register' component={RegisterPage} exact />
            <UserRoute path='/dashboard' component={DashboardUser} exact />
            <UserRoute path='/myevent' component={MyEventUser} exact />
            <UserRoute path='/transaksi' component={MyTransaction} exact />
            <UserRoute path='/profile-detail' component={ProfileDetail} exact />
            <UserRoute path='/profile-password' component={PasswordPage} exact />
            <UserRoute path='/info-kontak' component={InfoKontakPage} exact />
            <UserRoute path='/checkout' component={CheckoutPage} exact />
            <Route path='/event' component={MoreEvent} exact />
            <Route path='/more-event-search' component={MoreEvent} exact />
            <Route path='/event-disukai' component={LikedEvents} exact />
            <Route path='/syarat-ketentuan' component={SK} exact />
            <Route path='/search/:keyword' component={Eventbykeyword} exact />
            <Route path='/tag/:keyword' component={EventbyTag} exact />
            <Route path='/panduan' component={FAQ} exact />

            {/* <Route path='/detail/:slug' component={Single} exact /> */}
            <Route path='/allcatmob' component={AllMob} exact />


            <Route path='*' component={ErrorPage} exact />
          </Switch>
        </BrowserRouter>

      </>
    )
  }
}

export default AppRoute;
