import React, { useState } from 'react';
import {
  Button,
  CssBaseline,
  TextField,
  Container,
  Grid,
  Box,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useLocation } from 'react-router-dom'
import Axios from 'axios'
import { API } from './../../config'
import swal from 'sweetalert'
import * as SecureLs from 'secure-ls'
const ls = new SecureLs();

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up('xs')]: {
      paddingTop: '70px',
      height: 700
    },
    [theme.breakpoints.up('sm')]: {
      paddingTop: '70px',
      height: 750
    },
    [theme.breakpoints.up('md')]: {
      paddingTop: '100px',
      height: 700
    },
    [theme.breakpoints.up('lg')]: {
      paddingTop: '120px',
      height: 700
    },
    [theme.breakpoints.up('xl')]: {
      paddingTop: '150px',
      height: 900
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  facebookclass: {
    width: '100%',
    height: '45px',
    backgroundColor: '#4267B2',
    color: '#fff',
    border: 'none',
    fontSize: '14px'
  },
  submitBtn: {
    borderRadius: '20px',
    backgroundColor: '#9e69d2',
    "&:hover": {
      backgroundColor: "#574bb3 !important",
    },
    paddingTop: '10px',
    paddingBottom: '10px',
    marginTop: '20px',
    marginBottom: '20px'
  },
  btnText: {
    textTransform: 'capitalize',
    fontSize: '15px',
    paddingLeft: '10px',
    paddingRight: '10px',
    fontWeight: 'bold',
    color: '#fbfbfb'
  },
}));

const ResetPassComponent = () => {
  const classes = useStyles();

  const responreg = useSelector(state => state.register)


  const [resetPassword, setResetPassword] = useState({
    email: '',
    password: '',
    retype_password: ''
  })

  let data_token = useLocation()

  const handleChangeSignup = name => event => {
    setResetPassword({
      ...resetPassword,
      [name]: event.target.value
    })
  }

  const onClickSubmit = (e) => {
    e.preventDefault()

    let formdata = new FormData();
    // let token = localStorage.getItem('token')
    let token = ls.get('@_tknid').data

    let Headers = {
      headers: {
        "Content-Type": "multipart/form-data",
        "Authorization": `Bearer ${token}`
      },
    };

    formdata.append("email", resetPassword.email);
    formdata.append("password", resetPassword.password);
    formdata.append("retype_password", resetPassword.retype_password);

    Axios.post(`${API}/reset_password${data_token.search}`, formdata, Headers)
      .then((res) => {
        if (res.data.error) {
          swal("Error!", `${res.data.error}`, "error");
        } else if (res.data.success) {
          swal({
            title: "Yeaayy!",
            text: `${res.data.success}`,
            icon: "success",
            button: "Login",
          })
            .then((redirect) => {
              if (redirect) {
                window.location.href = "/login";
              }
            });
        }
      }).catch((err) => {
        swal("Error", `${err.response}`, "error");
      })
  }

  const showLoading = () => (
    responreg.loading && (
      <Box textAlign='center' pb={2} >
        <CircularProgress />
      </Box>
    )
  )

  return (
    <>

      <Container component="main" maxWidth="xs" className={classes.root}>
        <CssBaseline />
        <div className={classes.paper}>

          <Box pb={2} id='fontStyle' fontSize={35} fontWeight='bold' color='#9e69d2' >Ganti Password</Box>

          <form className={classes.form} onSubmit={onClickSubmit} >
            {showLoading()}
            <Grid container spacing={1}>

              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  id="email"
                  fullWidth
                  onChange={handleChangeSignup('email')}
                  label="Email"
                  type="email"

                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  onChange={handleChangeSignup('password')}
                  label="Password Baru"
                  type="password"
                  id="password"

                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  onChange={handleChangeSignup('retype_password')}
                  label="Konfirmasi Password"
                  type="password"
                  id="repassword"

                />
              </Grid>

            </Grid>

            <Button
              type='submit'
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submitBtn}
            >
              <Box id='fontStyle' className={classes.btnText} >Submit</Box>
            </Button>

          </form>
        </div>

      </Container>

    </>
  );
}

export default ResetPassComponent;