import Axios from 'axios'
import { API } from '../../config'
import {
    // GET DATA UPCOMING EVENT
    GET_POPULAR_EVENT_REQUEST,
    GET_POPULAR_EVENT_FAILED,
    GET_POPULAR_EVENT_SUCCESS

} from './PopEventTypes'
import * as SecureLs from 'secure-ls'
import jwt_decode from 'jwt-decode'

const ls = new SecureLs();


const CheckToken = () => {
    let rtoken = ls.get('@_rtknid').data
    let tokenn = ls.get('@_tknid').data
    if (tokenn) {
        var jwt = jwt_decode(tokenn);
        var today = new Date().getTime();
        var jwt_exp = jwt.exp * 1000 - (1000 * 20); // sebelum 20 detik

        if (jwt_exp < today) {
            // request
            let formdata = new FormData()
            let options = {
                headers: {
                    Authorization: `Bearer ${tokenn}`
                }
            }
            formdata.append("refresh_token", rtoken)
            const refreshToken = Axios.post(`${API}/refresh_token`, formdata, options, null)
            if (refreshToken) {
                ls.set('@_tknid', { data: refreshToken.data.data.token })
                ls.set('@_rtknid', { data: refreshToken.data.data.refreshToken })
            }
        } else {
            console.log("tes")

        }
    }
}

const getPopularEventRequest = () => {
    return {
        type: GET_POPULAR_EVENT_REQUEST
    }
}

const getPopularEventSuccess = (data) => {
    return {
        type: GET_POPULAR_EVENT_SUCCESS,
        payload: data
    }
}

const getPopularEventFailed = (message) => {
    return {
        type: GET_POPULAR_EVENT_FAILED,
        payload: message
    }
}

export const getPopularEvent = () => {
    let token = ls.get('@_tknid').data
    return (
        async (dispatch) => {
            try {
                const testing = await CheckToken()
                let token = ls.get('@_tknid').data
                if (token) {
                    await Axios.get(`${API}/event/all/?order_by=event_total_clicked&sort_by=desc&limit=5`, { headers: { Authorization: `Bearer ${token}` } })
                        .then((res) => {
                            dispatch(getPopularEventSuccess(res.data.data))
                        }).catch((err) => {
                            dispatch(getPopularEventFailed(err))
                            if (err.response.status === 401) {
                                let tokenn = ls.get('@_tknid').data
                                let rtokenn = ls.get('@_rtknid').data
                                let formdata = new FormData()
                                let options = {
                                    headers: {
                                        Authorization: `Bearer ${tokenn}`
                                    }
                                }
                                formdata.append("refresh_token", rtokenn)
                                Axios.post(`${API}/refresh_token`, formdata, options, null)
                                    .then(res => {
                                        ls.set('@_tknid', { data: res.data.data.token })
                                        ls.set('@_rtknid', { data: res.data.data.refreshToken })
                                    }).catch(err => {
                                    })
                            }
                        })
                } else {
                    await Axios.get(`${API}/event/all/?order_by=event_total_clicked&sort_by=desc&limit=5`)
                        .then((res) => {
                            dispatch(getPopularEventSuccess(res.data.data))
                        }).catch((err) => {
                            dispatch(getPopularEventFailed(err))
                        })
                }

            } catch (error) {
                ls.removeAll()
                localStorage.clear()
                sessionStorage.clear()
                window.location.href = "/"
            }
            // dispatch(getPopularEventRequest())

        }
    )
}

