// import { Input } from '@material-ui/core'
import Axios from 'axios'
import { API } from '../../config'
import jwt_decode from 'jwt-decode'
import {
    GET_SEARCHED_ALL_EVENT_REQUEST,
    GET_SEARCHED_ALL_EVENT_FAILED,
    GET_SEARCHED_ALL_EVENT_SUCCESS,
    GET_SEARCHED_ONLINE_EVENT_SUCCESS,
    GET_SEARCHED_STORE_EVENT_SUCCESS,
    GET_SEARCHED_PUBLIC_EVENT_SUCCESS,
    KLIK_SEARCH_TO_SHOW_CONTENT,
    GET_FILTER_SEARCHED,
    GET_SEARCHED_BY_KEYWORD,
    KLIK_TO_SHOW_SEARCHED_BY_KEYWORD,
    GET_EVENT_BY_TAG,
    KLIK_TO_SHOW_SEARCHED_BY_TAG
} from './SearchedTypes'

import * as SecureLs from 'secure-ls'
const ls = new SecureLs();

const CheckToken = () => {
    let rtoken = ls.get('@_rtknid').data
    let tokenn = ls.get('@_tknid').data
    if (tokenn) {
        var jwt = jwt_decode(tokenn);
        var today = new Date().getTime();
        var jwt_exp = jwt.exp * 1000 - (1000 * 20); // sebelum 20 detik

        if (jwt_exp < today) {
            // request
            let formdata = new FormData()
            let options = {
                headers: {
                    Authorization: `Bearer ${tokenn}`
                }
            }
            formdata.append("refresh_token", rtoken)
            const refreshToken = Axios.post(`${API}/refresh_token`, formdata, options, null)
            if (refreshToken) {
                ls.set('@_tknid', { data: refreshToken.data.data.token })
                ls.set('@_rtknid', { data: refreshToken.data.data.refreshToken })
            }
        } else {
            console.log("tes")

        }
    }
}

const getSearchedEventRequest = () => {
    return {
        type: GET_SEARCHED_ALL_EVENT_REQUEST
    }
}


const getSearchedEventFailed = (message) => {
    return {
        type: GET_SEARCHED_ALL_EVENT_FAILED,
        payload: message
    }
}

const getSearchedEventKeywordSuccess = (data) => {
    return {
        type: GET_SEARCHED_BY_KEYWORD,
        payload: data
    }
}

export const klikSearchByKeyword = (input) => {
    return (
        (dispatch) => {
            dispatch(klikSearchKeywordSucces(input))
        }
    )
}

const klikSearchKeywordSucces = (data) => {
    return {
        type: KLIK_TO_SHOW_SEARCHED_BY_KEYWORD,
        payload: data
    }
}

export const getEventByKeyword = (keyword, cat, page) => {
    let token = ls.get('@_tknid').data
    if (cat === undefined || cat === "") {
        return (
            async (dispatch) => {

                try {
                    const testing = await CheckToken()
                    let token = ls.get('@_tknid').data
                    if (token) {
                        // console.log(`${API}/event/all?event_name=${keyword}&limit=6&page=${page}`)
                        await Axios.get(`${API}/event/all?event_name=${keyword}&limit=9&page=${page}`, { headers: { Authorization: `Bearer ${token}` } })
                            .then(res => {
                                dispatch(getSearchedEventKeywordSuccess(res.data))
                                dispatch(klikSearchByKeyword(keyword))
                            }).catch(err => {

                            })
                    } else {
                        await Axios.get(`${API}/event/all?event_name=${keyword}&limit=9&page=${page}`)
                            .then(res => {
                                dispatch(getSearchedEventKeywordSuccess(res.data))
                                dispatch(klikSearchByKeyword(keyword))
                            }).catch(err => {

                            })
                    }

                } catch (error) {
                    ls.removeAll()
                    localStorage.clear()
                    sessionStorage.clear()
                    window.location.href = "/"
                }


            }
        )
    } else {

        return (
            async (dispatch) => {
                try {
                    const testing = await CheckToken()
                    let token = ls.get('@_tknid').data
                    if (token) {
                        // console.log("iniID", cat)
                        // console.log(`${API}/event/all?event_name=${keyword}&category_event_id=${cat}`)
                        await Axios.get(`${API}/event/all?event_name=${keyword}&category_event_id=${cat}&limit=9&page=${page}`, { headers: { Authorization: `Bearer ${token}` } })
                            .then(res => {
                                // console.log(res.data)
                                dispatch(getSearchedEventKeywordSuccess(res.data))
                                dispatch(klikSearchByKeyword(keyword))
                            }).catch(err => {
                                // if (err.response.status === 401) {
                                //     let tokenn = ls.get('@_tknid').data
                                //     let rtokenn = ls.get('@_rtknid').data
                                //     let formdata = new FormData()
                                //     let options = {
                                //         headers: {
                                //             Authorization: `Bearer ${tokenn}`
                                //         }
                                //     }
                                //     formdata.append("refresh_token", rtokenn)
                                //     Axios.post(`${API}/refresh_token`, formdata, options, null)
                                //         .then(res => {
                                //             ls.set('@_tknid', { data: res.data.data.token })
                                //             ls.set('@_rtknid', { data: res.data.data.refreshToken })
                                //         }).catch(err => {
                                //         })
                                // }
                            })
                    } else {
                        Axios.get(`${API}/event/all?event_name=${keyword}&category_event_id=${cat}&limit=9&page=${page}`)
                            .then(res => {
                                dispatch(getSearchedEventKeywordSuccess(res.data))
                                dispatch(klikSearchByKeyword(keyword))
                            }).catch(err => {

                            })
                    }
                } catch (error) {
                    ls.removeAll()
                    localStorage.clear()
                    sessionStorage.clear()
                    window.location.href = "/"
                }

            }
        )
    }
}


//  ===================

const getEventbyTagSuccess = (data) => {
    return {
        type: GET_EVENT_BY_TAG,
        payload: data
    }
}

export const klikEventByTag = (input) => {
    return (
        (dispatch) => {
            dispatch(klikEventTagSuccess(input))
        }
    )
}

const klikEventTagSuccess = (data) => {
    return {
        type: KLIK_TO_SHOW_SEARCHED_BY_TAG,
        payload: data
    }
}

export const getEventbyTag = (keyword, cat, page) => {
    let token = ls.get('@_tknid').data
    if (cat === undefined || cat === "") {
        return (
            async (dispatch) => {
                try {
                    const testing = await CheckToken()
                    let token = ls.get('@_tknid').data
                    if (token) {
                        // console.log(`${API}/event/all?event_name=${keyword}&limit=6&page=${page}`)
                        await Axios.get(`${API}/event/tag/${keyword}?limit=9&page=${page}`, { headers: { Authorization: `Bearer ${token}` } })
                            .then(res => {
                                dispatch(getEventbyTagSuccess(res.data))
                                dispatch(klikEventByTag(keyword))
                            }).catch(err => {

                            })
                    } else {
                        await Axios.get(`${API}/event/tag/${keyword}?limit=9&page=${page}`)
                            .then(res => {
                                dispatch(getEventbyTagSuccess(res.data))
                                dispatch(klikEventByTag(keyword))
                            }).catch(err => {

                            })
                    }
                } catch (error) {
                    ls.removeAll()
                    localStorage.clear()
                    sessionStorage.clear()
                    window.location.href = "/"
                }

            }
        )
    }
    else {

        return (
            async (dispatch) => {
                try {
                    const testing = await CheckToken()
                    let token = ls.get('@_tknid').data
                    if (token) {
                        // console.log("iniID", cat)
                        // console.log(`${API}/event/all?event_name=${keyword}&category_event_id=${cat}`)
                        await Axios.get(`${API}/event/tag/${keyword}?category_event_id=${cat}&limit=9&page=${page}`, { headers: { Authorization: `Bearer ${token}` } })
                            .then(res => {
                                // console.log(res.data)
                                dispatch(getEventbyTagSuccess(res.data))
                                dispatch(klikEventByTag(keyword))
                            }).catch(err => {

                            })
                    } else {
                        await Axios.get(`${API}/event/tag/${keyword}?category_event_id=${cat}&limit=9&page=${page}`)
                            .then(res => {
                                dispatch(getEventbyTagSuccess(res.data))
                                dispatch(klikEventByTag(keyword))
                            }).catch(err => {

                            })
                    }
                } catch (error) {
                    ls.removeAll()
                    localStorage.clear()
                    sessionStorage.clear()
                    window.location.href = "/"
                }

            }
        )
    }


}

// =========================

const getSearchedEventAllSuccess = (data) => {
    return {
        type: GET_SEARCHED_ALL_EVENT_SUCCESS,
        payload: data
    }
}

export const getSearchedEventAll = (input) => {
    // console.log(input, 'searched redux')
    let token = ls.get('@_tknid').data
    let category = input.category
    let kota = input.kota
    let date = input.date
    let page = input.page
    return (
        async (dispatch) => {
            try {
                const testing = await CheckToken()
                let token = ls.get('@_tknid').data
                if (token) {
                    await Axios.get(`${API}/event/all?ms_city_id=${kota}&category_event_id=${category}&event_start_datetime=${date}&limit=6&page=${page}`, { headers: { Authorization: `Bearer ${token}` } })
                        .then((res) => {
                            dispatch(getSearchedEventAllSuccess(res.data))
                            dispatch(getFilter(input))
                        }).catch((err) => {
                            dispatch(getSearchedEventFailed(err))
                        })
                } else {
                    // Axios.get(`${API}/event/all?ms_city_id=${kota}&category_event_id=${category}&event_start_datetime=${date}`)

                    await Axios.get(`${API}/event/all?ms_city_id=${kota}&category_event_id=${category}&event_start_datetime=${date}&limit=6&page=${page}`)
                        .then((res) => {
                            dispatch(getSearchedEventAllSuccess(res.data))
                            dispatch(getFilter(input))
                        }).catch((err) => {
                            dispatch(getSearchedEventFailed(err))
                        })

                }
            } catch (error) {
                ls.removeAll()
                localStorage.clear()
                sessionStorage.clear()
                window.location.href = "/"
            }
            // dispatch(getSearchedEventRequest())

            // Axios.get(`${API}/event/all?ms_city_id=${kota}&category_event_id=${category}&event_start_datetime=${date}`)
        }
    )
}

const getFilter = (data) => {
    return {
        type: GET_FILTER_SEARCHED,
        payload: data
    }
}

// ONLINE 
const getSearchedOnlineEventSuccess = (data) => {
    return {
        type: GET_SEARCHED_ONLINE_EVENT_SUCCESS,
        payload: data
    }
}

export const getSearchedOnlineEvent = () => {
    return (
        (dispatch) => {
            dispatch(getSearchedEventRequest())
            Axios.get(`${API}/event/all/?upcoming_only=true&online_only=true`)
                .then((res) => {
                    dispatch(getSearchedOnlineEventSuccess(res.data.data))
                }).catch((err) => {
                    dispatch(getSearchedEventFailed(err))
                })
        }
    )
}

// STORE 
const getSearchedStoreEventSuccess = (data) => {
    return {
        type: GET_SEARCHED_STORE_EVENT_SUCCESS,
        payload: data
    }
}

export const getSearchedStoreEvent = () => {
    return (
        (dispatch) => {
            dispatch(getSearchedEventRequest())
            Axios.get(`${API}/event/all/?upcoming_only=true&store_only=true`)
                .then((res) => {
                    dispatch(getSearchedStoreEventSuccess(res.data.data))
                }).catch((err) => {
                    dispatch(getSearchedEventFailed(err))
                })
        }
    )
}

// PUBLIC 
const getSearchedPublicEventSuccess = (data) => {
    return {
        type: GET_SEARCHED_PUBLIC_EVENT_SUCCESS,
        payload: data
    }
}

export const getSearchedPublicEvent = () => {
    return (
        (dispatch) => {
            dispatch(getSearchedEventRequest())
            Axios.get(`${API}/event/all/?upcoming_only=true&public_only=true`)
                .then((res) => {
                    dispatch(getSearchedPublicEventSuccess(res.data.data))
                }).catch((err) => {
                    dispatch(getSearchedEventFailed(err))
                })
        }
    )
}

// KLIK_SEARCH_TO_SHOW_CONTENT
const klikSearchSucces = (data) => {
    return {
        type: KLIK_SEARCH_TO_SHOW_CONTENT,
        payload: data
    }
}

export const klikSearch = (input) => {
    return (
        (dispatch) => {
            dispatch(klikSearchSucces(input))
        }
    )
}

export const klikUnshowSearch = (i) => {
    return {
        type: "KLIK_UNSEARCH_TO_SHOW_CONTENT",
        payload: i
    }
}

