import React from 'react';
import AppBarComponent from '../../components/AppBar';
import FooterDashboard from '../../components/FooterDashboard';
import RegisterComponent from '../../components/AuthComponent/RegisterComponent';
import Meta from '../../components/Util/MetaDecorator'

const RegisterPage = () => {
    // document.title = 'Register | Eventories.com '


    return (
        <React.Fragment>
            <Meta title="Register | Eventories.com" description="Belum memiliki akun? Silakan daftarkan akun baru" />
            <AppBarComponent />
            <RegisterComponent />
            <FooterDashboard />
        </React.Fragment>
    )
}

export default RegisterPage;