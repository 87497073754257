import Axios from 'axios'
import jwt_decode from "jwt-decode";
import * as SecureLs from 'secure-ls'
export const API = process.env.REACT_APP_API_URL
export const CLIENT_KEY = process.env.REACT_APP_CLIENT_KEY
export const SNAP = process.env.REACT_APP_SNAP_SCRIPT
export const GOOGLE = process.env.REACT_APP_GOOGLE_CLIENTID



// console.log("SNAP", GOOGLE)

const ls = new SecureLs();



if (localStorage.getItem('@_tknid')) {
    // const isLogin = async () => {
    //     let token = ls.get('@_tknid').data
    //     let r_token = ls.get('@_rtknid').data
    //     let formdata = new FormData();
    //     formdata.append("refresh_token", r_token)
    //     try {
    //         const res1 = await Axios.post(`${API}/token/own`, formdata, { headers: { Authorization: `Bearer ${token}` } })
    //         if (res1) {
    //             const found = res1.data.data.find(e => e.current_status);
    //             if (found === undefined) {
    //                 ls.removeAll()
    //                 localStorage.clear()
    //                 window.location.href = "/"
    //             }
    //         }
    //     } catch (error) {

    //     }
    // }
    // isLogin()

    let tkn = ls.get('@_tknid').data
    var jwt = jwt_decode(tkn);
    var today = new Date().getTime();
    var jwt_exp = jwt.exp * 1000 - (1000 * 20); // sebelum 20 detik
    // let timeout = jwt_exp - today
    // setTimeout(() => {

    //     const getRefreshToken = () => {
    //         try {
    //             let tokenn = ls.get('@_tknid').data
    //             let rtokenn = ls.get('@_rtknid').data
    //             let formdata = new FormData()
    //             let options = {
    //                 headers: {
    //                     Authorization: `Bearer ${tokenn}`
    //                 }
    //             }
    //             formdata.append("refresh_token", rtokenn)
    //             Axios.post(`${API}/refresh_token`, formdata, options, null)
    //                 .then(res => {
    //                     if (res.data) {

    //                         ls.set('@_tknid', { data: res.data.data.token })
    //                         ls.set('@_rtknid', { data: res.data.data.refreshToken })
    //                     }
    //                     // window.location.reload()
    //                     // console.log("refresh token....", res.data)
    //                 }).catch(err => {
    //                     ls.removeAll()
    //                     localStorage.clear()
    //                     sessionStorage.clear()
    //                     window.location.href = "/"
    //                 })

    //         } catch (error) {
    //             ls.removeAll()
    //             localStorage.clear()
    //             sessionStorage.clear()
    //             window.location.href = "/"
    //         }
    //     }
    //     getRefreshToken();
    //     setInterval(getRefreshToken, 2400 * 1000)
    // }, 2400 * 10000);


    // const setinter = (x) => {
    //     alert(x)
    // }

    // setInterval(setinter(timeout), 1000);


    if (jwt_exp < today) {
        try {
            let tokenn = ls.get('@_tknid').data
            let rtokenn = ls.get('@_rtknid').data
            let formdata = new FormData()
            let options = {
                headers: {
                    Authorization: `Bearer ${tokenn}`
                }
            }
            formdata.append("refresh_token", rtokenn)
            Axios.post(`${API}/refresh_token`, formdata, options, null)
                .then(res => {
                    console.log("ms")

                    ls.set('@_tknid', { data: res.data.data.token })
                    ls.set('@_rtknid', { data: res.data.data.refreshToken })
                })

        } catch (error) {
            ls.removeAll()
            localStorage.clear()
            sessionStorage.clear()
            window.location.href = "/"
        }

    } else {
        // gaperlu request
        console.log("tes")
    }

    // ===
    // setTimeout(() => {

    // }, timeout);

    // setTimeout(() => {
    //     const getRefreshToken = async () => {
    //         try {
    //             let tokenn = ls.get('@_tknid').data
    //             let rtokenn = ls.get('@_rtknid').data
    //             let formdata = new FormData()
    //             let options = {
    //                 headers: {
    //                     Authorization: `Bearer ${tokenn}`
    //                 }
    //             }
    //             formdata.append("refresh_token", rtokenn)
    //             const refreshToken = await Axios.post(`${API}/refresh_token`, formdata, options, null)
    //             if (refreshToken) {
    //                 ls.set('@_tknid', { data: refreshToken.data.data.token })
    //                 ls.set('@_rtknid', { data: refreshToken.data.data.refreshToken })
    //             }
    //         } catch (error) {
    //             ls.removeAll()
    //             localStorage.clear()
    //             window.location.href = "/"
    //         }
    //     }
    //     getRefreshToken(); // It's already been 14.5 minutes, so run it now 840
    //     setInterval(getRefreshToken, 870 * 1000); // Run it every 14.5 minutes from here on out
    // }, 870 * 1000);

}




