import Axios from 'axios'
import { API } from '../../config'
import {
    // GET DATA UPCOMING EVENT
    GET_MS_PAYMENT_REQUEST,
    GET_MS_PAYMENT_FAILED,
    GET_MS_PAYMENT_SUCCESS

} from './MsPaymentTypes'

import * as SecureLs from 'secure-ls'
const ls = new SecureLs();

const getMsPaymentRequest = () => {
    return {
        type: GET_MS_PAYMENT_REQUEST
    }
}

const getMsPaymentSuccess = (data) => {
    return {
        type: GET_MS_PAYMENT_SUCCESS,
        payload: data
    }
}

const getMsPaymentFailed = (message) => {
    return {
        type: GET_MS_PAYMENT_FAILED,
        payload: message
    }
}

export const getMsPayment = () => {

    return (
        (dispatch) => {
            // let token = localStorage.getItem('token')
            let token = ls.get('@_tknid').data

            dispatch(getMsPaymentRequest())
            let options = {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
            Axios.get(`${API}/list/ms_payment?eligible_for_topup_balance=true`, options)
                .then((res) => {
                    // console.log('ms payment', res)
                    // console.log('loginheaders', res.response)                
                    dispatch(getMsPaymentSuccess(res.data.data))
                }).catch((err) => {
                    dispatch(getMsPaymentFailed(err))
                    if (err.response.status === 401) {
                        let tokenn = ls.get('@_tknid').data
                        let rtokenn = ls.get('@_rtknid').data
                        let formdata = new FormData()
                        let options = {
                            headers: {
                                Authorization: `Bearer ${tokenn}`
                            }
                        }
                        formdata.append("refresh_token", rtokenn)
                        Axios.post(`${API}/refresh_token`, formdata, options, null)
                            .then(res => {
                                ls.set('@_tknid', { data: res.data.data.token })
                                ls.set('@_rtknid', { data: res.data.data.refreshToken })
                            }).catch(err => {
                            })
                    }
                    // console.log(err)

                })
        }
    )
}

