import React, { useState, useEffect } from 'react';
import { Container, Box } from '@material-ui/core';
import './../assets/css/main.css'
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import { FaChevronRight, FaChevronLeft } from 'react-icons/fa';
import { Carousel } from 'react-bootstrap';
// import {} from 'react-slider'
import { useSelector } from 'react-redux';
// import moment from 'moment';
import Skeleton from '@material-ui/lab/Skeleton';
import { API } from '../config'
import Axios from 'axios'

// styles 
import useStyles from "./stylesComponent/SliderStyle";

const SliderCarousel = () => {

    const classes = useStyles();

    const [index, setIndex] = useState(0);
    const [feature, setfeature] = useState([]);
    const [loading, setloading] = useState(true);

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };

    useEffect(() => {
        const listfeature = async () => {
            try {
                const list = await Axios.get(`${API}/featured/domain/eventories`)
                setloading(true)
                if (list) {
                    // console.log("lis", list.data.data)
                    setfeature(list.data.data)
                    setloading(false)
                }
                return list;

            } catch (error) {
                console.log(error)
                // window.location.href = "/"
            }
        }
        listfeature()

    }, [])

    return (
        <React.Fragment>

            {
                feature && feature.length === 0 ?
                    <Container maxWidth='lg' className={classes.rootdivtitle} style={{ marginTop: '20px' }} >
                    </Container>
                    :
                    <Container maxWidth='lg' className={classes.rootdivtitle} style={{ marginTop: '20px' }} >

                        {loading === true ?

                            <div >
                                <Box pb={2}>
                                    <Skeleton variant="rect" height={410} style={{ borderRadius: '10px' }} />
                                </Box>
                            </div>

                            :
                            <Carousel activeIndex={index} onSelect={handleSelect} indicators={false} nextIcon={false} prevIcon={false}
                                nextIcon={
                                    feature && feature.length > 1 ?
                                        (<Box fontSize={18} color='#fff' ><FaChevronRight /></Box>)
                                        :
                                        null
                                }
                                prevIcon={
                                    feature && feature.length > 1 ?
                                        (<Box fontSize={18} color='#fff' ><FaChevronLeft /></Box>)
                                        :
                                        null
                                }
                            >

                                {
                                    feature.map((val, index) => (
                                        <Carousel.Item key={index} interval={1000}
                                            style={{
                                                cursor: `${val.featured_redirect_url && val.featured_redirect_url.length > 0 ? 'pointer' : 'default'}`
                                            }}
                                            onClick={() => {
                                                // console.log(`${API}/featured/${val.featured_id}/redirect`)
                                                var matcher = "eventories.com";
                                                if (val.featured_redirect_url) {
                                                    let str = val.featured_redirect_url
                                                    if (str.match(matcher.toLowerCase()) || str.match(matcher.toUpperCase())) {
                                                        window.location.href = `${API}/featured/${val.featured_id}/redirect`

                                                    } else {
                                                        // console.log(str + " unmatch")
                                                        window.open(`${API}/featured/${val.featured_id}/redirect`, '_blank');
                                                    }
                                                }
                                            }
                                            }
                                        >

                                            <img
                                                src={val.featured_image_url}
                                                alt={val.featured_subject}
                                                // height="auto"
                                                className={classes.imgstyle}
                                            />
                                            {/* <Carousel.Caption> */}
                                            {
                                                val.featured_subject || val.featured_description ?
                                                    <div className="caption">
                                                        <Box className={classes.captionTitle} >{val.featured_subject}</Box >
                                                        <Box className={classes.captionDesc}  >{val.featured_description}</Box>
                                                    </div>
                                                    :
                                                    null
                                            }
                                            {/* </Carousel.Caption> */}
                                        </Carousel.Item>
                                    ))
                                }

                            </Carousel>
                        }

                    </Container>


            }

        </React.Fragment>
    )
}

export default SliderCarousel;