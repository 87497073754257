import React, { useEffect, useState } from "react"
import { Button, Col, Container, Modal, Navbar, Row, Spinner } from "react-bootstrap"
import * as SecureLs from 'secure-ls'
import Axios from "axios"
import { TextField } from "@material-ui/core"
import { useDispatch, useSelector } from "react-redux"
import swal from 'sweetalert'
import { Helmet } from "react-helmet";
import ReactHtmlParser from 'react-html-parser';
import { InfoRounded, AccountCircle } from "@material-ui/icons"

import './../../assets/css/ict2023.css'
import { API } from "../../config"
import { userLogin } from "../../redux"

import logo from './../../assets/img/ict2023/logo-ict.png'
import logo2 from './../../assets/img/ict2023/logo2.png'
import banner from './../../assets/img/ict2023/banner.jpg'
import banner_mobile from './../../assets/img/ict2023/banner_mobile.jpg'

import speaker from './../../assets/img/ict2023/speaker.png'
import speaker2 from './../../assets/img/ict2023/speaker2.png'
import speaker3 from './../../assets/img/ict2023/speaker3.png'
import speaker_mobile from './../../assets/img/ict2023/speaker_mobile.png'
import speaker2_mobile from './../../assets/img/ict2023/speaker2_mobile.png'
import speaker3_mobile from './../../assets/img/ict2023/speaker3_mobile.png'

import topic1 from './../../assets/img/ict2023/green-energy.png'
import topic2 from './../../assets/img/ict2023/team.png'
import topic3 from './../../assets/img/ict2023/computer.png'
import favicon from './../../assets/img/ict2023/favicon.ico'
import scheduleText from './../../assets/img/ict2023/schedule-text.png'
import bg from './../../assets/img/ict2023/bg.jpg'
import doorprize from './../../assets/img/ict2023/doorprize.png'

import sponsor_1 from './../../assets/img/ict2023/sponsor/LogoSponsor-01.png'
import sponsor_2 from './../../assets/img/ict2023/sponsor/LogoSponsor-02.png'
import sponsor_3 from './../../assets/img/ict2023/sponsor/LogoSponsor-03.png'
import sponsor_4 from './../../assets/img/ict2023/sponsor/LogoSponsor-04.png'
import sponsor_5 from './../../assets/img/ict2023/sponsor/LogoSponsor-05.png'
import sponsor_6 from './../../assets/img/ict2023/sponsor/LogoSponsor-06.png'
import sponsor_7 from './../../assets/img/ict2023/sponsor/LogoSponsor-07.png'
import sponsor_8 from './../../assets/img/ict2023/sponsor/LogoSponsor-08.png'
import sponsor_9 from './../../assets/img/ict2023/sponsor/LogoSponsor-09.png'
import sponsor_10 from './../../assets/img/ict2023/sponsor/LogoSponsor-10.png'
import sponsor_11 from './../../assets/img/ict2023/sponsor/LogoSponsor-11.png'
import sponsor_12 from './../../assets/img/ict2023/sponsor/LogoSponsor-12.png'
import sponsor_13 from './../../assets/img/ict2023/sponsor/LogoSponsor-13.png'
import sponsor_14 from './../../assets/img/ict2023/sponsor/LogoSponsor-14.png'
import sponsor_15 from './../../assets/img/ict2023/sponsor/LogoSponsor-15.png'
import sponsor_16 from './../../assets/img/ict2023/sponsor/LogoSponsor-16.png'
import sponsor_17 from './../../assets/img/ict2023/sponsor/LogoSponsor-17.png'
import sponsor_18 from './../../assets/img/ict2023/sponsor/LogoSponsor-18.png'

import PDFDummies from './../../assets/img/ict2023/exhibitor/dummies.pdf'
import exibitIMages from './../../assets/img/ict2023/exhibitor/images.png'

import moment from "moment"

const ls = new SecureLs();

const MEDIACARTZ_API_CLIENT = process.env.REACT_APP_MEDIACARTZ_API_CLIENT
const event_slug = process.env.REACT_APP_ICT_SLUG
const passkey = process.env.REACT_APP_ICT_PASSKEY
const MAIN = 'main'
const PANEL_1 = 'panel_1'
const PANEL_4 = 'panel_4'
const PANEL_7 = 'panel_7'
const PANEL_2 = 'panel_2'
const PANEL_5 = 'panel_5'
const PANEL_8 = 'panel_8'
const PANEL_3 = 'panel_3'
const PANEL_6 = 'panel_6'
const PANEL_9 = 'panel_9'
const PANEL_10 = 'panel_10'
const PANEL_11 = 'panel_11'

const DATA_MAIN = {
  title: 'Main Event',
  ticket: MAIN,
  keynote: "",
  speaker: "",
  speaker_title: "",
  materi: null,
  button: true
}

const DATA_PANEL_1 = {
  title: 'Panel 1',
  ticket: PANEL_1,
  keynote: "IBM Solution for Indonesia PDPL (Personal Data Protection Law)",
  speaker: "Winton Winton",
  speaker_title: "Client Engineering Leader, PT IBM Indonesia",
  speaker_image: null,
  materi: null,
  button: true
}

const DATA_PANEL_2 = {
  title: 'Panel 2',
  ticket: PANEL_2,
  keynote: "High Performance Data Management for Data Driven Organisations",
  speaker: "Dominic Wong Chee Kiong",
  speaker_title: "Chief Architect for ASEAN, Content and Data intelligence Hitachi Vantara",
  speaker_image: null,
  materi: "",
  button: true
}

const DATA_PANEL_3 = {
  title: 'Panel 3',
  ticket: PANEL_3,
  keynote: "The Future of Branch Banking includes More Biometrics",
  speaker: "Nikkie Cai",
  speaker_title: "Indonesia & Thailand Country Manager Centerm",
  speaker_image: null,
  materi: null,
  button: true
}

const DATA_PANEL_4 = {
  title: 'Panel 4',
  ticket: PANEL_4,
  keynote: "Meet where you are - OCI Distributed Cloud for Enterprise",
  speaker: "Christian Hartono Tanu",
  speaker_title: "Senior Manager, Technology Solution Engineering, Oracle Indonesia",
  speaker_image: null,
  materi: null,
  button: true
}

const DATA_PANEL_5 = {
  title: 'Panel 5',
  ticket: PANEL_5,
  keynote: "Sustainable & Secured Virtualized Networks",
  speaker: "David Roberts",
  speaker_title: "Technical Director APJC 6Wind",

  speaker_image: null,
  materi: null,
  button: true
}

const DATA_PANEL_6 = {
  title: 'Panel 6',
  ticket: PANEL_6,
  keynote: "Transforming the Finance Services with Generative AI",
  speaker: "Anissh Pandey",
  speaker_title: "Director Enterprise Business ASEAN Nvidia",
  speaker_image: null,
  materi: null,
  button: false
}

const DATA_PANEL_7 = {
  title: 'Panel 7',
  ticket: PANEL_7,

  keynote: "Economics of cloud: How do the figures stack up? How to make it work in indonesia",
  speaker: "Dr. Kenneth Tan",
  speaker_title: "Executive Director Sardina Systems",

  speaker_image: null,
  materi: null,
  button: true
}

const DATA_PANEL_8 = {
  title: 'Panel 8',
  ticket: PANEL_8,
  keynote: "Revolutionize your Corporate Clients Experience and Simplifying your Pay",
  speaker: "Donny",
  speaker_title: "Product Manager Inti Corpora Teknologi",
  speaker_image: null,
  materi: null,
  button: false
}

const DATA_PANEL_9 = {
  title: 'Panel 9',
  ticket: PANEL_9,
  keynote: "How to simply deploy and manage a multi-site, multi-active database",
  speaker: "Teddy Setiawan",
  speaker_title: "Senior Solutions Engineer Yugabyte",
  speaker_image: null,
  materi: null,
  button: true
}

const DATA_PANEL_10 = {
  title: 'Panel 10',
  ticket: PANEL_10,
  keynote: "Veritas Alta™ Cloud Data Management Platform",
  speaker: "Devid Casandra",
  speaker_title: "Technical Consultant Veritas",
  speaker_image: null,
  materi: null,
  button: true
}

const DATA_PANEL_11 = {
  title: 'Panel 11',
  ticket: PANEL_11,
  keynote: "Insurance: What's next?",
  speaker: "Tan Jack Kie",
  speaker_title: "Regional Insurance Pre-Sales Consultant (APACMEA) FIS",
  speaker_image: null,
  materi: null,
  button: true
}

const exhibitor = [
  {
    image: exibitIMages,
    name: 'Exhibitor 1',
    desc: 'Consectetuer tellus malesuada convallis luctus elit gravida',
    materi: PDFDummies
  },
  {
    image: exibitIMages,
    name: 'Exhibitor 2',
    desc: 'Consectetuer tellus malesuada convallis luctus elit gravida',
    materi: PDFDummies
  },
  {
    image: exibitIMages,
    name: 'Exhibitor 3',
    desc: 'Consectetuer tellus malesuada convallis luctus elit gravida',
    materi: PDFDummies
  },
  {
    image: exibitIMages,
    name: 'Exhibitor 4',
    desc: 'Consectetuer tellus malesuada convallis luctus elit gravida',
    materi: PDFDummies
  },
  {
    image: exibitIMages,
    name: 'Exhibitor 5',
    desc: 'Consectetuer tellus malesuada convallis luctus elit gravida',
    materi: PDFDummies
  },
  {
    image: exibitIMages,
    name: 'Exhibitor 6',
    desc: 'Consectetuer tellus malesuada convallis luctus elit gravida',
    materi: PDFDummies
  },
  {
    image: exibitIMages,
    name: 'Exhibitor 7',
    desc: 'Consectetuer tellus malesuada convallis luctus elit gravida',
    materi: PDFDummies
  },
  {
    image: exibitIMages,
    name: 'Exhibitor 8',
    desc: 'Consectetuer tellus malesuada convallis luctus elit gravida',
    materi: PDFDummies
  },
  {
    image: exibitIMages,
    name: 'Exhibitor 9',
    desc: 'Consectetuer tellus malesuada convallis luctus elit gravida',
    materi: PDFDummies
  },
  {
    image: exibitIMages,
    name: 'Exhibitor 10',
    desc: 'Consectetuer tellus malesuada convallis luctus elit gravida',
    materi: PDFDummies
  }
]

export default function IctPage() {
  const dispatch = useDispatch()
  const respLogin = useSelector(state => state.login)
  const captcha = useSelector(state => state.login.captcha)
  const [modalRegister, setModalRegister] = useState(false)
  const [modalLogin, setModalLogin] = useState(false)
  const [modalRecover, setModalRecover] = useState(false)
  const [signupEmail, setSignupEmail] = useState({
    name: '',
    email: '',
    password: 'ict2023',
    retype_password: 'ict2023',
    company_name: '',
    msisdn: '',
    bio: '',
    industry: ''
  })
  const [signinEmail, setSigninEmail] = useState({
    email: '',
    password: 'ict2023',
    captcha: ''
  })
  const [emailRecover, setEmailRecover] = useState({
    email: ''
  })
  const [dataEvent, setDataEvent] = useState(null)
  const [loading, setLoading] = useState(false)
  const [dataTicket, setDataTickt] = useState([])
  const [ticketSelected, setTicketSelected] = useState(null)
  const [completion, setCompletion] = useState({
    name: '',
    msisdn: '',
    bio: '',
    company_name: '',
    industry: '',
    company_id: null,
    ms_city_id: null
  })
  const [isEnd, setIsEnd] = useState(false)
  const [counter, setCounter] = useState(0) //validasi auto booking main biar tidak multiple request
  const [dataTicketSet, setDataTicktSet] = useState(false)

  useEffect(()=>{
    if(ls.get('@_tknid')){
      setModalRegister(false)
    }
  }, [ls.get('@_tknid').data])

  const handleChangeSignup = name => event => {
    setSignupEmail({
      ...signupEmail,
      [name]: event.target.value
    })
  }

  const handleChangeLogin = email => event => {
    setSigninEmail({ ...signinEmail, [email]: event.target.value })
  }

  const handleChangeCompletion = name => event => {
    setCompletion({
      ...completion,
      [name]: event.target.value
    })
  }

  const bookNow = (data_ticket) => {
    const type = data_ticket.ticket

    if (ls.get('@_tknid').data) {
      const idx = dataEvent.seat.seat_detail.filter(x => x.seat_detail_name===type)
      const dataSeat = idx[0]
      if(dataSeat){
        purchaseTicket(dataSeat, data_ticket)
      }
    }else{
      setModalRegister(true)
    }
  }

  const onRegister = (e) => {
    e.preventDefault()
    setLoading(true)

    Axios.post(`${MEDIACARTZ_API_CLIENT}/register/complete`, {...signupEmail, company_name: `${signupEmail.company_name} - ${signupEmail.industry}`, ms_city_id: 3173, category_company_id: 1})
    .then((res) => {
      if(res.data.success){
        dispatch(userLogin({ email: signupEmail.email, password: signupEmail.password, captcha: '' }))
      }else{
        if(res.data.error==='Unexpected error (1062 : ER_DUP_ENTRY)'){
          swal("Error", `Phone number was registered`, "error");
        }else{
          swal("Error", `${res.data.error}`, "error");
        }
        setLoading(false)
      }
    }).catch((error) => {
      if (error.response) {
        swal("Error", `${error.response.data.error}`, "error");
			} else if (error.request) {
        swal("Error", `${error.request}`, "error");
			} else {
        swal("Error", `Something Wrong`, "error");
			}
      setLoading(false)
    })
  }

  const onLogin = (e) => {
    e.preventDefault()
    dispatch(userLogin(signinEmail))
  }

  const onCompletion = (e) => {
    e.preventDefault()
    
    setLoading(true)

    let formdata = new FormData()
    formdata.append("name", completion.name)
    formdata.append("bio", completion.bio)
    formdata.append("msisdn", completion.msisdn)
    formdata.append("ms_city_id", completion.ms_city_id)

    let token = ls.get('@_tknid').data

    Axios.put(`${API}/profile`, formdata, { headers: { Authorization: `Bearer ${token}` } })
    .then((res) => {
      if(res.data.success){
        const profile = ls.get('@_profile').data
        var profileTemp = profile
        var profileNew = {
          ...profileTemp,
          name: completion.name,
          msisdn: completion.msisdn,
          bio: completion.bio
        }
        ls.set('@_profile', { data: profileNew })

        if(completion.company_id){
          // edit company
          const dataCompany = {
            company_name: completion.company_name+' - '+completion.industry,
            ms_city_id: 3171,
            user_id: profile.id,
            category_company_id: 1
          }

          Axios.put(`${MEDIACARTZ_API_CLIENT}/company/${completion.company_id}`, dataCompany, { headers: { Authorization: `Bearer ${token}` } })
          .then((res) => {
            if(res.data.success){
              // set session
              var companyTemp = ls.get('@_companydata').data
              var companyNew = {
                ...companyTemp,
                company_name: completion.company_name+' - '+completion.industry
              }
              ls.set('@_companydata', { data: companyNew })
            }
          }).catch((error) => {
            if (error.response) {
              swal("Error", `${error.response.data.error}`, "error");
            } else if (error.request) {
              swal("Error", `${error.request}`, "error");
            } else {
              swal("Error", `Something Wrong`, "error");
            }
            setLoading(false)
          }).finally(()=>{
            // purchase ticket
            purchaseTicket(ticketSelected.seat_detail, ticketSelected.data_ticket)
          })

        }else{
          // create company
          const dataCompany = {
            company_name: completion.company_name+' - '+completion.industry,
            ms_city_id: 3171,
            user_id: profile.id,
            category_company_id: 1
          }

          Axios.post(`${MEDIACARTZ_API_CLIENT}/company`, dataCompany, { headers: { Authorization: `Bearer ${token}` } })
          .then((res) => {
            if(res.data.success){
              // set session
              var companyTemp = ls.get('@_companydata').data
              var companyNew = {
                ...companyTemp,
                company_name: completion.company_name+' - '+completion.industry
              }
              ls.set('@_companydata', { data: companyNew })
              // purchase ticket
              purchaseTicket(ticketSelected.seat_detail, ticketSelected.data_ticket)
            }else{
              swal("Error!", `${res.data.error}`, "error");
              setLoading(false)
            }
          }).catch((error) => {
            if (error.response) {
              swal("Error", `${error.response.data.error}`, "error");
            } else if (error.request) {
              swal("Error", `${error.request}`, "error");
            } else {
              swal("Error", `Something Wrong`, "error");
            }
            setLoading(false)
          })
        }
      }else{
        swal("Error!", `${res.data.error}`, "error");
        setLoading(false)
      }
    }).catch((error) => {
      if (error.response) {
        swal("Error", `${error.response.data.error}`, "error");
			} else if (error.request) {
        swal("Error", `${error.request}`, "error");
			} else {
        swal("Error", `Something Wrong`, "error");
			}
      setLoading(false)
    })
  }

  const getEventDetail = () => {
    setLoading(true)
    let token = ls.get('@_tknid').data
    Axios.get(`${API}/event/slug/${event_slug}/${passkey}`)
    .then((res) => {
      if(res.data.success){
        setDataEvent(res.data.data)
      }else{
        swal("Error!", `${res.data.error}`, "error");
      }
      setLoading(false)
    }).catch((error) => {
      setLoading(false)
      if (error.response) {
        swal("Error", `${error.response.data.error}`, "error");
			} else if (error.request) {
        swal("Error", `${error.request}`, "error");
			} else {
        swal("Error", `Something Wrong`, "error");
			}
    })
  }

  useEffect(()=>{
    setLoading(respLogin.loading)
  }, [respLogin])

  useEffect(()=>{
    getEventDetail()
  }, [])

  useEffect(()=>{
    if(dataEvent){
      const endDate = moment(moment(dataEvent.event_end_datetime).format('YYYY-MM-DD'))
      const today = moment(moment(new Date()).format('YYYY-MM-DD'))
      const check = endDate.diff(today, 'days')
      if(check < 0){ // expired
        setModalRegister(false)
        setIsEnd(true)
      }else{
        setIsEnd(false)
        setModalRegister(true)
      }

      if(ls.get('@_tknid').data){
        getDataProfile()  
        getPurchaseTicket()
        setModalRegister(false)
      }
    }
  }, [dataEvent, ls.get('@_tknid').data])

  const available = (type) => {
    const idx = dataEvent.seat.seat_detail.filter(x => x.seat_detail_name===type)
    const dataSeat = idx[0]
    if(dataSeat){
      return parseInt(dataSeat.seat_detail_quantity) - parseInt(dataSeat.ticket.map(x=>x.ticket_status===1).length)
    }else{
      return 0
    }
  }

  const getPurchaseTicket = () => {
    setLoading(true)

    let token = ls.get('@_tknid').data
    Axios.get(`${API}/ticket?ticket_status=1&event_id=${dataEvent.event_id}`, { headers: { Authorization: `Bearer ${token}` } })
    .then((res) => {
      if(res.data.success){
        setDataTickt(res.data.data)
        setDataTicktSet((true))
      }else{
        swal("Error!", `${res.data.error}`, "error");
      }
    }).finally(()=>{
      setLoading(false)
    })
  }

  const getDataProfile = () => {
    setLoading(true)

    let token = ls.get('@_tknid').data
    Axios.get(`${API}/profile`, { headers: { Authorization: `Bearer ${token}` } })
    .then((res) => {
      if(res.data.success){
        ls.set('@_profile', { data: res.data.data })
      }
    }).finally(()=>{
      setLoading(false)
    })
  }

  const purchaseTicket = (seat_detail, data_ticket) => {
    setCompletion({
      name: '',
      msisdn: '',
      bio: '',
      company_name: '',
      industry: '',
      company_id: null,
      ms_city_id: null
    })

    setLoading(true)

    const profile = ls.get('@_profile').data
    const company = ls.get('@_companydata').data

    const industry_regex = new RegExp(" - ", "g")
    const industry_check = company?.company_name?.match(industry_regex)

    const error_profile = !profile.bio ? true : false
    const error_company = !company || !industry_check

    if(error_profile || error_company){
      var industry = ''
      var company_name = ''
      var company_id = null

      if(company){
        company_id = company.company_id

        if(industry_check){
          const split = company.company_name.split(" - ")
          industry = split[1]
          company_name = split[0]
        }else{
          company_name = company.company_name
        }
      }

      setCompletion({
        name: profile.name,
        msisdn: profile.msisdn,
        bio: profile.bio,
        ms_city_id: profile.ms_city_id?profile.ms_city_id:3171,
        company_name: company_name,
        company_id: company_id,
        industry: industry
      })

      setTicketSelected({seat_detail, data_ticket})
      setLoading(false)
      return false
    }

    setTicketSelected(null)

    let token = ls.get('@_tknid').data
    let inputbody = {
      user_id: localStorage.getItem('id'),
      seat_detail_id: seat_detail.seat_detail_id,
      seat_number: [""],
      ms_payment_id: '4',
      donation_amount: 0,
      custom_email: {
        referrer_from: 'ict',
        subject: `[BOOKED] ICT 2023 - ${data_ticket.title} ${data_ticket.keynote} - ${moment(seat_detail.seat_detail_datetime).format('dddd DD MMMM YYYY')}`,
        ticket_title: `ICT - 2023 ${data_ticket.keynote}`,
        ticket_date: moment(seat_detail.seat_detail_datetime).format('dddd DD MMMM YYYY'),
        ticket_class_text1: data_ticket.title,
        ticket_class_text2: `Speaker: ${data_ticket.speaker}`
      }
    }

    let options = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }

    Axios.post(`${API}/transaction/ticket`, inputbody, options)
    .then((res) => {
      if(res.data.success) {
        // berhasil purchase
        swal("Success!", "Ticket Successfully Purchased", "success");
        getEventDetail()
        getPurchaseTicket()
      }else{
        swal("Error!", `${res.data.error}`, "error");
      }
    }).catch((err) => {
      if (err.response.status === 401) {
        let tokenn = ls.get('@_tknid').data
        let rtokenn = ls.get('@_rtknid').data
        let formdata = new FormData()
        let options = {
          headers: {
            Authorization: `Bearer ${tokenn}`
          }
        }
        formdata.append("refresh_token", rtokenn)
        Axios.post(`${API}/refresh_token`, formdata, options, null)
        .then(res => {
          ls.set('@_tknid', { data: res.data.data.token })
          ls.set('@_rtknid', { data: res.data.data.refreshToken })
        }).catch(err => {
        })
      }
    }).finally(()=>{
      setLoading(false)
    })
  }

  useEffect(()=>{
    let token = ls.get('@_tknid').data
    if(token && dataTicketSet && dataTicket.length === 0){
      bookNow(DATA_MAIN)
    }
  }, [dataEvent, dataTicketSet])

  const renderMainButton = (data_ticket) => {
    const { ticket, keynote, speaker, speaker_title, materi } = data_ticket

    const check = dataTicket.findIndex(x=>x.ticket_name===ticket)
    const panel_selected = check >= 0 ? true : false

    return(
      <div className="text-white">
        {
          !isEnd ? (
            <div className="mt-2">
              {
                panel_selected?(
                  <button className="book-now booked" disabled>BOOKED</button>
                ):(
                  <button className={`book-now ${dataEvent && available(ticket)===0?"full":"white"}`} onClick={()=>bookNow(data_ticket)}>{dataEvent && available(ticket)===0?'FULL':'BOOK NOW'}</button>
                )
              }
              {
                dataEvent &&
                <div className="sisa-tiket mt-1"><strong>Remaining: {available(ticket)} Ticket</strong></div>
              }
            </div>
          ) : (
            <div>
              {
                materi && <a className="book-now white" href={materi} download>Download Materi</a>
              }
            </div>
          )
        }
      </div>
    )
  }

  const renderPanel = (data_ticket) => {
    const { title, ticket, keynote, speaker, speaker_title, materi, button } = data_ticket
    const check = dataTicket.findIndex(x=>x.ticket_name===ticket)
    const panel_selected = check >= 0 ? true : false

    return(
      <>
        <Row className="align-items-center">
          <Col><div className="subtitle">{title}</div></Col>
          {
            !isEnd ? (
              <Col xs={'auto'}>
                {
                  button &&
                  <>
                    {
                      panel_selected?(
                        <button className="book-now booked" disabled>BOOKED</button>
                      ):(
                        <button className={`book-now ${dataEvent && available(ticket)===0?"full":"enable"}`} onClick={()=>dataEvent && available(ticket)===0?null:bookNow(data_ticket)}>{dataEvent && available(ticket)===0?'FULL':'BOOK NOW'}</button>
                      )
                    }
                  </>
                }
              </Col>
            ) : (
              <Col xs={'auto'}>
                {
                  materi && <a className="book-now enable" href={materi} download>Download Materi</a>
                }
              </Col>
            )
          }
        </Row>
        <div className="panel-desc mt-2">"{keynote}"</div>
        {/* <div className="d-flex align-items-center">
          <div className="mr-1">
            <AccountCircle style={{width:50, height:50, color:'#ccc'}}/>
          </div>
          <div className="flex-fill">
            <div className="panel-speaker"><b>{speaker}</b></div>
            <div className="panel-speaker">{speaker_title}</div>
          </div>
        </div> */}
        <div className="panel-speaker"><b>Speaker: {speaker}</b></div>
        <div className="panel-speaker">{speaker_title}</div>
        
        {
          dataEvent && !isEnd && button &&
          <div className="sisa-tiket mt-2"><strong>Remaining: {available(ticket)} Ticket</strong></div>
        }
        
      </>
    )
  }

  // forget oassword
  const handleEmailRecover = email => event => {
    setEmailRecover({ ...emailRecover, [email]: event.target.value })
  }

  const onRecover = (e) => {
    e.preventDefault()
    setLoading(true)
    let formdata = new FormData();

    // let token = localStorage.getItem('token')  
    let token = ls.get('@_tknid').data
    let Headers = {
      headers: {
        "Content-Type": "multipart/form-data",
        "Authorization": `Bearer ${token}`
      },
    };

    formdata.append("email", emailRecover.email);

    Axios.post(`${API}/recover_password`, formdata, Headers)
    .then((res) => {
      if (res.data.error) {
        swal("Error!", `${res.data.error}`, "error");
      } else if (res.data.success) {
        swal({
          title: "Success!",
          text: `${res.data.success}`,
          icon: "success",
          button: "Oke",
        })
        .then((redirect) => {
          setModalRecover(false)
          setModalLogin(true)
        });
      }
    }).catch((error) => {
      if (error.response) {
        swal("Error", `${error.response.data.error}`, "error");
			} else if (error.request) {
        swal("Error", `${error.request}`, "error");
			} else {
        swal("Error", `Something Wrong`, "error");
			}
    }).finally(()=>{
      setLoading(false)
    })
  }

  return(
    <body style={{backgroundImage: `url(${bg})`}}>
      <Helmet>
        <link rel="icon" href={favicon} /> 
        <title>ICT Solution Day 2023</title>
        <meta name="title" content="ICT Solution Day 2023" />
        <meta property="og:title" content="ICT Solution Day 2023" />
        <meta property="keyword" content="ICT Solution Day 2023" />
        <meta name="description" content="Navigationg The Future Together" />
        <meta property="og:description" content="Navigationg The Future Together" />
      </Helmet>
      <Navbar bg="light" variant="light" sticky="top" className="d-flex">
        <Navbar.Brand>
          <img
            alt=""
            src={logo}
            className="d-inline-block align-top main-logo"
          />
        </Navbar.Brand>
        <div className="flex-fill"/>
        {
          ls.get('@_tknid').data &&
          <div className="mr-3 desktop">
            <span className="mr-3"><strong>{localStorage.getItem('name')}</strong></span>
            <Button
              variant="outline-dark"
              onClick={()=>{
                localStorage.clear()
                sessionStorage.clear()
                window.location.reload(false)
              }}
            >
              Log Out
            </Button>
          </div>
        }
        <Navbar.Brand className="mr-0">
          <img
            alt=""
            src={logo2}
            className="d-inline-block align-top main-logo"
          />
        </Navbar.Brand>
      </Navbar>

      {
        ls.get('@_tknid').data &&
        <div className="mobile">
          <div className="p-2 text-white align-items-center d-flex">
            <span className="mr-3 flex-fill"><strong>Hi, {localStorage.getItem('name')}</strong></span>
            <Button
              variant="outline-light"
              onClick={()=>{
                localStorage.clear()
                sessionStorage.clear()
                window.location.reload(false)
              }}
            >
              Log Out
            </Button>
          </div>
        </div>
      }

      <img
        alt=""
        src={banner}
        width="100%"
        className="desktop"
      />

      <img
        alt=""
        src={banner_mobile}
        width="100%"
        className="mobile"
      />

      <div className="bg-main text-white">
        <Container className="pt-5 pb-5">
          <div className="title text-center">Event Overview</div>
          <div className="mt-4">
            <p className="text-center">By incorporating the latest G20 summit theme: “Recover Together, Recover Stronger”, this symposium can create a platform for industry leaders, experts and stakeholders to come together and exchange ideas. This is to explore how IT solutions can contribute to the Indonesia’s economic recovery and building a stronger future.</p>
            <p className="text-center">Here, ICT and its partners will share solutions, best practices, exploring emerging trends, building networks, and showcase innovative IT solutions to accelerate recovery and promote long-term growth. All to provide future partners the opportunities for interdisciplinary collaboration.</p>
            <p className="text-center">This event is hosted by many experts from across financial services system and security to identify, adapt, and help financial institutions improve their operations, reduce costs, and enhance customer experiences. It will feature presentations on innovative technologies such as on-premises cloud infrastructure, artificial intelligence, and machine learning, as well as discussions on how these technologies can be applied in the finance sector.</p>
          </div>

          <Row className="mt-5 pt-5 justify-content-center mb-5">
            <Col xs={4} md={'auto'}>
              <div className="key-topic">
                <img src={topic1} alt=""/>
              </div>
              <div className="key-topic-title mt-3 text-center">Sustainable Growth</div>
            </Col>
            <Col xs={4} md={'auto'}>
              <div className="key-topic">
                <img src={topic2} alt=""/>
              </div>
              <div className="key-topic-title mt-3 text-center">Financial Inclusion</div>
            </Col>
            <Col xs={4} md={'auto'}>
              <div className="key-topic">
                <img src={topic3} alt =""/>
              </div>
              <div className="key-topic-title mt-3 text-center">Data Protection</div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="text-white">
        <Container className="pt-5 mt-5 mb-5">
          <Row>
            <Col xs={12} md={12} lg={12}>
              <img src={speaker} className="speaker-img desktop" alt=""/>
              <img src={speaker_mobile} className="speaker-img mobile" alt=""/>
            </Col>
            <Col xs={12} md={12} lg={12}>
              <img src={speaker2} className="speaker-img desktop" alt=""/>
              <img src={speaker2_mobile} className="speaker-img mobile" alt=""/>
            </Col>
            <Col xs={12} md={12} lg={12}>
              <img src={speaker3} className="speaker-img desktop" alt=""/>
              <img src={speaker3_mobile} className="speaker-img mobile" alt=""/>
            </Col>
            {/* <Col xs={12} md={12} lg={5}>
              <div className="keynote-wrap">
                <div className="keynote-header">
                  <div className="titile"><b>Our Exhibitor</b></div>
                </div>
                <div className="keynote-body">
                  <Row>
                    {
                      exhibitor.map((item, index) => (
                        <Col xs={6} className="mt-4" key={index}>
                          <div className="d-flex">
                            <img src={item.image} className="image-profile" alt=""/>
                            <div className="flex-fill ml-2">
                              <div className="subtitle">{item.name}</div>
                              <div className="panel-speaker mb-2">{item.desc}</div>
                              <a href={item.materi} download className='book-now enable'>Learn More</a>
                            </div>
                          </div>
                        </Col>
                      ))
                    }
                  </Row>
                </div>
              </div>
            </Col> */}
          </Row>
        </Container>
      </div>

      <div className="schedule-wrapper">
        <Container className="pt-5 pb-5">
          <img className="title" src={scheduleText} alt=""/>

          {/* AGENDA DESKTOP VIEW */}
          <table className="mt-5 pt-5 agenda">
            <tbody>
              <tr>
                <td style={{height:30}}/>
                <td className="agenda-main">
                  <div className="agenda-main-wrapper">
                  </div>
                </td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr className="header">
                <td className="text-center">Time</td>
                <td className="bg-main-2 text-white text-left">MAIN EVENT</td>
                <td className="border-right-line" style={{width:'22%'}}>Break-Out<br/>Room 1</td>
                <td className="border-right-line" style={{width:'22%'}}>Break-Out<br/>Room 2</td>
                <td style={{width:'22%'}}>Break-Out<br/>Room 3</td>
              </tr>
              <tr className="bg-gray">
                <td className="bg-main-2 text-center text-white time middle">10.01 - 12.00</td>
                <td rowSpan={3} className="bg-main-2 text-white" style={{verticalAlign:'top'}}>
                  <div className="subtitle">Opening Speech</div>
                  {/* <div className="d-flex align-items-center">
                    <div className="mr-1">
                      <AccountCircle style={{width:50, height:50, color:'#ccc'}}/>
                    </div>
                    <div className="flex-fill">
                      <div className="panel-speaker mt-1"><b>Bapak Lucas Gunawan</b></div>
                      <div className="panel-speaker">Managing Director Inti Corpora Teknologi</div>
                    </div>
                  </div> */}
                  <div className="panel-speaker mt-1"><b>Bapak Lucas Gunawan</b></div>
                  <div className="panel-speaker">Managing Director Inti Corpora Teknologi</div>
                  <div className="subtitle mt-3">Overview about ICT</div>
                  {/* <div className="d-flex align-items-center">
                    <div className="mr-1">
                      <AccountCircle style={{width:50, height:50, color:'#ccc'}}/>
                    </div>
                    <div className="flex-fill">
                      <div className="panel-speaker mt-1"><b>Bapak Yoseph Dwi Cahaya</b></div>
                      <div className="panel-speaker">Director Infracom Technology</div>
                    </div>
                  </div> */}
                  <div className="panel-speaker mt-1"><b>Speaker: Bapak Yoseph Dwi Cahaya</b></div>
                  <div className="panel-speaker">Director Infracom Technology</div>
                  <div className="subtitle mt-3">Keynote Speaker</div>
                  <div className="panel-speaker mt-1"><b>Speaker: Bapak Djamin Edison Nainggolan</b></div>
                  <div className="panel-speaker">Direktur Eksekutif Asosiasi Sistem Pembayaran Indonesia (ASPI)</div>
                  <div className="panel-speaker mt-2"><b>Speaker: Bapak Adrian Putra Taslim</b></div>
                  <div className="panel-speaker">Head of Digital IFG Life</div>
                  <div className="panel-speaker mt-2"><b>Speaker: Bapak Faizal Rachman</b></div>
                  <div className="panel-speaker">Deputi Direktur Bidang Pelayanan Elektronik dan Pengaduan, BP Jamsostek</div>
                </td>
                <td className="border-right-line"></td>
                <td className="border-right-line"></td>
                <td></td>
              </tr>
              <tr>
                <td className="text-center time middle">13.30 - 14.30</td>
                <td className="border-right-line">
                  { renderPanel(DATA_PANEL_1) }
                </td>
                <td className="border-right-line">
                  { renderPanel(DATA_PANEL_4) }
                </td>
                <td>
                  { renderPanel(DATA_PANEL_9) }
                </td>
              </tr>
              <tr className="bg-gray">
                <td className="text-center time middle">14.30 - 15.30</td>
                <td className="border-right-line">
                  { renderPanel(DATA_PANEL_2) }
                </td>
                <td className="border-right-line p-0">
                  <div className="pl-3 pr-3 pt-2 pb-2">
                    { renderPanel(DATA_PANEL_5) }
                  </div>
                  <div className="line-ntaf"/>
                  <div className="pl-3 pr-3 pt-2 pb-2">
                    { renderPanel(DATA_PANEL_6) }
                  </div>
                </td>
                <td>
                  { renderPanel(DATA_PANEL_10) }
                </td>
              </tr>
              <tr className="last">
                <td className="text-center time middle">16.00 - 17.00</td>
                <td className="bg-main-2 text-white justify">
                </td>
                <td className="border-right-line">
                  { renderPanel(DATA_PANEL_3) }
                </td>
                <td className="border-right-line p-0">
                  <div className="pl-3 pr-3 pt-2 pb-2">
                    { renderPanel(DATA_PANEL_7) }
                  </div>
                  <div className="line-ntaf"/>
                  <div className="pl-3 pr-3 pt-2 pb-2">
                    { renderPanel(DATA_PANEL_8) }
                  </div>
                </td>
                <td>
                  { renderPanel(DATA_PANEL_11) }
                </td>
              </tr>
              <tr style={{height:130}}>
                <td className="bg-gray text-center time middle" style={{borderBottomLeftRadius:20}}>17.00 - 20.00</td>
                <td className="agenda-main">
                  <div className="agenda-footer-wrapper pl-3 pr-3 pb-3">
                    <div className="subtitle">Doorprize</div>
                    <div className="subtitle">Closing</div>
                    <div className="subtitle">Dinner</div>
                    { renderMainButton(DATA_MAIN) }
                  </div>
                </td>
                <td colSpan={3} className="bg-gray" style={{borderBottomRightRadius:20}}>
                  <div className="panel-desc pt-4"><b>Closing Speech by Bapak Cliffton Nursalim</b> - GM Sales Commercial Infracom Technology</div>
                </td>
              </tr>
            </tbody>
          </table>
          {/* END AGENDA DESKTOP VIEW */}

          {/* AGENDA MOBILE VIEW */}
          <div className="agenda-mobile mt-5 p-3">
            <div>
              <div className="time"><strong>TIME: 10.01 - 12.00</strong></div>
              <div className="bg-main-2 p-3 mt-1 text-white" style={{borderRadius:10}}>
                <div className="subtitle">MAIN EVENT</div>
                <div className="subtitle">Opening Speech</div>
                {/* <div className="d-flex align-items-center">
                  <div className="mr-1">
                    <AccountCircle style={{width:50, height:50, color:'#ccc'}}/>
                  </div>
                  <div className="flex-fill">
                    <div className="panel-speaker mt-1"><b>Speaker: Bapak Lucas Gunawan</b></div>
                    <div className="panel-speaker">Managing Director Inti Corpora Teknologi</div>
                  </div>
                </div> */}
                <div className="panel-speaker mt-1"><b>Speaker: Bapak Lucas Gunawan</b></div>
                <div className="panel-speaker">Managing Director Inti Corpora Teknologi</div>
                <div className="subtitle mt-3">Overview about ICT</div>
                {/* <div className="d-flex align-items-center">
                  <div className="mr-1">
                    <AccountCircle style={{width:50, height:50, color:'#ccc'}}/>
                  </div>
                  <div className="flex-fill">
                    <div className="panel-speaker mt-1"><b>Speaker: Bapak Yoseph Dwi Cahaya</b></div>
                    <div className="panel-speaker">Director Infracom Technology</div>
                  </div>
                </div> */}
                <div className="panel-speaker mt-1"><b>Speaker: Bapak Yoseph Dwi Cahaya</b></div>
                <div className="panel-speaker">Director Infracom Technology</div>
                <div className="subtitle mt-3">Keynote Speaker</div>
                <div className="panel-speaker mt-1"><b>Speaker: Bapak Djamin Edison Nainggolan</b></div>
                  <div className="panel-speaker">Direktur Eksekutif Asosiasi Sistem Pembayaran Indonesia (ASPI)</div>
                  <div className="panel-speaker mt-2"><b>Speaker: Bapak Adrian Putra Taslim</b></div>
                  <div className="panel-speaker">Head of Digital IFG Life</div>
                  <div className="panel-speaker mt-2"><b>Speaker: Bapak Faizal Rachman</b></div>
                  <div className="panel-speaker">Deputi Direktur Bidang Pelayanan Elektronik dan Pengaduan, BP Jamsostek</div>
                { renderMainButton(DATA_MAIN) }
              </div>
            </div>
            <div className="mt-3">
              <div className="time"><strong>TIME: 13.30 - 14.30</strong></div>

              <div className="breakout-card p-3 mt-1">
                <div className="subtitle mb-2">BREAK-OUT ROOM 1</div>
                { renderPanel(DATA_PANEL_1) }
                <hr/>
                <div className="subtitle mb-2">BREAK-OUT ROOM 2</div>
                { renderPanel(DATA_PANEL_4) }
                <hr/>
                <div className="subtitle mb-2">BREAK-OUT ROOM 3</div>
                { renderPanel(DATA_PANEL_9) }
              </div>
            </div>
            <div className="mt-3">
              <div className="time"><strong>TIME: 14.30 - 15.30</strong></div>

              <div className="breakout-card p-3 mt-1">
                <div className="subtitle mb-2">BREAK-OUT ROOM 1</div>
                { renderPanel(DATA_PANEL_2) }
                <hr/>
                <div className="subtitle mb-2">BREAK-OUT ROOM 2</div>
                { renderPanel(DATA_PANEL_5) }
                <div className="mt-2"/>
                { renderPanel(DATA_PANEL_6) }
                <hr/>
                <div className="subtitle mb-2">BREAK-OUT ROOM 3</div>
                { renderPanel(DATA_PANEL_10) }
              </div>
            </div>
            <div className="mt-3">
              <div className="time"><strong>TIME: 16.00 - 17.00</strong></div>

              <div className="breakout-card p-3 mt-1">
                <div className="subtitle">BREAK-OUT ROOM 1</div>
                { renderPanel(DATA_PANEL_3) }
                <hr/>
                <div className="subtitle">BREAK-OUT ROOM 2</div>
                { renderPanel(DATA_PANEL_7) }
                <div className="mt-2"/>
                { renderPanel(DATA_PANEL_8) }
                <hr/>
                <div className="subtitle">BREAK-OUT ROOM 3</div>
                { renderPanel(DATA_PANEL_11) }
              </div>
            </div>
            <div className="mt-3">
              <div className="time"><strong>TIME: 17.00 - 20.00</strong></div>
              <div className="bg-main-2 p-3 mt-1 text-white" style={{borderRadius:10}}>
                <div className="subtitle">Doorprize</div>
                <div className="subtitle">Closing</div>
                <div className="subtitle">Dinner</div>
                <div className="panel-desc pt-4 text-white"><b>Closing Speech by Bapak Cliffton Nursalim</b> - GM Sales Commercial Infracom Technology</div>
              </div>
            </div>
          </div>
          {/* END AGENDA MOBILE VIEW */}
        </Container>
        <Container className="pt-5">
          <div className="card border-20 doorprize">
            <div className="d-flex">
              <img src={doorprize} alt="doorprize"/>
              <div className="flex-fill">
                <div className="title">Win Doorprize:</div>
                <div className="subtitle">Apple watch, external hardisk and many more.</div>
              </div>
            </div>
          </div>
        </Container>
        <Container className="pt-5 pb-5">
          <div className="card border-20 p-4">
            <Row>
              <Col>
                <div className="sponsor">Sponsored by:</div>
              </Col>
              <Col xs={12} md={'auto'}>
                Click the logo to learn more.
              </Col>
            </Row>
            <div className="row mt-4 justify-content-center align-items-center">
              <div className="col-md-2 col-sm-6 col-6">
                <a href="https://drive.google.com/drive/u/1/folders/1eWkMwRQhP02qp7x6Klz6mA-cyOLKp26V" target="_blank">
                  <img src={sponsor_1} width="100%" alt="" className="sponsor-item"/>
                </a>
              </div>
              <div className="col-md-2 col-sm-6 col-6">
                <a href="https://drive.google.com/drive/u/1/folders/1gfvXv8fuYb0-etcdB39YZfgF_dqG4Nqp" target="_blank">
                  <img src={sponsor_14} width="100%" alt="" className="sponsor-item"/>
                </a>
              </div>
              <div className="col-md-2 col-sm-6 col-6">
                <a href="https://drive.google.com/drive/u/1/folders/1vUaJFL1NuWkxcPNXk_2bY5BRdavWLhYC" target="_blank">
                  <img src={sponsor_11} width="100%" alt="" className="sponsor-item"/>
                </a>
              </div>
              <div className="col-md-2 col-sm-6 col-6">
                <a href="https://drive.google.com/drive/u/1/folders/1VUwMyErrEhFDkLBCxEJMtseKgph3_QUW" target="_blank">
                  <img src={sponsor_5} width="100%" alt="" className="sponsor-item"/>
                </a>
              </div>
              <div className="col-md-2 col-sm-6 col-6">
                <a href="https://drive.google.com/drive/u/1/folders/142-g4Y6IIVYjxXTbFbDJdzTwh2I_RPga" target="_blank">
                  <img src={sponsor_3} width="100%" alt="" className="sponsor-item"/>
                </a>
              </div>
              <div className="col-md-2 col-sm-6 col-6">
                <a href="https://drive.google.com/drive/u/1/folders/1fDCyTdAiOIKV2gx2OHliFa6Silaj_Ud6" target="_blank">
                  <img src={sponsor_18} width="100%" alt="" className="sponsor-item"/>
                </a>
              </div>
              <div className="col-md-2 col-sm-6 col-6">
                <a href="https://drive.google.com/drive/u/1/folders/1KJa98lTDmKfF4fS2zYUImL4mynZQaRAV" target="_blank">
                  <img src={sponsor_16} width="100%" alt="" className="sponsor-item"/>
                </a>
              </div>
              <div className="col-md-2 col-sm-6 col-6">
                <a href="https://drive.google.com/drive/u/1/folders/10gtXSj6UVqf-XQ3y8SQIlGZjtLFTz17P" target="_blank">
                  <img src={sponsor_15} width="100%" alt="" className="sponsor-item"/>
                </a>
              </div>
              <div className="col-md-2 col-sm-6 col-6">
                <a href="https://drive.google.com/drive/u/1/folders/1AcPhHU8UurX19ETW3VF4VrEFhJVZVJIi" target="_blank">
                  <img src={sponsor_10} width="100%" alt="" className="sponsor-item"/>
                </a>
              </div>
              <div className="col-md-2 col-sm-6 col-6">
                <a href="https://drive.google.com/drive/u/1/folders/13ty_i6aeW5E3cAluxJOKYQM9RLuKuwZH" target="_blank">
                  <img src={sponsor_4} width="100%" alt="" className="sponsor-item"/>
                </a>
              </div>
              <div className="col-md-2 col-sm-6 col-6">
                <a href="https://drive.google.com/drive/u/1/folders/106VfKZNPgEthVOy3jaWhOFB4eqHbKiTr" target="_blank">
                  <img src={sponsor_9} width="100%" alt="" className="sponsor-item"/>
                </a>
              </div>
              <div className="col-md-2 col-sm-6 col-6">
                <a href="https://drive.google.com/drive/u/1/folders/18RkAX_iMDFr1TwTkwLtX0IdQnTzL9xI0" target="_blank">
                  <img src={sponsor_17} width="100%" alt="" className="sponsor-item"/>
                </a>
              </div>
              <div className="col-md-2 col-sm-6 col-6">
                <a href="https://drive.google.com/drive/u/1/folders/1zvbFoF66ESIP5e3Kmo-E5ebMgN8HyB-W" target="_blank">
                  <img src={sponsor_13} width="100%" alt="" className="sponsor-item"/>
                </a>
              </div>
              <div className="col-md-2 col-sm-6 col-6">
                <a href="https://drive.google.com/drive/u/1/folders/1pY9U2QOGapndajtUNFVP4dAB308Ds-uZ" target="_blank">
                  <img src={sponsor_6} width="100%" alt="" className="sponsor-item"/>
                </a>
              </div>
              <div className="col-md-2 col-sm-6 col-6">
                <a href="https://drive.google.com/drive/u/1/folders/13upkRRUNwJPSGt_0CmFNx_Xkdh234L1M" target="_blank">
                  <img src={sponsor_12} width="100%" alt="" className="sponsor-item"/>
                </a>
              </div>
              <div className="col-md-2 col-sm-6 col-6">
                <a href="https://drive.google.com/drive/u/1/folders/1g5hl601NFuL4fDTMkAy12MdB-JtY5G7e" target="_blank">
                  <img src={sponsor_7} width="100%" alt="" className="sponsor-item"/>
                </a>
              </div>
              <div className="col-md-2 col-sm-6 col-6">
                <a href="https://drive.google.com/drive/u/1/folders/1oPxVUaV9xa4AxKUAIL5pXAy9Mw-oOb5m" target="_blank">
                  <img src={sponsor_8} width="100%" alt="" className="sponsor-item"/>
                </a>
              </div>
            </div>
          </div>
        </Container>
      </div>

      <div className="footer">
        <div>© Copyright <b>ICT 2023</b>. All Rights Reserved</div>
      </div>

      <Modal show={modalRegister} centered onHide={()=>setModalRegister(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Registration</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={onRegister} >
            <TextField
              size="small"
              type='text'
              onChange={handleChangeSignup('name')}
              variant="outlined"
              required
              fullWidth
              id="name"
              label="Full Name"
              autoFocus
            />
            <TextField
              className="mt-3"
              size="small"
              variant="outlined"
              required
              id="email"
              fullWidth
              onChange={handleChangeSignup('email')}
              label="Email"
              type="email"
            />
            <TextField
              className="mt-3"
              size="small"
              variant="outlined"
              required
              id="msisdn"
              fullWidth
              onChange={handleChangeSignup('msisdn')}
              label="Phone"
            />
            <TextField
              className="mt-3 border-10"
              size="small"
              variant="outlined"
              required
              fullWidth
              onChange={handleChangeSignup('bio')}
              label="Job Title"
            />
            <TextField
              className="mt-3"
              size="small"
              variant="outlined"
              required
              fullWidth
              onChange={handleChangeSignup('company_name')}
              label="Company"
            />
            <TextField
              className="mt-3"
              size="small"
              variant="outlined"
              required
              fullWidth
              onChange={handleChangeSignup('industry')}
              label="Industry"
            />
            <Button type='submit' className="w-100 mt-2 book-now enable btn-form mt-4 border-10">Register</Button>

            <div className="subtitle mt-3 d-flex justify-content-center">
              <div>Already have an account?</div>
              <div 
                className="main-color-2 ml-2 pointer"
                onClick={()=>{
                  setModalLogin(true)
                  setModalRegister(false)
                }}
              >
                <strong>Login Now</strong>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      <Modal show={modalLogin} centered onHide={()=>setModalLogin(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Login</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={onLogin} >
            <TextField
              className="mt-3 border-10"
              size="small"
              variant="outlined"
              required
              fullWidth
              onChange={handleChangeLogin('email')}
              label="Email"
            />
          
            <TextField
              className="mt-3 border-10"
              size="small"
              variant="outlined"
              required
              fullWidth
              value={signinEmail.password}
              label="Password"
              type="password"
              autoComplete="current-password"
              onChange={handleChangeLogin('password')}
            />
            {
              captcha ?
                <Row>
                  <Col>
                    <TextField
                      className="mt-3 border-10"
                      size="small"
                      variant="outlined"
                      required
                      fullWidth
                      label="Input captcha"
                      type="captcha"
                      autoComplete="current-captcha"
                      onChange={handleChangeLogin('captcha')}
                    />
                  </Col>
                  <Col cs={'auto'}>
                    {ReactHtmlParser(captcha)}
                  </Col>
                </Row>
                :
                null
            }
            <Button type='submit' className="w-100 mt-2 book-now enable btn-form mt-4 border-10">Login</Button>
            <div className="d-flex flex-row mt-2">
              <InfoRounded fontSize="small"/>
              <div className="panel-speaker ml-2">If you have just registered for this event, a default password has been set. Please enter the email you registered with and Login. Use forgot password to change your default password and relogin here.</div>
            </div>
          </form>

          <Row className="mt-3 align-items-center justify-content-between subtitle">
            <Col xs={'auto'}>
              <div 
                className="main-color-2 ml-2 pointer"
                onClick={()=>{
                  setModalLogin(false)
                  setModalRecover(true)
                }}
              >
                <strong>Forgot Password</strong>
              </div>
            </Col>
            <Col xs={'auto'}>
              <div className="d-flex">
                <div>Create a New Account?</div>
                <div 
                  className="main-color-2 ml-2 pointer"
                  onClick={()=>{
                    setModalLogin(false)
                    setModalRegister(true)
                  }}
                >
                  <strong>Register Here</strong>
                </div>
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      <Modal show={modalRecover} centered onHide={()=>setModalRecover(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Forgot Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>Enter the registered e-mail. We'll send you a link to reset your password.</div>
          <form onSubmit={onRecover} >
            <TextField
              size="small"
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              onChange={handleEmailRecover('email')}
              autoComplete="email"
              autoFocus
            />
            
            <Button type='submit' className="w-100 book-now enable btn-form mt-4 border-10">Send</Button>
          </form>

          <div 
            className="main-color-2 ml-2 pointer mt-3"
            onClick={()=>{
              setModalRecover(false)
              setModalLogin(true)
            }}
          >
            <strong>Back to Login</strong>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={ticketSelected?true:false} centered onHide={()=>setTicketSelected(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Data Completion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={onCompletion} >
            <TextField
              size="small"
              type='text'
              value={completion.name}
              onChange={handleChangeCompletion('name')}
              variant="outlined"
              required
              fullWidth
              id="name"
              label="Full Name"
              autoFocus
            />
            <TextField
              className="mt-3"
              size="small"
              variant="outlined"
              required
              id="msisdn"
              fullWidth
              value={completion.msisdn}
              onChange={handleChangeCompletion('msisdn')}
              label="Phone"
            />
            <TextField
              className="mt-3 border-10"
              size="small"
              variant="outlined"
              required
              fullWidth
              value={completion.bio}
              onChange={handleChangeCompletion('bio')}
              label="Job Title"
            />
            <TextField
              className="mt-3"
              size="small"
              variant="outlined"
              required
              fullWidth
              value={completion.company_name}
              onChange={handleChangeCompletion('company_name')}
              label="Company"
            />
            <TextField
              className="mt-3"
              size="small"
              variant="outlined"
              required
              fullWidth
              value={completion.industry}
              onChange={handleChangeCompletion('industry')}
              label="Industry"
            />
            <Button type='submit' className="w-100 mt-2 book-now enable btn-form mt-4 border-10">Submit</Button>
          </form>
        </Modal.Body>
      </Modal>

      <Modal className="loading" show={loading} centered>
        <Spinner animation="border" variant="primary" />
        <div className="text-primary mt-2">Loading...</div>
      </Modal>
    </body>
  )
}