import React, { useEffect, useState, } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Box, Paper, InputBase, Divider, IconButton, Card, Button } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { FilterList, Close, } from '@material-ui/icons';
// import InputLabel from '@material-ui/core/InputLabel';
// import FormControl from '@material-ui/core/FormControl';
// import NativeSelect from '@material-ui/core/NativeSelect';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import {
    KeyboardDatePicker,
} from '@material-ui/pickers';
import { useDispatch, useSelector } from 'react-redux';
import {
    getCategoryEvent,
    getKotaList,
    getSearchedEventAll,
    klikSearch,
    getEventByKeyword,
    klikSearchByKeyword
} from './../redux'
import moment from 'moment'
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
// import { Form } from 'react-bootstrap'
// import { Typeahead } from 'react-bootstrap-typeahead';
// // import DatePicker from 'react-date-picker';


const materialTheme = createMuiTheme({
    palette: {
        primary: { main: "#9E69D2" },
    },
});





const useStyles = makeStyles((theme) => ({
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 380,
        borderRadius: "10px"
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        height: 28,
        margin: 4,
    },
    formControl: {
        margin: theme.spacing(1),
        width: "100%"
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    option: {
        color: "black"
    }
}));

const SearchEvent = () => {
    const classes = useStyles();

    const [openFilter, setopenFilter] = useState(false);
    const [keyword, setkeyword] = useState();


    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getCategoryEvent())
        dispatch(getKotaList())
    }, [dispatch]);

    const category = useSelector(state => state.category_event.category_event)
    const kotalist = useSelector(state => state.category_event.kota_list)

    // const [CategoryID, setCategoryID] = useState()
    // const [KotaID, setKotaID] = useState()
    // const [DateValue, setDateValue] = useState()
    const [selectedDate, setSelectedDate] = useState(null);

    const optionKota = {
        options: kotalist,
        getOptionLabel: (option) => option.ms_city_name
    };
    const [kotaValue, setkotaValue] = React.useState(null);

    const optionCategory = {
        options: category,
        getOptionLabel: (option) => option.category_event_name
    };
    const [categoryValue, setcategoryValue] = React.useState(null);


    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    // const handleChangeCategory = (event) => {
    //     // console.log("event target", event)
    //     setCategoryID(event.target.value);
    // };

    // const handleChangeKota = (event) => {
    //     setKotaID(event.target.value);
    // };



    // const renderOptCategory = () => {
    //     return category.map((data, index) => {
    //         return (
    //             <option key={index} value={data.category_event_id} > { data.category_event_name}</option >
    //         )
    //     })
    // }

    // const renderOptKota = () => {
    //     return kotalist.map((data, index) => {
    //         return (
    //             <option key={index} value={data.ms_city_id} > { data.ms_city_name}</option >
    //         )
    //     })
    // }


    const onClickSearch = () => {
        dispatch(getSearchedEventAll({
            'category': categoryValue === null ? '' : categoryValue.category_event_id,
            'kota': kotaValue === null ? '' : kotaValue.ms_city_id,
            'date': selectedDate === null ? '' : moment(selectedDate).format('YYYY-MM-DD')
        }))
        dispatch(klikSearch(true))
        // setCategoryID()
        // setKotaID()
        setkotaValue(null)
        setcategoryValue(null)
        handleDateChange()
        setSelectedDate(null)
        setopenFilter(false)
    }

    // console.log("kategori id", CategoryID)
    return (
        <React.Fragment>
            <Container maxWidth="lg">
                {
                    openFilter ? (
                        <Box pt={2}>
                            <Card style={{ padding: "15px" }}>
                                <Paper className={classes.root} style={{ width: "100%" }}>
                                    <IconButton className={classes.iconButton} aria-label="menu">
                                        <SearchIcon />
                                    </IconButton>
                                    <InputBase
                                        disabled={true}
                                        className={classes.input}
                                        placeholder="Apa yang Anda cari"
                                    />
                                    <Divider className={classes.divider} orientation="vertical" />
                                    <IconButton color="primary" className={classes.iconButton} aria-label="directions">
                                        <Close onClick={() => {
                                            setopenFilter(false)
                                        }} />
                                    </IconButton>


                                </Paper>
                                <Box pt={2} style={{ width: "100%" }}>
                                    {/* <Box className={classes.root} style={{ width: "100%" }}>
                                        <FormControl className={classes.formControl}>
                                            <InputLabel shrink htmlFor="age-native-label-placeholder"> Cari Event </InputLabel>
                                            <NativeSelect onChange={handleChangeCategory}>
                                                <option value="">Semua Event</option>
                                                {renderOptCategory()}
                                            </NativeSelect>
                                        </FormControl>
                                    </Box>
                                    <Box className={classes.root} style={{ width: "100%" }}>
                                        <FormControl className={classes.formControl}>
                                            <InputLabel shrink htmlFor="age-native-label-placeholder">Area</InputLabel>
                                            <NativeSelect onChange={handleChangeKota}>
                                                <option value="">Semua Lokasi</option>
                                                {renderOptKota()}
                                            </NativeSelect>
                                        </FormControl>

                                    </Box> */}


                                    <Box>
                                        <Autocomplete
                                            {...optionCategory}
                                            id="category-opt"
                                            value={categoryValue}
                                            classes={{
                                                option: classes.option
                                            }}
                                            onChange={(event, newValue) => {
                                                // console.log("ini", newValue)
                                                setcategoryValue(newValue);
                                            }}
                                            renderInput={(params) => <TextField {...params}
                                                label="Semua Event" margin="normal" />}
                                        />
                                    </Box>

                                    <Box>
                                        <Autocomplete
                                            {...optionKota}
                                            id="kota-opt"
                                            value={kotaValue}
                                            classes={{
                                                option: classes.option
                                            }}
                                            onChange={(event, newValue) => {
                                                setkotaValue(newValue);
                                            }}
                                            renderInput={(params) => <TextField {...params}
                                                label="Semua Lokasi" margin="normal" />}
                                        />
                                    </Box>

                                    <Box className={classes.root} justifyContent="center" alignItems="center" style={{ width: "100%" }}>
                                        <ThemeProvider theme={materialTheme}>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <KeyboardDatePicker
                                                    disableToolbar
                                                    variant="inline"
                                                    format="MM/dd/yyyy"
                                                    margin="normal"
                                                    id="date-picker-inline"
                                                    label="Kapan Aja"
                                                    value={selectedDate}
                                                    onChange={handleDateChange}
                                                    style={{ color: '#9E69D2', width: "100%" }}
                                                    KeyboardButtonProps={{
                                                        'aria-label': 'change date',
                                                    }}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </ThemeProvider>
                                    </Box>


                                </Box>
                                <Box pt={2} display="flex" justifyContent="center" alignItems="center">
                                    <Button style={{ backgroundColor: '#9E69D2', color: "white", textTransform: "capitalize", width: "50%" }}
                                        onClick={onClickSearch}>Cari Event</Button>
                                </Box>
                            </Card>
                        </Box>
                    )
                        :
                        (<Box pt={2}>
                            <Paper component="form" className={classes.root} style={{ width: "100%" }}>
                                <IconButton onClick={() => {
                                    if (keyword) {
                                        dispatch(klikSearchByKeyword(keyword))
                                        dispatch(getEventByKeyword(keyword, ""))
                                        window.location.href = `/search/${keyword}`
                                    }
                                }} className={classes.iconButton} aria-label="menu">
                                    <SearchIcon />
                                </IconButton>
                                <InputBase
                                    // disabled={true}
                                    className={classes.input}
                                    placeholder="Apa yang Anda Cari"
                                    onChange={(e) => setkeyword(e.target.value)}
                                    onKeyPress={event => {
                                        if (event.key === 'Enter') {
                                            event.preventDefault();
                                            if (keyword) {
                                                dispatch(klikSearchByKeyword(keyword))
                                                dispatch(getEventByKeyword(keyword, ""))
                                                window.location.href = `/search/${keyword}`
                                            }
                                        }
                                    }}
                                />

                                <Divider className={classes.divider} orientation="vertical" />
                                <IconButton color="primary" className={classes.iconButton} aria-label="directions">
                                    <FilterList onClick={() => {
                                        setopenFilter(true)
                                    }} />
                                </IconButton>

                            </Paper>

                        </Box>)
                }

            </Container>
        </React.Fragment>
    )
}

export default SearchEvent