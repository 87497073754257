import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import './../assets/css/main.css'
import Container from '@material-ui/core/Container'
import logowhite from './../assets/img/logowhite.png'
import logocolor from './../assets/img/logocolor.png'


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  [theme.breakpoints.up('xs')]: {
    display: 'none'
  },

  [theme.breakpoints.up('sm')]: {
    display: 'none'

  },
  [theme.breakpoints.up('md')]: {
    display: 'block'

  },
  [theme.breakpoints.up('lg')]: {
    display: 'block',



  },


}));

export default function AppBarCongrats() {
  const classes = useStyles();

  useEffect(() => {
    document.addEventListener('scroll', () => {
      if (window.scrollY < 70) {
        setHeaderBackground('fixed')
      } else if (window.scrollY < 100) {
        setHeaderBackground('opacity25%')
      } else if (window.scrollY < 200) {
        setHeaderBackground('opacity50%')
      } else if (window.scrollY < 225) {
        setHeaderBackground('opacity60%')
      } else if (window.scrollY < 250) {
        setHeaderBackground('opacity75%')
      } else if (window.scrollY < 300) {
        setHeaderBackground('opacity80%')
      } else if (window.scrollY < 400) {
        setHeaderBackground('opacity90%')
      } else {
        setHeaderBackground('action')
      }
    })
  })

  const [headerBackground, setHeaderBackground] = useState('fixed')




  return (

    <React.Fragment>



      <div className={classes.root}>
        <AppBar
          position="fixed"
          style={{
            backgroundColor: headerBackground === 'fixed' ? '#fff' : '#9e69d2',
            paddingTop: '5px',
            paddingBottom: '5px'

          }}


        >
          <Container maxWidth='lg'>
            <Toolbar>

              <Typography variant="h6" className={classes.title} style={{ color: `${headerBackground === 'fixed' ? 'white' : 'black'}` }}>
                <a href='/'>
                  {
                    headerBackground === 'fixed' ?
                      <img
                        src={logocolor}
                        width='150px'
                        alt='logo'
                      />

                      :
                      <img
                        src={logowhite}
                        width='150px'
                        alt='logo'
                      />


                  }

                </a>
              </Typography>


            </Toolbar>
          </Container>
        </AppBar>
      </div>
    </React.Fragment>
  );
}
