// import 'date-fns';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import {Grid, Box} from '@material-ui/core';
import Container from '@material-ui/core/Container';
import './../assets/css/main.css'
import SectionPillsSearched from './SectionPillsSearchedEvent';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
    rootdiv: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(5)
    },
    divmargin: {
        paddingLeft: '20px',
        paddingRight: '20px'
    },
    buttontab: {
        borderRadius: '30px'
    }
  }));

  
  const SearchedEventDashboard = () => {
 
    const classes = useStyles();
     

    const show = useSelector( state => state.searched_event.show )


    return (
    <div className={classes.rootdiv} style={{display: show ? 'block' : 'none' }} >
      <Container maxWidth="lg">
        <div className={classes.divargin}>            
            <Grid container >
                <Typography variant='h5'>
                    <Box fontWeight={600} color='#9e69d2'>Pencarian Event</Box>
                </Typography>
            </Grid>  
            <Box pt={2} style={{height: 'fit-content'}}>
                <SectionPillsSearched/>
            </Box>  

        </div>
      </Container>
    </div>

    )
}

export default SearchedEventDashboard;