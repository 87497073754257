
import React, { useState, useEffect, useRef } from 'react';
import { useParams, withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import {
    Container,
    Box,
    Grid,
    Paper,
    Button,
    Divider,
    TextField,
    Slide,
    Tooltip,
    Zoom,
    IconButton,
    Card,
    CardMedia,
    Link,
    DialogActions
} from '@material-ui/core';

import { Alert, AlertTitle } from '@material-ui/lab';
import Axios from 'axios';
import { API } from '../../config'
import moment from 'moment'
import ReactHtmlParser from 'react-html-parser';
import Slider from 'react-slick'
import Skeleton from '@material-ui/lab/Skeleton';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import swal from 'sweetalert'
import { useDispatch } from 'react-redux';
import useStyles from '../stylesComponent/SingleComStyle'
// import Countdown from 'react-countdown'
import ButtonGroup from '@material-ui/core/ButtonGroup';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
// import { SRLWrapper } from "simple-react-lightbox";
import StackGrid, { transitions } from "react-stack-grid";
import { withSize } from 'react-sizeme'
import Tidaktersedia from '../../assets/img/statekosong.png'
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
// import { InlineShareButtons } from 'sharethis-reactjs';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import Disqus from 'disqus-react'
// import ShareIcon from '@material-ui/icons/Share';
import CloseIcon from '@material-ui/icons/HighlightOff';
import Meta from '../Util/MetaDecorator'
import LinkIcon from '@material-ui/icons/Link';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import WAIcon from '@material-ui/icons/WhatsApp';
import Email from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import IgIcon from '@material-ui/icons/Instagram';
import TwitIcon from '@material-ui/icons/Twitter';
import FbIcon from '@material-ui/icons/Facebook';
import YouTubeIcon from '@material-ui/icons/YouTube';
import Carousel from "react-multi-carousel";
import * as SecureLs from 'secure-ls'
import ClipLoader from "react-spinners/ClipLoader";
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import {
    KeyboardDatePicker,
} from '@material-ui/pickers';
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"
import {
    // EmailShareButton,
    // EmailIcon,
    FacebookIcon,
    FacebookShareButton,
    LineShareButton,
    LineIcon,
    // TelegramShareButton,
    // TelegramIcon,
    TwitterShareButton,
    TwitterIcon,
    WhatsappShareButton,
    WhatsappIcon
} from "react-share";

import Agendaicon from '../../assets/img/calender.png'
import Speakericon from '../../assets/img/speaker.png'

import { getEventbyTag } from '../../redux'
import jwt_decode from 'jwt-decode'


const ls = new SecureLs();

const materialTheme = createMuiTheme({
    palette: {
        primary: { main: "#9E69D2" },
    },
});


// const { scaleDown } = transitions;

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const eventStyle = makeStyles((theme) => ({
    rootdiv: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(5)
    },
    divmargin: {
        paddingLeft: '20px',
        paddingRight: '20px'
    },
    buttontab: {
        borderRadius: '30px'
    },
    buttonCategory: {
        textTransform: "capitalize",
        borderRadius: '30px',
        fontSize: '12px',
    },
    root: {
        marginTop: "30px",
        maxWidth: 380,
        borderRadius: '10px 10px 0 0',
        position: 'relative'
    },
    media: {
        height: 150,
        width: '100%',
        filter: 'brightness(0.8)'
    },
    info: {
        position: 'absolute',
        top: 0,
        left: 0
    },
    boxinfo: {

        opacity: 0.9,
        borderRadius: '15px',
    },
    gridList: {
        flexWrap: 'nowrap',
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
    },
    share: {
        position: 'absolute',
        top: 0,
        right: 0
    },
    boxshare: {
        display: 'flex'
    },
    rootcatevent: {
        [theme.breakpoints.up('xs')]: {
            display: 'none'
        },
        [theme.breakpoints.up('sm')]: {
            display: 'none'
        },
        [theme.breakpoints.up('md')]: {
            display: 'block'
        },
        [theme.breakpoints.up('lg')]: {
            display: 'block'
        },
        [theme.breakpoints.up('xl')]: {
            display: 'block'
        }
    },
    rootcateventmob: {
        [theme.breakpoints.up('xs')]: {
            display: 'block'
        },
        [theme.breakpoints.up('sm')]: {
            display: 'block'
        },
        [theme.breakpoints.up('md')]: {
            display: 'none'
        },
        [theme.breakpoints.up('lg')]: {
            display: 'none'
        },
        [theme.breakpoints.up('xl')]: {
            display: 'none'
        }
    },

}));

const SingleEventComponent = (props) => {


    const { history, size } = props
    const styleEvent = eventStyle()

    const classes = useStyles();

    const [dataLoaded, setDataLoaded] = useState(false)
    const [dataEvent, setDataEvent] = useState([])
    const [dataOrganizer, setDataOrganizer] = useState([])
    const [dataAgenda, setDataAgenda] = useState([])
    const [eventGallery, setEventGallery] = useState()

    const [ticketSeat, setTicketSeat] = useState([])
    const [loading, setLoading] = useState(false)
    const [Tag, setTag] = useState([]);

    const [company, setcompany] = useState([]);
    const [organizer, setOrganizer] = useState();
    const [logoOrganizer, setlogoOrganizer] = useState({ source: null });
    const [moreEventOrganizer, setmoreEventOrganizer] = useState();
    const [loadButton, setloadButton] = useState(false);
    const [kategori, setkategori] = useState(0);

    const [selectedDate, setSelectedDate] = useState(null);
    const handleDateChange = (date) => {
        setSelectedDate(date);
    };


    const dataslug = useParams()
    const inputRef = useRef(dataslug.slug)

    // dispatch action
    const dispatch = useDispatch()

    const disqusShortname = "events-4"
    let inputSlug = inputRef.current

    // console.log('event slug', document.location.href)
    const disqusConfig = {
        url: `${document.location.href}`,
        identifier: `${inputSlug}-mobversion`,
        title: "Diskusi Eventories"
    }


    let startEvent = new Date(dataEvent.event_start_datetime)
    let endEvent = new Date(dataEvent.event_end_datetime)
    let dateEnd = new Date(dataEvent.event_ticket_sale_end_datetime)
    let dateStart = new Date(dataEvent.event_ticket_sale_start_datetime)
    let dateNow = new Date(Date.now())

    const [dataSeat, setDataSeat] = React.useState({
        dialog: false,
        seat_id: '',
        seat_detail_name: '',
        seat_detail_price: '',
        seat_detail_datetime: '',
        seat_detail_id: '',
        seat_detail_maximum_purchased_quantity: '',
        seat_detail_quantity: '',
        ticket: [],
        type_seat: '',
        type_price: '',
        min_donation: ""
    });

    const [fullWidth] = React.useState(true);
    const [maxWidth] = React.useState('md');

    const [pickSeat, setPickSeat] = React.useState([]);
    const [unreservedSeat, setUnreservedSeat] = React.useState([]);
    const [donationAmount, setDonationAmount] = useState(0)
    const [descTab, setdescTab] = useState(true);
    const [agendaTab, setagendaTab] = useState(false);
    const [penyelenggaraTab, setpenyelenggaraTab] = useState(false);
    const [galleryTab, setgalleryTab] = useState(false);
    const [ticketTab, setticketTab] = useState(false);

    const [errorMax, setErrorMax] = useState(false)
    const [errorChoose, setErrorChoose] = useState(false)

    const [openImg, setopenImg] = useState(false);


    const [openImageGallery, setopenImageGallery] = React.useState(false);
    const [openImage, setopenImage] = useState();
    const [openIndexImg, setopenIndexImg] = useState(-1);


    const handleImageGallery = () => {
        setopenImageGallery(false);
    };

    const handleImg = () => {
        setopenImg(false);
    };

    let priceSeat = pickSeat.length * dataSeat.seat_detail_price
    let priceUnreserved = unreservedSeat.length * dataSeat.seat_detail_price
    let totalPriceSeat = parseInt(priceSeat) + parseInt(priceUnreserved) + parseInt(donationAmount)


    const [seatTicket, setSeatTicket] = React.useState()
    const [openPrivate, setOpenPrivate] = useState(false);

    const handleDialog = () => {
        setOpenPrivate(false)
        setprivateEvent({
            slug: '',
            access: ''
        })
    }

    const [privateEvent, setprivateEvent] = useState({
        slug: inputSlug,
        access: ''
    })
    const [loadingprivate, setloadingprivate] = useState(false);

    const handlePrivate = i => event => {
        setprivateEvent({
            ...privateEvent
            , [i]: event.target.value
        })
    }

    const CheckToken = () => {
        let rtoken = ls.get('@_rtknid').data
        let tokenn = ls.get('@_tknid').data
        if (tokenn) {
            var jwt = jwt_decode(tokenn);
            var today = new Date().getTime();
            var jwt_exp = jwt.exp * 1000 - (1000 * 20); // sebelum 20 detik
            // console.log("jwt", jwt_exp - today)

            if (jwt_exp < today) {
                // request
                let formdata = new FormData()
                let options = {
                    headers: {
                        Authorization: `Bearer ${tokenn}`
                    }
                }
                formdata.append("refresh_token", rtoken)
                const refreshToken = Axios.post(`${API}/refresh_token`, formdata, options, null)
                if (refreshToken) {
                    ls.set('@_tknid', { data: refreshToken.data.data.token })
                    ls.set('@_rtknid', { data: refreshToken.data.data.refreshToken })
                }
            } else {
                console.log("tes")

            }
        }
    }

    useEffect(() => {
        let inputSlug = inputRef.current

        const loadSingleEvent = async () => {
            try {
                const testing = await CheckToken()

                let token = ls.get('@_tknid').data
                if (token) {
                    setLoading(true)
                    const res = await Axios.get(`${API}/event/slug/${inputSlug}`, { headers: { Authorization: `Bearer ${token}` } })
                        .then((res) => {
                            if (res.data.error === "Valid access phrase is needed to view this event") {
                                setLoading(false)
                                // alert('ini access')
                                // alert(res.data.error)
                                setOpenPrivate(true)
                            }
                            else if (res.data.error && res.data.error !== "Valid access phrase is needed to view this event") {
                                window.location.href = "/notfound"
                            } else {

                                setDataEvent(res.data.data)
                                setkategori(res.data.data.category_venue_id)
                                setTag(res.data.data.tag)
                                setDataOrganizer(res.data.data.organizer)
                                setDataAgenda(res.data.data.event_agenda)
                                setEventGallery(res.data.data.gallery)
                                // setTicketSeat(res.data.data.seat.seat_detail)
                                if (res.data.data.seat) {
                                    setTicketSeat(res.data.data.seat.seat_detail)
                                }

                                Axios.get(`${API}/organizer/${res.data.data.organizer_id}`)
                                    .then(res1 => {
                                        setOrganizer(res1.data.data)
                                        setcompany(res1.data.data.company)
                                        if (res1.data.data.organizer_logo === null) {
                                            setlogoOrganizer({ source: "" })
                                            setLoading(false)
                                            setDataLoaded(true)
                                        } else {
                                            Axios.get(`${API}/organizer/${res1.data.data.organizer_id}/logo`, { responseType: 'arraybuffer' })
                                                .then(res2 => {
                                                    const base64 = btoa(
                                                        new Uint8Array(res2.data).reduce(
                                                            (data, byte) => data + String.fromCharCode(byte),
                                                            '',
                                                        ),
                                                    );
                                                    setlogoOrganizer({ source: "data:;base64," + base64 })

                                                    Axios.get(`${API}/event/all?organizer_id=${res.data.data.organizer_id}`)
                                                        .then(res3 => {
                                                            let dataevent = res3.data.data.filter(e => e.event_id !== res.data.data.event_id)
                                                            // console.log("dataev", dataevent)
                                                            // console.log(res.data.data.event_id, "<<<")
                                                            setmoreEventOrganizer(dataevent)
                                                            setLoading(false)
                                                            setDataLoaded(true)
                                                        })
                                                }).catch(err => {
                                                })
                                        }

                                    }).catch(err => {
                                    })

                            }
                        }).catch((err) => {
                            // console.log(err)
                            setLoading(false)
                        }).catch((err) => {
                            console.log(err)
                            if (err.response.status === 401) {
                                let tokenn = ls.get('@_tknid').data
                                let rtokenn = ls.get('@_rtknid').data
                                let formdata = new FormData()
                                let options = {
                                    headers: {
                                        Authorization: `Bearer ${tokenn}`
                                    }
                                }
                                formdata.append("refresh_token", rtokenn)
                                Axios.post(`${API}/refresh_token`, formdata, options, null)
                                    .then(res => {
                                        ls.set('@_tknid', { data: res.data.data.token })
                                        ls.set('@_rtknid', { data: res.data.data.refreshToken })
                                    }).catch(err => {
                                    })
                            }
                        })
                    return res;
                } else {
                    setLoading(true)
                    const res = await Axios.get(`${API}/event/slug/${inputSlug}`)
                        .then((res) => {
                            if (res.data.error === "Valid access phrase is needed to view this event") {
                                setLoading(false)
                                setOpenPrivate(true)
                            }
                            else if (res.data.error && res.data.error !== "Valid access phrase is needed to view this event") {
                                window.location.href = "/notfound"
                            } else {

                                setDataEvent(res.data.data)
                                if (res.data.data.seat) {

                                    setTicketSeat(res.data.data.seat.seat_detail)
                                }
                                setkategori(res.data.data.category_venue_id)
                                setTag(res.data.data.tag)
                                setDataOrganizer(res.data.data.organizer)
                                setDataAgenda(res.data.data.event_agenda)
                                setEventGallery(res.data.data.gallery)


                                Axios.get(`${API}/organizer/${res.data.data.organizer_id}`)
                                    .then(res1 => {
                                        setOrganizer(res1.data.data)
                                        setcompany(res1.data.data.company)
                                        if (res1.data.data.organizer_logo === null) {
                                            setlogoOrganizer({ source: "" })
                                            setLoading(false)
                                            setDataLoaded(true)
                                        } else {
                                            Axios.get(`${API}/organizer/${res1.data.data.organizer_id}/logo`, { responseType: 'arraybuffer' })
                                                .then(res2 => {
                                                    const base64 = btoa(
                                                        new Uint8Array(res2.data).reduce(
                                                            (data, byte) => data + String.fromCharCode(byte),
                                                            '',
                                                        ),
                                                    );
                                                    setlogoOrganizer({ source: "data:;base64," + base64 })
                                                    Axios.get(`${API}/event/all?organizer_id=${res.data.data.organizer_id}`)
                                                        .then(res3 => {
                                                            let dataevent = res3.data.data.filter(e => e.event_id !== res.data.data.event_id)

                                                            setmoreEventOrganizer(dataevent)
                                                            setLoading(false)
                                                            setDataLoaded(true)
                                                        })
                                                }).catch(err => {
                                                })
                                        }

                                    }).catch(err => {
                                    })
                            }
                        }).catch((err) => {
                            // console.log(err)
                            setLoading(false)
                        })
                    return res;

                }

            } catch (error) {
                // console.log(error)
                ls.removeAll()
                localStorage.clear()
                sessionStorage.clear()
                window.location.href = "/"
            }
        }
        loadSingleEvent()

    }, [dispatch]);


    const clickPurhaseTicket = () => {
        let token = ls.get('@_tknid').data
        let inputbody = {
            user_id: localStorage.getItem('id'),
            seat_detail_id: dataSeat.seat_detail_id,
            seat_number: pickSeat.length === 0 ? unreservedSeat : pickSeat,
            ms_payment_id: '4',
            donation_amount: parseInt(donationAmount)
        }
        let options = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        Axios.post(`${API}/transaction/ticket`, inputbody, options, null)
            .then((res) => {
                if (res) {
                    // console.log('res data',)
                    setloadButton(false)

                    if (res.data.token) {
                        localStorage.setItem('_pay_tkn', res.data.token)
                        swal("Redirecting...", "", "success");
                        setTimeout(() => {
                            swal.close()
                            history.push('/checkout')
                        }, 2000);
                    } else if (res.data.error) {
                        swal("Error!", `${res.data.error}`, "error");
                    } else {
                        closeDialog()
                        swal("Berhasil!", "Tiket Berhasil Di Beli", "success");
                        setTimeout(() => {
                            swal.close()
                            history.push('/myevent')
                        }, 2000);
                    }
                }
            }).catch((err) => {
                // console.log(err.response.data)

            })
    }

    function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style, display: "block", background: "red" }}
                onClick={onClick}
            />
        );
    }

    function SamplePrevArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style, display: "block", background: "green" }}
                onClick={onClick}
            />
        );
    }

    let settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 2000,
                settings: {
                    slidesToShow: ticketSeat.filter(e => e.seat_detail_active_status_name === "active").length < 2 ? 1 :
                        ticketSeat.filter(e => e.seat_detail_active_status_name === "active").length < 3 ? 2 : 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 1042,
                settings: {
                    slidesToShow: ticketSeat.filter(e => e.seat_detail_active_status_name === "active").length < 2 ? 1 :
                        ticketSeat.filter(e => e.seat_detail_active_status_name === "active").length < 2 ? 1 : 2,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 720,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
        ]
    };

    let settingsfilter = {
        dots: true,
        infinite: true,
        speed: 500,
        arrows: true,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 2000,
                settings: {
                    slidesToShow: (ticketSeat.filter(e => e.seat_detail_active_status_name === "active" && (moment(e.seat_detail_datetime).format('DD/MM/YYYY') === moment(selectedDate).format('DD/MM/YYYY')))).length < 2 ? 1 :
                        ticketSeat.filter(e => e.seat_detail_active_status_name === "active" && (moment(e.seat_detail_datetime).format('DD/MM/YYYY') === moment(selectedDate).format('DD/MM/YYYY'))).length < 3 ? 2 : 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 1042,
                settings: {
                    slidesToShow: ticketSeat.filter(e => e.seat_detail_active_status_name === "active" && (moment(e.seat_detail_datetime).format('DD/MM/YYYY') === moment(selectedDate).format('DD/MM/YYYY'))).length < 2 ? 1 :
                        (ticketSeat.filter(e => e.seat_detail_active_status_name === "active" && (moment(e.seat_detail_datetime).format('DD/MM/YYYY') === moment(selectedDate).format('DD/MM/YYYY')))).length < 3 ? 1 : 2,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 720,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
        ]
    };


    const renderContinueCheckout = () => {
        if (dataLoaded) {
            let start_date_ticket = new Date(dataEvent.event_ticket_sale_start_datetime)

            if (start_date_ticket < dateNow) {
                return (
                    <>

                        {(seatTicket && seatTicket.length !== 0 && dataSeat.type_price === "DONATION" || (seatTicket && seatTicket.length === 0 && dataSeat.type_price === "DONATION")) ?
                            <Box pt={2} textAlign='center'>
                                <Box pb={1} fontSize={14} id="fontStyle" >DONATION</Box>
                                <TextField
                                    autoFocus
                                    label="Rp."
                                    type="number"
                                    id="standard-size-small"
                                    onChange={(e) => setDonationAmount(e.target.value)}
                                    size="small"
                                    variant="outlined"
                                />
                                <Box pt={1} fontSize={12} id="fontStyle" >Min Donation Rp. {dataSeat.min_donation.toLocaleString()} </Box>
                            </Box> : ""}

                        <Box textAlign='center' pb={2} pt={2} fontSize={15}>Sub Total {' '}
                            <strong>IDR {' '}
                                {totalPriceSeat.toLocaleString()}
                            </strong>
                        </Box>

                        <Box pt={2} pb={3} textAlign='center'>
                            {
                                loadButton ?
                                    <Button
                                        variant='contained'
                                        style={{ backgroundColor: '#9e69d2', color: '#fff' }}
                                    >
                                        <ClipLoader color="#FFFF" size={20} />

                                    </Button>
                                    :
                                    <Button
                                        onClick={ErrorContineCheckout}
                                        variant='contained'
                                        style={{ backgroundColor: '#9e69d2', color: '#fff' }}
                                    >
                                        Continue to checkout
                        </Button>

                            }

                        </Box>
                    </>

                )
            } else {
                return (
                    <>
                        <Box pt={2} pb={3} textAlign='center'>
                            <Button
                                onClick={closeDialog}
                                variant='contained'
                                style={{ backgroundColor: '#9e69d2', color: '#fff' }}
                            >
                                Close
                        </Button>
                        </Box>
                    </>
                )
            }
        }
    }

    const renderTicketDate = () => {
        if (dataLoaded) {
            let start_date_ticket = new Date(dataEvent.event_ticket_sale_start_datetime)
            if (start_date_ticket < dateNow) {
                return (
                    <>
                        <Grid container>
                            <Grid item xs={6}>
                                <Box pt={1} fontWeight={100} fontSize={13}>Sale End Date</Box>
                                <Box fontWeight={600} fontSize={15} color='#e669ab'>
                                    {moment(dataEvent.event_ticket_sale_end_datetime).format('DD/MM/YYYY')}
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                <Box pt={1} fontWeight={100} fontSize={13}>Sale End Time</Box>
                                <Box fontWeight={600} fontSize={15} color='#e669ab' >
                                    {moment(dataEvent.event_ticket_sale_end_datetime).format('h:mm A')}
                                </Box>
                            </Grid>
                        </Grid>
                    </>
                )
            } else {
                return (
                    <>
                        <Grid container>
                            <Grid item xs={6}>
                                <Box fontWeight={100} fontSize={13}>SALE START DATE</Box>
                                <Box fontWeight={600} color='#e669ab'>
                                    {moment(dataEvent.event_ticket_sale_start_datetime).format('DD/MM/YYYY')}
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                <Box fontWeight={100} fontSize={13}>SALE START TIME</Box>
                                <Box fontWeight={600} color='#e669ab'>
                                    {moment(dataEvent.event_ticket_sale_start_datetime).format('h:mm A')}
                                </Box>
                            </Grid>
                        </Grid>
                    </>
                )
            }
        }
    }


    const renderBTN = () => {
        if (seatTicket) {
            if (seatTicket.length !== 0 && dataSeat.type_seat === "RESERVED") {
                return (
                    seatTicket.map((data, i) => (
                        <Grid item key={i}>
                            <Box key={i} pl={1} pr={1} pt={1} pb={1} >
                                <Button
                                    disabled={data.color === '#d1d1d1' | '#9e69d2' ? true : false}
                                    variant='contained'
                                    size="small"
                                    style={{ backgroundColor: data.color, paddingRight: "0px", paddingLeft: "0px", }}
                                    onClick={() => {
                                        let check = pickSeat.indexOf(data.seat_number)
                                        if ((pickSeat.length === dataSeat.seat_detail_maximum_purchased_quantity && check > -1) || check > -1) {
                                            pickSeat.splice(check, 1)
                                            setSeatTicket([
                                                ...seatTicket,
                                                {
                                                    seatTicket: seatTicket[i].color = 'white',
                                                    seatTicket: seatTicket[i].colorName = 'black',

                                                }
                                            ])

                                            setSeatTicket(seatTicket.filter((data, i) => { return i !== seatTicket.length }))
                                            setErrorMax(false)
                                        }
                                        else if (pickSeat.length === dataSeat.seat_detail_maximum_purchased_quantity) {
                                            setErrorMax(true)
                                            if (errorMax) {
                                                setSeatTicket([
                                                    ...seatTicket,
                                                    {
                                                        seatTicket: seatTicket[i].color = 'white',
                                                        seatTicket: seatTicket[i].colorName = 'black',

                                                    }
                                                ])
                                                setSeatTicket(seatTicket.filter((data, i) => { return i !== seatTicket.length }))
                                                setErrorMax(false)
                                            }
                                        } else {
                                            setSeatTicket([
                                                ...seatTicket,
                                                {
                                                    seatTicket: seatTicket[i].color = '#9e69d2',
                                                    seatTicket: seatTicket[i].colorName = 'white',

                                                }
                                            ])

                                            setSeatTicket(seatTicket.filter((data, i) => { return i !== seatTicket.length }))
                                            setErrorChoose(false)
                                            if (pickSeat.indexOf(data.seat_number) > -1) {
                                                setSeatTicket([
                                                    ...seatTicket,
                                                    {
                                                        seatTicket: seatTicket[i].color = 'white',
                                                        seatTicket: seatTicket[i].colorName = 'black',

                                                    }
                                                ])
                                                setSeatTicket(seatTicket.filter((data, i) => { return i !== seatTicket.length }))
                                            } else {
                                                setPickSeat([
                                                    ...pickSeat,
                                                    data.seat_number,
                                                ]);
                                            }
                                        }
                                    }}
                                ><div style={{ color: `${data.colorName}` }}>{data.seat_number}</div></Button>
                            </Box>
                        </Grid>

                    ))

                )
            } else if (dataSeat.type_seat === "UNRESERVED") {
                let totalSeatLeft = parseInt(dataSeat.seat_detail_quantity) - parseInt(seatTicket.length) - parseInt(unreservedSeat.length)
                return (
                    <Grid container direction="column" alignItems='center' >
                        <Box pt={4} pb={2} fontSize={16} fontWeight="bold" color="#9e69d2" id='fontStyle' >
                            Total Kursi Tersisa : {totalSeatLeft}
                        </Box>

                        <Box pb={2}>
                            <ButtonGroup>
                                <Button
                                    disabled={unreservedSeat.length === 0 ? true : false}
                                    aria-label="reduce"
                                    onClick={() => {
                                        setUnreservedSeat(unreservedSeat.slice(0, -1))
                                    }}
                                >
                                    <RemoveIcon fontSize="small" />
                                </Button>
                                <Button>{unreservedSeat.length}</Button>
                                <Button
                                    aria-label="increase"
                                    disabled={unreservedSeat.length === dataSeat.seat_detail_maximum_purchased_quantity ? true : false}
                                    onClick={() => {
                                        setUnreservedSeat([...unreservedSeat, ""])
                                        // console.log('klik tambah bro')
                                    }}
                                >
                                    <AddIcon fontSize="small" />
                                </Button>
                            </ButtonGroup>
                        </Box>

                        <Box pb={2} id="fontStyle" fontSize={13} >Maksimal Pembelian kursi : {dataSeat.seat_detail_maximum_purchased_quantity} </Box>

                    </Grid>
                )
            }
        }
    }



    const ErrorMaxBook = () => {
        if (errorMax) {
            return (
                <Box fontSize={10} pl={2} pr={2} pt={2} pb={2} style={{ fontSize: '10px' }}>
                    <Alert
                        onClose={() => {
                            setErrorMax(false)
                        }}
                        severity="error" style={{ fontSize: '10px' }} >Maksimal Pembelian Tiket {' '}
                        {dataSeat.seat_detail_maximum_purchased_quantity} kursi!
                    </Alert>
                </Box>
            )
        }
    }


    const showErrorChooseTicket = () => {
        if (errorChoose === true) {
            return (
                <Box fontSize={10} pl={2} pr={2} pt={2} pb={2} style={{ fontSize: '10px' }}>
                    <Alert onClose={() => {
                        setErrorChoose(false)
                    }} textAlign='center' severity="error" style={{ fontSize: '10px' }}>Silahkan pilih seat terlebih dahulu!</Alert>
                </Box>
            )
        }
    }

    const ErrorContineCheckout = () => {
        if (pickSeat.length !== 0 || unreservedSeat.length !== 0) {
            setloadButton(true)

            clickPurhaseTicket()
            setErrorChoose(false)
            setErrorMax(false)
            let idcheck = dataSeat.seat_detail_price + priceSeat / localStorage.getItem('id')
            let dateNowTicket = Date.now()

            localStorage.setItem('_datEtIcket', JSON.stringify(dateNowTicket))
            localStorage.setItem('_idCheckOut', idcheck)
            localStorage.setItem('data_seat', JSON.stringify(pickSeat))
            localStorage.setItem('data_seat_unreserved', JSON.stringify(unreservedSeat))
            localStorage.setItem('seat_price', dataSeat.seat_detail_price)
            localStorage.setItem('total_price', totalPriceSeat)
            localStorage.setItem('price_seat', priceSeat)
            localStorage.setItem('price_seat_unreserved', priceUnreserved)
            localStorage.setItem('donation_amount', donationAmount)
            localStorage.setItem('category_ticket', dataSeat.seat_detail_name)
            localStorage.setItem('event_name', dataEvent.event_name)
            localStorage.setItem('event_start_datetime', moment(dataEvent.event_start_datetime).format('D MMM YYYY'))
            localStorage.setItem('event_end_datetime', moment(dataEvent.event_end_datetime).format('D MMM YYYY'))
            localStorage.setItem('event_start_datetime_time', moment(dataEvent.event_start_datetime).format('LT'))
            localStorage.setItem('event_end_datetime_time', moment(dataEvent.event_end_datetime).format('LT'))

            let place = ""
            if (kategori === 3) {
                place = "ONLINE EVENT"
                // localStorage.setItem('event_place', "ONLINE EVENT")
            } else if (dataEvent.event_location_detail !== null || dataEvent.event_location_detail.length > 0) {
                place = `${dataEvent.event_location} - ${dataEvent.event_location_detail}`
            } else {
                place = `${dataEvent.event_location}`
            }
            localStorage.setItem('event_place', place)
            localStorage.setItem('organizer_name', dataOrganizer.organizer_name)

            let logo = ""
            if (logoOrganizer.source === null || logoOrganizer.source === "") {
                logo = ""
            } else {
                logo = logoOrganizer.source
            }
            localStorage.setItem('logo', logo)


        } else {
            setErrorChoose(true)
            setTimeout(() => {
                setErrorChoose(false)
            }, 3000);
        }
    }

    const closeDialog = () => {
        setDataSeat({ ...dataSeat, dialog: false, seat_detail_quantity: '' })
        setPickSeat([])
        setErrorChoose(false)
        setErrorMax(false)
        setDonationAmount(0)
        setUnreservedSeat([])
        setSeatTicket([])
        // state = []
    }

    // setting for image lightbox
    const options = {
        buttons: {
            backgroundColor: 'rgba(30,30,36,0.8)',
            showAutoplayButton: false,
            showDownloadButton: false,
            showNextButton: false,
            showPrevButton: false,
        },
        caption: {
            showCaption: false
        },
        thumbnails: {
            showThumbnails: false,
        }
    }



    const getPrivateEvent = async () => {
        setloadingprivate(true)
        await Axios.get(`${API}/event/slug/${privateEvent.slug}/${privateEvent.access}`)
            .then(res => {
                if (res.data.error) {
                    swal("Error!", `${res.data.error}`, "error");
                    setloadingprivate(false)
                } else {
                    setloadingprivate(false)
                    if (loadingprivate === false) {
                        window.location.href = `/detail-event/${privateEvent.slug}/${privateEvent.access}`;
                        handleDialog()
                    }
                }
            }).catch(err => { })
    }

    function extractContent(s, space) {
        var span = document.createElement('span');
        span.innerHTML = s;
        if (space) {
            var children = span.querySelectorAll('*');
            for (var i = 0; i < children.length; i++) {
                if (children[i].textContent)
                    children[i].textContent += ' ';
                else
                    children[i].innerText += ' ';
            }
        }
        return [span.textContent || span.innerText].toString().replace(/ +/g, ' ');
    };

    let descrip = extractContent(`${dataEvent.event_description}`, true)

    let token = ls.get('@_tknid').data
    // console.log('token', token)


    return (
        <React.Fragment>
            <Meta title={dataEvent.event_name ? `${dataEvent.event_name} | Eventories.com` : "Eventories"} description={descrip ? descrip.slice(0, 150) : "Eventories"} />


            <Dialog onClose={() => window.location.href = '/'} aria-labelledby="simple-dialog-title" open={openPrivate} aria-labelledby="form-dialog-title" className="popup_private">
                <DialogTitle id="form-dialog-title">Private Event</DialogTitle>
                <DialogContent>
                    <DialogContentText className={classes.textAccess}>
                        Kode akses diperlukan untuk melihat Event ini. Silakan masukkan kode akses:
                </DialogContentText>

                    <TextField
                        className={classes.textField}
                        margin="dense"
                        id="access"
                        label="Kode"
                        type="text"
                        onChange={handlePrivate('access')}
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => window.location.href = '/'}
                        color="primary">
                        Tutup
                    </Button>
                    {
                        loadingprivate ?
                            <Button style={{ backgroundColor: "#9E69D2" }} onClick={getPrivateEvent} color="primary">
                                <ClipLoader color="#FFFF" size={20} />
                            </Button> :
                            <Button style={{ backgroundColor: "#9E69D2", color: "#ffff", }} onClick={getPrivateEvent} color="primary">
                                Buka
                </Button>
                    }
                </DialogActions>
            </Dialog>


            <Dialog open={openImageGallery} onClose={handleImageGallery} TransitionComponent={Transition}>
                {
                    openIndexImg === 0 ?
                        null :
                        <Box
                            className="btnarrow"
                            style={{
                                position: 'absolute',
                                zIndex: 2,
                                cursor: 'pointer',
                                top: '50%',
                                left: '8px',
                                padding: 0
                            }}
                        >
                            <Button onClick={() => setopenIndexImg(openIndexImg - 1)}
                                // style={{ borderRadius: '50%', width: 'auto' }}
                                style={{ height: '50px' }}
                            >
                                <ArrowBackIosIcon fontSize='small' style={{ color: 'white' }} />
                            </Button>
                        </Box>
                }

                {
                    openImageGallery && openIndexImg > -1 && eventGallery && eventGallery.length > 0 ?
                        <img src={`${eventGallery[openIndexImg].gallery_image_url}`} width="100%" height="auto" />
                        :
                        null
                }

                {
                    eventGallery && eventGallery.length - openIndexImg === 1 ?
                        null
                        :
                        <Box
                            style={{
                                position: 'absolute',
                                zIndex: 2,
                                cursor: 'pointer',
                                top: '50%',
                                right: '2px',
                            }}>
                            <Button onClick={() => setopenIndexImg(openIndexImg + 1)}
                                // style={{ borderRadius: '50%', width: 'auto' }}
                                style={{ height: '50px' }}
                            >
                                <ArrowForwardIosIcon fontSize='small' style={{ color: 'white' }} />
                            </Button>
                        </Box>
                }
            </Dialog>


            <Dialog open={openImg} onClose={handleImg} TransitionComponent={Transition}>
                <TransformWrapper>
                    {({ zoomIn, zoomOut, resetTransform }) => (
                        <React.Fragment>
                            <TransformComponent>
                                <img src={`${dataEvent.event_image_url}`} width="100%" height="auto" alt='image-event' />
                            </TransformComponent>
                        </React.Fragment>
                    )}
                </TransformWrapper>
            </Dialog>

            {/* popup ticket mob */}
            <Dialog
                className="mobile"
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                open={dataSeat.dialog}
                onClose={closeDialog}>
                <DialogTitle aria-labelledby="customized-dialog-title">
                    <DialogContentText>
                        <Box style={{ position: 'absolute', top: 0, right: 0, zIndex: 5 }} >
                            <IconButton onClick={closeDialog}>
                                <CloseIcon />
                            </IconButton>
                        </Box>

                        <Box textAlign='center' pt={2} fontSize={20} fontWeight={600} >{dataEvent.event_name}

                        </Box>
                        {
                            kategori > 0 ?
                                <>
                                    {
                                        kategori === 3 ?
                                            <Box textAlign='center' fontSize={14} fontWeight={100}>
                                                ONLINE EVENT
                                                </Box>
                                            :

                                            <Box textAlign='center' fontSize={14} fontWeight={100}>
                                                {dataEvent.event_location}
                                                {dataEvent.event_location_detail === null ? null : dataEvent.event_location_detail.length > 0 ? ` - ${dataEvent.event_location_detail}` : null}
                                            </Box>
                                    }
                                </> : null
                        }
                    </DialogContentText>
                    <Divider />
                </DialogTitle>
                {/* <DialogContent> */}

                <Box pb={2} pl={2}>
                    <Box >
                        <Box fontSize={14}>Ticket Class</Box>
                        <Box fontSize={15} style={{ fontWeight: "bold" }}>{dataSeat.seat_detail_name}</Box>
                    </Box>
                    <Box pt={2}>
                        <Grid container>
                            <Grid item xs={4} >
                                <Box fontSize={11}>Price</Box>
                                <Box fontSize={13} style={{ fontWeight: "bold" }}>IDR {dataSeat.seat_detail_price.toLocaleString()}</Box>
                            </Grid>
                            <Grid item xs={4} >
                                <Box fontSize={11}>Event Date</Box>
                                <Box fontSize={13} style={{ fontWeight: "bold" }}> {moment(dataSeat.seat_detail_datetime).format('DD-MM-YYYY')}</Box>
                            </Grid>
                            <Grid item xs={4} >
                                <Box fontSize={11}>Event Time</Box>
                                <Box fontSize={13} style={{ fontWeight: "bold", textTransform: "uppercase" }}>{moment(dataSeat.seat_detail_datetime).format('h:mm A')}</Box>
                            </Grid>

                        </Grid>
                    </Box>

                </Box>

                <Divider style={{ marginBottom: "5px" }} />
                {/* Render button seat */}
                <Box width={1}>
                    <Grid Grid container
                        spacing={2}
                        direction="row"
                        alignItems="center"
                        justify="center">
                        {renderBTN()}

                    </Grid>
                </Box>
                {/* </DialogContent> */}
                <Divider />
                {ErrorMaxBook()}
                {showErrorChooseTicket()}
                {renderContinueCheckout()}
            </Dialog>

            {/* popup ticket deks */}
            <Dialog
                className="deks"
                fullWidth={fullWidth}
                maxWidth='xl'
                open={dataSeat.dialog}
                onClose={closeDialog}
            >
                <DialogTitle aria-labelledby="customized-dialog-title">
                    <DialogContentText>
                        <Box pt={0} pb={0} textAlign="right"><IconButton onClick={closeDialog}>
                            <CloseIcon />
                        </IconButton></Box>
                        <Box textAlign='center' pt={2} fontSize={20} fontWeight={600} >{dataEvent.event_name}</Box>
                        {/* <Box textAlign='center' fontSize={14} fontWeight={100}>{dataEvent.event_location_detail}</Box> */}
                        {
                            kategori > 0 ?
                                <>
                                    {
                                        kategori === 3 ?
                                            <Box textAlign='center' fontSize={14} fontWeight={100}> ONLINE EVENT</Box>
                                            :
                                            <Box textAlign='center' fontSize={14} fontWeight={100}>
                                                {dataEvent.event_location}
                                                {/* {dataEvent.event_location_detail.length > 0 ? ` - ${dataEvent.event_location_detail}` : null} */}
                                                {dataEvent.event_location_detail === null ? null : dataEvent.event_location_detail.length > 0 ? ` - ${dataEvent.event_location_detail}` : null}

                                            </Box>
                                    }
                                </> : null
                        }

                    </DialogContentText>
                </DialogTitle>
                {/* <DialogContent> */}
                <Box pb={2}>
                    <Grid container direction='row' alignItems='center' >
                        <Grid item lg={3} md={3} sm={3} xs={12}>
                            <Box textAlign='center' fontWeight={100}>TICKET CLASS</Box>
                            <Box textAlign='center' fontWeight={600}>
                                {dataSeat.seat_detail_name}
                            </Box>
                        </Grid>
                        <Grid item lg={3} md={3} sm={3} xs={12} >
                            <Box textAlign='center' fontWeight={100}>PRICE</Box>
                            <Box textAlign='center' fontWeight={600}>
                                {
                                    dataSeat.type_price === "FREE" ? "FREE"
                                        : `IDR ${dataSeat.seat_detail_price.toLocaleString()}`
                                }
                            </Box>
                        </Grid>
                        <Grid item lg={3} md={3} sm={3} xs={12} >
                            <Box textAlign='center' fontWeight={100}>TICKET DATE</Box>
                            <Box textAlign='center' fontWeight={600}>
                                {moment(dataSeat.seat_detail_datetime).format('DD/MM/YYYY')}
                            </Box>
                        </Grid>
                        <Grid item lg={3} md={3} sm={3} xs={12}>
                            <Box textAlign='center' fontWeight={100}>TIME</Box>
                            <Box textAlign='center' fontWeight={600}>
                                {moment(dataSeat.seat_detail_datetime).format('h:mm A')}
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                <Divider />
                <Box>

                </Box>

                <Box width={1}>
                    <Grid Grid container
                        spacing={2}
                        direction="row"
                        alignItems="center"
                        justify="center">
                        {renderBTN()}
                    </Grid>
                </Box>


                {/* </DialogContent> */}
                <Divider />
                {ErrorMaxBook()}
                {showErrorChooseTicket()}
                {renderContinueCheckout()}
            </Dialog>


            {loading === false && dataEvent ?
                <Container maxWidth='lg' className={classes.root}>
                    {/* event_status_active_inactive */}
                    {
                        dataEvent.event_active_status === 0 ?
                            <Box py={1} style={{ width: '100%' }} >
                                <Alert severity="warning" style={{ fontSize: "12px" }}>
                                    <AlertTitle style={{ fontSize: "14px" }}>Status event inaktif</AlertTitle>
                                        Saat ini status event sedang tidak aktif. <strong>Untuk sementara Anda tidak dapat dapat memesan tiket di event ini</strong>. Suatu waktu Penyelenggara event dapat mengubah event menjadi aktif kembali
                                </Alert>
                            </Box>
                            :
                            dataEvent.event_active_status === 1 ?
                                null
                                :
                                null
                    }
                    <Box>
                        <Card className={styleEvent.rootcatevent}>
                            <Box
                                textAlign='center'
                                className="imgstyle singleback"
                                style={{
                                    backgroundImage: `url(${dataEvent.event_image_url})`,
                                    backgroundRepeat: 'no-repeat',
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center'
                                }}
                            >
                            </Box>
                        </Card>
                        <Card className={styleEvent.rootcateventmob}>
                            <Box
                                textAlign='center'
                                className="imgstyle "
                                style={{
                                    backgroundImage: `url(${dataEvent.event_image_url})`,
                                    backgroundRepeat: 'no-repeat',
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center'
                                }}
                            >
                            </Box>
                        </Card>
                        <Box className={styleEvent.rootcatevent}>
                            {
                                dataEvent.event_active_status === 0 ?
                                    <Box textAlign="center" className="singleimg singleimg-inactive" style={{ alignContent: "center", zIndex: 1 }}>

                                        <img src={dataEvent.event_image_url} height='100%' />
                                    </Box>
                                    :
                                    <Box textAlign="center" className="singleimg" style={{ alignContent: "center", zIndex: 1 }}>

                                        <img src={dataEvent.event_image_url} height='100%' />
                                    </Box>
                            }
                        </Box>

                        <Box>
                            <Grid container>

                                <Grid item xs={12} >
                                    <Paper elevation={2} className="paperStyles">
                                        <Box style={{ color: "#39364F", fontSize: '20px' }} id='fontStyle' className={classes.nameEvent} >
                                            {dataEvent.event_name}
                                        </Box>

                                        <Box className="box_detail" pl={5} pr={4} pt={2} style={{ color: "#39364F" }} >
                                            <Grid container style={{ borderTop: 'solid 1px #eeeeee', paddingTop: '10px' }} >
                                                <Grid item xl={3} lg={3} md={3} sm={12} xs={12} className={classes.borderBox} style={{ paddingTop: "10px" }}>
                                                    <Box style={{ paddingTop: "10px" }} id='fontStyle' className={classes.titleBox} >TANGGAL</Box>
                                                    {
                                                        moment(dataEvent.event_start_datetime).format('DD MMMM YYYY') !== moment(dataEvent.event_end_datetime).format('DD MMMM YYYY') ?
                                                            <Box id='fontStyle' className={classes.captionBox} style={{ fontSize: "14px", color: "#39364F" }}>
                                                                {moment(dataEvent.event_start_datetime).format('DD MMM YYYY')} - {' '}
                                                                {moment(dataEvent.event_end_datetime).format('DD MMM YYYY')}
                                                            </Box> :
                                                            <Box id='fontStyle' className={classes.captionBox} style={{ fontSize: "14px", color: "#39364F" }}>
                                                                {moment(dataEvent.event_end_datetime).format('DD MMMM YYYY')}
                                                            </Box>
                                                    }

                                                </Grid>
                                                <Grid item xl={3} lg={3} md={3} sm={12} xs={12} style={{ paddingTop: "10px" }} className={classes.borderBox}>
                                                    <Box id='fontStyle' className={classes.titleBox} style={{ color: "#39364F" }}>PUKUL</Box>
                                                    <Box style={{ fontSize: "14px", color: "#39364F" }} id='fontStyle' className={classes.captionBox}>
                                                        {moment(dataEvent.event_start_datetime).format('LT')}
                                                        {moment(dataEvent.event_start_datetime).format('LT')!=moment(dataEvent.event_end_datetime).format('LT')?' - '+moment(dataEvent.event_end_datetime).format('LT'):null} WIB </Box>
                                                </Grid>

                                                <Grid item xl={4} lg={4} md={4} sm={12} xs={12} style={{ paddingTop: "10px" }} className={classes.borderBox}>
                                                    <Box id='fontStyle' className={classes.titleBox} style={{ color: "#39364F" }}>TEMPAT</Box>
                                                    {/* <Box style={{ fontSize: "14px", color: "#39364F" }} id='fontStyle' className={classes.captionBox}>{dataEvent.event_location_detail}</Box> */}
                                                    {
                                                        kategori > 0 ?
                                                            <>
                                                                {
                                                                    kategori === 3 ?
                                                                        <Box style={{ color: "#39364F", fontSize: "14px" }} id='fontStyle' className={classes.captionBox}>ONLINE EVENT
                                                                        </Box> :
                                                                        <Box style={{ color: "#39364F", fontSize: "14px" }} id='fontStyle' className={classes.captionBox}>{dataEvent.event_location} {dataEvent.event_location_detail.length > 0  ? ` - ${dataEvent.event_location_detail}` : null}
                                                                        </Box>
                                                                }
                                                            </> : null
                                                    }
                                                </Grid>
                                                <Grid item xl={2} lg={2} md={2} sm={12} xs={12} style={{ paddingTop: "10px" }} >
                                                    <Box id='fontStyle' className={classes.titleBox} style={{ color: "#39364F" }}>PENYELENGGARA</Box>
                                                    {/* <Box style={{ fontSize: "14px", color: "#39364F" }} id='fontStyle' className={classes.captionBox}>{dataOrganizer.organizer_name}</Box> */}

                                                    {
                                                        logoOrganizer.source ?
                                                            <Box style={{ color: "#39364F", alignItems: 'center', fontSize: "14px" }} id='fontStyle' className={classes.captionBox}>
                                                                <Grid container alignItems="center" spacing={1}>
                                                                    <Grid item md={5} lg={5} xl={5}>
                                                                        <img src={logoOrganizer.source} style={{ borderRadius: '50%', objectFit: 'cover', width: '40px', height: '40px', border: '1.5px solid #d1d1d1' }} />
                                                                    </Grid>
                                                                    <Grid onClick={() => {
                                                                        setpenyelenggaraTab(true)
                                                                        setgalleryTab(false)
                                                                        setdescTab(false)
                                                                        setticketTab(false)
                                                                        setagendaTab(false)
                                                                    }
                                                                    } item md={7} lg={7} xl={7} style={{ textAlign: 'left', fontSize: "14px", color: "#39364F", cursor: 'pointer' }}>
                                                                        {dataOrganizer.organizer_name}
                                                                    </Grid>
                                                                </Grid>
                                                            </Box>

                                                            :
                                                            <Box style={{ color: "#39364F" }} id='fontStyle' className={classes.captionBox}>
                                                                {/* <img src={Logo} width="20px" />{` `} */}
                                                                {dataOrganizer.organizer_name}</Box>
                                                    }
                                                </Grid>

                                                {
                                                    Tag.length === 0 ? null : Tag === undefined ? null : (Tag.length > 0 && Tag[0].tag_name === "undefined") ? null : (Tag.length > 0 && Tag[0].tag_name === undefined) ? null
                                                        : (
                                                            <Grid className="mobile" item lg={2} md={3} sm={3} xs={12} style={{ paddingTop: "10px" }}>
                                                                <Box id='fontStyle' className={classes.titleBox} style={{ color: "#39364F" }}>Tag</Box>

                                                                {
                                                                    Tag.map((val, i) => (
                                                                        <Button key={i}
                                                                            size="small"
                                                                            fontSize={13}
                                                                            variant="outlined"
                                                                            style={{ textTransform: "capitalize", borderRadius: "25px", marginRight: '5px', marginBottom: '5px' }}
                                                                            onClick={() => {
                                                                                dispatch(getEventbyTag(val.tag_name, "", 1))
                                                                                window.location.href = `/tag/${val.tag_slug}`
                                                                            }}
                                                                        >{val.tag_name}</Button>
                                                                    ))
                                                                }
                                                            </Grid>
                                                        )
                                                }

                                                <Grid item sm={12} xs={12}>
                                                    <Box className="mobile" py={3} >
                                                        <Box style={{ fontSize: "12px", fontWeight: 'bold', color: 'gray' }} >
                                                            Bagikan & Sukai
                                                        </Box>
                                                        <Box pl={1} pt={1}>
                                                            <FacebookShareButton url={`${dataEvent.event_full_url}`}>
                                                                <FacebookIcon size={30} round={true} />&nbsp;
                                                            </FacebookShareButton>
                                                            <TwitterShareButton url={`${dataEvent.event_full_url}`}>
                                                                <TwitterIcon size={30} round={true} />&nbsp;
                                                            </TwitterShareButton>
                                                            <LineShareButton url={`${dataEvent.event_full_url}`}>
                                                                <LineIcon size={30} round={true} />&nbsp;
                                                            </LineShareButton>
                                                            <WhatsappShareButton url={`${dataEvent.event_full_url}`}>
                                                                <WhatsappIcon size={30} round={true} />&nbsp;
                                                            </WhatsappShareButton>

                                                            {
                                                                dataEvent.current_auth_liked ?
                                                                    <Tooltip TransitionComponent={Zoom} title="Hapus sebagai favorit" arrow placement="bottom">
                                                                        <FavoriteIcon color="error"
                                                                            style={{ cursor: 'pointer', fontSize: '30px' }}
                                                                            onClick={async () => {
                                                                                try {
                                                                                    const testing = await CheckToken()

                                                                                    let token = ls.get('@_tknid').data
                                                                                    const res = await Axios.delete(`${API}/event/like`, {
                                                                                        headers: { Authorization: `Bearer ${token}` },
                                                                                        data: { event_id: dataEvent.event_id }
                                                                                    })
                                                                                    if (res) {
                                                                                        await Axios.get(`${API}/event/slug/${inputSlug}`, { headers: { Authorization: `Bearer ${token}` } })
                                                                                            .then((res) => {
                                                                                                if (res.data.error) {
                                                                                                    setLoading(false)
                                                                                                } else {

                                                                                                    setDataEvent(res.data.data)
                                                                                                    setTag(res.data.data.tag)
                                                                                                    setDataOrganizer(res.data.data.organizer)
                                                                                                    setDataAgenda(res.data.data.event_agenda)
                                                                                                    setEventGallery(res.data.data.gallery)
                                                                                                    setTicketSeat(res.data.data.seat.seat_detail)
                                                                                                    setLoading(false)
                                                                                                    setDataLoaded(true)
                                                                                                }
                                                                                            }).catch((err) => {
                                                                                                // console.log(err)
                                                                                                setLoading(false)
                                                                                            })
                                                                                    }
                                                                                } catch (error) {
                                                                                    // console.log(error)
                                                                                    ls.removeAll()
                                                                                    localStorage.clear()
                                                                                    sessionStorage.clear()
                                                                                    window.location.href = "/"
                                                                                }
                                                                            }}
                                                                        />
                                                                    </Tooltip>
                                                                    :
                                                                    <Tooltip TransitionComponent={Zoom} title="Tambah sebagai favorit" arrow placement="bottom">
                                                                        <FavoriteBorderIcon
                                                                            style={{ cursor: 'pointer', fontSize: '30px' }}
                                                                            onClick={async () => {
                                                                                try {
                                                                                    const testing = await CheckToken()
                                                                                    let token = ls.get('@_tknid').data
                                                                                    if (!token) {
                                                                                        window.location.href = "/login";
                                                                                    } else {
                                                                                        let formdata = new FormData()
                                                                                        let options = {
                                                                                            headers: {
                                                                                                Authorization: `Bearer ${token}`
                                                                                            }
                                                                                        }
                                                                                        formdata.append("event_id", dataEvent.event_id)
                                                                                        const res = await Axios.post(`${API}/event/like`, formdata, options, null)
                                                                                        if (res) {
                                                                                            await Axios.get(`${API}/event/slug/${inputSlug}`, { headers: { Authorization: `Bearer ${token}` } })
                                                                                                .then((res) => {
                                                                                                    if (res.data.error) {
                                                                                                        setLoading(false)
                                                                                                        // window.location.href = "/notfound"
                                                                                                    } else {

                                                                                                        setDataEvent(res.data.data)
                                                                                                        setDataOrganizer(res.data.data.organizer)
                                                                                                        setTag(res.data.data.tag)
                                                                                                        setDataAgenda(res.data.data.event_agenda)
                                                                                                        setEventGallery(res.data.data.gallery)
                                                                                                        setTicketSeat(res.data.data.seat.seat_detail)
                                                                                                        setLoading(false)
                                                                                                        setDataLoaded(true)
                                                                                                    }
                                                                                                }).catch((err) => {
                                                                                                    // console.log(err)
                                                                                                    setLoading(false)
                                                                                                })
                                                                                        }
                                                                                    }
                                                                                } catch (error) {
                                                                                    ls.removeAll()
                                                                                    localStorage.clear()
                                                                                    sessionStorage.clear()
                                                                                    window.location.href = "/"
                                                                                }

                                                                            }}
                                                                        />
                                                                    </Tooltip>
                                                            }
                                                        </Box>

                                                    </Box>
                                                </Grid>
                                            </Grid>
                                            {/* ============================================= */}
                                        </Box>
                                    </Paper>
                                </Grid>


                            </Grid>
                        </Box>
                    </Box>

                    <Box pt={3}>

                        {/* START TABS BUTTON  MOB*/}
                        {
                            dataEvent.seat === null ?
                                <Box className="mobile mobile-button">
                                    <Grid container
                                        direction="row"
                                        justify="space-around"
                                        alignItems="center">
                                        <Button
                                            className="buttontab"
                                            px={1}
                                            style={{
                                                textTransform: 'capitalize',
                                                backgroundColor: `${descTab ? '#9E69D2' : ''} `, color: `${descTab ? '#fff' : '#39364F'}`
                                            }}
                                            onClick={() => {
                                                // setagendaTab(false)
                                                setpenyelenggaraTab(false)
                                                setgalleryTab(false)
                                                setticketTab(false)
                                                setdescTab(true)
                                            }}
                                        >Deskripsi</Button>

                                        <Button className="buttontab"
                                            px={1}
                                            style={{
                                                textTransform: 'capitalize',
                                                backgroundColor: `${penyelenggaraTab ? '#9E69D2' : ''} `, color: `${penyelenggaraTab ? '#fff' : '#39364F'}`
                                            }}
                                            onClick={() => {
                                                setpenyelenggaraTab(true)
                                                // setagendaTab(false)
                                                setgalleryTab(false)
                                                setdescTab(false)
                                                setticketTab(false)
                                            }}>Penyelenggara</Button>


                                        <Button className="buttontab"
                                            px={1}
                                            style={{
                                                textTransform: 'capitalize',
                                                backgroundColor: `${galleryTab ? '#9E69D2' : ''} `, color: `${galleryTab ? '#fff' : '#39364F'}`
                                            }}
                                            onClick={() => {
                                                setpenyelenggaraTab(false)
                                                // setagendaTab(false)
                                                setgalleryTab(true)
                                                setdescTab(false)
                                                setticketTab(false)
                                            }}>Galeri</Button>
                                        {
                                            dataEvent.seat === null ? null :
                                                (<Button className="buttontab"
                                                    px={1}
                                                    style={{
                                                        textTransform: 'capitalize',
                                                        backgroundColor: `${ticketTab ? '#9E69D2' : ''} `, color: `${ticketTab ? '#fff' : '#39364F'}`
                                                    }}
                                                    onClick={() => {
                                                        setpenyelenggaraTab(false)
                                                        // setagendaTab(false)
                                                        setgalleryTab(false)
                                                        setdescTab(false)
                                                        setticketTab(true)
                                                    }}>Tiket</Button>)
                                        }
                                    </Grid>
                                </Box>
                                :

                                <Box textAlign="center" whiteSpace="nowrap" justifyContent="center" display="flex" flexDirection="row" justify="space-between" alignItems="center" className="mobile mobile-button">

                                    <Button
                                        className="buttontab"
                                        px={1}
                                        style={{
                                            textTransform: 'capitalize',
                                            backgroundColor: `${descTab ? '#9E69D2' : ''} `, color: `${descTab ? '#fff' : '#39364F'}`
                                        }}
                                        onClick={() => {
                                            // setagendaTab(false)
                                            setpenyelenggaraTab(false)
                                            setgalleryTab(false)
                                            setticketTab(false)
                                            setdescTab(true)
                                        }}
                                    >Deskripsi</Button>

                                    <Button className="buttontab"
                                        px={1}
                                        style={{
                                            textTransform: 'capitalize',
                                            backgroundColor: `${penyelenggaraTab ? '#9E69D2' : ''} `, color: `${penyelenggaraTab ? '#fff' : '#39364F'}`
                                        }}
                                        onClick={() => {
                                            setpenyelenggaraTab(true)
                                            // setagendaTab(false)
                                            setgalleryTab(false)
                                            setdescTab(false)
                                            setticketTab(false)
                                        }}>Penyelenggara</Button>


                                    <Button className="buttontab"
                                        px={1}
                                        style={{
                                            textTransform: 'capitalize',
                                            backgroundColor: `${galleryTab ? '#9E69D2' : ''} `, color: `${galleryTab ? '#fff' : '#39364F'}`
                                        }}
                                        onClick={() => {
                                            setpenyelenggaraTab(false)
                                            // setagendaTab(false)
                                            setgalleryTab(true)
                                            setdescTab(false)
                                            setticketTab(false)
                                        }}>Galeri</Button>
                                    {
                                        dataEvent.seat === null ? null : dataEvent.seat && dataEvent.seat.seat_detail.length === 0 ? null : dataEvent.event_ticket_sale_end_datetime === null || dataEvent.event_ticket_sale_start_datetime === null ? null
                                            :
                                            (<Button className="buttontab"
                                                px={1}
                                                style={{
                                                    textTransform: 'capitalize',
                                                    backgroundColor: `${ticketTab ? '#9E69D2' : ''} `, color: `${ticketTab ? '#fff' : '#39364F'}`
                                                }}
                                                onClick={() => {
                                                    setpenyelenggaraTab(false)
                                                    // setagendaTab(false)
                                                    setgalleryTab(false)
                                                    setdescTab(false)
                                                    setticketTab(true)
                                                }}>Tiket</Button>)
                                    }
                                </Box>
                        }


                        {/* START TABS CONTENT  MOB*/}
                        <Box pt={2} className="mobile">
                            {
                                descTab ? (
                                    <Box className="paperStyleRoot" style={{ overflow: 'hidden' }}>
                                        <Paper elevation={2} >
                                            <Box className="deskripsiTab" >
                                                <Box id="eventContent" width='100%'>
                                                    {ReactHtmlParser(dataEvent.event_description)}
                                                </Box>
                                            </Box>
                                            <Box className="agendaTab">

                                                {
                                                    dataAgenda.length > 0 ?
                                                        <>
                                                            <Box fontSize={15} fontWeight={700} pb={1}>Agenda:</Box>
                                                            {/* {dataAgenda.map((data, index) => (
                                                                <Box pt={1} pb={1}>
                                                                    <Box fontSize={14} >
                                                                        <span style={{ textTransform: 'capitalize' }} >
                                                                            <strong>{data.event_agenda_title}</strong>
                                                                        </span>: {'  '}
                                                                        {data.event_agenda_description} {'  '}
                                                                        {
                                                                            moment(data.event_agenda_start_datetime).format('DD MMMM YYYY') !== moment(data.event_agenda_end_datetime).format('DD MMMM YYYY') ?
                                                                                <Box fontSize={13} fontWeight={200} style={{ textTransform: 'capitalize' }}>
                                                                                    {moment(data.event_agenda_start_datetime).format(' h:mm A D MMMM YYYY')}
                                                                                    {' '}- {' '}
                                                                                    {moment(data.event_agenda_end_datetime).format(' h:mm A D MMMM YYYY')}
                                                                                </Box> :
                                                                                <Box fontSize={13} fontWeight={200}>
                                                                                    {moment(data.event_agenda_end_datetime).format(' h:mm A D MMMM')}
                                                                                </Box>
                                                                        }
                                                                    </Box>
                                                                    <Box fontSize={13} pl={2}>
                                                                        <Box><strong>Speaker</strong> :
                                                                            {'  '} {data.event_agenda_speaker_name}
                                                                        </Box>
                                                                        <Box><strong>Position</strong> :
                                                                            {'  '} {data.event_agenda_speaker_position}
                                                                        </Box>
                                                                    </Box>
                                                                </Box>
                                                            ))} */}

                                                            <Box pb={2} fontSize={14} >{dataEvent.event_name} dibagi menjadi {dataAgenda.length} sesi:</Box>
                                                            {dataAgenda.map((data, index) => (
                                                                <Box pt={1} pb={1} key={index}>
                                                                    {/* <Agendaicon /> */}
                                                                    {
                                                                        index > 0 ?
                                                                            <Box style={{ borderTop: 'solid 1.5px #d1d1d1', paddingTop: '10px', paddingBottom: '10px' }}></Box>
                                                                            :
                                                                            null
                                                                    }
                                                                    <Box fontSize={14}>
                                                                        <Grid container >
                                                                            <Grid item xl={1} lg={1} md={1} sm={2} xs={2}>
                                                                                <Box>
                                                                                    <img src={Agendaicon} width='25px' />
                                                                                </Box>
                                                                            </Grid>
                                                                            <Grid item xl={11} lg={11} sm={10} xs={10}>
                                                                                <Box pb={1}><span style={{ fontWeight: 'bold', color: '#393755' }}>{data.event_agenda_title}: </span>{data.event_agenda_description}
                                                                                            ({
                                                                                        moment(data.event_agenda_start_datetime).format('DD MMMM YYYY') !== moment(data.event_agenda_end_datetime).format('DD MMMM YYYY') ?
                                                                                            <>
                                                                                                {moment(data.event_agenda_start_datetime).format(' h:mm A DD MMMM YYYY')}
                                                                                                {' '}- {' '}
                                                                                                {moment(data.event_agenda_end_datetime).format(' h:mm A DD MMMM YYYY')}
                                                                                            </> :
                                                                                            <>
                                                                                                {moment(data.event_agenda_end_datetime).format(' h:mm A DD MMMM YYYY')}
                                                                                            </>
                                                                                    })
                                                                                            </Box>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Box>
                                                                    <Box fontSize={14} pb={2}>
                                                                        <Grid container>
                                                                            <Grid item xl={1} lg={1} md={1} sm={2} xs={2}>
                                                                                <Box>
                                                                                    <img src={Speakericon} width='25px' />
                                                                                </Box>
                                                                            </Grid>
                                                                            <Grid item xl={11} lg={11} md={11} sm={10} xs={10}>

                                                                                <Box pb={1}><span style={{ fontWeight: 'bold', color: '#393755' }}>Speaker: </span>{data.event_agenda_speaker_name} - {data.event_agenda_speaker_position}</Box>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Box>

                                                                </Box>
                                                            ))}

                                                        </>

                                                        :
                                                        null
                                                }

                                            </Box>
                                        </Paper>
                                    </Box>
                                ) :

                                    penyelenggaraTab ? (
                                        <Box className="paperStyleRoot">
                                            {
                                                // dataAgenda.length > 0 ? (
                                                <Paper elevation={3} >
                                                    <Box p={3} className="penyelenggaraTab">
                                                        <Box pb={2} textAlign="center">
                                                            {
                                                                logoOrganizer.source === null ?
                                                                    null
                                                                    :
                                                                    logoOrganizer.source === "" ?
                                                                        null

                                                                        :
                                                                        <img alt={organizer.organizer_name} src={logoOrganizer.source} width="55%" />
                                                            }
                                                        </Box>

                                                        {
                                                            organizer === null || organizer === undefined ?
                                                                null
                                                                :
                                                                <>
                                                                    <Box fontWeight={600} textAlign="center" style={{ fontSize: "15px" }}>{organizer.organizer_name}</Box>
                                                                    <Box fontWeight={300} color="#9E69D2" style={{ fontSize: "15px" }} textAlign="center">{company.company_name}</Box>

                                                                    {
                                                                        organizer !== undefined && organizer.organizer_website !== "" ?
                                                                            <Link href={organizer.organizer_website} target="_blank">
                                                                                <Box textAlign="center">
                                                                                    <LinkIcon fontSize="small" style={{ color: "#9E69D2" }} />
                                                                                    <span style={{ fontSize: "14px", textDecoration: "none", color: "black" }}>{organizer.organizer_website}</span>

                                                                                </Box>
                                                                            </Link>
                                                                            : null
                                                                    }
                                                                    <Box textAlign="center">
                                                                        {
                                                                            organizer !== undefined && organizer.organizer_address ?
                                                                                <>
                                                                                    <LocationOnIcon fontSize="small" /> <span style={{ fontSize: "14px" }}>{organizer.organizer_address}</span>
                                                                                </>
                                                                                : null
                                                                        }
                                                                    </Box>
                                                                    <Box pt={2} textAlign="center">
                                                                        {
                                                                            organizer ?
                                                                                <Grid container justify="center" spacing={1} style={{ fontSize: "13px" }}>
                                                                                    {
                                                                                        organizer.organizer_phone1 && organizer.organizer_phone1 !== "null" ?
                                                                                            <Grid item>
                                                                                                <PhoneIcon fontSize="small" /> <span>{organizer.organizer_phone1}</span>
                                                                                            </Grid>
                                                                                            : null
                                                                                    }

                                                                                    {
                                                                                        organizer.organizer_email ?
                                                                                            <Grid item>
                                                                                                <Email fontSize="small" /> <span>{organizer.organizer_email}</span>

                                                                                            </Grid>
                                                                                            : null
                                                                                    }

                                                                                    {
                                                                                        organizer.organizer_whatsapp1 && organizer.organizer_whatsapp1 !== "null" ?
                                                                                            <Grid item>
                                                                                                <WAIcon fontSize="small" /> <span>{organizer.organizer_whatsapp1}</span>
                                                                                            </Grid>
                                                                                            : null
                                                                                    }
                                                                                    {
                                                                                        organizer.organizer_facebook_name ?
                                                                                            <Grid item>
                                                                                                <FbIcon fontSize="small" /> <span>{organizer.organizer_facebook_name}</span>
                                                                                            </Grid>
                                                                                            : null
                                                                                    }
                                                                                    {
                                                                                        organizer.organizer_instagram_name ?
                                                                                            <Grid item>
                                                                                                <IgIcon fontSize="small" /> <span>{organizer.organizer_instagram_name}</span>
                                                                                            </Grid>
                                                                                            : null
                                                                                    }
                                                                                    {
                                                                                        organizer.organizer_twitter_name ?
                                                                                            <Grid item>
                                                                                                <TwitIcon fontSize="small" /> <span>{organizer.organizer_twitter_name}</span>
                                                                                            </Grid>
                                                                                            : null
                                                                                    }
                                                                                    {
                                                                                        organizer.organizer_youtube_name ?
                                                                                            <Grid item>
                                                                                                <YouTubeIcon fontSize="small" /> <span>{organizer.organizer_youtube_name}</span>
                                                                                            </Grid>
                                                                                            : null
                                                                                    }

                                                                                </Grid>

                                                                                :
                                                                                null
                                                                        }
                                                                    </Box>

                                                                </>

                                                        }
                                                        {
                                                            moreEventOrganizer !== undefined && moreEventOrganizer.length > 0 ?
                                                                <Box pt={7} textAlign="center">
                                                                    <Box style={{ fontSize: "15px" }}>Event Lainnya dari Penyelenggara</Box>
                                                                    <Box pt={2}>
                                                                        <Carousel
                                                                            additionalTransfrom={0}
                                                                            arrows
                                                                            autoPlaySpeed={3000}
                                                                            centerMode={false}
                                                                            className=""
                                                                            containerClass="container-with-dots"
                                                                            dotListClass=""
                                                                            draggable
                                                                            focusOnSelect={false}

                                                                            itemClass=""
                                                                            keyBoardControl
                                                                            minimumTouchDrag={80}
                                                                            renderButtonGroupOutside={false}
                                                                            renderDotsOutside={false}
                                                                            responsive={{
                                                                                desktop: {
                                                                                    breakpoint: {
                                                                                        max: 3000,
                                                                                        min: 1024
                                                                                    },
                                                                                    items: 3,
                                                                                    partialVisibilityGutter: 40
                                                                                },
                                                                                mobile: {
                                                                                    breakpoint: {
                                                                                        max: 464,
                                                                                        min: 0
                                                                                    },
                                                                                    items: 1,
                                                                                    partialVisibilityGutter: 30
                                                                                },
                                                                                tablet: {
                                                                                    breakpoint: {
                                                                                        max: 1024,
                                                                                        min: 464
                                                                                    },
                                                                                    items: 2,
                                                                                    partialVisibilityGutter: 30
                                                                                }
                                                                            }}
                                                                            showDots={false}
                                                                            sliderClass=""
                                                                            slidesToSlide={1}
                                                                            swipeable
                                                                        >


                                                                            {/* <Grid container spacing={3} justify="center"> */}
                                                                            {
                                                                                moreEventOrganizer.map((data, index) => {
                                                                                    return (
                                                                                        // <Grid key={index} item lg={3} md={4}>
                                                                                        <Card style={{ marginRight: "3%" }} key={index}>
                                                                                            <Link href={`/detail-event/${data.event_slug}`}>
                                                                                                <CardMedia
                                                                                                    className={styleEvent.media}
                                                                                                    image={data.event_image_url}
                                                                                                    title={data.event_name} />
                                                                                            </Link>
                                                                                            <Box pl={1} pr={1} pt={1} color='#000' textAlign="left" style={{ width: '100%', whiteSpace: 'nowrap' }}>
                                                                                                <Box fontSize={15} fontWeight={600} component="div" overflow="hidden" textOverflow="ellipsis" color="#393755">
                                                                                                    {
                                                                                                        data.event_name
                                                                                                    }
                                                                                                </Box>
                                                                                                {
                                                                                                    moment(data.event_start_datetime).format('DD MMMM YYYY') !== moment(data.event_end_datetime).format('DD MMMM YYYY') ?
                                                                                                        <Box color="#393755" fontSize={14} fontWeight={100} overflow="hidden" textOverflow="ellipsis">
                                                                                                            {moment(data.event_start_datetime).format('DD MMMM YYYY')}
                                                                                                            {' '}- {' '}
                                                                                                            {moment(data.event_end_datetime).format('DD MMMM YYYY')}
                                                                                                        </Box> :
                                                                                                        <Box color="#393755" fontSize={14} fontWeight={100} overflow="hidden" textOverflow="ellipsis">
                                                                                                            {moment(data.event_end_datetime).format('DD MMMM YYYY')}
                                                                                                        </Box>
                                                                                                }
                                                                                                {
                                                                                                    data.category_venue_id === 3 ?
                                                                                                        <Box pb={1} color="#393755" fontSize={14} fontWeight={100} component="div" overflow="hidden" textOverflow="ellipsis">Online Event
                                                                                                                            </Box> :
                                                                                                        <Box pb={1} color="#393755" fontSize={14} fontWeight={100} component="div" overflow="hidden" textOverflow="ellipsis">{data.event_location} {data.event_location_detail === null ? null : data.event_location_detail.length > 0 ? ` - ${data.event_location_detail}` : null}
                                                                                                        </Box>

                                                                                                }
                                                                                                <Box style={{ borderBottom: 'solid 2px #d1d1d1', paddingTop: '10px' }}></Box>

                                                                                            </Box>
                                                                                        </Card>
                                                                                        // </Grid>
                                                                                    )
                                                                                })
                                                                            }
                                                                            {/* </Grid> */}
                                                                        </Carousel>
                                                                    </Box>
                                                                </Box>


                                                                : null
                                                        }

                                                    </Box>
                                                </Paper>
                                            }
                                        </Box>
                                    ) :
                                        galleryTab ? (
                                            <Box className="paperStyleRoot">
                                                {
                                                    eventGallery && eventGallery.length > 0 ?
                                                        (

                                                            <Paper elevation={2}>
                                                                <Box className="galleryTab" >

                                                                    <ResponsiveMasonry
                                                                        // columnsCountBreakPoints={{ 350: 2, 450: 3, 750: 3, 900: 4, 1000: 4 }}
                                                                        columnsCountBreakPoints={{ 400: 2, 600: 3, 800: 3, 1100: 4, }}

                                                                    >
                                                                        <Masonry>
                                                                            {eventGallery.map((tile, index) => (
                                                                                <div key={index} >
                                                                                    <img
                                                                                        src={tile.gallery_image_url}
                                                                                        alt='gallery'
                                                                                        className="styleImgGalery"
                                                                                        width="100%"
                                                                                        onClick={() => {
                                                                                            setopenIndexImg(index)
                                                                                            setopenImageGallery(true)
                                                                                            setopenImage(tile.gallery_image_url)
                                                                                        }}
                                                                                    />


                                                                                </div>
                                                                            ))}
                                                                        </Masonry>
                                                                    </ResponsiveMasonry>
                                                                </Box>
                                                            </Paper>
                                                        ) :

                                                        <Paper elevation={2} >
                                                            <Grid container direction='row' justify='center'>
                                                                <Box textAlign="center" pt={4}>
                                                                    <img alt='nothing here' src={Tidaktersedia} width='80%' />
                                                                </Box>
                                                            </Grid>
                                                            <Grid container direction='row' justify='center'>
                                                                <Box id="fontStyle" pt={3} pb={3}>Saat ini belum tersedia</Box>
                                                            </Grid>
                                                        </Paper>
                                                }

                                            </Box>
                                        ) :
                                            ticketTab ? (
                                                <Box className="paperStyleRoot" style={{ overflow: 'hidden' }} >
                                                    <Paper elevation={2} >



                                                        <Box className="ticketTab">
                                                            {/* {
                                                                ticketSeat.length > 1 ?
                                                                    <Box pl={2} width="220px">
                                                                        <ThemeProvider theme={materialTheme}>
                                                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                                                <KeyboardDatePicker
                                                                                    disableToolbar
                                                                                    variant="outlined"
                                                                                    format="MM/dd/yyyy"
                                                                                    margin="normal"
                                                                                    id="date-picker-inline"
                                                                                    label="Filter Tanggal"
                                                                                    value={selectedDate}
                                                                                    onChange={handleDateChange}
                                                                                    style={{ color: '#9E69D2', width: "100%" }}
                                                                                    KeyboardButtonProps={{
                                                                                        'aria-label': 'change date',
                                                                                    }}
                                                                                    autoOk={true}
                                                                                />
                                                                            </MuiPickersUtilsProvider>
                                                                        </ThemeProvider>
                                                                    </Box>
                                                                    :
                                                                    null
                                                            } */}

                                                            <Box px={2}>
                                                                <Grid container
                                                                    direction="row"
                                                                    justify="space-between">
                                                                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                                                        {
                                                                            ticketSeat.length > 1 ?
                                                                                <Box width="230px">
                                                                                    <ThemeProvider theme={materialTheme}>
                                                                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                                                            <KeyboardDatePicker

                                                                                                disableToolbar
                                                                                                variant="outlined"
                                                                                                format="MM/dd/yyyy"
                                                                                                margin="normal"
                                                                                                id="date-picker-inline"
                                                                                                label="Filter Tanggal"
                                                                                                value={selectedDate}
                                                                                                onChange={handleDateChange}
                                                                                                style={{ color: '#9E69D2', width: "100%" }}
                                                                                                KeyboardButtonProps={{
                                                                                                    'aria-label': 'change date',
                                                                                                }}
                                                                                                autoOk={true}

                                                                                            />
                                                                                        </MuiPickersUtilsProvider>
                                                                                    </ThemeProvider>
                                                                                </Box>
                                                                                :
                                                                                null
                                                                        }
                                                                    </Grid>

                                                                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                                                        {
                                                                            dataEvent.event_ticket_sale_end_datetime === null || dataEvent.event_ticket_sale_start_datetime === null ? null
                                                                                :
                                                                                dateStart < dateNow || dateStart === dateNow ?

                                                                                    <Box pt={1} fontWeight={600} fontSize={13} color='#e669ab'>
                                                                                        Berakhir tanggal {moment(dataEvent.event_ticket_sale_end_datetime).format('DD MMMM YYYY ')} | {moment(dataEvent.event_ticket_sale_end_datetime).format('hh:mm A')}
                                                                                    </Box>
                                                                                    :
                                                                                    <Box pt={1} fontWeight={600} fontSize={13} color='#e669ab'>

                                                                                        Pemesanan Tiket dibuka tanggal {` `}
                                                                                        {moment(dataEvent.event_ticket_start_end_datetime).format('DD MMMM YYYY ')} Pk. {moment(dataEvent.event_ticket_sale_start_datetime).format('hh:mm A')}
                                                                                        {' '} s/d {' '}
                                                                                        {moment(dataEvent.event_ticket_sale_end_datetime).format('DD MMMM YYYY ')} Pk. {moment(dataEvent.event_ticket_sale_end_datetime).format('hh:mm A')}
                                                                                    </Box>
                                                                        }
                                                                    </Grid>
                                                                </Grid>


                                                            </Box>

                                                            {
                                                                selectedDate === null ?


                                                                    <Slider {...settings}>
                                                                        {
                                                                            ticketSeat.filter(e => e.seat_detail_active_status_name === "active").map((data, index) => (

                                                                                <Box p={1} key={index} >
                                                                                    <Paper elevation={1} square style={{ border: '1px solid #DCCDE7' }}>
                                                                                        <Box p={2} color='#707070' style={{ backgroundColor: '#fff' }}  >

                                                                                            {/* TICKET CLASS */}
                                                                                            <Box pt={1}>
                                                                                                <Grid container>
                                                                                                    <Grid item xs={6}>
                                                                                                        <Box fontWeight={100} fontSize={12}>Ticket Class</Box>
                                                                                                        <Box fontWeight={600} fontSize={15} >{data.seat_detail_name}</Box>
                                                                                                    </Grid>
                                                                                                    <Grid item xs={6}>
                                                                                                        <Box fontWeight={100} fontSize={12}>Price</Box>
                                                                                                        <Box fontWeight={600} fontSize={15}>
                                                                                                            {data.ms_price.ms_price_identifier === "FREE" ? "FREE" :
                                                                                                                `IDR ${data.seat_detail_price.toLocaleString()}`}
                                                                                                        </Box>
                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                            </Box>

                                                                                            {/* TYPE PRICE */}
                                                                                            <Box pt={1}>
                                                                                                <Grid container>
                                                                                                    <Grid item xs={6}>
                                                                                                        <Box pt={1} fontWeight={100} fontSize={12}>Type Price</Box>
                                                                                                        <Box fontWeight={600} fontSize={15} >
                                                                                                            {data.ms_price.ms_price_identifier === "FREE" ? "FREE" :
                                                                                                                data.ms_price.ms_price_identifier === "DONATION" ? "DONATION" : "PAID"}
                                                                                                        </Box>

                                                                                                    </Grid>
                                                                                                    <Grid item xs={6}>
                                                                                                        <Box pt={1} fontWeight={100} fontSize={12}>Type Seat</Box>
                                                                                                        <Box fontWeight={600} fontSize={15}>
                                                                                                            {data.ms_seat.ms_seat_identifier}
                                                                                                        </Box>
                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                            </Box>

                                                                                            {/* TICKET DATE */}
                                                                                            <Box pt={1}>
                                                                                                <Grid container>
                                                                                                    <Grid item xs={6}>
                                                                                                        <Box pt={1} fontWeight={100} fontSize={13}>Ticket Date</Box>
                                                                                                        <Box fontWeight={600} fontSize={15}>
                                                                                                            {moment(data.seat_detail_datetime).format('DD/MM/YYYY')}
                                                                                                        </Box>
                                                                                                    </Grid>
                                                                                                    <Grid item xs={6}>
                                                                                                        <Box pt={1} fontWeight={100} fontSize={13}>Ticket Time</Box>
                                                                                                        <Box fontWeight={600} fontSize={15}>
                                                                                                            {moment(data.seat_detail_datetime).format('h:mm A')}
                                                                                                        </Box>
                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                            </Box>

                                                                                            {/* {
                                                                                                dataEvent.event_ticket_sale_end_datetime === null || dataEvent.event_ticket_sale_end_datetime === undefined || dataEvent.event_ticket_sale_start_datetime === null ? null :
                                                                                                    <Box pt={1}>
                                                                                                        {renderTicketDate()}
                                                                                                    </Box>
                                                                                            } */}
                                                                                        </Box>


                                                                                        <Box pt={1} pb={3} className={classes.paperTiket} >
                                                                                            <Grid container direction='row' alignItems='center' justify='center' >
                                                                                                <Box >
                                                                                                    <Box pt={1} pb={1} pl={2} pr={2}>
                                                                                                        {
                                                                                                            dataEvent.event_active_status === 0 ?
                                                                                                                <Box title="Event inaktif">
                                                                                                                    <Button variant='contained' disabled={true} style={{ color: "white", cursor: 'none' }}>Select Ticket</Button></Box>
                                                                                                                :
                                                                                                                (dataEvent.event_ticket_sale_end_datetime === null || dataEvent.event_ticket_sale_start_datetime === null) && (dateNow < endEvent) ?
                                                                                                                    <Box>
                                                                                                                        <Button variant='contained' disabled={true} style={{ color: "white", cursor: 'none' }}>Select Ticket</Button></Box>
                                                                                                                    :
                                                                                                                    (dataEvent.event_ticket_sale_end_datetime === null || dataEvent.event_ticket_sale_start_datetime === null) && (dateNow > endEvent) ?
                                                                                                                        <Box>
                                                                                                                            <Button variant='contained' disabled={true} style={{ color: "white", cursor: 'none' }}>Ticket Sale End</Button></Box>
                                                                                                                        :
                                                                                                                        dateEnd < dateNow ?
                                                                                                                            <Button
                                                                                                                                variant='contained'
                                                                                                                                color='secondary'
                                                                                                                                disabled={true}
                                                                                                                            >Ticket Sale End
                                                                                                                    </Button>
                                                                                                                            :
                                                                                                                            (data.ticket.length !== 0 &&
                                                                                                                                (data.ticket.filter(val => val.ticket_status_name === "pending").length + data.ticket.filter(val => val.ticket_status_name === "issued").length
                                                                                                                                    >= data.seat_detail_quantity)) ?
                                                                                                                                <Button
                                                                                                                                    variant='contained'
                                                                                                                                    disabled={true}
                                                                                                                                    style={{ color: "white" }}
                                                                                                                                >Ticket Sold Out
                                                                                                                    </Button>
                                                                                                                                :
                                                                                                                                <Button
                                                                                                                                    variant='contained'
                                                                                                                                    color='inherit'
                                                                                                                                    onClick={() => {
                                                                                                                                        // if (localStorage.getItem('token')) {
                                                                                                                                        if (ls.get('@_tknid').data) {
                                                                                                                                            setDataSeat({
                                                                                                                                                ...dataSeat,
                                                                                                                                                dialog: true,
                                                                                                                                                seat_detail_name: data.seat_detail_name,
                                                                                                                                                seat_detail_price: data.seat_detail_price,
                                                                                                                                                seat_detail_datetime: data.seat_detail_datetime,
                                                                                                                                                seat_detail_id: data.seat_detail_id,
                                                                                                                                                seat_id: data.seat_id,
                                                                                                                                                seat_detail_maximum_purchased_quantity: data.seat_detail_maximum_purchased_quantity,
                                                                                                                                                seat_detail_quantity: data.seat_detail_quantity,
                                                                                                                                                ticket: data.ticket,
                                                                                                                                                type_price: data.ms_price.ms_price_identifier,
                                                                                                                                                type_seat: data.ms_seat.ms_seat_identifier,
                                                                                                                                                min_donation: data.seat_detail_minimum_donation_amount
                                                                                                                                            })

                                                                                                                                            let state = []
                                                                                                                                            let tikettt = false

                                                                                                                                            if (data.ms_seat.ms_seat_identifier === "RESERVED") {

                                                                                                                                                for (var i = 0; i < data.seat_detail_quantity; i++) {
                                                                                                                                                    for (var j = 0; j < data.ticket.length; j++) {
                                                                                                                                                        if (i === data.ticket[j].ticket_seat_number - 1 && data.ticket[j].ticket_status_name !== 'failed') {
                                                                                                                                                            state.push({ seat_number: i + 1, color: '#d1d1d1', colorName: 'black' });
                                                                                                                                                            tikettt = true
                                                                                                                                                        }
                                                                                                                                                    }
                                                                                                                                                    if (!tikettt) {
                                                                                                                                                        state.push({ seat_number: i + 1, color: '#fff', colorName: 'black' })
                                                                                                                                                    }
                                                                                                                                                    tikettt = false
                                                                                                                                                }
                                                                                                                                            } else if (data.ms_seat.ms_seat_identifier === "UNRESERVED") {
                                                                                                                                                if (data.ticket.length !== 0) {
                                                                                                                                                    for (var k = 0; k < data.ticket.length; k++) {
                                                                                                                                                        if (data.ticket[k].ticket_status_name !== 'failed') {
                                                                                                                                                            state.push({ tiket_name: data.ticket[k].ticket_status_name })
                                                                                                                                                        }
                                                                                                                                                    }
                                                                                                                                                }
                                                                                                                                            }
                                                                                                                                            setSeatTicket(state)
                                                                                                                                        } else {
                                                                                                                                            swal({
                                                                                                                                                title: "Login!",
                                                                                                                                                text: "Untuk membeli tiket ini anda harus login terlabih dahulu!",
                                                                                                                                                icon: "warning",
                                                                                                                                                buttons: true,
                                                                                                                                            })
                                                                                                                                                .then((redirect) => {
                                                                                                                                                    if (redirect) {
                                                                                                                                                        window.location.href = "/login";
                                                                                                                                                    }
                                                                                                                                                });
                                                                                                                                        }
                                                                                                                                    }}
                                                                                                                                >Select Ticket
                                                                                                                </Button>
                                                                                                        }

                                                                                                    </Box>

                                                                                                </Box>
                                                                                            </Grid>
                                                                                        </Box>
                                                                                    </Paper>
                                                                                </Box>
                                                                            ))}

                                                                    </Slider>
                                                                    :
                                                                    // null
                                                                    <>

                                                                        {

                                                                            ticketSeat.filter(e => (e.seat_detail_active_status_name === "active") && (moment(e.seat_detail_datetime).format('DD/MM/YYYY') === moment(selectedDate).format('DD/MM/YYYY'))).length > 0 ?
                                                                                <Slider {...settingsfilter}>
                                                                                    {ticketSeat.filter(e => (e.seat_detail_active_status_name === "active") && (moment(e.seat_detail_datetime).format('DD/MM/YYYY') === moment(selectedDate).format('DD/MM/YYYY'))).map((data, index) => (
                                                                                        // { console.log(data) }

                                                                                        <Box p={1} key={index} >
                                                                                            <Paper elevation={1} square style={{ border: '1px solid #DCCDE7' }}>
                                                                                                <Box p={2} color='#707070' style={{ backgroundColor: '#fff' }}  >

                                                                                                    {/* TICKET CLASS */}
                                                                                                    <Box pt={1}>
                                                                                                        <Grid container>
                                                                                                            <Grid item xs={6}>
                                                                                                                <Box fontWeight={100} fontSize={12}>Ticket Class</Box>
                                                                                                                <Box fontWeight={600} fontSize={15} >{data.seat_detail_name}</Box>
                                                                                                            </Grid>
                                                                                                            <Grid item xs={6}>
                                                                                                                <Box fontWeight={100} fontSize={12}>Price</Box>
                                                                                                                <Box fontWeight={600} fontSize={15}>
                                                                                                                    {data.ms_price.ms_price_identifier === "FREE" ? "FREE" :
                                                                                                                        `IDR ${data.seat_detail_price.toLocaleString()}`}
                                                                                                                </Box>
                                                                                                            </Grid>
                                                                                                        </Grid>
                                                                                                    </Box>

                                                                                                    {/* TYPE PRICE */}
                                                                                                    <Box pt={1}>
                                                                                                        <Grid container>
                                                                                                            <Grid item xs={6}>
                                                                                                                <Box pt={1} fontWeight={100} fontSize={12}>Type Price</Box>
                                                                                                                <Box fontWeight={600} fontSize={15} >
                                                                                                                    {data.ms_price.ms_price_identifier === "FREE" ? "FREE" :
                                                                                                                        data.ms_price.ms_price_identifier === "DONATION" ? "DONATION" : "PAID"}
                                                                                                                </Box>

                                                                                                            </Grid>
                                                                                                            <Grid item xs={6}>
                                                                                                                <Box pt={1} fontWeight={100} fontSize={12}>Type Seat</Box>
                                                                                                                <Box fontWeight={600} fontSize={15}>
                                                                                                                    {data.ms_seat.ms_seat_identifier}
                                                                                                                </Box>
                                                                                                            </Grid>
                                                                                                        </Grid>
                                                                                                    </Box>

                                                                                                    {/* TICKET DATE */}
                                                                                                    <Box pt={1}>
                                                                                                        <Grid container>
                                                                                                            <Grid item xs={6}>
                                                                                                                <Box pt={1} fontWeight={100} fontSize={13}>Ticket Date</Box>
                                                                                                                <Box fontWeight={600} fontSize={15}>
                                                                                                                    {moment(data.seat_detail_datetime).format('DD/MM/YYYY')}
                                                                                                                </Box>
                                                                                                            </Grid>
                                                                                                            <Grid item xs={6}>
                                                                                                                <Box pt={1} fontWeight={100} fontSize={13}>Ticket Time</Box>
                                                                                                                <Box fontWeight={600} fontSize={15}>
                                                                                                                    {moment(data.seat_detail_datetime).format('h:mm A')}
                                                                                                                </Box>
                                                                                                            </Grid>
                                                                                                        </Grid>
                                                                                                    </Box>

                                                                                                    {/* <Box pt={1}>
                                                                                                        {renderTicketDate()}
                                                                                                    </Box> */}
                                                                                                </Box>
                                                                                                <Box pt={1} pb={3} className={classes.paperTiket} >
                                                                                                    <Grid container direction='row' alignItems='center' justify='center' >
                                                                                                        <Box >
                                                                                                            <Box pt={1} pb={1} pl={2} pr={2}>
                                                                                                                {
                                                                                                                    dataEvent.event_active_status === 0 ?
                                                                                                                        <Box title="Event inaktif">
                                                                                                                            <Button variant='contained' disabled={true} style={{ color: "white", cursor: 'none' }}>Select Ticket</Button></Box>
                                                                                                                        :
                                                                                                                        dateEnd < dateNow ?
                                                                                                                            <Button
                                                                                                                                variant='contained'
                                                                                                                                color='secondary'
                                                                                                                                disabled={true}
                                                                                                                                style={{ color: "white" }}
                                                                                                                            >Ticket Sale End
                                                                                                                    </Button>
                                                                                                                            :
                                                                                                                            (data.ticket.length !== 0 &&
                                                                                                                                (data.ticket.filter(val => val.ticket_status_name === "pending").length + data.ticket.filter(val => val.ticket_status_name === "issued").length
                                                                                                                                    >= data.seat_detail_quantity)) ?
                                                                                                                                <Button
                                                                                                                                    variant='contained'
                                                                                                                                    disabled={true}
                                                                                                                                    style={{ color: "white" }}
                                                                                                                                >Ticket Sold Out
                                                                                                                    </Button>
                                                                                                                                :
                                                                                                                                <Button
                                                                                                                                    variant='contained'
                                                                                                                                    color='inherit'
                                                                                                                                    onClick={() => {
                                                                                                                                        // if (localStorage.getItem('token')) {
                                                                                                                                        if (ls.get('@_tknid').data) {
                                                                                                                                            setDataSeat({
                                                                                                                                                ...dataSeat,
                                                                                                                                                dialog: true,
                                                                                                                                                seat_detail_name: data.seat_detail_name,
                                                                                                                                                seat_detail_price: data.seat_detail_price,
                                                                                                                                                seat_detail_datetime: data.seat_detail_datetime,
                                                                                                                                                seat_detail_id: data.seat_detail_id,
                                                                                                                                                seat_id: data.seat_id,
                                                                                                                                                seat_detail_maximum_purchased_quantity: data.seat_detail_maximum_purchased_quantity,
                                                                                                                                                seat_detail_quantity: data.seat_detail_quantity,
                                                                                                                                                ticket: data.ticket,
                                                                                                                                                type_price: data.ms_price.ms_price_identifier,
                                                                                                                                                type_seat: data.ms_seat.ms_seat_identifier,
                                                                                                                                                min_donation: data.seat_detail_minimum_donation_amount
                                                                                                                                            })

                                                                                                                                            let state = []
                                                                                                                                            let tikettt = false

                                                                                                                                            if (data.ms_seat.ms_seat_identifier === "RESERVED") {

                                                                                                                                                for (var i = 0; i < data.seat_detail_quantity; i++) {
                                                                                                                                                    for (var j = 0; j < data.ticket.length; j++) {
                                                                                                                                                        if (i === data.ticket[j].ticket_seat_number - 1 && data.ticket[j].ticket_status_name !== 'failed') {
                                                                                                                                                            state.push({ seat_number: i + 1, color: '#d1d1d1', colorName: 'black' });
                                                                                                                                                            tikettt = true
                                                                                                                                                        }
                                                                                                                                                    }
                                                                                                                                                    if (!tikettt) {
                                                                                                                                                        state.push({ seat_number: i + 1, color: '#fff', colorName: 'black' })
                                                                                                                                                    }
                                                                                                                                                    tikettt = false
                                                                                                                                                }
                                                                                                                                            } else if (data.ms_seat.ms_seat_identifier === "UNRESERVED") {
                                                                                                                                                if (data.ticket.length !== 0) {
                                                                                                                                                    for (var k = 0; k < data.ticket.length; k++) {
                                                                                                                                                        if (data.ticket[k].ticket_status_name !== 'failed') {
                                                                                                                                                            state.push({ tiket_name: data.ticket[k].ticket_status_name })
                                                                                                                                                        }
                                                                                                                                                    }
                                                                                                                                                }
                                                                                                                                            }
                                                                                                                                            setSeatTicket(state)
                                                                                                                                        } else {
                                                                                                                                            swal({
                                                                                                                                                title: "Login!",
                                                                                                                                                text: "Untuk membeli tiket ini anda harus login terlabih dahulu!",
                                                                                                                                                icon: "warning",
                                                                                                                                                buttons: true,
                                                                                                                                            })
                                                                                                                                                .then((redirect) => {
                                                                                                                                                    if (redirect) {
                                                                                                                                                        window.location.href = "/login";
                                                                                                                                                    }
                                                                                                                                                });
                                                                                                                                        }
                                                                                                                                    }}
                                                                                                                                >Select Ticket
                                                                                                                </Button>
                                                                                                                }

                                                                                                            </Box>

                                                                                                        </Box>
                                                                                                    </Grid>
                                                                                                </Box>
                                                                                            </Paper>
                                                                                        </Box>
                                                                                    ))}

                                                                                </Slider>
                                                                                :

                                                                                <>
                                                                                    <Grid container direction='row' justify='center'>
                                                                                        <Box textAlign="center" pt={4}>
                                                                                            <img alt='nothing here' src={Tidaktersedia} width='50%' />
                                                                                        </Box>
                                                                                    </Grid>
                                                                                    <Grid container direction='row' justify='center'>
                                                                                        <Box id="fontStyle" pt={3}>Tidak ditemukan. Silakan cari di tanggal lain</Box>
                                                                                    </Grid>
                                                                                </>
                                                                        }
                                                                    </>
                                                            }
                                                            <Box pt={5} pb={3} >
                                                                <Grid container spacing={3} >
                                                                    <Grid item xs={12}>
                                                                        <Box pt={3} pb={2} >
                                                                            <TransformWrapper>
                                                                                {({ zoomIn, zoomOut, resetTransform }) => (
                                                                                    <React.Fragment>
                                                                                        <Grid
                                                                                            display="flex"
                                                                                            direction="column"
                                                                                            style={{}}>
                                                                                            <Box
                                                                                                display="flex"
                                                                                                justifyContent="center">
                                                                                                <div className="tools">
                                                                                                    <button onClick={zoomIn}>+</button>
                                                                                                    <button onClick={zoomOut}>-</button>
                                                                                                    <button onClick={resetTransform}>x</button>
                                                                                                </div>
                                                                                            </Box> <br />
                                                                                            <Box
                                                                                                display="flex"
                                                                                                justifyContent="center"
                                                                                            >
                                                                                                <TransformComponent>
                                                                                                    <img
                                                                                                        width="100%"
                                                                                                        src={dataEvent.seat_image_url}
                                                                                                        alt="Denah"
                                                                                                    />
                                                                                                </TransformComponent>
                                                                                            </Box>
                                                                                        </Grid>
                                                                                    </React.Fragment>
                                                                                )}
                                                                            </TransformWrapper>
                                                                        </Box>
                                                                    </Grid>
                                                                </Grid>
                                                            </Box>
                                                        </Box>
                                                    </Paper>
                                                </Box>
                                            ) : null
                            }
                        </Box>


                        {/* FOR DEKS */}
                        <Box className="deks">
                            <Grid container >
                                {/* BUTTON TAB DEKS */}
                                <Grid item sm={1} md={1} lg={1}  >
                                    <Box my={2}  >
                                        <Button
                                            className="buttonTabs"
                                            style={{
                                                textTransform: 'capitalize',
                                                backgroundColor: `${descTab ? '#9E69D2' : ''} `, color: `${descTab ? '#fff' : '#39364F'}`
                                            }}
                                            onClick={() => {
                                                setagendaTab(false)
                                                setpenyelenggaraTab(false)
                                                setgalleryTab(false)
                                                setticketTab(false)
                                                setdescTab(true)
                                            }}
                                        >Deskripsi</Button></Box>
                                    <Box my={2} >
                                        <Button className="buttonTabs"
                                            style={{
                                                textTransform: 'capitalize',
                                                backgroundColor: `${agendaTab ? '#9E69D2' : ''} `, color: `${agendaTab ? '#fff' : '#39364F'}`
                                            }}
                                            onClick={() => {
                                                setagendaTab(true)
                                                setpenyelenggaraTab(false)
                                                setgalleryTab(false)
                                                setdescTab(false)
                                                setticketTab(false)
                                            }}>Agenda</Button></Box>
                                    <Box my={2} ><Button className="buttonTabs"
                                        style={{
                                            textTransform: 'capitalize',
                                            backgroundColor: `${penyelenggaraTab ? '#9E69D2' : ''} `, color: `${penyelenggaraTab ? '#fff' : '#39364F'}`
                                        }}
                                        onClick={() => {
                                            setpenyelenggaraTab(true)
                                            setagendaTab(false)
                                            setgalleryTab(false)
                                            setdescTab(false)
                                            setticketTab(false)
                                        }}>Penyelenggara</Button></Box>
                                    <Box my={2} > <Button className="buttonTabs"
                                        style={{
                                            textTransform: 'capitalize',
                                            backgroundColor: `${galleryTab ? '#9E69D2' : ''} `, color: `${galleryTab ? '#fff' : '#39364F'}`
                                        }}
                                        onClick={() => {
                                            setpenyelenggaraTab(false)
                                            setagendaTab(false)
                                            setgalleryTab(true)
                                            setdescTab(false)
                                            setticketTab(false)
                                        }}>Galeri</Button></Box>
                                    <Box my={2} >{
                                        dataEvent.seat === null ? null : dataEvent.seat && dataEvent.seat.seat_detail.length === 0 ? null : dataEvent.event_ticket_sale_end_datetime === null || dataEvent.event_ticket_sale_start_datetime === null ? null :
                                            (<Button className="buttonTabs"
                                                style={{
                                                    textTransform: 'capitalize',
                                                    backgroundColor: `${ticketTab ? '#9E69D2' : ''} `, color: `${ticketTab ? '#fff' : '#39364F'}`
                                                }}
                                                onClick={() => {
                                                    setpenyelenggaraTab(false)
                                                    setagendaTab(false)
                                                    setgalleryTab(false)
                                                    setdescTab(false)
                                                    setticketTab(true)
                                                }}>Tiket</Button>)
                                    }</Box>
                                </Grid>

                                {/* CONTENT TAB DEKS*/}
                                <Grid item sm={11} md={11} lg={11} >
                                    <Box>
                                        {
                                            descTab ? (
                                                <Box className="paperStyleRoot" style={{ overflow: 'hidden' }}>
                                                    <Paper elevation={2} >
                                                        <Box className="deskripsiTab" >
                                                            <Box id="eventContent" width='100%'>
                                                                {ReactHtmlParser(dataEvent.event_description)}
                                                            </Box>
                                                            {
                                                                Tag.length === 0 ? null : Tag === undefined ? null : (Tag.length > 0 && Tag[0].tag_name === "undefined") ? null : (Tag.length > 0 && Tag[0].tag_name === undefined) ? null
                                                                    : (
                                                                        <>
                                                                            <Box pt={6} pb={2} id='fontStyle' style={{ color: "#39364F" }}>Tag</Box>

                                                                            {
                                                                                Tag.map((val, i) => (
                                                                                    <Button key={i}
                                                                                        className="btnTag"
                                                                                        size="small"
                                                                                        variant="outlined"
                                                                                        style={{ textTransform: "capitalize", borderRadius: "25px", marginRight: '5px', marginBottom: '5px' }}
                                                                                        onClick={() => {
                                                                                            dispatch(getEventbyTag(val.tag_name, "", 1))
                                                                                            window.location.href = `/tag/${val.tag_slug}`

                                                                                        }}
                                                                                    >{val.tag_name}</Button>
                                                                                ))
                                                                            }
                                                                        </>
                                                                    )
                                                            }
                                                            <Box pt={6} pb={1} id='fontStyle' style={{ color: "#39364F" }}>Bagikan & Sukai </Box>
                                                            <Box pt={1} pb={3}>
                                                                <Grid container
                                                                    direction="row"
                                                                    justify="flex-start"
                                                                    alignItems="center">
                                                                    <Grid item title="Bagikan ke Facebook">
                                                                        <FacebookShareButton url={`${dataEvent.event_full_url}`} >
                                                                            <FacebookIcon size={33} round={true} />&nbsp;
                                                                        </FacebookShareButton>
                                                                    </Grid>
                                                                    <Grid item title="Bagikan ke Twitter">
                                                                        <TwitterShareButton url={`${dataEvent.event_full_url}`} >
                                                                            <TwitterIcon size={33} round={true} />&nbsp;
                                                                    </TwitterShareButton>
                                                                    </Grid>
                                                                    <Grid item title="Bagikan ke Line">
                                                                        <LineShareButton url={`${dataEvent.event_full_url}`} >
                                                                            <LineIcon size={33} round={true} />&nbsp;
                                                                    </LineShareButton>
                                                                    </Grid>
                                                                    <Grid item title="Bagikan ke Whatsapp">
                                                                        <WhatsappShareButton url={`${dataEvent.event_full_url}`} >
                                                                            <WhatsappIcon size={33} round={true} />&nbsp;
                                                                    </WhatsappShareButton>
                                                                    </Grid>

                                                                    {
                                                                        dataEvent.current_auth_liked ?
                                                                            <Tooltip TransitionComponent={Zoom} title="Hapus sebagai favorit" arrow placement="bottom">
                                                                                <FavoriteIcon color="error"
                                                                                    style={{ cursor: 'pointer', fontSize: '30px' }}
                                                                                    onClick={async () => {
                                                                                        try {
                                                                                            const testing = await CheckToken()

                                                                                            let token = ls.get('@_tknid').data
                                                                                            const res = await Axios.delete(`${API}/event/like`, {
                                                                                                headers: { Authorization: `Bearer ${token}` },
                                                                                                data: { event_id: dataEvent.event_id }
                                                                                            })
                                                                                            if (res) {
                                                                                                await Axios.get(`${API}/event/slug/${inputSlug}`, { headers: { Authorization: `Bearer ${token}` } })
                                                                                                    .then((res) => {
                                                                                                        if (res.data.error) {
                                                                                                            setLoading(false)
                                                                                                        } else {

                                                                                                            setDataEvent(res.data.data)
                                                                                                            setTag(res.data.data.tag)
                                                                                                            setDataOrganizer(res.data.data.organizer)
                                                                                                            setDataAgenda(res.data.data.event_agenda)
                                                                                                            setEventGallery(res.data.data.gallery)
                                                                                                            setTicketSeat(res.data.data.seat.seat_detail)
                                                                                                            setLoading(false)
                                                                                                            setDataLoaded(true)
                                                                                                        }
                                                                                                    }).catch((err) => {
                                                                                                        // console.log(err)
                                                                                                        setLoading(false)
                                                                                                    })
                                                                                            }
                                                                                        } catch (error) {
                                                                                            // console.log(error)
                                                                                            ls.removeAll()
                                                                                            localStorage.clear()
                                                                                            sessionStorage.clear()
                                                                                            window.location.href = "/"
                                                                                        }
                                                                                    }}
                                                                                />
                                                                            </Tooltip>
                                                                            :
                                                                            <Tooltip TransitionComponent={Zoom} title="Tambah sebagai favorit" arrow placement="bottom">
                                                                                <FavoriteBorderIcon
                                                                                    style={{ cursor: 'pointer', fontSize: '30px' }}
                                                                                    onClick={async () => {
                                                                                        try {
                                                                                            const testing = await CheckToken()
                                                                                            let token = ls.get('@_tknid').data
                                                                                            if (!token) {
                                                                                                window.location.href = "/login";
                                                                                            } else {
                                                                                                let formdata = new FormData()
                                                                                                let options = {
                                                                                                    headers: {
                                                                                                        Authorization: `Bearer ${token}`
                                                                                                    }
                                                                                                }
                                                                                                formdata.append("event_id", dataEvent.event_id)
                                                                                                const res = await Axios.post(`${API}/event/like`, formdata, options, null)
                                                                                                if (res) {
                                                                                                    await Axios.get(`${API}/event/slug/${inputSlug}`, { headers: { Authorization: `Bearer ${token}` } })
                                                                                                        .then((res) => {
                                                                                                            if (res.data.error) {
                                                                                                                setLoading(false)
                                                                                                                // window.location.href = "/notfound"
                                                                                                            } else {

                                                                                                                setDataEvent(res.data.data)
                                                                                                                setDataOrganizer(res.data.data.organizer)
                                                                                                                setTag(res.data.data.tag)
                                                                                                                setDataAgenda(res.data.data.event_agenda)
                                                                                                                setEventGallery(res.data.data.gallery)
                                                                                                                setTicketSeat(res.data.data.seat.seat_detail)
                                                                                                                setLoading(false)
                                                                                                                setDataLoaded(true)
                                                                                                            }
                                                                                                        }).catch((err) => {
                                                                                                            // console.log(err)
                                                                                                            setLoading(false)
                                                                                                        })
                                                                                                }
                                                                                            }
                                                                                        } catch (error) {
                                                                                            ls.removeAll()
                                                                                            localStorage.clear()
                                                                                            sessionStorage.clear()
                                                                                            window.location.href = "/"
                                                                                        }

                                                                                    }}
                                                                                />
                                                                            </Tooltip>
                                                                    }
                                                                </Grid>
                                                            </Box>
                                                        </Box>


                                                    </Paper>
                                                </Box>
                                            ) :
                                                agendaTab ? (
                                                    <Box className="paperStyleRoot">
                                                        {
                                                            dataAgenda.length > 0 ? (
                                                                <Paper elevation={3} >
                                                                    <Box fontSize={15} className="agendaTab" pb={2}>
                                                                        <Box pb={2} fontSize={16} >{dataEvent.event_name} dibagi menjadi {dataAgenda.length} sesi:</Box>
                                                                        {dataAgenda.map((data, index) => (
                                                                            <Box pt={1} pb={1} key={index}>
                                                                                {/* <Agendaicon /> */}
                                                                                {
                                                                                    index > 0 ?
                                                                                        <Box style={{ borderTop: 'solid 1.5px #d1d1d1', paddingTop: '10px', paddingBottom: '10px' }}></Box>
                                                                                        :
                                                                                        null
                                                                                }
                                                                                <Box>
                                                                                    <Grid container >
                                                                                        <Grid item xl={1} lg={1} md={1} sm={1} xs={1}>
                                                                                            <Box pl={4}>
                                                                                                <img src={Agendaicon} width='25px' />
                                                                                            </Box>
                                                                                        </Grid>
                                                                                        <Grid item xl={11} lg={11} sm={11} xs={11}>
                                                                                            <Box pb={1}><span style={{ fontWeight: 'bold', color: '#393755' }}>{data.event_agenda_title}: </span>{data.event_agenda_description}
                                                                                            ({
                                                                                                    moment(data.event_agenda_start_datetime).format('DD MMMM YYYY') !== moment(data.event_agenda_end_datetime).format('DD MMMM YYYY') ?
                                                                                                        <>
                                                                                                            {moment(data.event_agenda_start_datetime).format(' h:mm A DD MMMM YYYY')}
                                                                                                            {' '}- {' '}
                                                                                                            {moment(data.event_agenda_end_datetime).format(' h:mm A DD MMMM YYYY')}
                                                                                                        </> :
                                                                                                        <>
                                                                                                            {moment(data.event_agenda_end_datetime).format(' h:mm A DD MMMM YYYY')}
                                                                                                        </>
                                                                                                })
                                                                                            </Box>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </Box>
                                                                                <Box pb={2}>
                                                                                    <Grid container>
                                                                                        <Grid item xl={1} lg={1} md={1} sm={1} xs={1}>
                                                                                            <Box pl={4}>
                                                                                                <img src={Speakericon} width='25px' />
                                                                                            </Box>
                                                                                        </Grid>
                                                                                        <Grid item xl={11} lg={11} md={11} sm={11} xs={11}>

                                                                                            <Box pb={1}><span style={{ fontWeight: 'bold', color: '#393755' }}>Speaker: </span>{data.event_agenda_speaker_name} - {data.event_agenda_speaker_position}</Box>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </Box>

                                                                            </Box>
                                                                        ))}

                                                                    </Box>
                                                                </Paper>
                                                            ) : <>
                                                                    <Paper elevation={3} >

                                                                        <Grid container direction='row' justify='center'>
                                                                            <Box textAlign="center" pt={4}>
                                                                                <img alt='nothing here' src={Tidaktersedia} width='80%' />
                                                                            </Box>
                                                                        </Grid>
                                                                        <Grid container direction='row' justify='center'>
                                                                            <Box id="fontStyle" pt={3} pb={3}>Saat ini belum tersedia</Box>
                                                                        </Grid>
                                                                    </Paper>
                                                                </>
                                                        }
                                                    </Box>
                                                ) :

                                                    penyelenggaraTab ? (
                                                        <Box className="paperStyleRoot">
                                                            {
                                                                // dataAgenda.length > 0 ? (
                                                                <Paper elevation={3} >
                                                                    <Box p={3} className="penyelenggaraTab">
                                                                        <Box pb={2} textAlign="center">
                                                                            {
                                                                                logoOrganizer.source === null ?
                                                                                    null
                                                                                    :
                                                                                    logoOrganizer.source === "" ?
                                                                                        // <img alt={organizer.organizer_name} src={Building} width="10%" />
                                                                                        null

                                                                                        :
                                                                                        <img alt={organizer.organizer_name} src={logoOrganizer.source} width="25%" />
                                                                            }
                                                                        </Box>

                                                                        {
                                                                            organizer === null || organizer === undefined ?
                                                                                // <Skeleton variant="rect" width={210} height={118} />
                                                                                null
                                                                                :
                                                                                <>
                                                                                    <Box fontWeight={600} textAlign="center" style={{ fontSize: "15px" }}>{organizer.organizer_name}</Box>
                                                                                    <Box fontWeight={300} color="#9E69D2" style={{ fontSize: "15px" }} textAlign="center">{company.company_name}</Box>

                                                                                    {
                                                                                        organizer !== undefined && organizer.organizer_website !== "" ?
                                                                                            <Link href={organizer.organizer_website} target="_blank">
                                                                                                <Box textAlign="center">
                                                                                                    <LinkIcon fontSize="small" style={{ color: "#9E69D2" }} />
                                                                                                    <span style={{ fontSize: "14px", textDecoration: "none", color: "black" }}>{organizer.organizer_website}</span>

                                                                                                </Box>
                                                                                            </Link>
                                                                                            : null
                                                                                    }
                                                                                    <Box textAlign="center">
                                                                                        {
                                                                                            organizer !== undefined && organizer.organizer_address ?
                                                                                                <>
                                                                                                    <LocationOnIcon fontSize="small" /> <span style={{ fontSize: "14px" }}>{organizer.organizer_address}</span>
                                                                                                </>
                                                                                                : null
                                                                                        }
                                                                                    </Box>
                                                                                    <Box pt={2} textAlign="center">
                                                                                        {
                                                                                            organizer ?
                                                                                                <Grid container justify="center" spacing={1} style={{ fontSize: "13px" }}>
                                                                                                    {
                                                                                                        organizer.organizer_phone1 && organizer.organizer_phone1 !== "null" ?
                                                                                                            <Grid item>
                                                                                                                <PhoneIcon fontSize="small" /> <span>{organizer.organizer_phone1}</span>
                                                                                                            </Grid>
                                                                                                            : null
                                                                                                    }

                                                                                                    {
                                                                                                        organizer.organizer_email ?
                                                                                                            <Grid item>
                                                                                                                <Email fontSize="small" /> <span>{organizer.organizer_email}</span>

                                                                                                            </Grid>
                                                                                                            : null
                                                                                                    }

                                                                                                    {
                                                                                                        organizer.organizer_whatsapp1 && organizer.organizer_whatsapp1 !== "null" ?
                                                                                                            <Grid item>
                                                                                                                <WAIcon fontSize="small" /> <span>{organizer.organizer_whatsapp1}</span>
                                                                                                            </Grid>
                                                                                                            : null
                                                                                                    }
                                                                                                    {
                                                                                                        organizer.organizer_facebook_name ?
                                                                                                            <Grid item>
                                                                                                                <FbIcon fontSize="small" /> <span>{organizer.organizer_facebook_name}</span>
                                                                                                            </Grid>
                                                                                                            : null
                                                                                                    }
                                                                                                    {
                                                                                                        organizer.organizer_instagram_name ?
                                                                                                            <Grid item>
                                                                                                                <IgIcon fontSize="small" /> <span>{organizer.organizer_instagram_name}</span>
                                                                                                            </Grid>
                                                                                                            : null
                                                                                                    }
                                                                                                    {
                                                                                                        organizer.organizer_twitter_name ?
                                                                                                            <Grid item>
                                                                                                                <TwitIcon fontSize="small" /> <span>{organizer.organizer_twitter_name}</span>
                                                                                                            </Grid>
                                                                                                            : null
                                                                                                    }
                                                                                                    {
                                                                                                        organizer.organizer_youtube_name ?
                                                                                                            <Grid item>
                                                                                                                <YouTubeIcon fontSize="small" /> <span>{organizer.organizer_youtube_name}</span>
                                                                                                            </Grid>
                                                                                                            : null
                                                                                                    }

                                                                                                </Grid>

                                                                                                :
                                                                                                null
                                                                                        }
                                                                                    </Box>

                                                                                </>

                                                                        }
                                                                        {
                                                                            moreEventOrganizer !== undefined && moreEventOrganizer.length > 0 ?
                                                                                <Box pt={7} textAlign="center">
                                                                                    <Box style={{ fontSize: "15px" }}>Event Lainnya dari Penyelenggara</Box>
                                                                                    <Box pt={2}>
                                                                                        <Carousel
                                                                                            additionalTransfrom={0}
                                                                                            arrows
                                                                                            autoPlaySpeed={3000}
                                                                                            centerMode={false}
                                                                                            className=""
                                                                                            containerClass="container-with-dots"
                                                                                            dotListClass=""
                                                                                            draggable
                                                                                            focusOnSelect={false}

                                                                                            itemClass=""
                                                                                            keyBoardControl
                                                                                            minimumTouchDrag={80}
                                                                                            renderButtonGroupOutside={false}
                                                                                            renderDotsOutside={false}
                                                                                            responsive={{
                                                                                                desktop: {
                                                                                                    breakpoint: {
                                                                                                        max: 3000,
                                                                                                        min: 1024
                                                                                                    },
                                                                                                    items: 3,
                                                                                                    partialVisibilityGutter: 40
                                                                                                },
                                                                                                mobile: {
                                                                                                    breakpoint: {
                                                                                                        max: 464,
                                                                                                        min: 0
                                                                                                    },
                                                                                                    items: 1,
                                                                                                    partialVisibilityGutter: 30
                                                                                                },
                                                                                                tablet: {
                                                                                                    breakpoint: {
                                                                                                        max: 1024,
                                                                                                        min: 464
                                                                                                    },
                                                                                                    items: 2,
                                                                                                    partialVisibilityGutter: 30
                                                                                                }
                                                                                            }}
                                                                                            showDots={false}
                                                                                            sliderClass=""
                                                                                            slidesToSlide={1}
                                                                                            swipeable
                                                                                        >


                                                                                            {/* <Grid container spacing={3} justify="center"> */}
                                                                                            {
                                                                                                moreEventOrganizer.map((data, index) => {
                                                                                                    return (
                                                                                                        // <Grid key={index} item lg={3} md={4}>
                                                                                                        <Card style={{ marginRight: "3%" }} key={index}>
                                                                                                            <Link href={`/detail-event/${data.event_slug}`}>
                                                                                                                <CardMedia
                                                                                                                    className={styleEvent.media}
                                                                                                                    image={data.event_image_url}
                                                                                                                    title={data.event_name} />

                                                                                                            </Link>

                                                                                                            <Box pl={1} pr={1} pt={1} color='#000' textAlign="left" style={{ width: '100%', whiteSpace: 'nowrap' }}>
                                                                                                                <Box fontSize={15} fontWeight={600} component="div" overflow="hidden" textOverflow="ellipsis" color="#393755">
                                                                                                                    {
                                                                                                                        data.event_name
                                                                                                                    }
                                                                                                                </Box>
                                                                                                                {
                                                                                                                    moment(data.event_start_datetime).format('DD MMMM YYYY') !== moment(data.event_end_datetime).format('DD MMMM YYYY') ?
                                                                                                                        <Box color="#393755" fontSize={14} fontWeight={100} overflow="hidden" textOverflow="ellipsis">
                                                                                                                            {moment(data.event_start_datetime).format('DD MMMM YYYY')}
                                                                                                                            {' '}- {' '}
                                                                                                                            {moment(data.event_end_datetime).format('DD MMMM YYYY')}
                                                                                                                        </Box> :
                                                                                                                        <Box color="#393755" fontSize={14} fontWeight={100} overflow="hidden" textOverflow="ellipsis">
                                                                                                                            {moment(data.event_end_datetime).format('DD MMMM YYYY')}
                                                                                                                        </Box>
                                                                                                                }
                                                                                                                {/* <Box color="#393755" pb={1} fontSize={14} fontWeight={100} component="div" overflow="hidden" textOverflow="ellipsis">
                                                                                                        {data.event_location_detail}
                                                                                                    </Box> */}
                                                                                                                {
                                                                                                                    data.category_venue_id === 3 ?
                                                                                                                        <Box pb={1} color="#393755" fontSize={14} fontWeight={100} component="div" overflow="hidden" textOverflow="ellipsis">Online Event
                                                                                                                            </Box> :
                                                                                                                        <Box pb={1} color="#393755" fontSize={14} fontWeight={100} component="div" overflow="hidden" textOverflow="ellipsis">{data.event_location} {data.event_location_detail === null ? null : data.event_location_detail.length > 0 ? ` - ${data.event_location_detail}` : null}
                                                                                                                        </Box>

                                                                                                                }
                                                                                                                <Box style={{ borderBottom: 'solid 2px #d1d1d1', paddingTop: '10px' }}></Box>

                                                                                                            </Box>
                                                                                                        </Card>
                                                                                                        // </Grid>
                                                                                                    )
                                                                                                })
                                                                                            }
                                                                                            {/* </Grid> */}
                                                                                        </Carousel>
                                                                                    </Box>
                                                                                </Box>


                                                                                : null
                                                                        }

                                                                    </Box>
                                                                </Paper>
                                                            }
                                                        </Box>
                                                    ) :
                                                        galleryTab ? (
                                                            <Box height="100%" className="paperStyleRoot">
                                                                {
                                                                    eventGallery && eventGallery.length > 0 ?
                                                                        (
                                                                            <Paper elevation={2} >
                                                                                <Box className="galleryTab" >
                                                                                    {/* <StackGrid
                                                                                                columnWidth={size <= 768 ? '70%' : '22%'}
                                                                                                appear={scaleDown.appear}
                                                                                                appeared={scaleDown.appeared}
                                                                                                enter={scaleDown.enter}
                                                                                                entered={scaleDown.entered}
                                                                                                leaved={scaleDown.leaved}
                                                                                            >
                                                                                                {eventGallery.map((tile, index) => (
                                                                                                    <div key={index} >
                                                                                                        <img
                                                                                                            src={tile.gallery_image_url}
                                                                                                            alt='gallery'
                                                                                                            className="styleImgGalery"
                                                                                                            width="100%"
                                                                                                            onClick={() => {
                                                                                                                setopenIndexImg(index)
                                                                                                                setopenImageGallery(true)
                                                                                                                setopenImage(tile.gallery_image_url)
                                                                                                            }}
                                                                                                        />
                                                                                                    </div>
                                                                                                ))}
                                                                                            </StackGrid> */}
                                                                                    <ResponsiveMasonry
                                                                                        columnsCountBreakPoints={{ 400: 2, 600: 3, 800: 4, 1100: 4, }}
                                                                                    >
                                                                                        <Masonry>
                                                                                            {eventGallery.map((tile, index) => (
                                                                                                <div key={index} >
                                                                                                    <img
                                                                                                        src={tile.gallery_image_url}
                                                                                                        alt='gallery'
                                                                                                        className="styleImgGalery"
                                                                                                        width="100%"
                                                                                                        onClick={() => {
                                                                                                            setopenIndexImg(index)
                                                                                                            setopenImageGallery(true)
                                                                                                            setopenImage(tile.gallery_image_url)
                                                                                                        }}
                                                                                                    />


                                                                                                </div>
                                                                                            ))}
                                                                                        </Masonry>
                                                                                    </ResponsiveMasonry>

                                                                                </Box>
                                                                            </Paper>
                                                                        ) :
                                                                        <Paper elevation={2} >
                                                                            <Grid container direction='row' justify='center'>
                                                                                <Box textAlign="center" pt={4}>
                                                                                    <img alt='nothing here' src={Tidaktersedia} width='80%' />
                                                                                </Box>
                                                                            </Grid>
                                                                            <Grid container direction='row' justify='center'>
                                                                                <Box id="fontStyle" pt={3} pb={3}>Saat ini belum tersedia</Box>
                                                                            </Grid>
                                                                        </Paper>
                                                                }

                                                            </Box>
                                                        ) :
                                                            ticketTab ? (
                                                                <span>
                                                                    <Box className="paperStyleRoot" style={{ overflow: 'hidden' }} >
                                                                        <Paper elevation={2} >

                                                                            <Box className="ticketTab">

                                                                                <Box px={2}>
                                                                                    <Grid container
                                                                                        direction="row"
                                                                                        justify="space-between">
                                                                                        <Grid item>
                                                                                            {
                                                                                                ticketSeat.length > 1 ?
                                                                                                    <Box width="230px">
                                                                                                        <ThemeProvider theme={materialTheme}>
                                                                                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                                                                                <KeyboardDatePicker

                                                                                                                    disableToolbar
                                                                                                                    variant="outlined"
                                                                                                                    format="MM/dd/yyyy"
                                                                                                                    margin="normal"
                                                                                                                    id="date-picker-inline"
                                                                                                                    label="Filter Tanggal"
                                                                                                                    value={selectedDate}
                                                                                                                    onChange={handleDateChange}
                                                                                                                    style={{ color: '#9E69D2', width: "100%" }}
                                                                                                                    KeyboardButtonProps={{
                                                                                                                        'aria-label': 'change date',
                                                                                                                    }}
                                                                                                                    autoOk={true}

                                                                                                                />
                                                                                                            </MuiPickersUtilsProvider>
                                                                                                        </ThemeProvider>
                                                                                                    </Box>
                                                                                                    :
                                                                                                    null
                                                                                            }
                                                                                        </Grid>

                                                                                        <Grid item>
                                                                                            {
                                                                                                dataEvent.event_ticket_sale_end_datetime === null || dataEvent.event_ticket_sale_start_datetime === null ? null
                                                                                                    :
                                                                                                    dateStart < dateNow || dateStart === dateNow ?

                                                                                                        <Box pt={5} fontWeight={600} fontSize={14} color='#e669ab'>
                                                                                                            Berakhir tanggal {moment(dataEvent.event_ticket_sale_end_datetime).format('DD MMMM YYYY ')} | {moment(dataEvent.event_ticket_sale_end_datetime).format('hh:mm A')}
                                                                                                        </Box>
                                                                                                        :
                                                                                                        <Box pt={5} fontWeight={600} fontSize={14} color='#e669ab'>

                                                                                                            Pemesanan Tiket dibuka tanggal {` `}
                                                                                                            {moment(dataEvent.event_ticket_start_end_datetime).format('DD MMMM YYYY ')} Pk. {moment(dataEvent.event_ticket_sale_start_datetime).format('hh:mm A')}
                                                                                                            {' '} s/d {' '}
                                                                                                            {moment(dataEvent.event_ticket_sale_end_datetime).format('DD MMMM YYYY ')} Pk. {moment(dataEvent.event_ticket_sale_end_datetime).format('hh:mm A')}
                                                                                                        </Box>
                                                                                            }
                                                                                        </Grid>
                                                                                    </Grid>


                                                                                </Box>
                                                                                {
                                                                                    selectedDate === null ?

                                                                                        <Slider {...settings}>
                                                                                            {
                                                                                                ticketSeat.filter(e => e.seat_detail_active_status_name === "active").map((data, index) => (

                                                                                                    <Box p={1} key={index} >
                                                                                                        <Paper elevation={1} square style={{ border: '1px solid #DCCDE7' }}>
                                                                                                            <Box p={2} color='#707070' style={{ backgroundColor: '#fff' }}  >

                                                                                                                {/* TICKET CLASS */}
                                                                                                                <Box pt={1}>
                                                                                                                    <Grid container>
                                                                                                                        <Grid item xs={6}>
                                                                                                                            <Box fontWeight={100} fontSize={12}>Ticket Class</Box>
                                                                                                                            <Box fontWeight={600} fontSize={15} >{data.seat_detail_name}</Box>
                                                                                                                        </Grid>
                                                                                                                        <Grid item xs={6}>
                                                                                                                            <Box fontWeight={100} fontSize={12}>Price</Box>
                                                                                                                            <Box fontWeight={600} fontSize={15}>
                                                                                                                                {data.ms_price.ms_price_identifier === "FREE" ? "FREE" :
                                                                                                                                    `IDR ${data.seat_detail_price.toLocaleString()}`}
                                                                                                                            </Box>
                                                                                                                        </Grid>
                                                                                                                    </Grid>
                                                                                                                </Box>

                                                                                                                {/* TYPE PRICE */}
                                                                                                                <Box pt={1}>
                                                                                                                    <Grid container>
                                                                                                                        <Grid item xs={6}>
                                                                                                                            <Box pt={1} fontWeight={100} fontSize={12}>Type Price</Box>
                                                                                                                            <Box fontWeight={600} fontSize={15} >
                                                                                                                                {data.ms_price.ms_price_identifier === "FREE" ? "FREE" :
                                                                                                                                    data.ms_price.ms_price_identifier === "DONATION" ? "DONATION" : "PAID"}
                                                                                                                            </Box>

                                                                                                                        </Grid>
                                                                                                                        <Grid item xs={6}>
                                                                                                                            <Box pt={1} fontWeight={100} fontSize={12}>Type Seat</Box>
                                                                                                                            <Box fontWeight={600} fontSize={15}>
                                                                                                                                {data.ms_seat.ms_seat_identifier}
                                                                                                                            </Box>
                                                                                                                        </Grid>
                                                                                                                    </Grid>
                                                                                                                </Box>

                                                                                                                {/* TICKET DATE */}
                                                                                                                <Box pt={1}>
                                                                                                                    <Grid container>
                                                                                                                        <Grid item xs={6}>
                                                                                                                            <Box pt={1} fontWeight={100} fontSize={13}>Ticket Date</Box>
                                                                                                                            <Box fontWeight={600} fontSize={15}>
                                                                                                                                {moment(data.seat_detail_datetime).format('DD/MM/YYYY')}
                                                                                                                            </Box>
                                                                                                                        </Grid>
                                                                                                                        <Grid item xs={6}>
                                                                                                                            <Box pt={1} fontWeight={100} fontSize={13}>Ticket Time</Box>
                                                                                                                            <Box fontWeight={600} fontSize={15}>
                                                                                                                                {moment(data.seat_detail_datetime).format('h:mm A')}
                                                                                                                            </Box>
                                                                                                                        </Grid>
                                                                                                                    </Grid>
                                                                                                                </Box>

                                                                                                                {/* {
                                                                                                                    dataEvent.event_ticket_sale_end_datetime === null || dataEvent.event_ticket_sale_start_datetime === null ? null :
                                                                                                                        <Box pt={1}>
                                                                                                                            {renderTicketDate()}
                                                                                                                        </Box>
                                                                                                                } */}
                                                                                                            </Box>
                                                                                                            <Box pt={1} pb={3} className={classes.paperTiket} >
                                                                                                                <Grid container direction='row' alignItems='center' justify='center' >
                                                                                                                    <Box >
                                                                                                                        <Box pt={1} pb={1} pl={2} pr={2}>
                                                                                                                            {
                                                                                                                                dataEvent.event_active_status === 0 ?
                                                                                                                                    <Box title="Event inaktif">
                                                                                                                                        <Button variant='contained' disabled={true} style={{ color: "white", cursor: 'none' }}>Select Ticket</Button></Box>
                                                                                                                                    :
                                                                                                                                    (dataEvent.event_ticket_sale_end_datetime === null || dataEvent.event_ticket_sale_start_datetime === null) && (dateNow < endEvent) ?
                                                                                                                                        <Box>
                                                                                                                                            <Button variant='contained' disabled={true} style={{ color: "white", cursor: 'none' }}>Select Ticket</Button></Box>
                                                                                                                                        :
                                                                                                                                        (dataEvent.event_ticket_sale_end_datetime === null || dataEvent.event_ticket_sale_start_datetime === null) && (dateNow > endEvent) ?
                                                                                                                                            <Box>
                                                                                                                                                <Button variant='contained' disabled={true} style={{ color: "white", cursor: 'none' }}>Ticket Sale End</Button></Box>
                                                                                                                                            :
                                                                                                                                            dateEnd < dateNow ?
                                                                                                                                                <Button
                                                                                                                                                    variant='contained'
                                                                                                                                                    color='secondary'
                                                                                                                                                    disabled={true}
                                                                                                                                                    style={{ color: "white" }}

                                                                                                                                                >Ticket Sale End
                                                                                                                                </Button>
                                                                                                                                                :
                                                                                                                                                (data.ticket.length !== 0 &&
                                                                                                                                                    (data.ticket.filter(val => val.ticket_status_name === "pending").length + data.ticket.filter(val => val.ticket_status_name === "issued").length
                                                                                                                                                        >= data.seat_detail_quantity)) ?
                                                                                                                                                    <Button
                                                                                                                                                        variant='contained'
                                                                                                                                                        disabled={true}
                                                                                                                                                        style={{ color: "white" }}
                                                                                                                                                    >Ticket Sold Out
                                                                                                                                    </Button>
                                                                                                                                                    :
                                                                                                                                                    <Button
                                                                                                                                                        variant='contained'
                                                                                                                                                        color='inherit'
                                                                                                                                                        onClick={() => {
                                                                                                                                                            // if (localStorage.getItem('token')) {
                                                                                                                                                            if (ls.get('@_tknid').data) {
                                                                                                                                                                setDataSeat({
                                                                                                                                                                    ...dataSeat,
                                                                                                                                                                    dialog: true,
                                                                                                                                                                    seat_detail_name: data.seat_detail_name,
                                                                                                                                                                    seat_detail_price: data.seat_detail_price,
                                                                                                                                                                    seat_detail_datetime: data.seat_detail_datetime,
                                                                                                                                                                    seat_detail_id: data.seat_detail_id,
                                                                                                                                                                    seat_id: data.seat_id,
                                                                                                                                                                    seat_detail_maximum_purchased_quantity: data.seat_detail_maximum_purchased_quantity,
                                                                                                                                                                    seat_detail_quantity: data.seat_detail_quantity,
                                                                                                                                                                    ticket: data.ticket,
                                                                                                                                                                    type_price: data.ms_price.ms_price_identifier,
                                                                                                                                                                    type_seat: data.ms_seat.ms_seat_identifier,
                                                                                                                                                                    min_donation: data.seat_detail_minimum_donation_amount
                                                                                                                                                                })

                                                                                                                                                                let state = []
                                                                                                                                                                let tikettt = false

                                                                                                                                                                if (data.ms_seat.ms_seat_identifier === "RESERVED") {

                                                                                                                                                                    for (var i = 0; i < data.seat_detail_quantity; i++) {
                                                                                                                                                                        for (var j = 0; j < data.ticket.length; j++) {
                                                                                                                                                                            if (i === data.ticket[j].ticket_seat_number - 1 && data.ticket[j].ticket_status_name !== 'failed') {
                                                                                                                                                                                state.push({ seat_number: i + 1, color: '#d1d1d1', colorName: 'black' });
                                                                                                                                                                                tikettt = true
                                                                                                                                                                            }
                                                                                                                                                                        }
                                                                                                                                                                        if (!tikettt) {
                                                                                                                                                                            state.push({ seat_number: i + 1, color: '#fff', colorName: 'black' })
                                                                                                                                                                        }
                                                                                                                                                                        tikettt = false
                                                                                                                                                                    }
                                                                                                                                                                } else if (data.ms_seat.ms_seat_identifier === "UNRESERVED") {
                                                                                                                                                                    if (data.ticket.length !== 0) {
                                                                                                                                                                        for (var k = 0; k < data.ticket.length; k++) {
                                                                                                                                                                            if (data.ticket[k].ticket_status_name !== 'failed') {
                                                                                                                                                                                state.push({ tiket_name: data.ticket[k].ticket_status_name })
                                                                                                                                                                            }
                                                                                                                                                                        }
                                                                                                                                                                    }
                                                                                                                                                                }
                                                                                                                                                                setSeatTicket(state)
                                                                                                                                                            } else {
                                                                                                                                                                swal({
                                                                                                                                                                    title: "Login!",
                                                                                                                                                                    text: "Untuk membeli tiket ini anda harus login terlabih dahulu!",
                                                                                                                                                                    icon: "warning",
                                                                                                                                                                    buttons: true,
                                                                                                                                                                })
                                                                                                                                                                    .then((redirect) => {
                                                                                                                                                                        if (redirect) {
                                                                                                                                                                            window.location.href = "/login";
                                                                                                                                                                        }
                                                                                                                                                                    });
                                                                                                                                                            }
                                                                                                                                                        }}
                                                                                                                                                    >Select Ticket
                                                                                                                                    </Button>
                                                                                                                            }

                                                                                                                        </Box>

                                                                                                                    </Box>
                                                                                                                </Grid>
                                                                                                            </Box>
                                                                                                        </Paper>
                                                                                                    </Box>
                                                                                                ))}

                                                                                        </Slider>
                                                                                        :
                                                                                        // null
                                                                                        <>
                                                                                            {

                                                                                                ticketSeat.filter(e => (e.seat_detail_active_status_name === "active") && (moment(e.seat_detail_datetime).format('DD/MM/YYYY') === moment(selectedDate).format('DD/MM/YYYY'))).length > 0 ?
                                                                                                    <Slider {...settingsfilter}>
                                                                                                        {ticketSeat.filter(e => (e.seat_detail_active_status_name === "active") && (moment(e.seat_detail_datetime).format('DD/MM/YYYY') === moment(selectedDate).format('DD/MM/YYYY'))).map((data, index) => (
                                                                                                            // { console.log(data) }

                                                                                                            <Box p={1} key={index} >
                                                                                                                <Paper elevation={1} square style={{ border: '1px solid #DCCDE7' }}>
                                                                                                                    <Box p={2} color='#707070' style={{ backgroundColor: '#fff' }}  >

                                                                                                                        {/* TICKET CLASS */}
                                                                                                                        <Box pt={1}>
                                                                                                                            <Grid container>
                                                                                                                                <Grid item xs={6}>
                                                                                                                                    <Box fontWeight={100} fontSize={12}>Ticket Class</Box>
                                                                                                                                    <Box fontWeight={600} fontSize={15} >{data.seat_detail_name}</Box>
                                                                                                                                </Grid>
                                                                                                                                <Grid item xs={6}>
                                                                                                                                    <Box fontWeight={100} fontSize={12}>Price</Box>
                                                                                                                                    <Box fontWeight={600} fontSize={15}>
                                                                                                                                        {data.ms_price.ms_price_identifier === "FREE" ? "FREE" :
                                                                                                                                            `IDR ${data.seat_detail_price.toLocaleString()}`}
                                                                                                                                    </Box>
                                                                                                                                </Grid>
                                                                                                                            </Grid>
                                                                                                                        </Box>

                                                                                                                        {/* TYPE PRICE */}
                                                                                                                        <Box pt={1}>
                                                                                                                            <Grid container>
                                                                                                                                <Grid item xs={6}>
                                                                                                                                    <Box pt={1} fontWeight={100} fontSize={12}>Type Price</Box>
                                                                                                                                    <Box fontWeight={600} fontSize={15} >
                                                                                                                                        {data.ms_price.ms_price_identifier === "FREE" ? "FREE" :
                                                                                                                                            data.ms_price.ms_price_identifier === "DONATION" ? "DONATION" : "PAID"}
                                                                                                                                    </Box>

                                                                                                                                </Grid>
                                                                                                                                <Grid item xs={6}>
                                                                                                                                    <Box pt={1} fontWeight={100} fontSize={12}>Type Seat</Box>
                                                                                                                                    <Box fontWeight={600} fontSize={15}>
                                                                                                                                        {data.ms_seat.ms_seat_identifier}
                                                                                                                                    </Box>
                                                                                                                                </Grid>
                                                                                                                            </Grid>
                                                                                                                        </Box>

                                                                                                                        {/* TICKET DATE */}
                                                                                                                        <Box pt={1}>
                                                                                                                            <Grid container>
                                                                                                                                <Grid item xs={6}>
                                                                                                                                    <Box pt={1} fontWeight={100} fontSize={13}>Ticket Date</Box>
                                                                                                                                    <Box fontWeight={600} fontSize={15}>
                                                                                                                                        {moment(data.seat_detail_datetime).format('DD/MM/YYYY')}
                                                                                                                                    </Box>
                                                                                                                                </Grid>
                                                                                                                                <Grid item xs={6}>
                                                                                                                                    <Box pt={1} fontWeight={100} fontSize={13}>Ticket Time</Box>
                                                                                                                                    <Box fontWeight={600} fontSize={15}>
                                                                                                                                        {moment(data.seat_detail_datetime).format('h:mm A')}
                                                                                                                                    </Box>
                                                                                                                                </Grid>
                                                                                                                            </Grid>
                                                                                                                        </Box>

                                                                                                                        {/* <Box pt={1}>
                                                                                                                            {renderTicketDate()}
                                                                                                                        </Box> */}
                                                                                                                    </Box>
                                                                                                                    <Box pt={1} pb={3} className={classes.paperTiket} >
                                                                                                                        <Grid container direction='row' alignItems='center' justify='center' >
                                                                                                                            <Box >
                                                                                                                                <Box pt={1} pb={1} pl={2} pr={2}>
                                                                                                                                    {
                                                                                                                                        dataEvent.event_active_status === 0 ?
                                                                                                                                            <Box title="Event inaktif">
                                                                                                                                                <Button variant='contained' disabled={true} style={{ color: "white", cursor: 'none' }}>Select Ticket</Button></Box>
                                                                                                                                            :
                                                                                                                                            dateEnd < dateNow ?
                                                                                                                                                <Button
                                                                                                                                                    variant='contained'
                                                                                                                                                    color='secondary'
                                                                                                                                                    disabled={true}
                                                                                                                                                >Ticket Sale End
                                                                                                                                            </Button>
                                                                                                                                                :
                                                                                                                                                (data.ticket.length !== 0 &&
                                                                                                                                                    (data.ticket.filter(val => val.ticket_status_name === "pending").length + data.ticket.filter(val => val.ticket_status_name === "issued").length
                                                                                                                                                        >= data.seat_detail_quantity)) ?
                                                                                                                                                    <Button
                                                                                                                                                        variant='contained'
                                                                                                                                                        disabled={true}
                                                                                                                                                        style={{ color: "white" }}
                                                                                                                                                    >Ticket Sold Out
                                                                                                                                            </Button>
                                                                                                                                                    :

                                                                                                                                                    <Button
                                                                                                                                                        variant='contained'
                                                                                                                                                        color='inherit'
                                                                                                                                                        onClick={() => {
                                                                                                                                                            // if (localStorage.getItem('token')) {
                                                                                                                                                            if (ls.get('@_tknid').data) {
                                                                                                                                                                setDataSeat({
                                                                                                                                                                    ...dataSeat,
                                                                                                                                                                    dialog: true,
                                                                                                                                                                    seat_detail_name: data.seat_detail_name,
                                                                                                                                                                    seat_detail_price: data.seat_detail_price,
                                                                                                                                                                    seat_detail_datetime: data.seat_detail_datetime,
                                                                                                                                                                    seat_detail_id: data.seat_detail_id,
                                                                                                                                                                    seat_id: data.seat_id,
                                                                                                                                                                    seat_detail_maximum_purchased_quantity: data.seat_detail_maximum_purchased_quantity,
                                                                                                                                                                    seat_detail_quantity: data.seat_detail_quantity,
                                                                                                                                                                    ticket: data.ticket,
                                                                                                                                                                    type_price: data.ms_price.ms_price_identifier,
                                                                                                                                                                    type_seat: data.ms_seat.ms_seat_identifier,
                                                                                                                                                                    min_donation: data.seat_detail_minimum_donation_amount
                                                                                                                                                                })

                                                                                                                                                                let state = []
                                                                                                                                                                let tikettt = false

                                                                                                                                                                if (data.ms_seat.ms_seat_identifier === "RESERVED") {

                                                                                                                                                                    for (var i = 0; i < data.seat_detail_quantity; i++) {
                                                                                                                                                                        for (var j = 0; j < data.ticket.length; j++) {
                                                                                                                                                                            if (i === data.ticket[j].ticket_seat_number - 1 && data.ticket[j].ticket_status_name !== 'failed') {
                                                                                                                                                                                state.push({ seat_number: i + 1, color: '#d1d1d1', colorName: 'black' });
                                                                                                                                                                                tikettt = true
                                                                                                                                                                            }
                                                                                                                                                                        }
                                                                                                                                                                        if (!tikettt) {
                                                                                                                                                                            state.push({ seat_number: i + 1, color: '#fff', colorName: 'black' })
                                                                                                                                                                        }
                                                                                                                                                                        tikettt = false
                                                                                                                                                                    }
                                                                                                                                                                } else if (data.ms_seat.ms_seat_identifier === "UNRESERVED") {
                                                                                                                                                                    if (data.ticket.length !== 0) {
                                                                                                                                                                        for (var k = 0; k < data.ticket.length; k++) {
                                                                                                                                                                            if (data.ticket[k].ticket_status_name !== 'failed') {
                                                                                                                                                                                state.push({ tiket_name: data.ticket[k].ticket_status_name })
                                                                                                                                                                            }
                                                                                                                                                                        }
                                                                                                                                                                    }
                                                                                                                                                                }
                                                                                                                                                                setSeatTicket(state)
                                                                                                                                                            } else {
                                                                                                                                                                swal({
                                                                                                                                                                    title: "Login!",
                                                                                                                                                                    text: "Untuk membeli tiket ini anda harus login terlabih dahulu!",
                                                                                                                                                                    icon: "warning",
                                                                                                                                                                    buttons: true,
                                                                                                                                                                })
                                                                                                                                                                    .then((redirect) => {
                                                                                                                                                                        if (redirect) {
                                                                                                                                                                            window.location.href = "/login";
                                                                                                                                                                        }
                                                                                                                                                                    });
                                                                                                                                                            }
                                                                                                                                                        }}
                                                                                                                                                    >Select Ticket
                                                                                                                </Button>
                                                                                                                                    }

                                                                                                                                </Box>

                                                                                                                            </Box>
                                                                                                                        </Grid>
                                                                                                                    </Box>
                                                                                                                </Paper>
                                                                                                            </Box>
                                                                                                        ))}

                                                                                                    </Slider>
                                                                                                    :

                                                                                                    <>
                                                                                                        <Grid container direction='row' justify='center'>
                                                                                                            <Box textAlign="center" pt={4}>
                                                                                                                <img alt='nothing here' src={Tidaktersedia} width='50%' />
                                                                                                            </Box>
                                                                                                        </Grid>
                                                                                                        <Grid container direction='row' justify='center'>
                                                                                                            <Box id="fontStyle" pt={3}>Tidak ditemukan. Silakan cari di tanggal lain</Box>
                                                                                                        </Grid>
                                                                                                    </>
                                                                                            }
                                                                                        </>
                                                                                }
                                                                                <Box pt={5} pb={3} >
                                                                                    <Grid container spacing={3} >
                                                                                        <Grid item xs={12}>
                                                                                            <Box pt={3} pb={2} >
                                                                                                <TransformWrapper>
                                                                                                    {({ zoomIn, zoomOut, resetTransform }) => (
                                                                                                        <React.Fragment>
                                                                                                            <Grid
                                                                                                                display="flex"
                                                                                                                direction="column"
                                                                                                                style={{}}>
                                                                                                                <Box
                                                                                                                    display="flex"
                                                                                                                    justifyContent="center">
                                                                                                                    <div className="tools">
                                                                                                                        <button onClick={zoomIn}>+</button>
                                                                                                                        <button onClick={zoomOut}>-</button>
                                                                                                                        <button onClick={resetTransform}>x</button>
                                                                                                                    </div>
                                                                                                                </Box> <br />
                                                                                                                <Box
                                                                                                                    display="flex"
                                                                                                                    justifyContent="center"
                                                                                                                >
                                                                                                                    <TransformComponent>
                                                                                                                        <img
                                                                                                                            width="100%"
                                                                                                                            src={dataEvent.seat_image_url}
                                                                                                                            alt="Denah"
                                                                                                                        />
                                                                                                                    </TransformComponent>
                                                                                                                </Box>
                                                                                                            </Grid>
                                                                                                        </React.Fragment>
                                                                                                    )}
                                                                                                </TransformWrapper>
                                                                                            </Box>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </Box>
                                                                            </Box>
                                                                        </Paper>
                                                                    </Box>
                                                                </span>
                                                            ) : null
                                        }
                                    </Box>

                                </Grid>
                            </Grid>


                        </Box>

                        {/* discuss */}
                        <Box px={3} pt={6}>
                            <Box style={{ fontWeight: 'bold', fontSize: '16px', color: '#39364F' }} py={1}>Diskusi</Box>
                            <Disqus.DiscussionEmbed
                                shortname={disqusShortname}
                                // config={disqusConfig}
                                config={disqusConfig}
                            ></Disqus.DiscussionEmbed>
                        </Box>

                        {
                            organizer && organizer.organizer_whatsapp1 && organizer.organizer_whatsapp1 !== "null" ?

                                <Box>
                                    <a
                                        href={`https://api.whatsapp.com/send?phone=${organizer.organizer_whatsapp1}&text=Halo, saya ingin tahu lebih lanjut mengenai event ${dataEvent.event_name}`}
                                        className="whatsapp_float"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {/* <i class="fa fa-whatsapp whatsapp-icon"></i> */}
                                        <WAIcon style={{ color: 'white' }} fontSize="large" className="whatsapp-icon" />
                                    </a>
                                </Box>
                                : null
                        }

                    </Box>


                </Container >
                :
                <Container maxWidth='lg'>
                    <Box pt={10} pb={2}>
                        <Skeleton variant="rect" height={380} style={{ borderRadius: '10px' }} />
                    </Box>
                    <Box pb={4}>
                        <Skeleton variant="rect" height={180} style={{ borderRadius: '10px' }} />
                    </Box>
                    <Box pb={5}>
                        <Skeleton variant="rect" height={380} style={{ borderRadius: '10px' }} />
                    </Box>
                </Container>
            }

        </React.Fragment >
    )
}

export default withSize()(withRouter(SingleEventComponent));